import {
  faBuilding,
  faMapPin,
  faUser,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { getListExemptionConf } from '../../api/exemptionAPI';
import { getListOccupancyRecordReqConf } from '../../api/occupancyRecordAPI';
import { getUserCount } from '../../api/userAPI';
import { DashboardFilterView } from '../../components/dashboard-filter-view';
import DashBoardView from '../../components/dashboard-view';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { ChartHandle } from '../../model/statistic.model';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';
import { DashboardOccupancyView } from './dashboard-occupancy-view';
import { DashboardPaymentView } from './dashboard-payment-view';
import { DashboardStayView } from './dashboard-stay-view';
import { DashboardOccupancyByPlaceView } from './dashboard-occupancy-by-place-view';
import { getListPlaceConf } from '../../api/placeAPI';

export default function DashboardPage() {
  const { loading, fetchData } = useRequest();
  const [countFacilities, setCountFacilities] = useState<any>();
  const [exemptions, setExemptions] = useState<any>();
  const [places, setPlaces] = useState<any>([]);
  const [countUsers, setCountUsers] = useState<any>();
  const [countSolicitors, setCountSolicitors] = useState<any>();
  const [countRequest, setCountRequest] = useState<any>();
  const [countOccupancies, setCountOccupancies] = useState<any>();
  const { showModal } = useAppUtilContext();
  const dashboardPaymentViewRef = useRef<ChartHandle>(null);
  const dashboardOccupancyViewRef = useRef<ChartHandle>(null);
  const dashboardStayViewRef = useRef<ChartHandle>(null);
  const dashboardOccupancyByPlaceViewRef = useRef<ChartHandle>(null);

  const [data, setData] = useState<any>();

  const onSuccessOccupancy = (data: any, headers: any) => {
    let countFa = Number(headers.get('X-Total-Count'));
    setCountOccupancies(countFa);
  };

  const onSuccessCountUser = (data: any, headers: any) => {
    setCountUsers(data.users);
    setCountSolicitors(data.solicitors);
    setCountFacilities(data.facilities);
    setData(data);
  };
  const onSuccessExemption = (data: any, headers: any) => {
    setExemptions(data);
  };

  const onSuccessPlace = (data: any, headers: any) => {
    setPlaces(data);
  };

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const refresh = () => {
    fetchData(getListExemptionConf(), onSuccessExemption, onErrors);
    fetchData(getListPlaceConf(), onSuccessPlace, onErrors);
    fetchData(getUserCount(), onSuccessCountUser, onErrors);
    fetchData(getListOccupancyRecordReqConf(), onSuccessOccupancy, onErrors);
  };

  useEffect(() => {
    refresh();
  }, []);

  const onFilterChange = (data: any) => {
    dashboardPaymentViewRef.current?.refresh(data);
    dashboardStayViewRef.current?.refresh(data);
    dashboardOccupancyViewRef.current?.refresh(data);
    dashboardOccupancyByPlaceViewRef.current?.refresh(data);
  };
  let stayByPlaces = data ? data.stayByPlaces : [];

  let activeFacilities = data ? data.activeFacilities : [];
  let allFacilities = data ? data.allFacilities : [];

  let userCount = stayByPlaces.reduce(
    (accumulator: number, currentObject: any) => {
      return accumulator + currentObject.count;
    },
    0
  );

  let occupancyByPlace = data ? data.occupancyByPlace : [];
  let occupancyCount = occupancyByPlace.reduce(
    (accumulator: number, currentObject: any) => {
      return accumulator + currentObject.count;
    },
    0
  );
  return (
    <div className="container dashboard-container">
      <div className="header-container">
        <h2 className="page-title">Dashboard</h2>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
          <DashBoardView
            color="#FFC107"
            icon={faBuilding}
            title={countFacilities}
            subtitle={t('dashboard.facilities')}
          ></DashBoardView>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
          <DashBoardView
            color="#30D5C8"
            icon={faUser}
            title={userCount}
            subtitle={'Turisti che hanno effetuato almeno una prenotazione'}
          ></DashBoardView>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
          <DashBoardView
            color="#804cdc"
            icon={faMapPin}
            title={occupancyCount}
            subtitle={'Presenze'}
          ></DashBoardView>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
          <div className="mt-2 row">
            <Table striped responsive>
              <thead>
                <tr style={{ whiteSpace: 'break-spaces' }}>
                  <th>{t('graphs.facilities')}</th>
                  <th>{t('graphs.active')}</th>
                  <th>{t('graphs.all')}</th>
                </tr>
              </thead>
              <tbody>
                {places.map((it: any, idx: number) => {
                  return (
                    <tr key={it.id}>
                      <td>{it.name}</td>
                      <td>
                        {
                          activeFacilities.find(
                            (it2: any) => it2.placeId === it.id
                          )?.count
                        }
                      </td>
                      <td>
                        {
                          allFacilities.find(
                            (it2: any) => it2.placeId === it.id
                          )?.count
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
          <div className="mt-2 row">
            <Table striped responsive>
              <thead>
                <tr style={{ whiteSpace: 'break-spaces' }}>
                  <th>{t('graphs.place')}</th>
                  <th>{t('graphs.users')}</th>
                </tr>
              </thead>
              <tbody>
                {places.map((it: any, idx: number) => {
                  return (
                    <tr key={it.id}>
                      <td>{it.name}</td>
                      <td>
                        {
                          stayByPlaces.find((it2: any) => it2.placeId === it.id)
                            ?.count
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
          <div className="mt-2 row">
            <Table striped responsive>
              <thead>
                <tr style={{ whiteSpace: 'break-spaces' }}>
                  <th>{t('graphs.place')}</th>
                  <th>{t('graphs.presences')}</th>
                </tr>
              </thead>
              <tbody>
                {places.map((it: any, idx: number) => {
                  return (
                    <tr key={it.id}>
                      <td>{it.name}</td>
                      <td>
                        {
                          occupancyByPlace.find(
                            (it2: any) => it2.placeId === it.id
                          )?.count
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="col-12 mt-2">
          <Card>
            <CardHeader>Filtri</CardHeader>
            <CardBody>
              <DashboardFilterView
                onFilterChange={onFilterChange}
              ></DashboardFilterView>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 mt-2">
          <Card>
            <CardBody>
              <DashboardPaymentView
                ref={dashboardPaymentViewRef}
              ></DashboardPaymentView>
            </CardBody>
          </Card>
        </div>
        <div className="col-12 mt-2">
          <Card>
            <CardBody>
              <DashboardStayView
                exemptions={exemptions ? exemptions : []}
                ref={dashboardStayViewRef}
              ></DashboardStayView>
            </CardBody>
          </Card>
        </div>

        <div className="col-12 mt-2">
          <Card>
            <CardBody>
              <DashboardOccupancyView
                exemptions={exemptions ? exemptions : []}
                ref={dashboardOccupancyViewRef}
              ></DashboardOccupancyView>
            </CardBody>
          </Card>
        </div>

        <div className="col-12 mt-2">
          <Card>
            <CardBody>
              <DashboardOccupancyByPlaceView
                places={places ? places : []}
                ref={dashboardOccupancyByPlaceViewRef}
              ></DashboardOccupancyByPlaceView>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}
