import { FacilityRecordRequestStatuses } from './facility-record-request.model';
import { DetailFacility } from './facility.model';
import { CreateStayGuest } from './stay-guest.model';
import { DetailUser } from './user.model';
export enum StayGuestTypes {
  ADULT = 'ADULT',
  CHILD = 'CHILD',
  OVER_65 = 'OVER_65',
}

export interface CreateStay {
  fromDate: string;
  toDate: string;
  userId: number;
  facilityId: number;
  status: FacilityRecordRequestStatuses;
  guests: guestEntity[];
}
export enum StayStatuses {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface guestEntity {
  firstName: string;
  lastName: string;
  email: string;
  typology: StayGuestTypes;
  hasHandicapp: boolean;
}

export interface DetailStay {
  id?: number;
  fromDate: string;
  toDate: string;
  userId: number;
  user: DetailUser;
  facilityId: number;
  facility: DetailFacility;
  status: StayStatuses;
  guests: DetailStayGuest[];
}

export interface DetailStayGuest {
  name: string;
  count: number;
}