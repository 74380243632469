import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconButtonWithTooltip from "../../components/icon-button-with-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faBaby, faEye, faClock, faTicket, faCalendar, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { SERVER_API_URL } from "../../axios/config";
import "./events_page.css";
import MobileButton from "../../components/mobile-button";
import { getDateDDMMYYYY, getMondayOfWeek, getSundayOfWeek, getWeekNumber, italianDateFormat } from "../../util/date.util";
import EventsDisplay from "./events_display";
import { DetailEvent } from "../../model/event.model";

const EventsItem = ({ events, anno } : any) => {
  const location = useLocation();
  const today = new Date();
  const dateString = today.toISOString();
  const thisWeek  = getWeekNumber(dateString)
  const [eventsData, setEventsData] = useState({ weeks: [] as any[], events: [] });

  useEffect(() => {
   let weeksArray : any[] = []
   let es = events.sort((a:any, b:any) => {
    const dateA = new Date(a.eventStartDate);
    const dateB = new Date(b.eventStartDate);
    return dateA.getTime() - dateB.getTime();
   }).map((ev: DetailEvent) => {
      let w = null
      const weekStart  = getWeekNumber(ev.eventStartDate)
      const weekEnd  = getWeekNumber(ev.eventEndDate)

      if(weekStart !== weekEnd && weekStart<= thisWeek && weekEnd >= thisWeek){
        w = thisWeek
      } else {
        w = weekStart
      }
 
      if(!weeksArray.includes(w))
        weeksArray.push(w)

      return { ...ev, ...{ week : w } }
   })

   setEventsData({ weeks: weeksArray.sort(function(a, b) {   return a - b; }), events: es})
  }, [events]);

  return (
    <div className="container ">
      {eventsData.weeks.map((num, index) => {
          let lunedi = getMondayOfWeek(anno, num-1)
          let domenica = getSundayOfWeek(anno, num-1)
          let eventiSettimanali = eventsData.events.filter((ev: any )=> ev.week? ev.week == num : 0)
 
        return <div key={index} >
          
      <h2  style={{marginTop: "2rem" }}>{t("general.date.settimana", { start: getDateDDMMYYYY(lunedi), end: getDateDDMMYYYY(domenica)})}</h2> 
      <div className="row ">
        {eventiSettimanali.map((event:any) => 
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={event.id}>
              <EventsDisplay event={event} isPrevious={false} />
            </div>
        )}
        </div>
        <hr />
        </div>
      }) }
    </div>
  );
};

export default EventsItem;
