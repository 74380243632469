import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import FacilityTypeListPage from './facility_type-list.page';
import FacilityTypeDetailPage from './facility_type-detail.page';
import FacilityTypeUpdatePage from './facility_type-update.page';
import FacilityTypeCreatePage from './facility-type-create.page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  currentUserSelector,
  Authorities,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

const FacilityTypeRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      ) && (
        <>
          <Route path="" element={<FacilityTypeListPage />} />
          <Route path=":id" element={<FacilityTypeDetailPage />} />
          <Route path="create" element={<FacilityTypeCreatePage />} />
          <Route path=":id/update" element={<FacilityTypeUpdatePage />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default FacilityTypeRoutes;
