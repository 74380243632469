import {
  ResetPasswordCodeReq,
  ResetPasswordReq,
  SigninReq,
  SignupReq,
  UpdateReq,
} from '../model/user.model';
import { defaultHeaders } from './common';

export const getSigninConf = (signinReq: SigninReq) => {
  return {
    method: 'post',
    url: '/api/auth/signin',
    headers: defaultHeaders,
    data: JSON.stringify(signinReq),
  };
};
export const updateUserInfo = (signinReq: UpdateReq) => {
  return {
    method: 'post',
    url: '/api/auth/user-edit',
    headers: defaultHeaders,
    data: JSON.stringify(signinReq),
  };
};

export const geCurrentUserConf = () => {
  return {
    method: 'get',
    url: '/api/auth/current-user',
    headers: defaultHeaders,
  };
};

export const getUserCount = () => {  
  return {
    method: 'get',
    url: '/api/auth/count',
    headers: defaultHeaders,
  };
};

export const getResetPasswordConf = (resetPasswordReq: ResetPasswordReq) => {
  let data = JSON.stringify(resetPasswordReq);
  return {
    method: 'post',
    url: '/api/auth/reset-password',
    headers: defaultHeaders,
    data: data,
  };
};

export const getResetPasswordCodeConf = (
  resetPasswordCodeReq: ResetPasswordCodeReq
) => {
  let data = JSON.stringify(resetPasswordCodeReq);
  return {
    method: 'post',
    url: '/api/auth/reset-password-code',
    headers: defaultHeaders,
    data: data,
  };
};

export const getSignupConf = (signupReq: SignupReq) => {
  return {
    method: 'post',
    url: '/api/auth/signup',
    headers: defaultHeaders,
    data: JSON.stringify(signupReq),
  };
};

export const getActivateConf = (code: string) => {
  let data = JSON.stringify({ code: code });
  return {
    method: 'post',
    url: '/api/auth/activate',
    headers: defaultHeaders,
    data: data,
  };
};

