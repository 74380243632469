// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Remove border from the entire calendar */
.react-calendar {
  border: none !important;
}

/* Remove borders from individual elements */
.react-calendar__tile {
  border: none;
}

.react-calendar__month-view__days__day {
  border: none;
}

.react-calendar__navigation {
  border: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border: none;
  background: none; /* Optionally remove background on hover/focus */
}
/* Define the dot style */
.dot {
  height: 6px;
  width: 6px;
  background-color: #004C6C; /* Change color as needed */
  border-radius: 50%; /* Make it round */
/*display: inline-block;  Ensure it's displayed inline */
}`, "",{"version":3,"sources":["webpack://./src/css/calendar.style.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;EACE,uBAAuB;AACzB;;AAEA,4CAA4C;AAC5C;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,gBAAgB,EAAE,gDAAgD;AACpE;AACA,yBAAyB;AACzB;EACE,WAAW;EACX,UAAU;EACV,yBAAyB,EAAE,2BAA2B;EACtD,kBAAkB,EAAE,kBAAkB;AACxC,wDAAwD;AACxD","sourcesContent":["/* Remove border from the entire calendar */\n.react-calendar {\n  border: none !important;\n}\n\n/* Remove borders from individual elements */\n.react-calendar__tile {\n  border: none;\n}\n\n.react-calendar__month-view__days__day {\n  border: none;\n}\n\n.react-calendar__navigation {\n  border: none;\n}\n\n.react-calendar__tile:enabled:hover,\n.react-calendar__tile:enabled:focus {\n  border: none;\n  background: none; /* Optionally remove background on hover/focus */\n}\n/* Define the dot style */\n.dot {\n  height: 6px;\n  width: 6px;\n  background-color: #004C6C; /* Change color as needed */\n  border-radius: 50%; /* Make it round */\n/*display: inline-block;  Ensure it's displayed inline */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
