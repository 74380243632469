import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { getDetailSolicitorConf } from '../../api/solicitorAPI';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { DetailSolicitor } from '../../model/solicitor.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { SolicitorForm } from './solicitor.form';

export default function SolicitorUpdatePage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData: fetchDataDetail } = useRequest<DetailSolicitor>();
  const [entity, setEntity] = useState<DetailSolicitor>();
  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailSolicitorConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const onSuccessDetail = (data: any) => {
    setEntity(data[0]);
  };

  const onErrorsDetail = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/solicitor');
  };

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/solicitor'}>{t('solicitor.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('solicitor.edit.title')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('solicitor.edit.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <SolicitorForm
            initialData={entity}
            enabled={true}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></SolicitorForm>
        </CardBody>
      </Card>
    </div>
  );
}
