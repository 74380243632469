import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { updateUserInfo } from '../../api/userAPI';
import useRequest from '../../hook/use-request.hook';
import {
  currentUserSelector,
  currentUserSuccess,
  signinSuccess,
} from '../../redux/reducers/userSlice';
import { AppThunkDispatch, useAppSelector } from '../../redux/store';

import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAppUtilContext } from '../../util/app-util.context';
import { registerRs } from '../../util/form.util';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DetailPage() {
  const dispatch = useDispatch() as AppThunkDispatch;
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();
  const currentUser = useAppSelector(currentUserSelector);

  const onSuccess = (data: any) => {
    // navigate('/');
    dispatch(currentUserSuccess(data));
    showModal(t('modal.request_success'), t('userDetail.success_edit'), null);
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_failed'), errorsView, null);
  };

  const onSubmit = (data: any) => {
    const { firstName, lastName } = data;
    fetchData(
      updateUserInfo({ firstName, lastName, id: currentUser?.id }),
      onSuccess,
      onErrors
    );
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <div className="container mt-2">
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: '12px' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
        {t('general.buttons.back')}
      </Button>
      <Card>
        <CardBody>
          <CardTitle tag="h5">{t('userDetail.pageEdit')}</CardTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="form-field">
              
              <Input
                id="firstName"
                name="firstName"
                type="text"
                defaultValue={currentUser?.firstName}
                placeholder={t('register.first_name_ph')}
                {...registerRs(register, 'firstName', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.firstName && errors.firstName != null}
              /><Label for="firstName">{t('register.first_name')}</Label>
              <FormFeedback> {'' + errors?.firstName?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
            
              <Input
                id="lastName"
                name="lastName"
                type="text"
                defaultValue={currentUser?.lastName}
                placeholder={t('register.last_name_ph')}
                {...registerRs(register, 'lastName', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.lastName && errors.lastName != null}
              />  <Label for="lastName">{t('register.last_name')}</Label>
              <FormFeedback>{'' + errors?.lastName?.message}</FormFeedback>
            </FormGroup>

            <Button type="submit" disabled={loading}>
              {!loading ? (
                t('action.send')
              ) : (
                <>
                  <Spinner size="sm">Loading...</Spinner>
                  <span> {t('action.loading')}</span>
                </>
              )}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
