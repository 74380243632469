import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";
import i18n from "../i18n";
import {
  Authorities,
  changeUserLanguage,
  currentUserSelector,
  logout,
  userAccessTokenSelector,
} from "../redux/reducers/userSlice";
import { AppThunkDispatch, useAppSelector } from "../redux/store";
import { useState } from "react";
import { faBars, faXmark, faUser, faGauge, faBell, faUserTie, faBuilding, faLandmark, faList, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppMenu from "./menu";
import SidebarFooter from "./sidebar-footer";
import { checkRequiredAuthorities } from "../components/authority-boundary-view";

export default function AppFooter() {
  return (
    <>
       <footer   style={{ marginTop: '40px'  }} id="apus-footer" className="apus-footer" role="contentinfo">
        <div className="footer-builder-wrapper purple">
          <div className="container">
            <div
              data-vc-full-width="true"
              data-vc-full-width-init="true"
              className="row wpb_row row-fluid custom_1591787784344 row-has-fill row-o-content-bottom row-flex"
              style={{ paddingLeft: "23.6667px", paddingRight: "23.3333px" }}
            >
              <div className="wpb_column column_container col-sm-12">
                <div className="column-inner custom_1523506394552">
                  <div className="wpb_wrapper">
                    <div className="wp_text wpb_content_element">
                      <div className="widget widget_text">
                        <div className="textwidget">
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row-full-width clearfix"></div>
            <div className="row wpb_row row-fluid">
              <div className="wpb_column column_container col-sm-12">
                <div className="column-inner">
                  <div className="wpb_wrapper">
                    <div
                      className="empty_space  hidden-xs hidden-sm"
                      style={{ height: "50px" }}
                    >
                      <span className="empty_space_inner"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="column-inner">
                  <div className="wpb_wrapper">
                    <div className="wpb_single_image wpb_content_element align_left wpb_content_element custom_1562222625763">
                      <figure className="wpb_wrapper figure">
                        <div className="single_image-wrapper   box_border_grey">
                          <img
                            className="single_image-img "
                            src="https://www.visitmelendugno.com/wp-content/uploads/2019/07/logo-comune-di-melendugno-70x100.png"
                            width="70"
                            height="100"
                            alt="logo-comune-di-melendugno"
                            title="logo-comune-di-melendugno"
                            loading="lazy"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="wpb_text_column wpb_content_element">
                      <div className="wpb_wrapper">
                        <p>
                          <span style={{ color: "#fff" }}>
                            <strong>COMUNE DI MELENDUGNO</strong>
                            <br />
                            Piazza Risorgimento, 24
                            <br />
                            73026 Melendugno (LE)
                            <br />
                            Tel. 0832.832111
                            <br />
                            Fax 0832.832545
                          </span>
                          <br />
                          <span style={{ color: "#fff" }}>
                            <br />
                            C.F. 80010060756
                            <br />
                            pec: comune.melendugno@legalmail.it
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row wpb_row row-fluid">
              <div className="wpb_column column_container col-sm-12">
                <div className="column-inner">
                  <div className="wpb_wrapper">
                    <div
                      className="empty_space  hidden-xs hidden-sm"
                      style={{ height: "40px" }}
                    >
                      <span className="empty_space_inner"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
