import { CreateCompany, UpdateCompany } from '../model/company.model';
import { defaultHeaders } from './common';

export const getCreatCompanyConf = (company: CreateCompany) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/company',
    headers: defaultHeaders,
    data: JSON.stringify(company),
  };
};
export const getUpdateCompanyConf = (company: UpdateCompany, id: number) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/company/' + id,
    headers: defaultHeaders,
    data: JSON.stringify(company),
  };
};

export const getListCompanyPagedConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url: '/api/company?page=' + page + '&size=' + size + query,
    headers: defaultHeaders,
  };
};
export const getListCompanyConf = () => {
  return {
    method: 'get',
    url: '/api/company',
    headers: defaultHeaders,
  };
};

export const getDetailCompanyConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/company/${id}`,
    headers: defaultHeaders,
  };
};

export const getDetailCompanyConfByEmail = () => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/company/mydetail`,
    headers: defaultHeaders,
  };
};
