import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Form, FormFeedback, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { getSignupConf } from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from "../../util/app-util.context";
import { registerRs } from "../../util/form.util";
import { NotificationTypes } from "../../util/app-util.context";

export default function RegisterPage() {
    const { t } = useTranslation();
    const { showModal } = useAppUtilContext();
    const { showNotification } = useAppUtilContext();

    const navigate = useNavigate();
    const { loading, fetchData } = useRequest();

    const onSuccess = (data: any) => {
        let message = data.message;
        showModal(t('modal.request_success'), message, null);
        navigate('/');
    }

    const onErrors = (errors: any) => {
        console.log('errors', errors);
        showNotification(errors[0].message, NotificationTypes.ERROR); // Shows first error message in errors array (missing passwords, etc.)
    }

    const onSubmit = (data: any) => {
        const { email, password, firstName, lastName } = data;

        fetchData(getSignupConf({ email, password, firstName, lastName }), onSuccess, onErrors);
    };

    const { register, formState: { errors }, handleSubmit, watch } = useForm();

    const password = watch("password");

    return (
        <div className="container mt-2" >
            <Card >
                <CardBody>
                    <CardTitle tag="h5">
                        {t('register.title')}
                    </CardTitle>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup className="form-field">
                            
                            <Input
                                id="firstName"
                                name="firstName"
                                type="text"
                                placeholder={t('register.first_name_ph')}
                                {...registerRs(register, "firstName", {
                                    required: t(`general.errors.required`),
                                })}
                                invalid={errors.firstName && errors.firstName != null}
                            /><Label for="firstName">
                                {t('register.first_name')}
                            </Label>
                            <FormFeedback>
                                {'' + errors?.firstName?.message}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup className="form-field">
                           
                            <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                placeholder={t('register.last_name_ph')}
                                {...registerRs(register, "lastName", {
                                    required: t(`general.errors.required`),
                                })}
                                invalid={errors.lastName && errors.lastName != null}
                            /> <Label for="lastName">
                                {t('register.last_name')}
                            </Label>
                            <FormFeedback>
                                {'' + errors?.lastName?.message}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup className="form-field">
                           
                            <Input
                                id="email"
                                name="email"
                                type="text"
                                placeholder={t('login.username_ph')}
                                {...registerRs(register, "email", {
                                    required: t(`general.errors.required`),
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: t(`general.errors.invalid_email`),
                                    }
                                })}
                                invalid={errors.email && errors.email != null}
                            /> <Label for="email">
                                {t('login.username')}
                            </Label>
                            <FormFeedback>
                                {'' + errors?.email?.message}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup className="form-field">
                         
                            <Input
                                id="password"
                                name="password"
                                placeholder={t('login.password_ph')}
                                type="password"
                                {...registerRs(register, "password", {
                                    required: t(`general.errors.required`),
                                })}
                                invalid={errors.password && errors.password != null}
                            />   <Label for="password">
                                {t('login.password')}
                            </Label>
                            <FormFeedback>
                                {'' + errors?.password?.message}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup className="form-field">
                          
                            <Input
                                id="confirm-password"
                                name="confirm-password"
                                placeholder={t('login.confirm_password_ph')}
                                type="password"
                                {...registerRs(register, "confirm-password", {
                                    required: t(`general.errors.required`),
                                    validate: (value: any) =>
                                        value === password || t('general.errors.passwords_do_not_match') // TODO: i18n
                                })}
                                invalid={errors["confirm-password"] && errors["confirm-password"] != null}
                            />  <Label for="confirm-password">
                                {t('login.confirm_password')}
                            </Label>
                            <FormFeedback>
                                {'' + errors?.["confirm-password"]?.message}
                            </FormFeedback>
                        </FormGroup>
                        <Button type='submit' disabled={loading}>
                            {!loading ? t('action.send') :
                                <><Spinner size="sm">
                                    Loading...
                                </Spinner>
                                    <span>
                                        {' '}   {t('action.loading')}
                                    </span></>}
                        </Button>
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};
