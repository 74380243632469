import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { Badge, Table } from 'reactstrap';
import { getConfirmpPaymentConf } from '../../api/paymentAPI';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import { ListPayment } from '../../model/payment.model';

interface PaymentTableProps {
  list?: ListPayment[];
  refresh?: Function;
}

const PaymentTable = (props: PaymentTableProps) => {
  const { list, refresh } = props;
  const { loading, fetchData } = useRequest();

  const giveStatus = (it: any) => {
    return it == 'CONFIRMED' ? (
      <Badge color="success">{it}</Badge>
    ) : it == 'CANCELLED' ? (
      <Badge color="danger">{it}</Badge>
    ) : (
      <Badge className="text-dark" color="light">
        {it}
      </Badge>
    );
  };

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th style={{ width: '5%' }}>{t('payment.id')}</th>
          <th style={{ width: '10%' }}>{t('payment.data')}</th>
          <th>{t('payment.facility')}</th>
          <th>{t('payment.message')}</th>
          <th style={{ width: '10%' }}>{t('payment.amount')}</th>
          {/* <th style={{ width: '6%' }}>{t('payment.taxValueProGuest')}</th> */}
          <th style={{ width: '10%' }}>{t('payment.status')}</th>
          {refresh && <th style={{ width: '15%' }} />}
        </tr>
      </thead>
      <tbody>
        {list &&
          list.map((it, idx) => {
            return (
              <tr>
                <td data-label={t('payment.id')}>{it.id}</td>
                <td data-label={t('payment.data')}>
                  {it.month + 1}/{it.year}
                </td>
                <td data-label={t('payment.facility')}>{it.facility?.name}</td>
                <td data-label={t('payment.message')} title={it.message}>
                  {it.message}
                </td>
                <td data-label={t('payment.amount')}>
                  {it.amount ? (it.amount / 100).toFixed(2) : 0} €
                </td>
                {/* <td data-label={t('payment.taxValueProGuest')}>
                  {it.taxValueProGuest ? it.taxValueProGuest.toFixed(2) : 0} €
                </td> */}
                <td data-label={t('payment.status')}>
                  {giveStatus(it.status)}
                </td>
                {refresh && (
                  <td style={{ whiteSpace: 'break-spaces' }}>
                    <MobileButton
                      inTable={true}
                      icon={faReceipt}
                      color={'success'}
                      disabled={it.status == 'CONFIRMED'}
                      size="sm"
                      text={
                        it.status == 'CONFIRMED'
                          ? t('payment.pagato')
                          : t('payment.contrassegnapagato')
                      }
                      id={'confirm' + it.id}
                      onClick={() => {
                        fetchData(
                          getConfirmpPaymentConf(it.id),
                          (data: any) => {
                            refresh();
                          },
                          (data: any) => {}
                        );
                      }}
                    ></MobileButton>
                  </td>
                )}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default PaymentTable;
