import { t } from 'i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';

import { useEffect, useMemo, useState } from 'react';
import { getDetailFacilityTypeConf } from '../../api/facilityTypeAPI';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { DetailCompany } from '../../model/company.model';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import FacilityTypeForm from './facility_type.form';
import { isValidJSON, splitCampi } from '../../util/form.util';
import { DetailFacilityType } from '../../model/facility-type.model';

export default function FacilityTypeUpdatePage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData } = useRequest<DetailFacilityType>();
  const [entity, setEntity] = useState<DetailFacilityType>();

  useEffect(() => {
    if (id) {
      fetchData(getDetailFacilityTypeConf(id), onSuccessDetail, onErrorsDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailFacilityType) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    let message = data.message
      ? data.message
      : 'La struttura è stata modificata';

    showModal(t('modal.request_success'), message, null);
    navigate('/facility-type' );
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };


  const data = useMemo(() => {
    let splittedData = entity;
    if(entity !== undefined && splittedData !== undefined){
      splittedData = splitCampi(entity, ['name']) 
    }
  
    return splittedData
  }, [entity])

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility-type'}>{t('facility-type.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t('facility-type.update.title')}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('facility-type.edit.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <FacilityTypeForm
            initialData={data}
            enabled={true}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></FacilityTypeForm>
        </CardBody>
      </Card>
    </div>
  );
}
