import {
  faEnvelope,
  faEye,
  faMapLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { DetailFacility } from '../model/facility.model';
import './facility-view.css';
import IconButtonWithTooltip from './icon-button-with-tooltip';
import { t } from 'i18next';
interface FacilityViewProps {
  facility: DetailFacility;
  onDetailClick: (facility: DetailFacility) => void;
}
const FacilityRequestView = (props: FacilityViewProps) => {
  const { facility, onDetailClick } = props;

  return (
    <Card>
      <CardHeader>
        <IconButtonWithTooltip
          id={`view-${facility.id}`}
          icon={faEye}
          color={'primary'}
          title={t('general.buttons.view')}
          style={{ float: 'right' }}
          onClick={() => onDetailClick(facility)}
        ></IconButtonWithTooltip>
        <span>{facility.name}</span>
      </CardHeader>
      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon icon={faMapLocationDot} />
              </td>
              <td> {`${facility.address}, ${facility.city} `}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faEnvelope} />
              </td>
              <td> {`${facility.email} `}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default FacilityRequestView;
