/* eslint-disable react/jsx-no-undef */
import "../home/home.css";
import EventSlideShow  from "../events/events_carousel";
import { imagesVisite } from "../events/imagesData";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { userLngSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";

export default function VisiteEntroterra() {
  const navigate = useNavigate();
  const lang = useAppSelector(userLngSelector);
 
  return (
    <>
     
      <EventSlideShow caption={<h5>{lang === 'it' ? 'Le nostre visite guidate' : 'Our guided tours'}</h5>} name="eventi" images={imagesVisite} />
      <div className="container pt-2 mt-2 mb-5">
       
   <Button
            color="trasparent"
            onClick={() => {
              navigate(-1);
            }}
            style={{ marginRight: '12px' }}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
            {t('general.buttons.back')}
          </Button>
        <div className="row">
          
          <div className="container pt-2 mt-2 mb-5" style={{ textAlign: "center", fontSize: "18px" }} >
       
            <h1 className="page-title">{lang === 'it' ? 'Roca e Grotte della Poesia' : 'Roca and Caves of Poetry '}</h1>
          {lang === 'it'  ? (
              <div className="clearfix">
              <p style={{fontSize: "22px"}}>
                <br/>
                <strong>Grotta della Poesia</strong>: dalle 9:30 alle 19:30.
                <br />
                <strong>Roca Vecchia</strong>:* dalle ore 15:30 alle ore 19:30.
                <br /><br />
                L'ingresso prevede il pagamento di un 
                <strong> ticket di soli 2€ </strong> 
                  per i possessori della Melendugno Card.
              </p>
              <p style={{fontSize: "16px"}}>
              *	i giorni di apertura del sito di Roca Vecchia sono: giugno / sabato, domenica e festivi; luglio / lunedì, mercoledì, sabato; agosto / tutti i giorni. È sempre possibile prenotare una visita per un gruppo minimo di 5 persone, contattando il 371.3424134 almeno 24h prima della data desiderata.

                {/* The opening days of the Roca Vecchia site are: June / Saturday, Sunday, and holidays; July / Monday, Wednesday, Saturday; August / every day. It is always possible to book a visit for a minimum group of 5 people by contacting +39 371.3424134.
              */}
              </p>
            </div>
          ) : (
            <div className="clearfix">
            <p style={{fontSize: "22px"}}>
              {/* <strong>Giugno</strong> */}
              <br/>
              <strong>Cave of Poetry</strong>: from 9.30 a.m. to 7.30 p.m.
              <br />
              <strong>Roca Vecchia</strong>:* from 15:30 to 19:30.
              <br /><br />
              {/* <strong>Luglio</strong> */}
              {/* <br/>
              Grotta della Poesia: dalle 9:30 alle 19:30.
              <br />
              Roca Vecchia: Lunedì-Mercoledì-Sabato. Dalle ore 15:30 alle ore 19:30.
              <br /><br /> */}
              {/* <strong>Agosto</strong> */}
              {/* <br/>
              Grotta della Poesia: dalle 9:30 alle 19:30.
              <br />
              Roca Vecchia: Tutti i giorni. Dalle ore 15:30 alle ore 19:30. */}
             
             Admission is subject to payment of a 
              <strong> ticket only 2€ </strong> 
              for Melendugno Card holders.
            </p>
            <p style={{fontSize: "16px"}}>
            *	the opening days of the Roca Vecchia site are: June / Saturdays, Sundays and public holidays; July / Mondays, Wednesdays, Saturdays; August / every day. It is always possible to book a visit for a minimum group of 5 people by contacting 371.3424134 at least 24 hours before the desired date.
              {/* The opening days of the Roca Vecchia site are: June / Saturday, Sunday, and holidays; July / Monday, Wednesday, Saturday; August / every day. It is always possible to book a visit for a minimum group of 5 people by contacting +39 371.3424134.
            */}
            </p>
          </div>
          )}
            
          </div>
     
        </div>
      </div>
    </>
  );
}
