import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const EventSlideShow = ({ images, caption }:any) => {
    return (
        // <div>
        <div className="events-page-main">            
                <div className="event-slideshow">
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval={3000} data-bs-pause="hover" >
                      {images.length > 1 ? <div className="carousel-indicators">
                            {images.map((it :any, index :any) => (
                                <button
                                    key={index}
                                    type="button"
                                    data-bs-target="#carouselExampleIndicators"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? 'active' : ''}
                                    aria-current={index === 0 ? 'true' : 'false'}
                                    aria-label={`Slide ${index + 1}`}
                                ></button>
                            ))}
                        </div> : ''}
                        <div className="carousel-inner">
                            {images.map((image :any, index :any) => (
                                 <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                 <img src={image.src} alt={`Slide ${index + 1}`} />
                                 <div className="carousel-caption" style={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                                 {caption}
                                 </div>
                             </div>
                            ))}
                        </div>
                       {images.length > 1 && <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button> }
                        {images.length > 1 &&  <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button> }
                    </div>
                </div>
           
        </div>
    );
};

export default EventSlideShow;
