import { faEye, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { icons, splitCampi } from '../../util/form.util';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup, Spinner, Table } from 'reactstrap';
import { getListFacilityTypePagedConf } from '../../api/facilityTypeAPI';
import { ErrorsView } from '../../components/errors.view';
import IconButtonWithTooltip from '../../components/icon-button-with-tooltip';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { DetailCompany } from '../../model/company.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { MobileTable } from '../../components/mobile-table';

export default function FacilityTypeListPage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailCompany[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const refresh = (pagination: any) => {
    fetchData(
      getListFacilityTypePagedConf(pagination.page, pagination.pageSize),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pagination,
          ...{ totalElements: parseInt(header['x-total-count'], 10) },
        });
      },
      onErrors
    );
  };
  const create = () => {
    console.log('click');
    navigate('create');
  };

  useEffect(() => {
    refresh(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-2">
      <div className="header-container">
        <h2>{t('facility-type.list.title')}</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <MobileButton
            onClick={create}
            disabled={loading}
            color="success"
            icon={faPlus}
            text={t('general.buttons.add')}
          />
          <MobileButton
            onClick={refresh}
            disabled={loading}
            color="secondary"
            outline
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className="d-none d-md-inline">
                  &nbsp; {t('general.buttons.refresh')}
                </span>
              </>
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span className="d-none d-md-inline">
                  {' '}
                  {t('action.loading')}{' '}
                </span>
              </>
            )}
          </MobileButton>
        </div>
      </div>
      <div className="mt-2 row">
            <MobileTable 
              columns={[
                {
                  Header: t('facility-type.id'),
                  accessor: 'id',
                  props: { width : '10%'}
                },
                {
                  Header: '',
                  props: { width : '10%'},
                  render: (it:any) => {
                    return  <FontAwesomeIcon
                    icon={icons.get(it.icon)}
                  />
                  }
                },
                {
                  Header: t('facility-type.name') + ' IT',
                  accessor: 'name.it'
                },
                {
                  Header: t('facility-type.name')+ ' EN',
                  accessor: 'name.en'
                }
              ]}
              data={list.map(ent => splitCampi(ent, ['name']))}
             
              goToedit={(it: DetailCompany) => navigate(`/facility-type/${it.id}/update`)}
              goTodetailProps={{ width : '50px'}}
              />
      </div>
      <div>
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            console.log('change page', n);
            refresh({ ...pagination, ...{ page: n } });
          }}
          onChange={(n) => {
            console.log('change page', n);
            refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
