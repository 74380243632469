import { CreateOccupancyRecord } from "../model/occupancy-record.model";
import { defaultHeaders } from "./common";

export const getCreateOccupancyRecordReqConf = (occupancyRecordReq: CreateOccupancyRecord) => {
    return {
        method: 'post',
        url: '/api/occupancy-record',
        headers: defaultHeaders,
        data: JSON.stringify(occupancyRecordReq)
    };
}


export const getListOccupancyRecordReqConf = () => {
    return {
        method: 'get',
        url: '/api/occupancy-record',
        headers: defaultHeaders,
    };
}

export const getDetailOccupancyRecordReqConf = (id: string) => {
    return {
        method: 'get',
        url: `/api/occupancy-record/${id}`,
        headers: defaultHeaders,
    };
}