import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import {
  ChartHandle,
  StatisticSearchParams,
} from '../../model/statistic.model';

import ReactECharts from 'echarts-for-react';
import useRequest from '../../hook/use-request.hook';
import { getStatisticOccupancyConf } from '../../api/statisticAPI';
import { generateDayArray, getDateYYYYMMDD } from '../../util/date.util';
interface Props {
  exemptions: any[];
}

export const DashboardOccupancyView = forwardRef<ChartHandle, Props>(
  (props: Props, ref) => {
    const { exemptions } = props;
    const { fetchData } = useRequest();
    const [graphData, setGraphData] = useState<any[]>([]);
    const [axis, setAxis] = useState<any[]>([]);
    const [parametri, setParametri] = useState<any>();

    useEffect(() => {
      if (parametri !== undefined && exemptions.length > 0) getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parametri, exemptions]);

    const onSuccess = (data: any, headers: any) => {
      try {
        let axi = generateDayArray(parametri.fromDate, parametri.toDate);
        let gData: any[] = [];
        let aData: any[] = [];

        gData[0] = [];
        exemptions.forEach((ex: any) => {
          gData[ex.id] = [];
        });

        axi.forEach((el: any) => {
          let tot = 0;
          let formatDate = getDateYYYYMMDD(el);
          let d = data.filter((da: any) => {
            return da.date === formatDate;
          });

          exemptions.forEach((ex: any) => {
            let exemp = d.filter((da: any) => {
              return da.exemptionId === ex.id;
            });

            if (exemp.length > 0) {
              const sum = exemp.reduce(
                (accumulator: any, currentValue: any) =>
                  accumulator + parseInt(currentValue.guests),
                0
              );
              gData[ex.id].push(sum);
              tot = tot + sum;
            } else {
              gData[ex.id].push(0);
            }
          });
          gData[0].push(tot);
          aData.push(formatDate);
        });

        setGraphData(gData);
        setAxis(aData);
      } catch (err) {
        console.log('err', err);
      }
    };

    const onErrors = (errors: any) => {
      console.log('onErrors', errors);
    };

    const getData = () => {
      fetchData(getStatisticOccupancyConf(parametri), onSuccess, onErrors);
    };

    useImperativeHandle(ref, () => ({
      refresh(params: StatisticSearchParams) {
        setParametri(params);
      },
    }));

    let option = {
      title: {
        text: 'Presenze da richieste struttura',
      },
      grid: {
        top: 40,
        bottom: 50,
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: [...exemptions?.map((ex: any) => ex.name)],
        bottom: 0,
      },
      xAxis: {
        type: 'category',
        data: axis.map((it) => {
          return it.split('-').reverse().join('/');
        }),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        ...exemptions?.map((ex: any) => {
          return {
            name: ex.name,
            data: graphData[ex.id],
            showSymbol: false,
            type: 'line',
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
          };
        }),
        ...[
          {
            name: 'Totale',
            type: 'line',
            stack: 'total',
            showSymbol: false,
            areaStyle: {},
            itemStyle: {
              color: 'rgba(80, 159, 187, 0.1)',
            },
            data: graphData[0],
          },
        ],
      ],
    };

    return <ReactECharts option={option} />;
  }
);
