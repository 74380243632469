import { Route } from 'react-router-dom';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import PaymentListPage from './payment-list.page';

const PaymentRoutes = () => {
  return (
    <ErrorBoundaryRoutes>
      <Route path="" element={<PaymentListPage />} />

      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default PaymentRoutes;
