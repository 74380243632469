import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  ChartHandle,
  StatisticSearchParams,
} from '../../model/statistic.model';

import ReactECharts from 'echarts-for-react';
import { getStatisticOccupancyByPlaceConf } from '../../api/statisticAPI';
import useRequest from '../../hook/use-request.hook';
import {
  generateDayArray,
  generateMonthArray,
  getDateYYYYMMDD,
  getMonthFromDate,
} from '../../util/date.util';
interface Props {
  places: any[];
}

export const DashboardOccupancyByPlaceView = forwardRef<ChartHandle, Props>(
  (props: Props, ref) => {
    const { places } = props;
    const { fetchData } = useRequest();
    const [graphData, setGraphData] = useState<any[]>([]);
    const [axis, setAxis] = useState<any[]>([]);
    const [parametri, setParametri] = useState<any>();
    useEffect(() => {
      if (parametri !== undefined && places.length > 0) getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parametri, places]);
    const onSuccess = (data: any, headers: any) => {
      try {
        let axi = generateMonthArray(parametri.fromDate, parametri.toDate);
        let gData: any[] = [];
        let aData: any[] = [];

        gData[0] = [];
        places.forEach((pl: any) => {
          gData[pl.id] = [];
        });

        axi.forEach((el: any) => {
          let month = getMonthFromDate(el);
          let year = el.getFullYear();

          let d = data.find((da: any) => {
            return da.month === month && da.year === year;
          });

          aData.push(month + '/' + year);
          if (d) gData.push(d.totalAmount);
          else gData.push(null);

          places.forEach((pl: any) => {
            let d = data.filter((da: any) => {
              return (
                da.month === month && da.year === year && da.placeId === pl.id
              );
            });
            if (d.length > 0) {
              const sum = d.reduce(
                (accumulator: any, currentValue: any) =>
                  accumulator + currentValue.count,
                0
              );
              gData[pl.id].push(sum);
            } else {
              gData[pl.id].push(0);
            }
          });
        });

        setGraphData(gData);
        setAxis(aData);
      } catch (err) {
        console.log('err', err);
      }
    };

    const onErrors = (errors: any) => {
      console.log('onErrors', errors);
    };

    const getData = () => {
      fetchData(
        getStatisticOccupancyByPlaceConf(parametri),
        onSuccess,
        onErrors
      );
    };

    useImperativeHandle(ref, () => ({
      refresh(params: StatisticSearchParams) {
        setParametri(params);
      },
    }));

    let option = {
      title: {
        text: 'Presenze da richieste struttura per luogo',
      },

      grid: {
        top: 40,
        bottom: 50,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      legend: {
        bottom: 0,
      },
      xAxis: {
        type: 'category',
        data: axis.map((it) => {
          return it.split('-').reverse().join('/');
        }),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        ...places?.map((ex: any) => {
          return {
            name: ex.name,
            data: graphData[ex.id],
            showSymbol: false,
            type: 'bar',
            //  stack: 'Total',
            emphasis: {
              focus: 'series',
            },
          };
        }),
      ],
    };

    return <ReactECharts option={option} />;
  }
);
