import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  ChartHandle,
  StatisticSearchParams,
} from '../../model/statistic.model';

import ReactECharts from 'echarts-for-react';
import { getStatisticPaymentConf } from '../../api/statisticAPI';
import useRequest from '../../hook/use-request.hook';
import { generateMonthArray, getMonthFromDate } from '../../util/date.util';

interface Props {}

export const DashboardPaymentView = forwardRef<ChartHandle, Props>(
  (props: Props, ref) => {
    const { fetchData } = useRequest();
    const [statuses, setStatuses] = useState<any[]>([]);
    const [graphData, setGraphData] = useState<any[]>([]);
    const [axis, setAxis] = useState<any[]>([]);
    const [parametri, setParametri] = useState<any>();

    useEffect(() => {
      if (parametri !== undefined) getData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parametri]);
    const onSuccess = (data: any, headers: any) => {
      try {
        let statusesTmp: any = [];
        statusesTmp = data.map((it: { status: any }) => it.status);
        statusesTmp = Array.from(new Set(statusesTmp));

        setStatuses(statusesTmp);
        let axi = generateMonthArray(parametri.fromDate, parametri.toDate);
        let gData: any = {};
        let aData: any[] = [];
        statusesTmp.forEach((status: any) => {
          gData['' + status] = [];
          axi.forEach((el) => {
            let month = getMonthFromDate(el);
            let year = el.getFullYear();

            let d = data.find((da: any) => {
              return (
                da.month === month && da.year === year && da.status === status
              );
            });

            aData.push(month + '/' + year);
            if (d) gData['' + status].push(d.totalAmount);
            else gData[status].push(null);
          });
        });

        setGraphData(gData);
        setAxis(aData);
      } catch (err) {}
    };

    const onErrors = (errors: any) => {
      console.log('onErrors', errors);
    };

    const getData = () => {
      fetchData(getStatisticPaymentConf(parametri), onSuccess, onErrors);
    };

    useImperativeHandle(ref, () => ({
      refresh(params: StatisticSearchParams) {
        setParametri(params);
      },
    }));

    let option = {
      title: {
        text: 'Pagamenti',
      },
      xAxis: {
        type: 'category',
        data: axis,
      },
      grid: {
        top: 40,
        bottom: 50,
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        bottom: 0,
      },
      yAxis: {
        type: 'value',
      },
      series: statuses.map((it: any) => {
        return {
          name: it,
          data: graphData[it],
          type: 'bar',
        };
      }),
    };
    return <ReactECharts option={option} />;
  }
);
