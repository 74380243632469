import { faBed, faBuilding, faCampground, faCaravan, faHome, faHotTub, 
  faHotel, faHouse, faMapSigns, faSpa, faSwimmingPool, faTent, faUmbrellaBeach,
  faPerson, faBaby, faPersonCane, faWheelchair, faPeopleRoof} from "@fortawesome/free-solid-svg-icons";
import { UseFormRegisterReturn } from "react-hook-form";

import {
  FacilityRecordRequestStatuses,
} from '../model/facility-record-request.model';

export const registerRs = (register: any, fieldName: string, options = {}) => {
    const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
    const ref = registeredField.ref;
    delete registeredField.ref;
    return { ...registeredField, innerRef: ref };
}

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const POSITIVE_INTEGER_NUMBER_REGEX = /^[1-9]\d*$/;


export const icons = new Map<string, any>();
icons.set("fa-hotel",faHotel);
icons.set("fa-house",faHouse);
icons.set("fa-tent",faTent);
icons.set("fa-campground",faCampground);
icons.set("fa-umbrella-beach", faUmbrellaBeach);
icons.set("fa-hot-tub",faHotTub);
icons.set("fa-home", faHome);
icons.set("fa-building", faBuilding);
icons.set("fa-swimming-pool", faSwimmingPool);
icons.set("fa-bed", faBed);
icons.set("fa-spa", faSpa);
icons.set("fa-caravan",  faCaravan);
icons.set("fa-map-signs",  faMapSigns);
icons.set("fa-people-roof",  faPeopleRoof);


icons.set("faPerson", faPerson);
icons.set("faBaby",  faBaby);
icons.set("faPersonCane",  faPersonCane);
icons.set("faWheelchair",  faWheelchair);


export const getColor = (status: FacilityRecordRequestStatuses): string => {
  let color;
  switch (status) {
    case FacilityRecordRequestStatuses.PENDING:
      color = 'warning';
      break;
    case FacilityRecordRequestStatuses.APPROVED:
      color = 'success';
      break;
    case FacilityRecordRequestStatuses.REJECTED:
      color = 'danger';
      break;
    default:
      color = 'primary';
  }
  return color;
};

export const isValidJSON = (str : string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const campoByLang = (campo: any, lang: string) => {
  try {
    let j = JSON.parse(campo);
    return j[lang];
  } catch (e) {
    return campo;
  }
}

export const splitCampi = (entity: any, campi: string[]) => {
  let ent = entity

  campi.forEach(campo => {
    if(typeof entity[campo] == "string" && isValidJSON(entity[campo])){
      ent[campo] = JSON.parse(entity[campo]);
    } else if (typeof entity[campo] == "string" ) {
      ent[campo] = { 
        'it' : entity[campo],
        'en' : entity[campo]
      }
    }
  })

// console.log("splitCampi", entity, ent)

  return ent; 
}




export const customSelectStyles = {
    control: (provided : any, state: any) => { 
      // console.log("state ... ", state)
      return {
      ...provided,
     borderWidth: '0 0 2px 0 !important',
     borderColor: state.isFocused ? '#a0cfde !important' : '#ced4da !important' ,
     boxShadow:  state.isFocused ? '0 0 0 0.25rem rgba(64, 159, 188, 0.25)' : 'none'
    }},
    container: (provided : any, state: any) => ({
      ...provided,
     borderWidth: '0 0 2px 0 !important',
     borderColor: state.isFocused ? 'red' : '#ced4da' ,
    }),
    menu: (provided : any) => ({
      ...provided,
      zIndex: 9999
    }),
    placeholder: (provided : any) => ({
      ...provided,
      color: '#6c757d'
    }),
    input: (provided : any, state: any) => ({
      ...provided,
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      height: '34px',
      borderColor: state.isFocused ? 'red !important' : '#ced4da !important' ,
    }),
    ValueContainer: (provided : any, state: any) => ({
      ...provided,
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      height: '34px',

    }),
  };


 