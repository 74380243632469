import axios from 'axios';
import { SERVER_API_URL } from './config';
import { logout, signinSuccess } from '../redux/reducers/userSlice';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

export const setupAxiosInterceptors = (store: any) => {
  // console.log(' axios.interceptors ', axios.interceptors);

  axios.interceptors.request.use((config) => {
    let state = store.getState();
    // console.log('use ', store);

    let token = state?.user?.accessToken;
    let lang = state?.user?.lang;
    if (token !== null) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (lang !== null) {
      config.headers['Accept-Language'] = lang;
    }
    // config.localId = config.localId ? config.localId : new Date().getTime()

    //console.log('config:', config);
    return config;
  });
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      // console.log('error', error);
      const originalRequest = error.config;

      // console.log('originalRequest._retry', originalRequest._retry);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          let state = store.getState();
          // console.log('use ', store);

          let refreshToken = state?.user?.refreshToken;
          const response = await axios.post('/api/auth/refresh-token', {
            refreshToken,
          });
          const { accessToken } = response.data;
          store.dispatch(signinSuccess(response.data));
          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
          store.dispatch(logout());
        }
      }

      return Promise.reject(error);
    }
  );
};
