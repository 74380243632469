import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipIcon = (props: any) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <span id={'tooltip' + props.text.replaceAll(' ', '')}>
        {props.icon ? <FontAwesomeIcon icon={props.icon} /> : props.label}
      </span>

      <Tooltip
        isOpen={tooltipOpen}
        target={'tooltip' + props.text.replaceAll(' ', '')}
        toggle={toggle}
      >
        {props.text}
      </Tooltip>
    </>
  );
};

export default TooltipIcon;
