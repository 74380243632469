import { faArrowLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody } from 'reactstrap';
import { getDetailFacilityConf } from '../../api/facilityAPI';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import { DetailCompany } from '../../model/company.model';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import CompanyForm from './company.form';
import {
  getDetailCompanyConf,
  getDetailCompanyConfByEmail,
} from '../../api/companyAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { current } from '@reduxjs/toolkit';
import { useAppSelector } from '../../redux/store';

export default function CompanyDetailPage() {
  let { id, email } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData: fetchDataDetail } = useRequest<DetailCompany>();
  const [entity, setEntity] = useState<DetailCompany>();
  const currentUser = useAppSelector(currentUserSelector);
  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailCompanyConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    } else {
      fetchDataDetail(
        getDetailCompanyConfByEmail(),
        onSuccessDetail,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailCompany) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    console.log('data', data);
    let message = data.message
      ? data.message
      : 'La struttura è stata modificata';

    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      {!checkRequiredAuthorities(
        [Authorities.MANAGER, Authorities.SOLICITOR, Authorities.USER],
        currentUser
      ) && (
        <Breadcrumb>
          <BreadcrumbItem color="link">
            <Link to={'/company'}>{t('company.list.title')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('company.detail.title')}</BreadcrumbItem>
        </Breadcrumb>
      )}
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: '12px' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
        {t('general.buttons.back')}
      </Button>
      <div className="header-container">
        <h2>{t('company.detail.title')}</h2>
        <div>
          <MobileButton
            onClick={() => navigate(`/company/${entity?.id}/update`)}
            color="success"
            icon={faPencil}
            text={t('general.buttons.edit')}
          />
        </div>
      </div>
      <Card className="mt-2">
        <CardBody>
          <CompanyForm
            initialData={entity}
            enabled={false}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></CompanyForm>
        </CardBody>
      </Card>
    </div>
  );
}
