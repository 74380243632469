/* eslint-disable react/jsx-no-undef */
import { Button } from "reactstrap";
import "../home/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBus,
  faCircleInfo,
  faHandHoldingMedical,
  faKitMedical,
  faTent,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function InfoAssistenza() {
  const navigate = useNavigate();

  const handleButtonClick = (src: string) => {
    navigate("/pdf-viewer?src=" + encodeURIComponent(src));
  };

  return (
    <>
      <div id="card">
        {/* <div className="wpb_wrapper wpb_wrapper_blue head">
          <div className="container">
            <div
              className="empty_space visible-lg"
              style={{ height: "50px" }}
            >
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space" style={{ height: "10px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="widget-text-heading center_white">
              <h2 className="widget-title">
                <span>Servizio TerraMare</span>
              </h2>
            </div>
            <div className="empty_space" style={{ height: "35px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
          </div>
        </div> */}
      </div>
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: "12px" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      <div style={{ marginTop: "20px", justifyContent: "center", alignItems: "center" }}>
        {/*   <div className="clearfix" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <img src={"/infoUtili.jpg"} alt={"Info Assistenza"} className="rounded float-start me-3" style={{  borderRadius: "10px" }} />
      <br /></div> <hr /> */}
        <h2 style={{ textAlign: "center" }} className="page-title">
          <strong>
            CONTATTI UTILI <br />
            Useful contacts
          </strong>
        </h2>
        <div>
          <div style={{ backgroundColor: "#d8ecfc", padding: "20px" }}>
            <div style={{ textAlign: "center" }} className="page-title">
              <h3 style={{ textAlign: "center" }} >
                {/* <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faBus} /> */}
                <strong>Comune di Melendugno Tel. 0832 832111</strong>
              </h3>
              <p>
                <strong> turismo@comune.melendugno.le.it</strong>
                <br /> www.comune.melendugno.le.it
                <br />
                www.visitmelendugno.com
              </p>
            </div>
            <div style={{ textAlign: "center" }} className="page-title">
              <h3 style={{ textAlign: "center" }} >
                <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faCircleInfo} />
                <strong>IAT uffici di informazione/accoglienza turistica</strong>
              </h3>
              <p >
                Tourist information office <br />{" "}
                <strong>
                  {" "}
                  Orario d'apertura dal 01 giugno al 30 settembre dalle 9:00 alle 13:00 - dalle 16:00 alle 20:00{" "}
                </strong>
                <br />
                Opening hours from 01st June to 30 September from 9:00 am to 1:00 pm - from 4:00 pm to 8:00 pm
              </p>
            </div>
          </div>
          <h2 style={{ textAlign: "center" }} className="page-title">
            <strong>Infopoint</strong>
          </h2>
          <div>
            <div
              style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "0.5rem", marginLeft: "20px" }}
            >
              <div style={{ paddingTop: "18px" }}>
                <div className="cubeItem" style={{ background: "#bb1e61", width: "20px", height: "20px" }}></div>
              </div>
              <div>
                <h5 className="page-title">
                  <strong>SAN FOCA</strong> - 329 3658519
                </h5>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "0.5rem",
                marginLeft: "20px",
              }}
            >
              <div style={{ paddingTop: "18px" }}>
                <div className="cubeItem" style={{ background: "#299991", width: "20px", height: "20px" }}></div>
              </div>
              <div>
                <h5 className="page-title">
                  <strong>TORRE DELL'ORSO</strong> - 329 2583678
                </h5>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "0.5rem",
                marginLeft: "20px",
              }}
            >
              <div style={{ paddingTop: "18px" }}>
                <div className="cubeItem" style={{ background: "#c8832a", width: "20px", height: "20px" }}></div>
              </div>
              <div>
                <h5 className="page-title">
                  <strong>SANT'ANDREA</strong> - 329 3952460
                </h5>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "0.5rem", marginLeft: "20px" }}
            >
              <h5 className="page-title">
                <strong>infopointmarinedimelendugno@gmail.com</strong>
              </h5>
            </div>
          </div>
          <div>
            <h2 style={{ textAlign: "center" }} className="page-title">
              <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faKitMedical} />
              <strong>Guardia medica </strong> / Medical Guard
            </h2>
            <h5 style={{ textAlign: "center" }} className="page-title">
              <strong>
                Orario d'apertura 8:00 - 20:00
                <br />
              </strong>
              Urgent care opening hours 8:00 am - 8:00 pm
            </h5>
            <h5 className="page-title" style={{ textAlign: "center" }}>
              {" "}
              <p>
                {" "}
                Ambulatorio <strong> San Foca </strong> - Piazza d'Oriente. Per informazioni tel. 329.3173692.{" "}
              </p>
              <p>
                Ambulatorio <strong> Torre dell'Orso </strong> - Piazza della Luna. Per informazioni tel. 327.7189629.{" "}
              </p>
              <p>
                <strong> Melendugno </strong> - Via G.D'Amely 22. Per informazioni tel. 0832 5831002.{" "}
              </p>
              <p>
                {" "}
                <strong> Tutti i giorni dalle ore 8.00 alle ore 20.00.</strong>{" "} <br/>
                Daily from 8 a.m. to 8 p.m.
              </p>
            </h5>
          </div>
          <div>
            <h2 style={{ textAlign: "center" }} className="page-title">
              <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faHandHoldingMedical} />
              <strong>Farmacie e parafarmacie </strong>/ Pharmacies and health care
            </h2>

            <h5 className="page-title" style={{ textAlign: "center" }}>
              <p>
                Farmacia Stefanelli, Melendugno - 0832 834574
                <br />
              </p>
              <p>
                Farmacia Conte, Borgagne - 0832 811095
                <br />
              </p>
              <p>
                Farmacie e Ass. Sanitaria Dispensario
                <br />
              </p>
              <p>
                Dr. Bursomanno, Torre dell'Orso - 0832 841445
                <br />
              </p>
              <p>
                Parafarmacia Durante, Melendugno - 0832 833812
                <br />
              </p>
              <p>
                Parafarmacia Rhokà, Melendugno - 0832 831482
                <br />
              </p>
              <p>
                Parafarmacia Di Scioscio, Torre dell'Orso e Borgagne - 328 2731277
                <br />
              </p>
            </h5>
          </div>

          <div>
            <h2 style={{ textAlign: "center" }} className="page-title">
              <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faUserShield} />
            </h2>

            <h5 className="page-title" style={{ textAlign: "center" }}>
              <p>
                <strong>Polizia Municipale </strong>/Local Police - 0832 832251
                <br />
              </p>
              <p>
                <strong>Pronto Intervento </strong> /Emergency - 328 8130696
                <br />
              </p>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
