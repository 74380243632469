import { CreatePlace } from '../model/place.model';
import { defaultHeaders } from './common';

export const getCreatePlaceConf = (createFacility: CreatePlace) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/place',
    headers: defaultHeaders,
    data: JSON.stringify(createFacility),
  };
};
export const getUpdatePlaceConf = (createFacility: CreatePlace, id: number) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/place/' + id,
    headers: defaultHeaders,
    data: JSON.stringify(createFacility),
  };
};

export const getListPlacePagedConf = (page: number, size: number) => {
  return {
    method: 'get',
    url: '/api/place?page=' + page + '&size=' + size,
    headers: defaultHeaders,
  };
};
export const getListPlaceConf = () => {
  return {
    method: 'get',
    url: '/api/place',
    headers: defaultHeaders,
  };
};

export const getDetailPlaceConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/place/${id}`,
    headers: defaultHeaders,
  };
};

export const getCreateFacilityImageConf = (formData: any) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/facility-image',
    headers: defaultHeaders,
    data: formData,
    body: formData,
  };
};
export const getDeleteFacilityImageConf = (facilityId: number) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: '/api/facility-image/' + facilityId,
    headers: defaultHeaders,
  };
};
