import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetailFacility, DetailFacilityEdit } from '../../model/facility.model';
import { useEffect, useState } from 'react';
import { faCity, faCrosshairs, faEnvelope, faFax, faPhone } from '@fortawesome/free-solid-svg-icons';
import { campoByLang, icons, splitCampi } from '../../util/form.util';
import { Button, UncontrolledCarousel } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/store';
import { userLngSelector } from '../../redux/reducers/userSlice';

interface FacilityDisplayProps {
  initialData?: DetailFacility;
}

const FacilityDisplay = (props: FacilityDisplayProps) => {
  const {  initialData } = props;
  const [data, setData] = useState<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = useAppSelector(userLngSelector);


  useEffect(() => {
    if(initialData){
      let d = JSON.parse(JSON.stringify(initialData))
      d.typologyId = initialData.typology? initialData.typology.id : 0
      d.placeId = initialData.place? initialData.place.id : 0
      d.active = initialData.active === false ? "No" : "Si"
      
      console.log("data", d)
      
      setData(d)
    }
  }, [initialData]);

  return (
   data ? <div className='row'>
      <div className='col-lg-8 col-md-8 col-sm-12'>
        <div className="card mb-3" style={{
              flexDirection: 'row',
              padding: '9px',
              alignItems: 'center',
              background: '#509fbb29', textTransform: 'capitalize',
        }}>
        <FontAwesomeIcon style={{marginRight: '10px'}} size="3x" icon={data.typology? icons.get(data.typology.icon) : ''} />
       { campoByLang(data.typology.name, lang ) }
        </div>
        { campoByLang(data.note, lang ) }
      </div>
      <div className='col-lg-4 col-md-4 col-sm-12'>
      <span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <FontAwesomeIcon
          icon={faCrosshairs}
          color="#004C6C"
        />{' '}
        {data.address} 
      </span>
      <span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <FontAwesomeIcon icon={faCity} color="#004C6C" />{' '}
        {/* {data.city} -  */}
        {data.place.name.toUpperCase()}
      </span>      
      <span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {data.province}, {data.zipCode}
      </span>
      <span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} color="#004C6C" />{' '}
        {data.email}
      </span>
      <span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <FontAwesomeIcon icon={faPhone} color="#004C6C" />{' '}
        {data.phoneNumber}
      </span>
      { data.fax &&(<span
        style={{
          textOverflow: 'ellipsis',
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <FontAwesomeIcon icon={faFax} color="#004C6C" />{' '}
        {data.fax}
      </span> )}
<hr />
      <Button
            className="mr-2"
            type="button"
            size="lg"
            onClick={(e) => {
              navigate(`/stay/new?facility=${data.id}`);
            }}
            color="primary"
          >
           {t("general.actions.registerstay")}
          </Button>

    </div>
   </div> : <></>
  );
};

export default FacilityDisplay;
