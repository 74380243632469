import {
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';


import QRCode from 'react-qr-code';

export default function QRcodeDetailPage() {



  return (

    <div className="container mt-4 col-lg-4">
      <Card>
        <CardBody>
          <CardTitle tag="h5">Ecco il tuo QRCODE</CardTitle>
          <QRCode value="https://www.example.com" />
        </CardBody>
      </Card>
    </div>
  );
}
