import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../shared/error-boundary-routes';
import PageNotFound from '../shared/page-not-found';
import CompanyRoutes from './company/company.routes';
import EventsRoutes from './events/events.routes';
import DashboardPage from './dashboard/dashboard.page';
import FacilityRecordRequestRoutes from './facility-record-request/facility-record-request.routes';
import FacilityTypeRoutes from './facility-type/facility_type.routes';
import FacilityRoutes from './facility/facility.routes';
import FullFacilityRoutes from './fullfacility/facility.routes';
import HomePage from './home/home.page';
import UserRoutes from './login/user.routes';
import OccupancyRoutes from './occupancy/occupancy.routes';
import PlaceRoutes from './place/place.routes';
import SolicitorRoutes from './solicitor/solicitor.routes';
import { useAppSelector } from '../redux/store';
import { Authorities, currentUserSelector } from '../redux/reducers/userSlice';
import { checkRequiredAuthorities } from '../components/authority-boundary-view';
import StayRoutes from './stay/stay.routes';
import PaymentRoutes from './payment/payment.routes';
import QrcodeRoutes from './qrcode/qrcode.routes';
import StayGuestRoutes from './stay-guest/stay-guest.routes';
import VisiteEntroterra from './services/visite-entroterra.page';
import VisiteRoca from './services/visite-roca.page';
import Navetta from './services/navetta.page';
import Infopoint from './services/infopoint.page';
import CustomPDFViewer from '../components/customPDFViewer';
import TerraMare from './services/terramare.page';
import Tour from './services/tour.page';
import InfoAssistenza from './services/infoAssistenza.page';

const PageRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities([Authorities.MANAGER], currentUser) && (
        <Route index element={<StayRoutes />} />
      )}

      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      ) && <Route index element={<DashboardPage />} />}

      <Route index element={<HomePage />} />
      <Route path="home/*" element={<HomePage />} />

      <Route path="servizio-sanitario" element={<VisiteEntroterra />} />
      <Route path="visite" element={<VisiteRoca />} />
      <Route path="terra-mare" element={<TerraMare />} />
      <Route path="navetta" element={<Navetta />} />
      <Route path="infopoint" element={<Infopoint />} />
      <Route path="/pdf-viewer" element={<CustomPDFViewer />} />
      <Route path="/tour" element={<Tour />} />
      <Route path="/assistenza" element={<InfoAssistenza />} />
      
      <Route path="solicitor/*" element={<SolicitorRoutes />} />
      <Route path="events/*" element={<EventsRoutes />} />

      <Route path="user/*" element={<UserRoutes />} />
      <Route
        path="facility-record-request/*"
        element={<FacilityRecordRequestRoutes />}
      />
      <Route path="stay/*" element={<StayRoutes />} />
      <Route path="facility/*" element={<FullFacilityRoutes />} />
      <Route path="oldfacility/*" element={<FacilityRoutes />} />
      <Route path="occupancy/*" element={<OccupancyRoutes />} />
      <Route path="company/*" element={<CompanyRoutes />} />
      <Route path="facility-type/*" element={<FacilityTypeRoutes />} />
      <Route path="place/*" element={<PlaceRoutes />} />
      <Route path="payment/*" element={<PaymentRoutes />} />
      <Route path="qrcode/*" element={<QrcodeRoutes />} />
      <Route path="stay-guest/*" element={<StayGuestRoutes />} />

      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default PageRoutes;
