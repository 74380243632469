import { CreateFacilityType, DetailFacilityType } from '../model/facility-type.model';
import { defaultHeaders } from './common';

export const getCreateFacilityTypeConf = (
  createFacility: CreateFacilityType
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/facility-type',
    headers: defaultHeaders,
    data: JSON.stringify(createFacility),
  };
};
export const getUpdateFacilityTypeConf = (
  createFacility: DetailFacilityType,
  id: number
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/facility-type/' + id,
    headers: defaultHeaders,
    data: JSON.stringify(createFacility),
  };
};

export const getListFacilityTypePagedConf = (page: number, size: number) => {
  return {
    method: 'get',
    url: '/api/facility-type?page=' + page + '&size=' + size,
    headers: defaultHeaders,
  };
};
export const getListFacilityTypeConf = () => {
  return {
    method: 'get',
    url: '/api/facility-type',
    headers: defaultHeaders,
  };
};

export const getDetailFacilityTypeConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/facility-type/${id}`,
    headers: defaultHeaders,
  };
};

export const getCreateFacilityImageConf = (formData: any) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/facility-image',
    headers: defaultHeaders,
    data: formData,
    body: formData,
  };
};
export const getDeleteFacilityImageConf = (facilityId: number) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: '/api/facility-image/' + facilityId,
    headers: defaultHeaders,
  };
};
