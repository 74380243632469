// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  table-layout: fixed;
  width: 100%;
}

td:first-child {
   width: 28px; /* Adjust maximum width as needed */
  /* Additional styles for the first column if needed */
}`, "",{"version":3,"sources":["webpack://./src/components/facility-view.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;GACG,WAAW,EAAE,mCAAmC;EACjD,qDAAqD;AACvD","sourcesContent":["table {\n  table-layout: fixed;\n  width: 100%;\n}\n\ntd:first-child {\n   width: 28px; /* Adjust maximum width as needed */\n  /* Additional styles for the first column if needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
