import { ApiError } from '../model/errors.model';

interface ErrorsViewProps {
  errors: ApiError[];
}
export const ErrorsView = (props: ErrorsViewProps) => {
  const { errors } = props;
  return (
    <ul>
      {errors.map((it: any, idx: number) => (
        <li key={idx}>{it.message}</li>
      ))}
    </ul>
  );
};
