import { defaultHeaders } from './common';

export const getListOccupancyConf = (query: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'api/occupancy-record' + query,
    headers: defaultHeaders,
  };
};

export const getCreateMultiOccupancyConf = (data: any[]) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'api/occupancy-record/multi',
    headers: defaultHeaders,
    data: JSON.stringify(data),
  };
};

export const getUdateMultiOccupancyConf = (data: any[]) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: 'api/occupancy-record/multi',
    headers: defaultHeaders,
    data: JSON.stringify(data),
  };
};

export const getListOccupancyPrevisionConf = (
  facilityId: string | number,
  query: string
) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `api/facility/${facilityId}/occupancy-record` + query,
    headers: defaultHeaders,
  };
};
