import { t } from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import 'react-form-wizard-component/dist/style.css';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  Alert,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner
} from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { getListFacilityConf } from '../../api/facilityAPI';
import { getCreateFacilityRecordReqConf } from '../../api/facilityRecordRequestAPI';
import { getListFacilityTypeConf } from '../../api/facilityTypeAPI';
import { getListPlaceConf } from '../../api/placeAPI';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { DetailFacilityType } from '../../model/facility-type.model';
import { DetailPlace } from '../../model/place.model';
import RequestDetail from './requerst-detail';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX, POSITIVE_INTEGER_NUMBER_REGEX, ZIP_CODE_REGEX, campoByLang, registerRs } from '../../util/form.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/store';
import { userLngSelector } from '../../redux/reducers/userSlice';

export interface OccupancyCreatePageHandle {
  open: () => void;
}
const FacilityRequestRecordPageCreate = forwardRef<OccupancyCreatePageHandle>(
  (props: {}, ref) => {
    const { loading, fetchData } = useRequest();
    const navigate = useNavigate();
    const lang = useAppSelector(userLngSelector);

    const [values, setValues] = useState<any>({});
    const [step, setStep] = useState(1);
    const [goto, setGoto] = useState(0);
    const [typeOptions, setTypeOptions] = useState<DetailFacilityType[]>([]);
    const [placeOptions, setPlaceOptions] = useState<DetailPlace[]>([]);
    const { loading: loadingTypology, fetchData: fetchTypologies } =
      useRequest();
    const { loading: loadingPlaces, fetchData: fetchPlaces } = useRequest();

    const stageField: any[] = [
      [],
      [],
      [
        'facilityName',
        'email',
        'address',
        'city',
        'zipCode',
        'province',
        'typologyId',
        'placeId',
        'active',
        'fax',
        'note',
        'phoneNumber',
        'beds',
      ],
      [
        'firstName',
        'lastName',
        'birthPlace',
        'birthDate',
        'birthProvince',
        'managerPhoneNumber',
      ],
      ['companyName', 'fiscalCode', 'vatNumber', 'isPersonal'],
    ];

    useImperativeHandle(ref, () => ({
      open() {
        // setModal(true);
      },
    }));
    const onSubmit = (data: any) => {
      console.log('onSubmit', data);
    };

    const {
      register,
      trigger,
      getFieldState,
      formState: { errors, isValid },
      handleSubmit,
      getValues,
    } = useForm();

    const changeStep = (nextStep: any) => {
      trigger(stageField[nextStep]);
      setGoto(nextStep);
    };

    useEffect(() => {
      // fetchData(getListFacilityConf(), (data: any) => {
      //     setFacilityList(data);
      //   }, (data: any) => {
      //     setFacilityList([]);
      //   });

      fetchTypologies(
        getListFacilityTypeConf(),
        (data, headers) => {
          if (Array.isArray(data)) {
            const emptyOption = [{id: '', name: '- seleziona una struttura -'}]
            setTypeOptions(emptyOption.concat(data));
          }
        },
        () => {
          console.log('error');
        }
      );

      fetchPlaces(
        getListPlaceConf(),
        (data, headers) => {
          if (Array.isArray(data)) {
            const emptyOption = [{id: '', name: '- seleziona una località -'}]
            setPlaceOptions(emptyOption.concat(data));
          }
        },
        () => {
          console.log('error');
        }
      );
    }, []);

    const checkFields = (fieldArray: string[]) => {
      let isValid = true;
      fieldArray.forEach((element) => {
        let field = getFieldState(element);
        if (field.error !== undefined) {
          isValid = false;
        }
      });

      return isValid;
    };

    useEffect(() => {
      let values = getValues();
      setValues(values)
    }, [step]);


    const create = () => {
      let newRecord = {
        email: values.email,
        phoneNumber: values.phoneNumber,
        address: values.address,
        city: values.city,
        zipCode: values.zipCode,
        province: values.province,
        firstName: values.firstName,
        lastName: values.lastName,
        birthPlace: values.birthPlace,
        birthProvince: values.birthProvince,
        birthDate: values.birthDate,
        fiscalCode: values.fiscalCode,
        facilityName: values.facilityName,
        companyName: values.companyName,
        vatNumber: values.vatNumber,
        isPersonal: values.isPersonal,
        placeId: values.placeId,
        typologyId: values.typologyId,
        active: values.active,
        note: values.note,
        fax: values.fax,
        beds: values.beds,
        managerCity: values.managerCity,
        managerProvince: values.managerProvince,
        managerCap: values.managerCap,
        managerPhoneNumber: values.managerPhoneNumber,
        managerFax: values.managerFax,
        managerEmail: values.managerEmail,
        managerFiscalCode: values.managerFiscalCode,

        legalEntityAddress: values.legalEntityAddress,
        legalEntityCity: values.legalEntityCity,
        legalEntityProvince: values.legalEntityProvince,
        legalEntityZipCode: values.legalEntityZipCode,
      };

      fetchData(getCreateFacilityRecordReqConf(newRecord),  () => {
          setStep(5);
        }, onErrors);
    }

    useEffect(() => {
      if (goto == 2 || goto == 3 || goto == 4) {
        trigger(stageField[goto]);
        if (checkFields(stageField[goto])) {
          setStep(goto);
        } else {
          setGoto(0);
        }
      }
    }, [goto]);

    const onSuccess = (dataa: any) => {
      //  onSubmitSuccess(dataa);
    };

    const onErrors = (errors: ApiError[]) => {
      //  onSubmitError(errors);
    };

    const giveField = (
      st: any,
      nome: string,
      errori: any,
      checks: any,
      typ: InputType = 'text'
    ) => {
      return (
        <FormGroup className={step == st ? 'form-field' : 'form-field hidden'}>
          <Input
            id={nome}
            name={nome}
            type={typ}
            // value={typ == "number"? 0 : null}
            {...registerRs(register, nome, checks)}
            invalid={errori && errori != null}
            placeholder={t('facility-record-request.' + nome)}
          />
          <Label className={typ} for={nome}>
            {t('facility-record-request.' + nome)}
          </Label>
          <FormFeedback>{'' + errori?.message}</FormFeedback>
        </FormGroup>
      );
    };

    const gotoStep = (x:number) => {
      setStep(x);
    }

    return (
      <div className="container mt-2 get-in-touch">
         <Button
          color="trasparent"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
          {t('general.buttons.back')}
        </Button>
        <div className="header-container">
          <h2 className="page-title">Registrazione Struttura</h2>
        </div>

        <div className="card-body">
        <div className="react-form-wizard">
          <div className="wizard-navigation">
            <div className="wizard-progress-with-circle">
              <div className="wizard-progress-bar"></div>
            </div>
            <ul className="form-wizard-steps  wizard-nav wizard-nav-pills  md" >
              <li  className={
                    step >= 1? (step == 1 ? 'current active' : 'active') : ''
                  }>
                <a onClick={() => { 
                  if(step > 1){gotoStep(1)}
                }} style={{ alignItems: 'flex-start' }} className="active">
                  <div className="wizard-icon-circle md checked " role="tab">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-user">1</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle active">Struttura</span>
                </a>
              </li>
              <li  className={
                    step >= 2 ? (step == 2 ? 'current active' : 'active') : ''
                  }>
                <a onClick={() => { 
                  if(step > 2){gotoStep(2)}
                }} className={step >= 2 ? 'active' : ''}>
                  <div
                    className="wizard-icon-circle md checked"
                    //  style="border-color: rgb(33, 150, 243);"
                  >
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-check">2</i>
                      </span>
                    </div>
                  </div>
                  <span
                    className="stepTitle "
                    // style="margin-top: 8px; color: rgb(33, 150, 243);"
                  >
                    Responsabile
                  </span>
                </a>
              </li>
              <li  className={
                    step >= 3 ? (step == 3 ? 'current active' : 'active') : ''
                  }>
                <a onClick={() => { 
                  if(step > 3){gotoStep(3)}
                }} className={step >= 3 ? 'active' : ''}>
                  <div className="wizard-icon-circle md checked">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-check">3</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle ">Billing</span>
                </a>
              </li>
              <li  className={
                    step >= 4 ? (step == 4 ? 'current active' : 'active') : ''
                  }>
                <a
                onClick={() => { 
                  if(step > 4){gotoStep(4)}
                }}
                  style={{ alignItems: 'flex-end' }}
                  className={step >= 4 ? 'active' : ''}
                >
                  <div className="wizard-icon-circle md checked">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-check">4</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle ">Riepilogo</span>
                </a>
              </li>
              <li  className={
                    step >= 5 ? (step == 5 ? 'current active' : 'active') : ''
                  }>
                <a
                onClick={() => { 
                  if(step > 5){gotoStep(5)}
                }}
                  style={{ alignItems: 'flex-end' }}
                  className={step >= 5 ? 'active' : ''}
                >
                  <div className="wizard-icon-circle md checked">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-check">5</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle ">Esito</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        </div>
        <div className="card mt-4">
          <div className="card-body">
        <Form className="form-floating" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'facilityName', errors.facilityName, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'email', errors.email, {
                required: t(`general.errors.required`),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t(`general.errors.invalid_email`),
                },
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'address', errors.address, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'city', errors.city, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'zipCode', errors.zipCode, {
                required: t(`general.errors.required`),
                pattern: {
                  value: ZIP_CODE_REGEX,
                  message: t(`general.errors.invalid_zip_code`)
                },
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'province', errors.province, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'active', errors.active, {
                // required: t(`general.errors.required`),
              }, 'checkbox')}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'fax', errors.fax, {
                // required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'note', errors.note, {
               // required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'phoneNumber', errors.phoneNumber, {
                required: t(`general.errors.required`),
                pattern: {
                  value: PHONE_NUMBER_REGEX,
                  message: t(`general.errors.invalid_phone_number`),
                }
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(1, 'beds', errors.beds, {
                required: t(`general.errors.required`),
                pattern: {
                  value: POSITIVE_INTEGER_NUMBER_REGEX,
                  message: t(`general.errors.invalid_positive_integer_number`),
                }
              }, 'number')}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <FormGroup
                className={step == 1 ? 'form-field' : 'form-field hidden'}
              >
                <Label className="select" for="typologyId">
                  {t('facility.typology')}
                </Label>

                <Input
                  id="typology"
                  name="typologyId"
                  type="select"
                  {...registerRs(register, 'typologyId', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.typologyId && errors.typologyId != null}
                >
                  {typeOptions.map((it) => {
                    return (
                      <option key={it.id} value={it.id}>
                        {campoByLang(it.name, lang)}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{'' + errors?.typologyId?.message}</FormFeedback>
              </FormGroup>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <FormGroup
                className={step == 1 ? 'form-field' : 'form-field hidden'}
              >
                <Label className="select" for="placeId">
                  {t('facility.place')}
                </Label>

                <Input
                  id="place"
                  name="placeId"
                  type="select"
                  {...registerRs(register, 'placeId', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.placeId && errors.placeId != null}
                >
                  {placeOptions.map((it) => {
                    return (
                      <option key={it.id} value={it.id}>
                        {it.name}
                      </option>
                    );
                  })}
                </Input>
                <FormFeedback>{'' + errors?.placeId?.message}</FormFeedback>
              </FormGroup>
            </div>
          </div>
          
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(2, 'firstName', errors.firstName, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(2, 'lastName', errors.lastName, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(2, 'birthPlace', errors.birthPlace, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(
                2,
                'birthDate',
                errors.birthDate,
                { required: t(`general.errors.required`) },
                'date'
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(2, 'birthProvince', errors.birthProvince, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(2, 'managerPhoneNumber', errors.managerPhoneNumber, {
                required: t(`general.errors.required`),
              })}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(3, 'companyName', errors.companyName, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(3, 'fiscalCode', errors.fiscalCode, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(3, 'vatNumber', errors.vatNumber, {
                required: t(`general.errors.required`),
              })}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              {giveField(
                3,
                'isPersonal',
                errors.isPersonal,
                {
                  //required: t(`general.errors.required`) 
                },
                'checkbox'
              )}
            </div>

            {/*
            <div className="col-lg-6 col-md-6 col-sm-12">{giveField(1, 'telefono', errors.telefono, {})}</div>
            <div className="col-sm-6">{giveField(1, 'fromDate', errors.fromDate, {required: t(`general.errors.required`)}, "date")}</div>
            <div className="col-sm-6">{giveField(1, 'toDate', errors.toDate, {required: t(`general.errors.required`)}, "date")}</div>

            <div className="col-sm-4">{giveField(1, 'guests', errors.guests, {required: t(`general.errors.required`), value: 0}, "number")}</div>
            <div className="col-sm-4">{giveField(1, 'over65s', errors.over65s, {required: t(`general.errors.required`), value: 0}, "number")}</div>
            <div className="col-sm-4">{giveField(1, 'children', errors.children, {required: t(`general.errors.required`), value: 0}, "number")}</div>
            */}
          </div>

          <div className="row"></div>

          <Button
            className={step !== 5 && step !== 4 ? "" : "d-none"}
            type="button"
            onClick={(e) => {
              changeStep(step + 1);
            }}
            color="primary"
          >
            Next step
          </Button>
        
        </Form>
    
      { step == 4 && (<><Alert color="warning">
                <h4 className="alert-heading">
                  La tua struttura non è ancora stata inviata
                </h4>
                <hr />
                <p className="mb-0">
                  Ricontrolla tutti i dati da te inseriti e conferma per
                  inviare tutti i dati alla piattaforma
                </p>
            </Alert>
            <RequestDetail data={values} typoList={typeOptions} placeList={placeOptions} />
            <Alert style={{ textAlign: 'center', marginTop: '1rem' }}  color="warning">
                  <h4 className="alert-heading">Invia i tuoi dati</h4>
                  <hr />
                  <Button
                    className="mr-2"
                    size="lg"
                    type="button"
                    onClick={(e) => {
                      create();
                    }}
                    color="primary"
                  >
                    {!loading ? (
                      'Invia dati'
                    ) : (
                      <>
                        <Spinner size="sm"> Loading...</Spinner>
                        <span> {t('action.loading')}</span>
                      </>
                    )}
                  </Button>
                </Alert></>) }

        {step == 5 && (
                  <Alert>
                    <h4 className="alert-heading">
                      La tua struttura è stata inviata
                    </h4>
                    {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vestibulum purus quis auctor bibendum. Nunc libero augue, volutpat ut mi ac, aliquam tincidunt dui.</p> 
                    <hr />
                    <Button
                      className="mr-2"
                      type="button"
                      onClick={(e) => {
                        navigate('/stay');
                      }}
                      color="primary"
                    >
                      Vai ai miei soggiorni
                    </Button>*/}
                  </Alert> )}
      </div>
      </div>
      </div>
    );
  }
);

export default FacilityRequestRecordPageCreate;
