import { CreateSolicitor, UpdateSolicitor } from '../model/solicitor.model';
import { defaultHeaders } from './common';

export const getCreateAndAddSolicitorConf = (
  facilityId: number,
  createSolicitor: CreateSolicitor
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: `/api/facility/${facilityId}/add-solicitor`,
    headers: defaultHeaders,
    data: JSON.stringify(createSolicitor),
  };
};

export const getCreateSolicitorConf = (createSolicitor: CreateSolicitor) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/solicitor',
    headers: defaultHeaders,
    data: JSON.stringify(createSolicitor),
  };
};

export const getListSolicitorConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url: '/api/solicitor?page=' + page + '&size=' + size + query,
    headers: defaultHeaders,
  };
};

export const getDetailSolicitorConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/solicitor/${id}`,
    headers: defaultHeaders,
  };
};

export const getListByFacilityIdConf = (id: string | number) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/facility/${id}/solicitor/`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

export const getUpdateSolicitorConf = (id: number, data: UpdateSolicitor) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: `/api/solicitor/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};

export const getCreateSolicitorImageConf = (formData: any) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/solicitor-image',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    body: formData,
  };
};
export const getDeleteSolicitorImageConf = (facilityId: number) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: '/api/solicitor-image/' + facilityId,
    headers: defaultHeaders,
  };
};
