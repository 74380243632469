import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import './facility-view.css';

const MobileButton = (props: any) => {
  let inTable = props.inTable;
  if (props.children !== undefined) {
    return <Button {...props}>{props.children}</Button>;
  } else {
    let cleanedProp = JSON.parse(JSON.stringify(props));
    delete cleanedProp.icon;
    delete cleanedProp.text;
    delete cleanedProp.inTable;
    cleanedProp.onClick = props.onClick;

    return (
      <Button {...cleanedProp}>
        <>
          <FontAwesomeIcon icon={props.icon} />
          <span
            className={
              inTable !== undefined
                ? 'd-md-none d-lg-inline'
                : 'd-none d-md-inline'
            }
          >
            &nbsp;
            {props.text}
          </span>
        </>
      </Button>
    );
  }
};

export default MobileButton;
