import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import {
  getCreateFacilityConf,
  getUpdateFacilityConf,
} from '../../api/facilityAPI';
import useRequest from '../../hook/use-request.hook';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { EMAIL_REGEX, ZIP_CODE_REGEX, registerRs } from '../../util/form.util';
import { DetailFacility } from '../../model/facility.model';

interface FacilityFormProps {
  initialData?: DetailFacility;
  enabled: boolean;
  onSubmitSuccess: (result: ApiSuccess) => void;
  onSubmitError: (errors: ApiError[]) => void;
}

const FacilityForm = (props: FacilityFormProps) => {
  const { enabled, onSubmitSuccess, onSubmitError, initialData } = props;
  const { loading, fetchData } = useRequest();
  const onSubmit = (data: any) => {
    console.log('onSubmit');
    const { id,email, address, city, zipCode, province, name, active, fax, note, phoneNumber, beds } = data;

    let config;
    if (initialData && initialData.id) {
      config = getUpdateFacilityConf(
        { id,
          email,
          address,
          city,
          zipCode,
          province,
          name,
          typologyId: 'fff',
          placeId: 'fff',
          active,
          fax,
          note,
          phoneNumber,
          beds
        },
        Number(initialData.id)
      );
    } else {
      config = getCreateFacilityConf({
        id,
        email,
        address,
        city,
        zipCode,
        province,
        name,
        typologyId: 'fff',
        placeId: 'fff',
        active,
        fax,
        note,
        phoneNumber,
        beds
      });
    }
    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    onSubmitSuccess(data);
  };

  const onErrors = (errors: ApiError[]) => {
    onSubmitError(errors);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...initialData } });
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormGroup className="form-field col-lg-6 col-md-6 col-sm-12">
         
          <Input
            id="name"
            name="name"
            type="text"
            disabled={!enabled}
            {...registerRs(register, 'name', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.name && errors.name != null}
          /> <Label for="name">{t('facility.name')}</Label>
          <FormFeedback>{'' + errors?.name?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="form-field col-lg-6 col-md-6 col-sm-12">
          
          <Input
            id="email"
            name="email"
            type="text"
            disabled={!enabled}
            {...registerRs(register, 'email', {
              required: t(`general.errors.required`),
              pattern: {
                value: EMAIL_REGEX,
                message: t(`general.errors.invalid_email`),
              },
            })}
            invalid={errors.email && errors.email != null}
          /><Label for="email">{t('facility.email')}</Label>
          <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="form-field col-lg-12 col-md-12 col-sm-12">
          
          <Input
            id="address"
            name="address"
            type="text"
            disabled={!enabled}
            {...registerRs(register, 'address', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.address && errors.address != null}
          /><Label for="address">{t('facility.address')}</Label>
          <FormFeedback>{'' + errors?.address?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="form-field col-lg-4 col-md-6 col-sm-12">
         
          <Input
            id="city"
            name="city"
            type="text"
            disabled={!enabled}
            {...registerRs(register, 'city', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.city && errors.city != null}
          /> <Label for="city">{t('facility.city')}</Label>
          <FormFeedback>{'' + errors?.city?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="form-field col-lg-4 col-md-6 col-sm-12">
         
          <Input
            id="zipCode"
            name="zipCode"
            type="text"
            disabled={!enabled}
            {...registerRs(register, 'zipCode', {
              required: t(`general.errors.required`),
              pattern: {
                value: ZIP_CODE_REGEX,
                message: t(`general.errors.invalid_zip_code`),
              },
            })}
            invalid={errors.zipCode && errors.zipCode != null}
          /> <Label for="zipCode">{t('facility.zipCode')}</Label>
          <FormFeedback>{'' + errors?.zipCode?.message}</FormFeedback>
        </FormGroup>
        <FormGroup className="form-field col-lg-4 col-md-6 col-sm-12">
       
          <Input
            id="province"
            name="province"
            type="text"
            disabled={!enabled}
            {...registerRs(register, 'province', {
              required: t(`general.errors.required`),
            })}
            invalid={errors.province && errors.province != null}
          />   <Label for="province">{t('facility.province')}</Label>
          <FormFeedback>{'' + errors?.province?.message}</FormFeedback>
        </FormGroup>
      </div>

      {enabled && (
        <Button type="submit" disabled={loading} color="primary">
          {!loading ? (
            t('action.send')
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span> {t('action.loading')}</span>
            </>
          )}
        </Button>
      )}
    </Form>
  );
};

export default FacilityForm;
