import { CreateFacilityRecordRequestReq } from '../model/facility-record-request.model';
import { defaultHeaders } from './common';

export const getCreateFacilityRecordReqConf = (
  createFacilityRecordRequestReq: CreateFacilityRecordRequestReq
) => {
  return {
    method: 'post',
    url: '/api/facility-record-request',
    headers: defaultHeaders,
    data: JSON.stringify(createFacilityRecordRequestReq),
  };
};

export const getUpdateFacilityRecordReqConf = (
  id: string,
  createFacilityRecordRequestReq: CreateFacilityRecordRequestReq
) => {
  return {
    method: 'put',
    url: `/api/facility-record-request/${id}`,
    headers: defaultHeaders,
    data: JSON.stringify(createFacilityRecordRequestReq),
  };
};

export const getListFacilityRecordReqConf = () => {
  return {
    method: 'get',
    url: '/api/facility-record-request',
    headers: defaultHeaders,
  };
};

export const getDetailFacilityRecordReqConf = (id: string) => {
  return {
    method: 'get',
    url: `/api/facility-record-request/${id}`,
    headers: defaultHeaders,
  };
};

export const getApproveFacilityRecordReqConf = (id: number) => {
  return {
    method: 'put',
    url: `/api/facility-record-request/${id}/approve`,
    headers: defaultHeaders,
  };
};

export const getRejectFacilityRecordReqConf = (id: number) => {
  return {
    method: 'put',
    url: `/api/facility-record-request/${id}/reject`,
    headers: defaultHeaders,
  };
};
