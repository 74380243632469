import {
  faArrowLeft,
  faCity,
  faCrosshairs,
  faEye,
  faHouseLock,
  faPlus,
  faRefresh,
  faSearch,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap';
import { getListFacilityPagedConf } from '../../api/facilityAPI';
import { getListFacilityTypePagedConf } from '../../api/facilityTypeAPI';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import { ErrorsView } from '../../components/errors.view';
import IconButtonWithTooltip from '../../components/icon-button-with-tooltip';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { DetailFacilityType } from '../../model/facility-type.model';
import { DetailFacility } from '../../model/facility.model';
import {
  Authorities,
  currentUserSelector,
  userLngSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';
import { campoByLang, icons, registerRs } from '../../util/form.util';
import { SERVER_API_URL } from '../../axios/config';
export const giveUrl = (it: any) => {
  return (
    'url("' +
    (it?.images && it?.images.length > 0
      ? SERVER_API_URL + '/' + it.images[0].url
      : // : SERVER_API_URL + '/default-weave.png') +
        SERVER_API_URL + '/placetoken.png') +
    '")'
  );
};

export default function FacilityListPage() {
  const currentUser = useAppSelector(currentUserSelector);
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailFacility[]>([]);
  const { loading: loadingTypology, fetchData: fetchTypologies } = useRequest();
  const [typeOptions, setTypeOptions] = useState<DetailFacilityType[]>([]);
  const [typeSelected, setTypeSelected] = useState<any | undefined>(undefined);
  const [text, setText] = useState<any | undefined>(undefined);
  const [all, setAll] = useState<boolean>(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order
  const lang = useAppSelector(userLngSelector);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSort = (order:any) => {
    setSortOrder(order);
    refresh(pagination, typeSelected, text, all, order)
  };


  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const refresh = (pag: any, type: any, text: any, all: any, order:string='asc') => {
    let query = '';
    if (type !== undefined) query = '&typologyId=' + type;
    if (text !== undefined) query = query + '&text=' + text;
    if (!all) query = query + '&isMine=true';
    if (order !== undefined) query = query + '&sortOrder=' + order;

    fetchData(
      getListFacilityPagedConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrors
    );
    // console.log('*****', pag);
  };
  const create = () => {
    navigate('create');
  };

  useEffect(() => {
    fetchTypologies(
      getListFacilityTypePagedConf(1, 20),
      (data, headers) => {
        if (Array.isArray(data)) {
          setTypeOptions(data);
        }
      },
      () => {
        console.log('error');
      }
    );
    refresh(pagination, typeSelected, text, true, sortOrder);
  }, []);

  const giveSrc = (it: any) => {
    return it?.images && it?.images.length > 0
      ? SERVER_API_URL + '/' + it.images[0].url
      : SERVER_API_URL + '/default-weave.png';
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data: any) => {
    refresh(
      {
        page: 1,
        pageSize: 20,
        totalElements: null,
      },
      typeSelected,
      data.text,
      all,
      sortOrder
    );
  };

  const isMine = (email: string) => {
    return currentUser?.email == email;
  };
  return (
    <div className="container mt-2">
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: '12px' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
        {t('general.buttons.back')}
      </Button>
      <div className="header-container">
        <h2 className="page-title">{t('facility.list.title')}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className="card-body">
          <h6 className="mt-1 mb-3">{t("facility.list.filter")}</h6>
          <div className="mt-2">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <FormGroup className="form-field">
                    <Input
                      id="text"
                      name="text"
                      type="text"
                      {...registerRs(register, 'text', {})}
                      invalid={errors.text && errors.text != null}
                    />
                  </FormGroup>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-2">
                  <Button color="primary" type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="mt-2">
            <Button
              color={typeSelected == undefined ? 'primary' : 'light'}
              onClick={() => {
                setTypeSelected(undefined);
                refresh(pagination, undefined, text, all,sortOrder);
              }}
              className="mb-2"
              style={{ marginRight: '10px' }}
            >
              {t("general.all")}
            </Button>
            {typeOptions.map((e: any, index: any) => {
              return (
                <Button
                  color={typeSelected == e.id ? 'primary' : 'light'}
                  onClick={() => {
                    setTypeSelected(e.id);
                    refresh(
                      {
                        page: 1,
                        pageSize: 20,
                        totalElements: null,
                      },
                      e.id,
                      text,
                      all,
                      sortOrder
                    );
                  }}
                  className="mb-2"
                  style={{ marginRight: '10px' }}
                  key={index}
                >
                  <FontAwesomeIcon icon={icons.get(e.icon)} />{' '}
                  <span style={{ textTransform: 'capitalize' }}>{ campoByLang(e.name, lang )}</span>
                </Button>
              );
            })}
          </div>
          {currentUser?.authorities?.includes(Authorities.MANAGER) && (
            <div className="mt-3">
              <ButtonGroup>
                <Button
                  color={all ? 'primary' : 'light'}
                  onClick={() => {
                    setAll(true);
                    refresh(pagination, undefined, text, true,sortOrder);
                  }}
                >
                  Tutte le strutture
                </Button>
                <Button
                  color={!all ? 'primary' : 'light'}
                  onClick={() => {
                    setAll(false);
                    refresh(pagination, undefined, text, false,sortOrder);
                  }}
                >
                  {t('general.buttons.myfacilities')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </div>
      </div>
      <div
        className="justify-content-end pt-3"
        style={{ display: 'flex', gap: '10px' }}
      >
        {currentUser &&
          checkRequiredAuthorities(
            [Authorities.ADMIN, Authorities.MUNICIPALITY],
            currentUser
          ) && (
            <MobileButton
              onClick={create}
              disabled={loading}
              color="primary"
              icon={faPlus}
              text={t('general.buttons.add')}
            />
          )}
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle caret color="primary">
          {sortOrder === 'asc' ? (
            <>
              <FontAwesomeIcon icon={faSortAlphaDown} /> {t('general.actions.order')}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faSortAlphaUp} /> {t('general.actions.order')}
            </>
          )}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleSort('asc')}>
            <FontAwesomeIcon icon={faSortAlphaDown} /> A-Z
          </DropdownItem>
          <DropdownItem onClick={() => handleSort('desc')}>
            <FontAwesomeIcon icon={faSortAlphaUp} /> Z-A
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
        <MobileButton
          onClick={() => refresh(pagination, typeSelected, text, all, sortOrder)}
          disabled={loading}
          color="secondary"
          outline
        >
          {!loading ? (
            <>
              <FontAwesomeIcon icon={faRefresh} />
              <span className="d-none d-md-inline">
                &nbsp; {t('general.buttons.refresh')}
              </span>
            </>
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span className="d-none d-md-inline">
                {' '}
                {t('action.loading')}{' '}
              </span>
            </>
          )}
        </MobileButton>
      </div>

      <div className="mt-3 mb-3 row">
        {list.map((it, idx) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={it.id}>
              <div className="facility-card float-right">
                <div className="row border-gradient" style={{ margin: 0 }}>
                  <div
                    onClick={() => navigate(`/facility/${it.id}`)}
                    style={{
                      backgroundImage: giveUrl(it),
                      backgroundSize:
                        it?.images && it?.images.length > 0
                          ? 'cover'
                          : 'contain',
                      backgroundColor: '#214f6c',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    className="col-4 ml-0"
                  ></div>
                  <div className="col-8">
                    <div className="body mt-3 mb-3 d-flex flex-column justify-content-center">
                      {it.typology && (
                        <span className="lightblu-contrast">
                          <FontAwesomeIcon icon={icons.get(it.typology.icon)} />{' '}
                          { campoByLang(it.typology.name, lang )}
                        </span>
                      )}
                      <span className="facility-card-title">{it.name}</span>
                      <span
                        style={{
                          fontSize: '.75em',
                          textOverflow: 'ellipsis',
                          width: '100%',
                          display: 'inline-block',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <FontAwesomeIcon icon={faCity} color="#004C6C" />{' '}
                        {/* {it.city} -  */}
                        {it.place?.name.toUpperCase()}
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faCrosshairs} color="#004C6C" />{' '}
                        {it.address}
                      </span>
                    </div>
                    <IconButtonWithTooltip
                      id={`view-${it.id}`}
                      icon={faEye}
                      color={'primary'}
                      title={t('general.buttons.view')}
                      style={{
                        float: 'right',
                        marginTop: '-27px',
                        marginRight: '-10px',
                      }}
                      onClick={() => navigate(`/facility/${it.id}`)}
                    ></IconButtonWithTooltip>
                    {/*checkRequiredAuthorities(
                      [Authorities.MANAGER],
                      currentUser
                    ) && isMine(it.email) ? (
                      <IconButtonWithTooltip
                        id={`occupancy-${it.id}`}
                        icon={faHouseLock}
                        color={'primary'}
                        title={t('general.buttons.view')}
                        style={{
                          float: 'right',
                          marginTop: '-27px',
                          marginRight: '-10px',
                        }}
                        onClick={() => navigate(`/facility/${it.id}/occupancy`)}
                      ></IconButtonWithTooltip>
                    ) : (
                      ''
                    )*/}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <PaginationUtility
        {...pagination}
        onSizeChange={(n: any) => {
          if (pagination.pageSize !== n)
            refresh(
              { ...pagination, ...{ page: 1, pageSize: n } },
              typeSelected,
              text,
              all,
              sortOrder
            );
        }}
        onChange={(n) => {
          if (pagination.page !== n)
            refresh({ ...pagination, ...{ page: n } }, typeSelected, text, all, sortOrder);
        }}
      />
    </div>
  );
}
