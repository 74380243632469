import { Route, useLocation } from "react-router-dom";
import PageRoutes from "./pages/page.routes";
import ErrorBoundaryRoutes from "./shared/error-boundary-routes";
import { userAccessTokenSelector } from "./redux/reducers/userSlice";
import { useAppSelector } from "./redux/store";
import AppSideBar from "./navigation/sidebar";
import AppNavBar from "./navigation/navbar";
import CurrentUserView from "./pages/login/current-user.view";
import AppFooter from "./navigation/footer";

const AppRoutes = () => {
  const accessToken = useAppSelector(userAccessTokenSelector);
  const location = useLocation(); // necessario per far aggiornare la pagina in caso di refresh
  return (
    <>
      {window.location.pathname === "/pdf-viewer" ? (
        <ErrorBoundaryRoutes>
          <Route path="*" element={<PageRoutes />} />
        </ErrorBoundaryRoutes>
      ) : (
        <div id="app" style={{ display: "flex", width: "100%" }}>
          {accessToken && false && <AppSideBar />}
          <main style={{ width: "100%", minHeight: "100vh" }}>
            <div style={{ minHeight: "100vh" }}>
              <AppNavBar></AppNavBar>
              <CurrentUserView></CurrentUserView>
              <ErrorBoundaryRoutes>
                <Route path="*" element={<PageRoutes />} />
              </ErrorBoundaryRoutes>
              <AppFooter />
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default AppRoutes;
