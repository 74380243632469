import { t } from 'i18next';


export default function RequestDetail(props: any) {
  let { data , typoList, placeList } = props;

  let fields = [
    "facilityName",
    "email",
    "address",
    "city",
    "zipCode",
    "province",
    "active",
    "fax"
  ]

  let fields1 = [
    "phoneNumber",
    "beds",
    "typologyId",
    "placeId"
  ]
  let fields2 = [
    "firstName",
    "lastName",
    "birthPlace",
    "birthDate",
    "birthProvince",
    "managerPhoneNumber"
  ]
  let fields3 = [
    "companyName",
    "fiscalCode",
    "vatNumber",
    "isPersonal" 
  ]

  const getCampi = (el:any) => {
    return <div className="col-lg-6 col-md-6 col-sm-12"><span style={{ color: 'grey', fontSize: '.8rem' }}>
    {t('facility-record-request.' + el)}
  </span> {data[el]}</div>
  }

  const type = typoList.find((el: any) => el.id == data.typologyId)
  const place = placeList.find((el: any) => el.id == data.placeId)

  return (
    <div className="row">
      <div className="col-sm-12"><h4>Struttura</h4></div>
      { fields.map(el => getCampi(el))}
      <div className="col-sm-12">{data.note}</div>
      { fields1.map(el => getCampi(el))}
      <div className="col-lg-6 col-md-6 col-sm-12"><span style={{ color: 'grey', fontSize: '.8rem' }}>
    {t('facility-record-request.typologyId')}
  </span> {type.name}</div>

  <div className="col-lg-6 col-md-6 col-sm-12"><span style={{ color: 'grey', fontSize: '.8rem' }}>
    {t('facility-record-request.placeId')}
  </span> {place.name}</div>
      <div className="col-sm-12 mt-4"><h4>Responsabile</h4></div>
      { fields2.map(el => getCampi(el))}
      <div className="col-sm-12 mt-4"><h4>Billing</h4></div>
      { fields3.map(el => getCampi(el))}
    </div>
  );
}
