/* eslint-disable react/jsx-no-undef */
import "../home/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faPhone, faClock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../redux/store";
import { userLngSelector } from "../../redux/reducers/userSlice";

export default function Infopoint() {
  const lang = useAppSelector(userLngSelector);
  return (
    <>
      <div id="card">
        <div className="wpb_wrapper wpb_wrapper_blue head">
          <div className="container">
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space" style={{ height: "10px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="widget-text-heading center_white">
              <h2 className="widget-title">
                <span>Infopoint</span>
              </h2>
            </div>
            <div className="empty_space" style={{ height: "35px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
          </div>
        </div>
      </div>

      {lang === "it" ? (
        <div className="container pt-2 mt-2 mb-5">
          <h1 className="page-title">Infopoint - Marine di Melendugno</h1>

          <div className="clearfix">
            <img
              src={"/infopoint.jpeg"}
              alt={"Infopoint"}
              className="rounded float-start me-3"
              style={{ width: "40%", height: "auto", borderRadius: "10px" }}
            />
            <p className="text-b">
              Dal 1° Giugno sarà in funzione tutti i giorni dalle 9:00 alle 13:00 e dalle 16:00 alle 20:00, il servizio di
              informazione e accoglienza turistica.
            </p>
            <div className="text-b">
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>e-mail</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faEnvelope} />{" "}
                <a href="mailto:infopointmarinedimelendugno@gmail.com">infopointmarinedimelendugno@gmail.com</a>
              </div>
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>San Foca</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faClock} /> 9/13 - 17/21
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faCrosshairs} /> Indirizzo: Zona
                Ricci
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faPhone} /> 329 3658519
              </div>
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>Torre dell'Orso</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faClock} /> 9/13 - 16/20
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faCrosshairs} /> Lungomare Matteotti
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faPhone} /> 329 2583678
              </div>
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>Sant'Andrea</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faClock} /> 9/13 - 16/20
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faCrosshairs} /> Zona Faraglioni
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faPhone} /> 329 3952460
              </div>
            </div>
            <div className="float-none"></div>
          </div>
        </div>
      ) : (
        <div className="container pt-2 mt-2 mb-5">
          <h1 className="page-title">Infopoint - Melendugno Marine</h1>

          <div className="clearfix">
            <img
              src={"/infopoint.jpeg"}
              alt={"Infopoint"}
              className="rounded float-start me-3"
              style={{ width: "40%", height: "auto", borderRadius: "10px" }}
            />
            <p className="text-b">
              Starting June 1, the tourist information and reception service will be in operation daily from 9:00 am to 1:00
              pm and 4:00 pm to 8:00 pm.
            </p>{" "}
            <div className="text-b">
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>e-mail</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faEnvelope} />{" "}
                <a href="mailto:infopointmarinedimelendugno@gmail.com">infopointmarinedimelendugno@gmail.com</a>
              </div>
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>San Foca</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faClock} /> 9/13 - 17/21
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faCrosshairs} /> Address: Zona
                Ricci
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faPhone} /> 329 3658519
              </div>
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>Torre dell'Orso</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faClock} /> 9/13 - 16/20
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faCrosshairs} /> Lungomare Matteotti
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faPhone} /> 329 2583678
              </div>
              <div className="pb-4">
                <b style={{ color: "#00415c" }}>Sant'Andrea</b>
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faClock} /> 9/13 - 16/20
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faCrosshairs} /> Zona Faraglioni
                <br />
                <FontAwesomeIcon style={{ marginLeft: "15px", color: "#00415c" }} icon={faPhone} /> 329 3952460
              </div>
            </div>
            <div className="float-none"></div>
          </div>
        </div>
      )}
    </>
  );
}
