import {Navigate, Route} from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';


import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import EventsPageMain from './eventi_page';
import EventDetails from './events_details';
import EventCreate from './event-create_page';
import EventUpdate from './event-update_page';

const EventsRoutes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentUser = useAppSelector(currentUserSelector);
  const isManager = checkRequiredAuthorities([Authorities.MANAGER], currentUser);
// console.log("entro in eventos")
  return (
    <ErrorBoundaryRoutes>
        <Route path="" element={<EventsPageMain />} />
        <Route path="detail" element={<EventDetails />} />
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY, Authorities.OPERATOR],
        currentUser
      ) && (
        <>         
          <Route path="create" element={<EventCreate />} />
          <Route path="detail/:id/update" element={<EventUpdate />} />
        </>
       )} 
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default EventsRoutes;
