import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export enum Authorities {
  USER = 'USER',
  MANAGER = 'MANAGER',
  MUNICIPALITY = 'MUNICIPALITY',
  SOLICITOR = 'SOLICITOR',
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
}

export interface CurrentUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  authorities: Authorities[];
}

export interface UserState {
  accessToken: string | null;
  refreshToken: string | null;
  lang: string | null;
  currentUser: CurrentUser | null;
}

const initialState: UserState = {
  accessToken: null,
  refreshToken: null,
  lang: 'it',
  currentUser: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearState: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.currentUser = null;
      return state;
    },
    logout: (state) => {
      //elimino le pendingStay
      localStorage.removeItem('pendingStay');
      //console.log('logout');
      state.currentUser = null;
      state.accessToken = null;
      state.refreshToken = null;
      return state;
    },
    signinSuccess: (state, action) => {
      //console.log('action', action);
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    currentUserSuccess: (state, action) => {
     // console.log('action', action);
      const { currentUser } = action.payload;
      state.currentUser = currentUser;
    },
    changeUserLanguage: (state, action) => {
      console.log('action change lang', action);
      state.lang = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // builder
    // .addCase(signinAsync.pending, (state) => {
    //     state.isLoading = true;
    //     state.isError = false;
    //     state.isSuccess = false;
    //     state.errors = []
    // })
    // .addCase(signinAsync.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isSuccess = true;
    //     state.jwt = action.payload.jwt;
    // })
    // .addCase(signinAsync.rejected, (state, action) => {
    //     let payload = action.payload as any;
    //     state.isLoading = false;
    //     state.isError = true;
    //     state.errors = payload.errors;
    // });
  },
});

export const {
  logout,
  clearState,
  signinSuccess,
  currentUserSuccess,
  changeUserLanguage,
} = userSlice.actions;

export const userAccessTokenSelector = (state: RootState) =>
  state.user.accessToken;
export const userLngSelector = (state: RootState) => state.user.lang || 'it';
export const currentUserSelector = (state: RootState) => state.user.currentUser;

export default userSlice.reducer;
