import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import FacilityCreatePage from './facility-create.page';
import FacilityListPage from './facility-list.page';
import FacilityDetailPage from './facility-detail.page';
import FacilityUpdatePage from './facility-update.page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

const FacilityRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      <Route path="" element={<FacilityListPage />} />
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      ) && (
        <>
          <Route path="" element={<FacilityListPage />} />
          <Route path=":id" element={<FacilityDetailPage />} />
          <Route path="create" element={<FacilityCreatePage />} />
          <Route path=":id/update" element={<FacilityUpdatePage />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default FacilityRoutes;
