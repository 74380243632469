import { defaultHeaders } from './common';

export const getDetailStayGuestConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/stay-guest/${id}`,
    headers: defaultHeaders,
  };
};

export const getCheckCodeConf = (code: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/stay-guest-code/check?code=${code}`,
    headers: defaultHeaders,
  };
};
