import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap';
import { checkRequiredAuthorities } from '../components/authority-boundary-view';
import { Authorities, currentUserSelector } from '../redux/reducers/userSlice';
import { useAppSelector } from '../redux/store';
import { useTranslation } from 'react-i18next';

interface AppMenuProps {
  onMenuItemClick: any;
}
export default function AppMenu({ onMenuItemClick }: AppMenuProps) {
  const [expanded, setExpanded] = useState(null);
  const currentUser = useAppSelector(currentUserSelector);
  const localtion = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // console.log('localtion', localtion);

  let menu: {
    text: string;
    link: string | null;
    external?: boolean;
    icon: string | null;
    child?: any;
  }[] = [
    {
      text: t("sidebar.home"),
      link: '/',
      external: true,
      icon: null,
    },
    {
      text:  t("sidebar.card"),
      link: localtion.pathname == '/' ? '#card' : '/stay/new',
      external: true,
      icon: null,
    },
    {
      text: t("sidebar.facilities"),
      link: localtion.pathname == '/' ? '#strutture' : '/facility',
      external: true,
      icon: null,
    },
    {
      text: t("sidebar.eventi"),
      link: '/events',
      external: true,
      icon: null,
    },
    {
      text:  t("sidebar.servizi"),
      link: '/#servizi',
      external: true,
      icon: null,
    },
    {
      text:  t("sidebar.tour"),
      link: '/tour',
      external: true,
      icon:null
    }
    
  ];

  const isAdministrator = checkRequiredAuthorities(
    [Authorities.ADMIN],
    currentUser
  );
  const isMunicipality = checkRequiredAuthorities(
    [Authorities.MUNICIPALITY],
    currentUser
  );
  const isManager = checkRequiredAuthorities(
    [Authorities.MANAGER],
    currentUser
  );
  const isSolicitor = checkRequiredAuthorities(
    [Authorities.SOLICITOR],
    currentUser
  );
  const isUser = checkRequiredAuthorities([Authorities.USER], currentUser);

  if (isMunicipality || isManager || isSolicitor || isAdministrator) {
    menu = [
      {
        text: t("sidebar.home"),
        link: '/',
        external: true,
        icon: null,
      },
      {
        text: t("sidebar.stay"),
        link: '/stay',
        external: true,
        icon: null,
      },
      {
        text: t("sidebar.facilities"),
        link: '/facility',
        external: true,
        icon: null,
      },
      {
        text: t("sidebar.events"),
        link: '/events',
        external: true,
        icon: null,
      },
    ];

    if (isMunicipality || isAdministrator) {
      menu.push({
        text: t("sidebar.tax"),
        link: '/payment',
        external: true,
        icon: null,
      });
    }
  } else if (isUser) {
    menu = [
      {
        text: t("sidebar.home"),
        link: '/',
        external: true,
        icon: null,
      },
      {
        text: t("sidebar.mystays"),
        link: '/stay',
        external: true,
        icon: null,
      },
      {
        text: t("sidebar.facilities"),
        link: '/facility',
        external: true,
        icon: null,
      },
      {
        text: t("sidebar.events"),
        link: '/events',
        external: true,
        icon: null,
      },
    ];
  }

  // if (isManager || isSolicitor) {
  //   menu.push({
  //     text: "Richieste di Soggiorno",
  //     link: '/stay',
  //     external: true,
  //     icon: null
  //   })
  // }

  // if (isMunicipality || isAdministrator) {
  //   menu.push({
  //     text: "Richieste di registrazione",
  //     link: '/facility-record-request',
  //     external: true,
  //     icon: null
  //   }, {
  //     text: "Anagrafiche",
  //     link: null,
  //     icon: null,
  //     child: [{
  //       text: "Responsabili di struttura",
  //       link: '/solicitor',
  //       external: true,
  //       icon: null
  //     },
  //     {
  //       text: "Gestione Dati Fiscali",
  //       link: '/company',
  //       external: true,
  //       icon: null
  //     }, {
  //       text: "Tipologia Strutture",
  //       link: '/facility-typology',
  //       external: true,
  //       icon: null
  //     },
  //     {
  //       text: "Località",
  //       link: '/place',
  //       external: true,
  //       icon: null
  //     },
  //     ]
  //   })
  // }

  const handleItemClick = (link: string) => {
    onMenuItemClick(); 
    navigate(link);
  };

  return (
    <Nav className="mr-auto" navbar>
      {menu.map((el: any, idx: number) => {
        if (el.child !== undefined) {
          return (
            <Dropdown
              nav
              isOpen={el.text == expanded}
              toggle={() => {
                if (el.text == expanded) setExpanded(null);
                else setExpanded(el.text);
              }}
              key={idx}
            >
              <DropdownToggle nav caret>
                {el.icon != null ? (
                  <>
                    <FontAwesomeIcon
                      style={{ marginRight: '10px' }}
                      icon={el.icon}
                    />{' '}
                  </>
                ) : (
                  ''
                )}
                {el.text}
              </DropdownToggle>
              <DropdownMenu>
                {el.child.map((ch: any) => {
                  return (
                    <DropdownItem>
                      <a
                        className="nav-link"
                        href={ch.link}
                        rel="noopener noreferrer"
                      >
                        {ch.text}
                      </a>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          );
        } else {
          return el.external ? (
            <li className="nav-item" key={idx}>
              <a className="nav-link" href={el.link} rel="noopener noreferrer" onClick={onMenuItemClick}>
                {el.text}
              </a>
            </li>
          ) : (
            <NavItem>
              <NavLink to={el.link} onClick={() => handleItemClick(el.link)}>
                {el.icon != null ? (
                  <>
                    <FontAwesomeIcon
                      style={{ marginRight: '10px' }}
                      icon={el.icon}
                    />{' '}
                  </>
                ) : (
                  ''
                )}
                {el.text}
                
              </NavLink>
            </NavItem>
          );
        }
      })}
    </Nav>
  );
}
