import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import {
  getCreateFacilityConf,
  getUpdateFacilityConf,
} from '../../api/facilityAPI';
import { getListFacilityTypePagedConf } from '../../api/facilityTypeAPI';
import { getListPlaceConf } from '../../api/placeAPI';
import useRequest from '../../hook/use-request.hook';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { DetailFacilityType } from '../../model/facility-type.model';
import { DetailFacility, DetailFacilityEdit } from '../../model/facility.model';
import { DetailPlace } from '../../model/place.model';
import { EMAIL_REGEX, ZIP_CODE_REGEX, campoByLang, registerRs, splitCampi } from '../../util/form.util';
import { useAppSelector } from '../../redux/store';
import { userLngSelector } from '../../redux/reducers/userSlice';

interface FacilityFormProps {
  initialData?: DetailFacility;
  enabled: boolean;
  onSubmitSuccess: (result: ApiSuccess) => void;
  onSubmitError: (errors: ApiError[]) => void;
}

const FacilityForm = (props: FacilityFormProps) => {
  const { enabled, onSubmitSuccess, onSubmitError, initialData } = props;
  const { loading, fetchData } = useRequest();
  const { loading: loadingTypology, fetchData: fetchTypologies } = useRequest();
  const { loading: loadingPlaces, fetchData: fetchPlaces } = useRequest();
  const lang = useAppSelector(userLngSelector);

  const [data, setData] = useState<DetailFacilityEdit>();
  const [typeOptions, setTypeOptions] = useState<DetailFacilityType[]>([]);
  const [placeOptions, setPlaceOptions] = useState<DetailPlace[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    // console.log('---- FacilityForm initialData', initialData?.typology);

    if (initialData) {
      let d = JSON.parse(JSON.stringify(initialData));
      d.typologyId = initialData.typology ? initialData.typology.id : 0;
      d.placeId = initialData.place ? initialData.place.id : 0;
      d.active = initialData.active ? 0 : 1;
      d = splitCampi(d, ['note']) 

      delete d.place;
      delete d.typology;
      // console.log('initialData elaborato', d);

      setData(d);
    }
  }, [initialData, typeOptions, placeOptions]);

  useEffect(() => {
    fetchTypologies(
      getListFacilityTypePagedConf(1, 20),
      (data, headers) => {
        if (Array.isArray(data)) {
          const emptyOption = [{ id: '', name: '- seleziona una struttura -' }];
          setTypeOptions(emptyOption.concat(data));
        }
      },
      () => {
        console.log('error');
      }
    );

    fetchPlaces(
      getListPlaceConf(),
      (data, headers) => {
        if (Array.isArray(data)) {
          const emptyOption = [{ id: '', name: '- seleziona una località -' }];
          setPlaceOptions(emptyOption.concat(data));
        }
      },
      () => {
        console.log('error');
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    // console.log('onSubmit');
    let {
      id,
      email,
      address,
      city,
      zipCode,
      province,
      name,
      typologyId,
      placeId,
      active,
      fax,
      note,
      phoneNumber,
      beds,
    } = data;
    //    console.log("DATI?????", data)
    active = active ? 0 : 1;
    let config;
    note = JSON.stringify(note)
    if (initialData && initialData.id) {
      config = getUpdateFacilityConf(
        {
          id,
          email,
          address,
          city,
          zipCode,
          province,
          name,
          typologyId,
          placeId,
          active,
          fax,
          note,
          phoneNumber,
          beds,
        },
        Number(initialData.id)
      );
    } else {
      config = getCreateFacilityConf({
        id,
        email,
        address,
        city,
        zipCode,
        province,
        name,
        typologyId,
        placeId,
        active,
        fax,
        note,
        phoneNumber,
        beds,
      });
    }
    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (dataa: any) => {
    onSubmitSuccess(dataa);
  };

  const onErrors = (errors: ApiError[]) => {
    onSubmitError(errors);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...data } });

  //  console.log("----------------", data)

  return !loadingPlaces && !loading && !loadingTypology ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="name"
              name="name"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'name', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.name && errors.name != null}
            />
            <Label for="name">{t('facility.name')}</Label>
            <FormFeedback>{'' + errors?.name?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="email"
              name="email"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'email', {
                required: t(`general.errors.required`),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t(`general.errors.invalid_email`),
                },
              })}
              invalid={errors.email && errors.email != null}
            />
            <Label for="email">{t('facility.email')}</Label>
            <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="address"
              name="address"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'address', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.address && errors.address != null}
            />{' '}
            <Label for="address">{t('facility.address')}</Label>
            <FormFeedback>{'' + errors?.address?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="city"
              name="city"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'city', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.city && errors.city != null}
            />{' '}
            <Label for="city">{t('facility.city')}</Label>
            <FormFeedback>{'' + errors?.city?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="zipCode"
              name="zipCode"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'zipCode', {
                required: t(`general.errors.required`),
                pattern: {
                  value: ZIP_CODE_REGEX,
                  message: t(`general.errors.invalid_zip_code`),
                },
              })}
              invalid={errors.zipCode && errors.zipCode != null}
            />
            <Label for="zipCode">{t('facility.zipCode')}</Label>
            <FormFeedback>{'' + errors?.zipCode?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="province"
              name="province"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'province', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.province && errors.province != null}
            />{' '}
            <Label for="province">{t('facility.province')}</Label>
            <FormFeedback>{'' + errors?.province?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Label className="select" for="typologyId">
              {t('facility.typology')}
            </Label>
            <Input
              id="typology"
              name="typologyId"
              type="select"
              disabled={!enabled}
              {...registerRs(register, 'typologyId', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.typologyId && errors.typologyId != null}
            >
              {typeOptions.map((it) => {
                return (
                  <option key={it.id} value={it.id}>
                    {campoByLang(it.name, lang)}
                  </option>
                );
              })}
            </Input>
            <FormFeedback>{'' + errors?.typologyId?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Label className="select" for="placeId">
              {t('facility.place')}
            </Label>

            <Input
              id="place"
              name="placeId"
              type="select"
              disabled={!enabled}
              {...registerRs(register, 'placeId', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.placeId && errors.placeId != null}
            >
              {placeOptions.map((it) => {
                return (
                  <option key={it.id} value={it.id}>
                    {it.name}
                  </option>
                );
              })}
            </Input>
            <FormFeedback>{'' + errors?.placeId?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup check>
            <Input
              id="active"
              name="active"
              disabled={!enabled}
              {...registerRs(register, 'active', {
                //  onChange: (e:any) => {
                //   let g = getValues()
                //   setIsPersonal(g.personal !== undefined ? g.personal : false)
                //  }
                //  required: t(`general.errors.required`),
              })}
              type="checkbox"
            />{' '}
            <Label for="active" check className="mb-2">
              {t('facility.active')}
            </Label>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="fax"
              name="fax"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'fax', {
                //required: t(`general.errors.required`),
              })}
              //invalid={errors.fax && errors.fax != null}
            />{' '}
            <Label for="fax">{t('facility.fax')}</Label>
            <FormFeedback>{'' + errors?.fax?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="beds"
              name="beds"
              type="number"
              disabled={!enabled}
              {...registerRs(register, 'beds', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.beds && errors.beds != null}
            />{' '}
            <Label className="number" for="beds">
              {t('facility.beds')}
            </Label>
            <FormFeedback>{'' + errors?.beds?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'phoneNumber', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.phoneNumber && errors.phoneNumber != null}
            />{' '}
            <Label for="phoneNumber">{t('facility.phoneNumber')}</Label>
            <FormFeedback>{'' + errors?.phoneNumber?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <FormGroup className="form-field">
            <span style={{ color: '#6c757d' }}>{t('facility.note')} IT</span>{' '}
            <Input
              id="note"
              name="note"
              type="textarea"
              disabled={!enabled}
              style={{ width: '100%', height: '100px' }}
              {...registerRs(register, 'note.it', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.note && errors.note != null}
            />{' '}
            {/* <Label for="note">{t('facility.note')}</Label> */}
            <FormFeedback>{'' + errors?.note?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-field">
            <span style={{ color: '#6c757d' }}>{t('facility.note')} EN</span>{' '}
            <Input
              id="note"
              name="note"
              type="textarea"
              disabled={!enabled}
              style={{ width: '100%', height: '100px' }}
              {...registerRs(register, 'note.en', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.note && errors.note != null}
            />{' '}
            {/* <Label for="note">{t('facility.note')}</Label> */}
            <FormFeedback>{'' + errors?.note?.message}</FormFeedback>
          </FormGroup>
        </div>
      </div>
      {enabled && (
        <Button
          disabled={loading}
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          {t('general.buttons.cancel')}
        </Button>
      )}
      {enabled && (
        <Button type="submit" disabled={loading} color="primary">
          {!loading ? (
            t('action.send')
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span> {t('action.loading')}</span>
            </>
          )}
        </Button>
      )}
    </Form>
  ) : (
    <>loading</>
  );
};

export default FacilityForm;
