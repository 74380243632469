import { Badge, Table } from 'reactstrap';
import { MobileTable } from './mobile-table';

import { t } from 'i18next';
function TouristTaxTable(props: { data: any; tax: number }) {
  let data = props.data;
  let tax = props.tax;
  const proUserTax = tax;

  let total = 0;
  let newData = data.map((it: any) => {
    let tax = it.guests * (1 - it.amount / 100) * proUserTax;
    total += tax;
    return { ...it, amount: it.amount + '%', price: tax.toFixed(2) + '€' };
  });
  let columns = [
    {
      Header: t('occupancy-record.id'),
      accessor: 'id',
      props: { width: '10%' },
    },
    {
      Header: t('occupancy-record.exemption_name'),
      accessor: 'name',
    },
    {
      Header: t('occupancy-record.exemption'),
      accessor: 'amount',
    },
    {
      Header: t('occupancy-record.guests'),
      accessor: 'guests',
    },
    {
      Header: 'price',
      accessor: 'price',
    },
  ];
  return (
    <div>
      {/* <div>{tax}</div> */}
      <MobileTable columns={columns} data={newData} />
      <div
        className="mt-2"
        style={{
          display: 'flex',
          fontSize: '24px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Totale:
        <Badge
          color="primary"
          style={{
            fontSize: '30px',
          }}
        >
          {total.toFixed(2) + '€'}
        </Badge>
      </div>
    </div>
  );
}

export default TouristTaxTable;
