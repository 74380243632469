/* eslint-disable react/jsx-no-undef */
import { Button } from "reactstrap";
import "../home/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useAppSelector } from "../../redux/store";
import { userLngSelector } from "../../redux/reducers/userSlice";

export default function TerraMare() {
  const navigate = useNavigate();
  const lang = useAppSelector(userLngSelector);
  const handleButtonClick = (src: string) => {
    navigate("/pdf-viewer?src=" + encodeURIComponent(src));
  };

  return (
    <>
      <div id="card">
        <div className="wpb_wrapper wpb_wrapper_blue head">
          <div className="container">
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space" style={{ height: "10px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="widget-text-heading center_white">
              <h2 className="widget-title">
                <span>Servizio TerraMare</span>
              </h2>
            </div>
            <div className="empty_space" style={{ height: "35px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
          </div>
        </div>
      </div>
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: "12px" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      {lang === "it" ? (
        <div className="container pt-2 mt-2   ">
          <h1 className="page-title">Servizio Terra Mare in bus</h1>

          <div className="clearfix">
            <div className="clearfix">
              {" "}
              <img
                src={"/terra-mare.jpeg"}
                alt={"TerraMare Mare in bus"}
                className="rounded float-start me-3"
                style={{ width: "40%", height: "auto", borderRadius: "10px" }}
              />
              <img
                src={"/terra-mare-orari.jpeg"}
                alt={"TerraMare Mare in bus"}
                className="rounded float-start me-3"
                style={{ width: "40%", height: "auto", borderRadius: "10px" }}
              />
              <br />
            </div>{" "}
            <hr />
            <h4 style={{ textAlign: "center" }}>Scarica l'opuscolo e i PDF con tutti gli orari</h4>
            <div className="row">
              {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6">
                <button onClick={() => handleButtonClick("/opuscolo.pdf")} className="btn btn-primary">
                Opuscolo
                </button>
              </div> */}
              <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-12">
                <button onClick={() => handleButtonClick("/2024-Orario integratoTERRAMARE.pdf")} className="btn btn-primary">
                  Orario integrato
                </button>
              </div>
            </div>
            <div className="float-none"></div>
          </div>
        </div>
      ) : (
        <div className="container pt-2 mt-2   ">
          <h1 className="page-title">Terra Mare bus service</h1>

          <div className="clearfix">
            <div className="clearfix">
              {" "}
              <img
                src={"/terra-mare.jpeg"}
                alt={"TerraMare Mare in bus"}
                className="rounded float-start me-3"
                style={{ width: "40%", height: "auto", borderRadius: "10px" }}
              />
              <img
                src={"/terra-mare-orari.jpeg"}
                alt={"TerraMare Mare in bus"}
                className="rounded float-start me-3"
                style={{ width: "40%", height: "auto", borderRadius: "10px" }}
              />
              <br />
            </div>{" "}
            <hr />
            <h4 style={{ textAlign: "center" }}>Download the brochure and PDFs with all the schedules</h4>
            <div className="row">
              {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6">
                      <button onClick={() => handleButtonClick("/opuscolo.pdf")} className="btn btn-primary">
                      Opuscolo
                      </button>
                    </div> */}
              <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-12">
                <button onClick={() => handleButtonClick("/2024-Orario integratoTERRAMARE.pdf")} className="btn btn-primary">
                Integrated schedule
                </button>
              </div>
            </div>
            <div className="float-none"></div>
          </div>
        </div>
      )}
    </>
  );
}
