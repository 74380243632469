// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-icon-container {
  display: flex;
  align-items: center;
}

.big-icon {
  flex: 0 0 auto; /* Make the icon container not grow */
  margin-right: 20px; /* Adjust margin as needed */
}

.big-icon img {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
}

.big-text {
  flex: 1 1; /* Make the text container grow to fill the available space */
}

.big-text h2 {
  font-size: 24px; /* Adjust font size as needed */
  margin: 0; /* Remove default margins */
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard-view.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc,EAAE,qCAAqC;EACrD,kBAAkB,EAAE,4BAA4B;AAClD;;AAEA;EACE,YAAY,EAAE,0BAA0B;EACxC,aAAa,EAAE,0BAA0B;AAC3C;;AAEA;EACE,SAAO,EAAE,6DAA6D;AACxE;;AAEA;EACE,eAAe,EAAE,+BAA+B;EAChD,SAAS,EAAE,2BAA2B;AACxC","sourcesContent":[".big-icon-container {\n  display: flex;\n  align-items: center;\n}\n\n.big-icon {\n  flex: 0 0 auto; /* Make the icon container not grow */\n  margin-right: 20px; /* Adjust margin as needed */\n}\n\n.big-icon img {\n  width: 100px; /* Adjust size as needed */\n  height: 100px; /* Adjust size as needed */\n}\n\n.big-text {\n  flex: 1; /* Make the text container grow to fill the available space */\n}\n\n.big-text h2 {\n  font-size: 24px; /* Adjust font size as needed */\n  margin: 0; /* Remove default margins */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
