import {
  faBaby,
  faBan,
  faCheck,
  faEye,
  faPerson,
  faPersonCane,
  faPlus,
  faRefresh,
  faSearch,
  faWheelchair,
} from '@fortawesome/free-solid-svg-icons';
import { icons } from '../../util/form.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getListExemptionConf } from '../../api/exemptionAPI';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  Spinner,
  Table,
} from 'reactstrap';
import {
  getApproveStayConf,
  getListStayPagedConf,
  getRejectStayConf,
} from '../../api/stayAPI';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import TooltipIcon from '../../components/tooltipIcon';
import useRequest from '../../hook/use-request.hook';
import { DetailStay, StayStatuses } from '../../model/stay.model';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';

export default function StayListPage() {
  const { showModal, showNotification } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [list, setList] = useState<DetailStay[]>([]);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [status, setStatus] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const currentUser = useAppSelector(currentUserSelector);
  const [exemptionList, setExemptionList] = useState<any[]>([]);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const handleChange = (e: any) => {
    if (e.target.id === 'fromDate') setFromDate(e.target.value);
    else if (e.target.id === 'toDate') setToDate(e.target.value);
  };

  const refreshFirstPage = () => {
    setPagination({
      page: 1,
      pageSize: 21,
      totalElements: null,
    });

    refresh(pagination);
  };

  const refresh = (pag: any) => {
    let query = '';
    if (status) query = '&status=' + status;
    if (fromDate) query = query + '&fromDate=' + fromDate;
    if (toDate) query = query + '&toDate=' + toDate;

    fetchData(
      getListStayPagedConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrors
    );
  };

  const create = () => {
    navigate('new');
  };

  useEffect(() => {
    const statusOptionList: any[] = [];
    Object.keys(StayStatuses).forEach((key, index) => {
      statusOptionList.push({ id: key, name: key });
    });

    fetchDataDetail(
      getListExemptionConf(),
      (data: any) => {
        setExemptionList(data);
      },
      (data: any) => {
        setExemptionList([]);
      }
    );

    setStatusOptions(statusOptionList);
    refresh(pagination);
  }, []);

  useEffect(() => {
    refreshFirstPage();
  }, [status]);

  const onSuccessApproveReject = (data: any) => {
    showNotification(data.message, 'success');
    refresh(pagination);
  };

  const onErrorsApproveReject = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  const onApproveClick = (id: number | undefined) => {
    if (id)
      fetchData(
        getApproveStayConf(id),
        onSuccessApproveReject,
        onErrorsApproveReject
      );
  };

  const onRejectClick = (id: number | undefined) => {
    if (id)
      fetchData(
        getRejectStayConf(id),
        onSuccessApproveReject,
        onErrorsApproveReject
      );
  };

  const giveNumber = (list: any[], key: string) => {
    let l = list.find((el) => el.name == key);
    return l ? l.count : 0;
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          {/* <Link to={'/dashboard'}>{t('navbar.home')}</Link> */}
          <Link to={'/'}>{t('navbar.home')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('stay.list.title')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2 className="page-title">{t('stay.list.title')}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className="card-body">
          <h6 className="mt-1 mb-3">{t('stay.list.filter')}</h6>
          <div className="mt-2">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: '.5rem', display: 'inline-block' }}
                  >
                    {t('stay.fromDate')}
                  </div>
                  <Input
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5">
                <FormGroup>
                  <div
                    style={{ marginBottom: '.5rem', display: 'inline-block' }}
                  >
                    {t('stay.toDate')}
                  </div>
                  <Input
                    id="toDate"
                    name="toDate"
                    type="date"
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-2">
                <div style={{ marginBottom: '.5rem' }}>&nbsp;</div>
                <Button color="primary" onClick={refreshFirstPage}>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div style={{ marginBottom: '.5rem' }}>Stato</div>
            <Button
              color={!status ? 'primary' : 'light'}
              onClick={() => {
                setStatus(null);
              }}
              className="mb-2"
              style={{ marginRight: '10px' }}
            >
              Tutti
            </Button>
            {statusOptions.map((e: any, index: any) => {
              return (
                <Button
                  color={status === e.id ? 'primary' : 'light'}
                  onClick={() => {
                    setStatus(e.id);
                  }}
                  className="mb-2"
                  style={{ marginRight: '10px' }}
                  key={index}
                >
                  <span style={{ textTransform: 'capitalize' }}>{e.name}</span>
                </Button>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="justify-content-end pt-3"
        style={{ display: 'flex', gap: '10px' }}
      >
        {!checkRequiredAuthorities(
          [Authorities.ADMIN, Authorities.MANAGER, Authorities.MUNICIPALITY],
          currentUser
        ) && (
          <MobileButton
            onClick={create}
            disabled={loading}
            color="primary"
            icon={faPlus}
            text={t('general.buttons.add')}
          />
        )}
        <MobileButton
          onClick={() => refresh(pagination)}
          disabled={loading}
          color="secondary"
          outline
        >
          {!loading ? (
            <>
              <FontAwesomeIcon icon={faRefresh} />
              <span className="d-none d-md-inline">
                &nbsp; {t('general.buttons.refresh')}
              </span>
            </>
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span className="d-none d-md-inline">
                {' '}
                {t('action.loading')}{' '}
              </span>
            </>
          )}
        </MobileButton>
      </div>
      {pagination.totalElements == 0 && (
        <div className="pb-3 pt-3 fs-5">
          {t('general.messages.no_items_found')}
        </div>
      )}
      {pagination.totalElements > 0 && (
        <div className="mt-2 row">
          <Table striped responsive>
            <thead>
              <tr style={{ whiteSpace: 'break-spaces' }}>
                <th rowSpan={2} style={{ width: '10%' }}>
                  {t('stay.fromDate')}
                  <br />
                  {t('stay.toDate')}
                </th>
                <th rowSpan={2} style={{ width: '30%' }}>
                  {t('stay.facility')}
                  <br />
                  {t('stay.user')}
                </th>
                <th rowSpan={2} style={{ width: '22%', textAlign : 'center' }}>
                  {t('stay.status')}
                </th>
                <th colSpan={4} style={{ width: '25%' }}>
                  {t('stay.guests')}
                </th>
                <th rowSpan={2} style={{ width: '13%' }}></th>
              </tr>
              <tr style={{ whiteSpace: 'break-spaces' }}>
                {exemptionList?.map((el: any, index: number) => (
                  <th key={index}>
                    <TooltipIcon text={el.name} icon={icons.get(el.icon)} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {list.map((it, idx) => {
                return (
                  <tr key={it.id}>
                    <td
                      data-label={t('stay.fromDate') + ' ' + t('stay.toDate')}
                      style={{ width: 'auto' }}
                    >
                      {it.fromDate.substring(0, 10)}
                      <br />
                      {it.toDate.substring(0, 10)}
                    </td>
                    <td data-label={t('stay.facility') + ' ' + t('stay.user')}>
                      <span className="fw-bold">
                        <Link to={'/facility/' + it.facility?.id}>
                          {it.facility?.name}
                        </Link>
                      </span>
                      <br />
                      {it.user.firstName + ' ' + it.user.lastName}
                    </td>
                    <td
                    style={{ textAlign : 'center'}}
                      data-label={t('stay.status')}
                      className={
                        it.status === 'REJECTED'
                          ? 'text-danger fw-bold'
                          : it.status === 'ACCEPTED'
                          ? 'text-success fw-bold'
                          : 'fw-bold'
                      }
                    >
                      {it.status}
<br />
                    {checkRequiredAuthorities(
                          [Authorities.MUNICIPALITY, Authorities.MANAGER],
                          currentUser
                        ) &&
                          it.status === 'PENDING' && (

                            <ButtonGroup>
                            <MobileButton
                              inTable={true}
                              icon={faCheck}
                              color={'success'}
                              size="sm"
                              text={t('general.buttons.approve')}
                              id={'approve' + it.id}
                              onClick={() => onApproveClick(it.id)}
                            />
                            <MobileButton
                              inTable={true}
                              icon={faBan}
                              color={'danger'}
                              size="sm"
                              text={t('general.buttons.reject')}
                              id={'reject' + it.id}
                              onClick={() => onRejectClick(it.id)}
                            ></MobileButton>
                      </ButtonGroup>
                          )}
                    </td>

                    {exemptionList?.map((el: any, index: number) => (
                      <td data-label={el.name} key={index}>
                        <TooltipIcon
                          text={el.name}
                          label={giveNumber(it.guests, el.name)}
                        />
                      </td>
                    ))}

                    <td style={{ whiteSpace: 'break-spaces' }}>
                    
                        <MobileButton
                          inTable={true}
                          icon={faEye}
                          className="d-block"
                          style={{    width: '100%' }}
                          color={'primary'}
                          size="sm"
                          text={t('general.buttons.detail')}
                          id={'view' + it.id}
                          onClick={() => navigate(`/stay/${it.id}`)}
                        />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      <div className="pb-5">
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
