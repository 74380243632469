export const formatDateForFilter = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getDateDDMMYYYY = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getDateYYYYMMDD = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};


export function generateMonthArray(startDate: any, endDate: any) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  do {
    dateArray.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  } while (currentDate <= new Date(endDate));

  return dateArray;
}
export function generateDayArray(startDate: any, endDate: any) {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

export function getMonthFromDate(date: any) {
  const d = new Date(date);
  const month = d.getMonth() + 1; 
  return month;
}
export function getDayFromDate(date: any) {
  const d = new Date(date);
  return d.getDate();
}

  // Function to format date in Italian format
  export const italianDateFormat = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long", // Full name of the weekday (e.g., "lunedì")
      year: "numeric", // Year (e.g., "2024")
      month: "long", // Full name of the month (e.g., "gennaio")
      day: "numeric", // Day of the month (e.g., "1")
    };
  
    const date = new Date(dateString);
    const formattedDateParts = new Intl.DateTimeFormat("it-IT", options).formatToParts(date);
  
    const formattedDate = formattedDateParts.map(({ type, value }) => {
      if (type === "weekday" || type === "month") {
        //iniziali in Maiuscolo
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
      return value;
    }).join(" ");
  
    return formattedDate;
  };

  export function getWeekNumber(d :any) {
    const date = new Date(d)
    const copyDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNr = (date.getDay() + 6) % 7;
    copyDate.setDate(copyDate.getDate() - dayNr + 3);
    const firstDayOfYear = new Date(Date.UTC(copyDate.getFullYear(),0,1));
    const daysDifference = (copyDate.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000) + 1;
    return Math.ceil(daysDifference / 7);
}

export function getMondayOfWeek(year: number, weekNumber : number) {
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
    const firstDayOfWeek = firstDayOfYear.getUTCDay();
  const daysToFirstMonday = (firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek);
  const mondayOfTargetWeek = new Date(Date.UTC(year, 0, 1 + daysToFirstMonday + (weekNumber - 1) * 7));

  return mondayOfTargetWeek;
}
export function getSundayOfWeek(year: number, weekNumber : number) {
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
  const firstDayOfWeek = firstDayOfYear.getUTCDay();
  const daysToFirstMonday = (firstDayOfWeek === 0 ? 1 : 8 - firstDayOfWeek);
  const mondayOfTargetWeek = new Date(Date.UTC(year, 0, 1 + daysToFirstMonday + 6 + (weekNumber - 1) * 7));

  return mondayOfTargetWeek;
}








