import { t } from 'i18next';
import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap';
import { registerRs } from '../util/form.util';

import QrFrame from '../assets/qr-frame.svg';
// Styles
import { useForm } from 'react-hook-form';
import '../css/QrStyles.css';
import { useAppUtilContext } from '../util/app-util.context';
import { getCheckCodeConf } from '../api/stayGuestAPI';
import { ApiError } from '../model/errors.model';
import { ErrorsView } from './errors.view';
import useRequest from '../hook/use-request.hook';
interface Props {
  onSuccess: (entity: any) => void;
}

const CardCodeReaderView = (props: Props) => {
  const { showModal } = useAppUtilContext();
  const { loading, fetchData } = useRequest();
  // QR States
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  // Result
  const [scannedResult, setScannedResult] = useState<string | undefined>('');

  // Success
  const onScanSuccess = (result: QrScanner.ScanResult) => {
    // 🖨 Print the "result" to browser console.
    console.log(result);
    // ✅ Handle success.
    // 😎 You can do whatever you want with the scanned result.
    setScannedResult(result?.data);
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    // 🖨 Print the "err" to browser console.
    console.log(err);
  };
  useEffect(() => {
    if (!qrOn)
      alert(
        'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.'
      );
  }, [qrOn]);

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: 'environment',
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);
  let initialData = { code: scannedResult };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { code: '' }, values: { ...initialData } });

  const onSuccess = (data: any) => {
    console.log(data);
    setScannedResult('');
    props.onSuccess(data);
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  const onSubmit = (data: any) => {
    console.log('onSubmit');
    const { code } = data;

    let config = getCheckCodeConf(code);

    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };
  return (
    <div className="row justify-content-center">
      <Card className="col-lg-6 col-md-8 col-sm-12">
        <div
          style={{
            textAlign: 'center',
            fontSize: '32px',
            margin: '12px',
            fontWeight: 'bold',
          }}
        >
          VERIFICA CARD
        </div>
        <CardBody>
          <div className="qr-reader">
            {/* QR */}
            <video ref={videoEl}></video>
            <div ref={qrBoxEl} className="qr-box">
              <img
                src={QrFrame}
                alt="Qr Frame"
                width={256}
                height={256}
                className="qr-frame"
              />
            </div>
          </div>
          {scannedResult && (
            <p
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 99999,
                color: 'white',
              }}
            >
              Scanned Result: {scannedResult}
            </p>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{ textAlign: 'center', fontSize: '24px', margin: '12px' }}
            >
              {t(`stayGuestCode.scan_message`)}
            </div>
            <div className="row justify-content-center">
              <FormGroup className="form-field">
                <Input
                  id="code"
                  name="code"
                  type="text"
                  style={{
                    width: '100%',
                    fontSize: '48px',
                    fontWeight: 'bold',
                    minHeight: '100px',
                    textAlign: 'center',
                    backgroundColor: 'lightgray',
                  }}
                  disabled={loading}
                  {...registerRs(register, 'code', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.code && errors.code != null}
                />
                <FormFeedback>{'' + errors?.code?.message}</FormFeedback>
              </FormGroup>
            </div>
            <div className="row justify-content-center"></div>
            {!loading && (
              <Button
                type="submit"
                disabled={loading}
                color="primary"
                style={{ width: '100%', fontSize: '24px' }}
              >
                {!loading ? (
                  t('action.verify')
                ) : (
                  <>
                    <Spinner size="sm">Loading...</Spinner>
                    <span> {t('action.loading')}</span>
                  </>
                )}
              </Button>
            )}
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardCodeReaderView;
