/* eslint-disable react/jsx-no-undef */
import { Button } from "reactstrap";
import "../home/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useAppSelector } from "../../redux/store";
import { userLngSelector } from "../../redux/reducers/userSlice";

export default function Navetta() {
  const navigate = useNavigate();
  const lang = useAppSelector(userLngSelector);
  const handleButtonClick = (src: string) => {
    navigate("/pdf-viewer?src=" + encodeURIComponent(src));
  };

  return (
    <>
      <div id="card">
        <div className="wpb_wrapper wpb_wrapper_blue head">
          <div className="container">
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space" style={{ height: "10px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="widget-text-heading center_white">
              <h2 className="widget-title">
                <span>Servizio Navetta</span>
              </h2>
            </div>
            <div className="empty_space" style={{ height: "35px" }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space visible-lg" style={{ height: "50px" }}>
              <span className="empty_space_inner"></span>
            </div>
          </div>
        </div>
      </div>
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: "12px" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      <div className="container pt-2 mt-2   ">
        <h1 className="page-title">{lang === "it" ? "Servizio Navetta" : "Shuttle Service"}</h1>
        {lang === "it" ? (
          <div className="clearfix">
            <img
              src={"/mareinbus.jpg"}
              alt={"Navetta Mare in bus"}
              className="rounded float-start me-3"
              style={{ width: "40%", height: "auto", borderRadius: "10px" }}
            />
            <p>
              Dalla prima settimana di Luglio 2024 al 30 settembre 2024, sarà attivo il servizio di trasporto pubblico
              MAREINBUS, un'iniziativa promossa dal Comune di Melendugno per offrire ai turisti e ai residenti un modo
              sostenibile e comodo per scoprire le splendide marine del proprio territorio: Torre Specchia, San Foca, Roca,
              Torre dell'Orso, Torre Saracena e Torre Sant'Andrea e viceversa.
            </p>

            <p>
              MAREINBUS è un esempio concreto di mobilità sostenibile e di riqualificazione dell'offerta turistica. Per
              usufruire del servizio, è possibile scaricare la mappa e gli orari delle fermate da nord e da sud dal sito web
              del comune di Melendugno.
            </p>

            <p>
              Sono previste dieci corse giornaliere (per ogni direzione sud e nord), della durata di circa 45 minuti
              ciascuna. Ha il costo di 1 euro per tratta e di 50 centesimi per i possessori della Melendugnocard.
            </p>

            <p>
              Visto il grande successo dello scorso anno, ci sarà la navetta elettrica “ROCA EXPRESS” che permetterà gli
              spostamenti più frequenti e veloci da San Foca a Torre dell'Orso (e viceversa), con sosta presso la Grotta
              della Poesia.
            </p>

            <p>
              Ci sarà la NAVETTA PER TUTTI, che consentirà il trasporto delle persone con ridotta capacità motoria, mediante
              un servizio a chiamata.
            </p>

            <p>
              A luglio partirà anche il “Terra Mare in bus” per gli spostamenti dal mare all'entroterra
              (Melendugno/Borgagne/Marine e viceversa).
            </p>

            <p>
              La novità del 2024 sarà il servizio aggiuntivo “Terramare Night”: il servizio di collegamento serale con bus
              panoramico, attivo a luglio nel fine settimana e ad agosto tutti i giorni.
            </p>

            <div style={{ textAlign: "center", marginTop: "20%", marginBottom: "30px" }}>
              <hr />
              <h4>Scarica i PDF con tutti gli orari</h4>
              <div className="row" style={{ marginBottom: "30px", justifyContent: "center" }}>
                {/* <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
         <a href="/NORD Mattina Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Nord mattina</a>
        </div>
       <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
         <a href="/NORD Pomeriggio Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Nord pomeriggio</a>
        </div>
   
        <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
         <a href="/SUD Mattina Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Sud mattina</a>
        </div>
       <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
         <a href="/SUD Pomeriggio Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Sud pomeriggio</a>
        </div> */}
                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/NORD Mattina Mare in Bus.pdf")} className="btn btn-primary">
                    Orari Nord mattina
                  </button>
                </div>
                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/NORD Pomeriggio Mare in Bus.pdf")} className="btn btn-primary">
                    Orari Nord pomeriggio
                  </button>
                </div>

                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/SUD Mattina Mare in Bus.pdf")} className="btn btn-primary">
                    Orari Sud mattina
                  </button>
                </div>
                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/SUD Pomeriggio Mare in Bus.pdf")} className="btn btn-primary">
                    Orari Sud pomeriggio
                  </button>
                </div>
              </div>
              {/*         
     <div style={{ textAlign: 'center', marginTop: '15px' }}>                    
                 <h4 style={{ textAlign: 'center', marginBottom: '15px' }} >Scarica i PDF con gli orari di Terra-Mare in Bus</h4>
                 <div style={{ textAlign: "center", marginTop: "10px" }} className="col-sm-3 mb-2 col-md-12">
                   <button onClick={() => handleButtonClick("/Orario TERRAMARE.pdf")} className="btn btn-primary">
                     Orari di Terra-Mare in Bus
                   </button>
                 </div>
       </div> */}
            </div>
            {/* <hr />
       <div >
         <h4 style={{ textAlign: 'center' }} >Non perdere anche il servizio Terra-Mare - Night</h4>
         <img src={"/terra-mare-night.jpeg"} alt={"Navetta Mare in bus"} className="rounded float-start me-3" style={{  justifyContent: "center", maxWidth: "100%", height: "auto", borderRadius: "10px" }} />
       </div> */}
            <div className="float-none"></div>
          </div>
        ) : (
          <div className="clearfix">
            <img
              src={"/mareinbus.jpg"}
              alt={"Navetta Mare in bus"}
              className="rounded float-start me-3"
              style={{ width: "40%", height: "auto", borderRadius: "10px" }}
            />
            <p>
              From the first week of July 2024 to September 30, 2024, the public transport service will be active. MAREINBUS,
              an initiative promoted by the Municipality of Melendugno to offer tourists and residents a way sustainable and
              convenient way to discover the beautiful marinas of its territory: Torre Specchia, San Foca, Roca, Torre
              dell'Orso, Torre Saracena and Torre Sant'Andrea and vice versa.
            </p>

            <p>
              MAREINBUS is a concrete example of sustainable mobility and upgrading of tourism offerings. For take advantage
              of the service, you can download the map and schedules of stops from the north and south from the website of
              the municipality of Melendugno.
            </p>

            <p>
              Ten daily rides are scheduled (for each direction south and north), lasting about 45 minutes each. It costs 1
              euro each way and 50 cents for Melendugnocard holders.
            </p>

            <p>
              Given the great success of last year, there will be the electric shuttle "ROCA EXPRESS" that will allow the
              more frequent and faster travel from San Foca to Torre dell'Orso (and vice versa), with a stop at the Grotto of
              Poetry.
            </p>

            <p>
              There will be the SHUTTLE FOR ALL, which will enable the transportation of people with reduced mobility,
              through an on-call service.
            </p>

            <p>
              July will also see the launch of the "Terra Mare bus" for travel from the sea to the hinterland
              (Melendugno/Borgagne/Marine and vice versa).
            </p>

            <p>
              New in 2024 will be the additional service "Terramare Night": the evening bus connection service scenic,
              operating in July on weekends and every day in August.
            </p>

            <div style={{ textAlign: "center", marginTop: "20%", marginBottom: "30px" }}>
              <hr />
              <h4>Download PDFs with all the schedules</h4>
              <div className="row" style={{ marginBottom: "30px", justifyContent: "center" }}>
                {/* <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
              <a href="/NORD Mattina Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Nord mattina</a>
             </div>
            <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
              <a href="/NORD Pomeriggio Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Nord pomeriggio</a>
             </div>
        
             <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
              <a href="/SUD Mattina Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Sud mattina</a>
             </div>
            <div style={{ textAlign: 'center' }} className="col-sm-3 mb-2 col-md-6">
              <a href="/SUD Pomeriggio Mare in Bus.pdf" target="_blank" className="btn btn-primary">Orari Sud pomeriggio</a>
             </div> */}
                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/NORD Mattina Mare in Bus.pdf")} className="btn btn-primary">
                    North morning schedule
                  </button>
                </div>
                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/NORD Pomeriggio Mare in Bus.pdf")} className="btn btn-primary">
                    North afternoon schedule
                  </button>
                </div>

                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/SUD Mattina Mare in Bus.pdf")} className="btn btn-primary">
                    South morning schedule
                  </button>
                </div>
                {/* <div style={{ textAlign: "center" }} className="col-sm-3 mb-2 col-md-6"> */}
                <div style={{ textAlign: "center" }} className="col-sm-6 mb-2 col-md-2 mt-2">
                  <button onClick={() => handleButtonClick("/SUD Pomeriggio Mare in Bus.pdf")} className="btn btn-primary">
                    South afternoon schedule
                  </button>
                </div>
              </div>
              {/*         
          <div style={{ textAlign: 'center', marginTop: '15px' }}>                    
                      <h4 style={{ textAlign: 'center', marginBottom: '15px' }} >Scarica i PDF con gli orari di Terra-Mare in Bus</h4>
                      <div style={{ textAlign: "center", marginTop: "10px" }} className="col-sm-3 mb-2 col-md-12">
                        <button onClick={() => handleButtonClick("/Orario TERRAMARE.pdf")} className="btn btn-primary">
                          Orari di Terra-Mare in Bus
                        </button>
                      </div>
            </div> */}
            </div>
            {/* <hr />
            <div >
              <h4 style={{ textAlign: 'center' }} >Non perdere anche il servizio Terra-Mare - Night</h4>
              <img src={"/terra-mare-night.jpeg"} alt={"Navetta Mare in bus"} className="rounded float-start me-3" style={{  justifyContent: "center", maxWidth: "100%", height: "auto", borderRadius: "10px" }} />
            </div> */}
            <div className="float-none"></div>
          </div>
        )}
      </div>
    </>
  );
}
