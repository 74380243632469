import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { getSigninConf } from '../../api/userAPI';
import useRequest from '../../hook/use-request.hook';
import { Authorities, signinSuccess } from '../../redux/reducers/userSlice';
import { AppThunkDispatch, useAppSelector } from '../../redux/store';

import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useAppUtilContext } from '../../util/app-util.context';
import { registerRs } from '../../util/form.util';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';

export default function LoginPage() {
  const dispatch = useDispatch() as AppThunkDispatch;
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();
  const currentUser = useAppSelector((state) => state.user.currentUser);

  const onSuccess = (data: any) => {
    let pendingStay =
      localStorage.getItem('pendingStay') !== null
        ? localStorage.getItem('pendingStay')
        : '';
    if (pendingStay !== undefined) {
      try {
        console.log('pendingStay', JSON.parse(pendingStay as string));
        navigate('/stay/finalizza');
      } catch (e) {
        console.log(e);
        navigate('/');
      }
    } else {
      navigate('/');
    }

    dispatch(signinSuccess(data));
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_failed'), errorsView, null);
  };

  const onSubmit = (data: any) => {
    const { email, password } = data;
    fetchData(getSigninConf({ email, password }), onSuccess, onErrors);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <div className="container mt-4 col-lg-4">
      <Card>
        <CardBody>
          <CardTitle tag="h5">{t('login.title')}</CardTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="form-field">
              <Input
                id="email"
                name="email"
                placeholder={t('login.username_ph')}
                {...registerRs(register, 'email', {
                  required: t(`general.errors.required`),
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: t(`general.errors.invalid_email`),
                  },
                })}
                invalid={errors.email && errors.email != null}
              />{' '}
              <Label for="username">{t('login.username')}</Label>
              <FormFeedback>{t('general.errors.required')}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="password"
                name="password"
                placeholder={t('login.password_ph')}
                type="password"
                {...registerRs(register, 'password', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.password && errors.password != null}
              />{' '}
              <Label for="password">{t('login.password')}</Label>
              <FormFeedback>{'' + errors?.password?.message}</FormFeedback>
            </FormGroup>

            <Button type="submit" disabled={loading}>
              {!loading ? (
                t('action.send')
              ) : (
                <>
                  <Spinner size="sm">Loading...</Spinner>
                  <span> {t('action.loading')}</span>
                </>
              )}
            </Button>
            <hr></hr>
            {/* <p>
                            {t('login.register_msg')}<Link to="/user/register"> {t('action.signup')}</Link>
                        </p> */}
            <p>
              <Link to="/user/forgot-password">
                {t('login.forgot_password')}
              </Link>
            </p>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
