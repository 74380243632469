import { t } from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import useRequest from "../../hook/use-request.hook";
import { registerRs } from "../../util/form.util";
import FormWizard, {
  type FormWizardProps,
  type FormWizardMethods,
} from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import React from "react";
import { InputType } from "reactstrap/types/lib/Input";
import { DetailFacility } from "../../model/facility.model";
import { getListFacilityConf } from "../../api/facilityAPI";
import { useParams } from "react-router-dom";

export interface OccupancyCreatePageHandle {
  open: () => void;
}
const OccupancyCreatePage = forwardRef<OccupancyCreatePageHandle>(
  (props: {}, ref) => {
    const { loading, fetchData } = useRequest();
    const [step, setStep] = useState(1);
    const [goto, setGoto] = useState(0);
    const [facilityList, setFacilityList] = useState<DetailFacility[]>([]);
    let { id } = useParams();
    const facilityId = id ? parseInt(id, 10) : undefined;
    console.log("facilityId", facilityId)

    useImperativeHandle(ref, () => ({
      open() {
        // setModal(true);
      },
    }));
    const onSubmit = (data: any) => {
      console.log("onSubmit", data);
    };

    const {
      register,
      trigger,
      getFieldState,
      formState: { errors , isValid},
      handleSubmit,
      getValues 
    } = useForm();

    const changeStep = (nextStep: any) => {
       if(nextStep == 2){
            trigger(["name", "fromDate", "toDate"])
        } else if(nextStep == 3){
            let fields = getStage2Field()
            trigger(fields)
        }

        setGoto(nextStep);
    };

    const checkFields = (fieldArray : string[]) =>  {
        let isValid = true;
        fieldArray.forEach(element => {
            let field = getFieldState(element);
            if(field.error !== undefined){
                isValid = false
            }
        });

        return isValid
    }

    const getStage2Field = () => {
        let fields = []; 
        let values = getValues()
        if(values.guests > 0){
            for(let i = 0; i < values.guests; i++)
                fields.push('nominativog'+i)        
        }
        if(values.children > 0){
            for(let i = 0; i < values.children; i++)
                fields.push('nominativoc'+i)    
        }
        if(values.over65s > 0){
            for(let i = 0; i < values.children; i++)
                fields.push('nominativoo'+i)    
        }

        return fields
    }

    useEffect(() => {
        fetchData(getListFacilityConf(), (data: any) => {
            setFacilityList(data);
          }, (data: any) => {
            setFacilityList([]);
          });
    }, []);

    useEffect(() => {
      if (goto == 2) {
        trigger(["name", "fromDate", "toDate"])
        if (checkFields(["name", "fromDate", "toDate"])) {
            setStep(2);
        } else {
            setGoto(0);
        }
      } else if (goto == 3) {
        let fields = getStage2Field()
        trigger(fields)
        if (checkFields(fields)) {
            setStep(3);
            let values = getValues()
            // let newOccupancyrecord = {
            //     name: values.name,
            //     fromDate:  values.name,
            //     toDate:  values.name,
            //     guests:  values.name,
            //     over65s: values.name,
            //     disableds:  values.name,
            //     children:  values.name,
            //     isGroup:  values.name,
            // }
            // getCreateOccupancyRecordReqConf(newOccupancyrecord)
        } else {
            setGoto(0);
        }
      }
    }, [goto]);

    const giveField = (st : any, nome : string, errori : any, checks: any, typ :InputType = "text"  ) => {
        return <FormGroup className={step == st ? "form-field" : "form-field hidden"}>
        <Input
          id={nome}
          name={nome}
          type={typ}
         // value={typ == "number"? 0 : null}
          {...registerRs(register, nome, checks)}
          invalid={errori && errori != null}
          placeholder={t("occupancy."+( nome.startsWith('nominativo') ? "nominativo": nome ))}
        />
        <Label className={typ} for={nome}>{t("occupancy."+( nome.startsWith('nominativo') ? "nominativo": nome ))}</Label>
        <FormFeedback>{"" + errori?.message}</FormFeedback>
      </FormGroup>
    }

    const doSomething = () => {
        let obj = []

        let values = getValues()

        if(values.guests > 0){
            obj.push(<div className="col-sm-12">Adulti</div>)
            for(let i = 0; i < values.guests; i++)
                obj.push(<div className="col-sm-4">{giveField(2, 'nominativog'+i , errors['nominativog'+i], {required: t(`general.errors.required`)})}</div>)
        }

        if(values.children > 0){
            obj.push(<div className="col-sm-12">Bambini</div>)
            for(let i = 0; i < values.children; i++)
                obj.push(<div className="col-sm-4">{giveField(2, 'nominativoc'+i , errors['nominativoc'+i], {required: t(`general.errors.required`)})}</div>)
        }
        if(values.over65s > 0){
            obj.push(<div className="col-sm-12">Over65</div>)
            for(let i = 0; i < values.over65s; i++)
                obj.push(<div className="col-sm-4">{giveField(2, 'nominativoo'+i , errors['nominativoo'+i], {required: t(`general.errors.required`)})}</div>)
        }
        return obj
    }
    return (
      <div className="container mt-2 get-in-touch">
        <div className="header-container">
          <h2>Registrazione Soggiorno</h2>
        </div>
        <div className="react-form-wizard">
          <div className="wizard-navigation">
            <div className="wizard-progress-with-circle">
              <div
                className="wizard-progress-bar"
                //  style="background-color: rgb(33, 150, 243); width: 50%; color: rgb(33, 150, 243);"
              ></div>
            </div>
            <ul
              className="form-wizard-steps  wizard-nav wizard-nav-pills  md"
              // style="border-color: rgb(33, 150, 243);"
            >
              <li className="active">
                <a style={{ alignItems: "flex-start" }} className="active">
                  <div className="wizard-icon-circle md checked " role="tab">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-user">1</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle active">Registrazione</span>
                </a>
              </li>
              <li className={step >= 2 ? "active" : ""}>
                <a className={step >= 2 ? "active" : ""}>
                  <div
                    className="wizard-icon-circle md checked"
                    //  style="border-color: rgb(33, 150, 243);"
                  >
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-check">2</i>
                      </span>
                    </div>
                  </div>
                  <span
                    className="stepTitle "
                    // style="margin-top: 8px; color: rgb(33, 150, 243);"
                  >
                    Nominativi
                  </span>
                </a>
              </li>
              <li className={step >= 3 ? "active" : ""}>
                <a
                  style={{ alignItems: "flex-end" }}
                  className={step >= 3 ? "active" : ""}
                >
                  <div className="wizard-icon-circle md checked">
                    <div className="wizard-icon-container ">
                      <span className="wizard-icon">
                        <i className="ti-check">3</i>
                      </span>
                    </div>
                  </div>
                  <span className="stepTitle ">Riepilogo</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <Form className="form-floating" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
         <div className="col-sm-12">{giveField(1, 'name', errors.name, {required: t(`general.errors.required`)})}</div>
         <div className="col-sm-12">

         <FormGroup className={step == 1 ? "form-field" : "form-field hidden"}>
            <Input
                invalid={errors.place && errors.place != null}
                type="select"
                {...registerRs(register, "place", {value: facilityId, required: t(`general.errors.required`)})}
            >
                <option>Seleziona una struttura</option>
           {facilityList.map((el) => {
            return  <option value={el.id}>{el.name}</option>
           })} 
            </Input>

        <Label className={"select"} for={"place"}>Struttura</Label>
        <FormFeedback>{"" + errors.place?.message}</FormFeedback>
      </FormGroup>
         </div>
         <div className="col-sm-12">{giveField(1, 'email', errors.email, {})}</div>
         <div className="col-sm-12">{giveField(1, 'telefono', errors.telefono, {})}</div>
         <div className="col-sm-6">{giveField(1, 'fromDate', errors.fromDate, {required: t(`general.errors.required`)}, "date")}</div>
         <div className="col-sm-6">{giveField(1, 'toDate', errors.toDate, {required: t(`general.errors.required`)}, "date")}</div>

         <div className="col-sm-4">{giveField(1, 'guests', errors.guests, {required: t(`general.errors.required`), value: 0}, "number")}</div>
         <div className="col-sm-4">{giveField(1, 'over65s', errors.over65s, {required: t(`general.errors.required`), value: 0}, "number")}</div>
         <div className="col-sm-4">{giveField(1, 'children', errors.children, {required: t(`general.errors.required`), value: 0}, "number")}</div>
        </div>

        <div className="row">
           { step == 2 ? doSomething() : ''}    
        </div>

          <Button
            type="button"
            onClick={(e) => {
              changeStep(step + 1);
            }}
            color="default"
          >
            Next step
          </Button>
{/* 
          <Button
            className={step == 3 ? "" : "hidden"}
            type="submit"
            disabled={loading}
            color="primary"
          >
            {!loading ? (
              t("action.send")
            ) : (
              <>
                <Spinner size="sm"> Loading...</Spinner>
                <span> {t("action.loading")}</span>
              </>
            )}
          </Button> */}
        </Form>
      </div>
    );
  }
);

export default OccupancyCreatePage;
