import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, UncontrolledAlert } from 'reactstrap';
import { geCurrentUserConf } from '../../api/userAPI';
import useRequest from '../../hook/use-request.hook';
import {
  currentUserSuccess,
  userAccessTokenSelector,
} from '../../redux/reducers/userSlice';
import { AppThunkDispatch, useAppSelector } from '../../redux/store';

export default function CurrentUserView() {
  const accessToken = useAppSelector(userAccessTokenSelector);
  const dispatch = useDispatch() as AppThunkDispatch;
  const [isError, setIsError] = useState(false);

  const { loading, fetchData } = useRequest();
  const onSuccess = (data: any) => {
    dispatch(currentUserSuccess(data));
    setIsError(false);
  };
  const onErrors = (errors: any) => {
    setIsError(true);
  };

  const getUserDatas = () => {
    fetchData(geCurrentUserConf(), onSuccess, onErrors);
  };

  useEffect(() => {
    setIsError(false);
    if (accessToken != null && !loading) {
      fetchData(geCurrentUserConf(), onSuccess, onErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <>
      {isError && (
        <div className="container">
          <UncontrolledAlert className={'mt-4'} color="danger">
            <p>{t('general.errors.error_retrieving_user_info')}</p>
            <Button color="danger" size="sm" outline onClick={getUserDatas}>
              {t('general.buttons.retry')}
            </Button>
          </UncontrolledAlert>
        </div>
      )}
    </>
  );
}
