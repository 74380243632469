import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { getResetPasswordConf } from '../../api/userAPI';
import useRequest from '../../hook/use-request.hook';
import { useAppUtilContext } from '../../util/app-util.context';
import { registerRs } from '../../util/form.util';
import { useNavigate } from 'react-router-dom';

export default function ResetPasswordPage() {
  let { code } = useParams();
  const { showModal } = useAppUtilContext();
  const { t } = useTranslation();
  const { loading, fetchData } = useRequest();
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    const { email, password } = data;
    let body = { email, password, code };
    fetchData(getResetPasswordConf(body), onSuccess, onErrors);
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };

  const onErrors = (errors: any) => {
    console.log('errors', errors);
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <div className="container">
      <Card>
        <CardBody>
          <CardTitle tag="h5">{t('reset_password.title')}</CardTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup  className="form-field">
              
              <Input
                disabled={loading}
                placeholder={t('login.username_ph')}
                {...registerRs(register, 'email', {
                  required: t(`general.errors.required`),
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: t(`general.errors.invalid_email`),
                  },
                })}
                invalid={errors.email && errors.email != null}
              />
              <Label for="email">{t('login.username')}</Label>
              <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="password"
                name="password"
                placeholder={t('login.password_ph')}
                type="password"
                {...registerRs(register, 'password', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.password && errors.password != null}
              />
                            <Label for="password">{t('login.password')}</Label>

              <FormFeedback>{'' + errors?.password?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="confirm-password"
                name="confirm-password"
                placeholder={t('login.confirm_password_ph')}
                type="password"
                {...registerRs(register, 'confirm-password', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.password && errors.password != null}
              />
               <Label for="confirm-password">
                {t('login.confirm_password')}
              </Label>
              <FormFeedback>{'' + errors?.password?.message}</FormFeedback>
            </FormGroup>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                t('action.send')
              ) : (
                <>
                  <Spinner size="sm">Loading...</Spinner>
                  <span> {t('action.loading')}</span>
                </>
              )}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
