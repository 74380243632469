import 'react-calendar/dist/Calendar.css';
import { Card, CardBody } from 'reactstrap';

import ReactECharts from 'echarts-for-react';
export default function OccupancydashboardPage() {
  let occupancyOptions = {
    title: {
      text: 'Occupancy',
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true,
      },
      {
        data: [720, 832, 801, 934, 1090, 1030, 1020],
        type: 'line',
        smooth: true,
      },
    ],
  };
  return (
    <div className="container mt-2">
      <div className="col-12 mt-2">
        <Card>
          <CardBody>
            <ReactECharts option={occupancyOptions} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
