import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import FacilityRequestRecordPageCreate from './facility-record-request-create-page';
import FacilityRequestRecordPageDetail from './facility-record-request-detail.page';
import FacilityRequestRecordPageList from './facility-record-request-list.page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

const UserRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      ) && <></>}
      <Route path=":id" element={<FacilityRequestRecordPageDetail />} />
      <Route path="" element={<FacilityRequestRecordPageList />} />
      <Route path="create" element={<FacilityRequestRecordPageCreate />} />
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default UserRoutes;
