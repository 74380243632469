import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';

import 'react-calendar/dist/Calendar.css';
import './App.css';
import i18n from './i18n';
import { userLngSelector } from './redux/reducers/userSlice';
import { useAppSelector } from './redux/store';
import { AppUtilContextProvider } from './util/app-util.context';
function App() {
  const lang = useAppSelector(userLngSelector);

  useEffect(() => {
    document.title = 'Marine di Melendugno';
  }, []);

  return (
    <AppUtilContextProvider>
      <BrowserRouter basename="/">
        <AppRoutes />
      </BrowserRouter>
    </AppUtilContextProvider>
  );
}

export default App;
