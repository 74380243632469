export interface CreateFacilityRecordRequestReq {
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  zipCode: string;
  province: string;
  firstName: string;
  lastName: string;
  birthPlace: string;
  birthProvince: string;
  birthDate: string;
  fiscalCode: string;
  facilityName: string;
  companyName: string;
  vatNumber: string;
  isPersonal: boolean;
  placeId: number;
  typologyId: number;
  active: boolean;
  note: string;
  fax: string;
  beds: number;
  managerCity: string;
  managerProvince: string;
  managerCap: string;
  managerPhoneNumber: string;
  managerFax: string;
  managerEmail: string;
  managerFiscalCode: string;

  legalEntityAddress: string;
  legalEntityCity: string;
  legalEntityProvince: string;
  legalEntityZipCode: string;
}

export interface DetailFacilityRequest {
  id: number;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  zipCode: string;
  province: string;
  firstName: string;
  lastName: string;
  birthPlace: string;
  birthProvince: string;
  birthDate: string;
  fiscalCode: string;
  facilityName: string;
  companyName: string;
  vatNumber: string;
  practiceNumber?: string;
  status: FacilityRecordRequestStatuses;
  isPersonal: boolean;
  active: boolean;
  note: string;
  fax: string;
  beds: number;
  managerCity: string;
  managerProvince: string;
  managerCap: string;
  managerPhoneNumber: string;
  managerFax: string;
  managerEmail: string;
  managerFiscalCode: string;

  legalEntityAddress: string;
  legalEntityCity: string;
  legalEntityProvince: string;
  legalEntityZipCode: string;
}
export enum FacilityRecordRequestStatuses {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}
