import { t } from 'i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getCreateFacilityRecordReqConf,
  getDetailFacilityRecordReqConf,
  getUpdateFacilityRecordReqConf,
} from '../../api/facilityRecordRequestAPI';
import useRequest from '../../hook/use-request.hook';
import { DetailFacilityRequest } from '../../model/facility-record-request.model';
import { useAppUtilContext } from '../../util/app-util.context';
import {
  EMAIL_REGEX,
  PHONE_NUMBER_REGEX,
  ZIP_CODE_REGEX,
  registerRs,
} from '../../util/form.util';

export default function FacilityRequestRecordPageDetail() {
  const { showModal } = useAppUtilContext();
  let { id } = useParams();

  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const { fetchData: fetchDataDetail } = useRequest<DetailFacilityRequest>();

  const [isVat, setIsVat] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [entity, setEntity] = useState<DetailFacilityRequest>();

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailFacilityRecordReqConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailFacilityRequest) => {
    setEntity({ ...data, birthDate: data.birthDate.slice(0, 10) });
  };

  const onErrorsDetail = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };
  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/facility-record-request');
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    const {
      email,
      phoneNumber,
      address,
      city,
      zipCode,
      province,
      firstName,
      lastName,
      birthPlace,
      birthProvince,
      birthDate,
      fiscalCode,
      facilityName,
      companyName,
      vatNumber,
      isPersonal,
      placeId,
      typologyId,
      active,
      note,
      fax,
      beds,
      managerCity,
      managerProvince,
      managerCap,
      managerPhoneNumber,
      managerFax,
      managerEmail,
      managerFiscalCode,
      legalEntityAddress,
      legalEntityCity,
      legalEntityProvince,
      legalEntityZipCode
    } = data;

    fetchData(
      getUpdateFacilityRecordReqConf(id || '', {
        email,
        phoneNumber,
        address,
        city,
        zipCode,
        province,
        firstName,
        lastName,
        birthPlace,
        birthProvince,
        birthDate,
        fiscalCode,
        facilityName,
        companyName,
        vatNumber,
        isPersonal,
        placeId,
        typologyId,
        active,
        note,
        fax,
        beds,
        managerCity,
        managerProvince,
        managerCap,
        managerPhoneNumber,
        managerFax,
        managerEmail,
        managerFiscalCode,
        legalEntityAddress,
      legalEntityCity,
      legalEntityProvince,
      legalEntityZipCode
      }),
      onSuccess,
      onErrors
    );
  };
  console.log(entity);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...entity } });
  return (
    <div className='container mt-2'>
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={'/facility-record-request'}>List</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('general.buttons.detail')}</BreadcrumbItem>
        </Breadcrumb>
      </>
      <div className='header-container'>
        <h2>{t('facility-record-request.create.title')}</h2>

        <Button onClick={() => setIsEdit(!isEdit)}>
          <span>
            {isEdit ? t('general.buttons.view') : t('general.buttons.edit')}
          </span>
        </Button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader>{t('facility-record-request.facility')}</CardHeader>
          <CardBody>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className="form-field">
                
                <Input
                  disabled={loading || !isEdit}
                  id='facilityName'
                  name='facilityName'
                  type='text'
                  {...registerRs(register, 'facilityName', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.facilityName && errors.facilityName != null}
                />
                <Label for='facilityName'>
                  {t('facility-record-request.facilityName')}
                </Label>
                <FormFeedback>
                  {'' + errors?.facilityName?.message}
                </FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                <Input
                  disabled={loading || !isEdit}
                  id='email'
                  name='email'
                  type='text'
                  {...registerRs(register, 'email', {
                    required: t(`general.errors.required`),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t(`general.errors.invalid_email`),
                    },
                  })}
                  invalid={errors.email && errors.email != null}
                />
                <Label for='email'>{t('facility-record-request.email')}</Label>
                <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                
                <Input
                  disabled={loading || !isEdit}
                  id='phoneNumber'
                  name='phoneNumber'
                  type='text'
                  {...registerRs(register, 'phoneNumber', {
                    required: t(`general.errors.required`),
                    pattern: {
                      value: PHONE_NUMBER_REGEX,
                      message: t(`general.errors.invalid_phone_number`),
                    },
                  })}
                  invalid={errors.phoneNumber && errors.phoneNumber != null}
                />
                <Label for='phoneNumber'>
                  {t('facility-record-request.phoneNumber')}
                </Label>
                <FormFeedback>{'' + errors?.phoneNumber?.message}</FormFeedback>
              </FormGroup>
              </div>
              <div className=' col-sm-12'>
              <FormGroup className='form-field'>
                <Input
                  disabled={!isEdit}
                  id='address'
                  name='address'
                  type='text'
                  {...registerRs(register, 'address', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.address && errors.address != null}
                />
                <Label for='address'>
                {t('facility-record-request.address')}
              </Label>
                <FormFeedback>{'' + errors?.address?.message}</FormFeedback>
              </FormGroup>    
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                <Input
                  disabled={!isEdit}
                  id='city'
                  name='city'
                  type='text'
                  {...registerRs(register, 'city', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.city && errors.city != null}
                />                <Label for='city'>{t('facility-record-request.city')}</Label>

                <FormFeedback>{'' + errors?.city?.message}</FormFeedback>
              </FormGroup>    
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                
                <Input
                  disabled={loading || !isEdit}
                  id='zipCode'
                  name='zipCode'
                  type='text'
                  {...registerRs(register, 'zipCode', {
                    required: t(`general.errors.required`),
                    pattern: {
                      value: ZIP_CODE_REGEX,
                      message: t(`general.errors.invalid_zip_code`),
                    },
                  })}
                  invalid={errors.zipCode && errors.zipCode != null}
                /><Label for='zipCode'>
                  {t('facility-record-request.zipCode')}
                </Label>
                <FormFeedback>{'' + errors?.zipCode?.message}</FormFeedback>
              </FormGroup>    
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                
                <Input
                  disabled={loading || !isEdit}
                  id='province'
                  name='province'
                  type='text'
                  {...registerRs(register, 'province', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.province && errors.province != null}
                /><Label for='province'>
                  {t('facility-record-request.province')}
                </Label>
                <FormFeedback>{'' + errors?.province?.message}</FormFeedback>
              </FormGroup>
            </div>
            </div>
          </CardBody>
        </Card>
        <Card className='mt-2'>
          <CardHeader>{t('facility-record-request.attorney')}</CardHeader>
          <CardBody>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
               
                <Input
                  disabled={loading || !isEdit}
                  id='fiscalCode'
                  name='fiscalCode'
                  type='text'
                  {...registerRs(register, 'fiscalCode', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.fiscalCode && errors.fiscalCode != null}
                />
                 <Label for='fiscalCode'>
                  {t('facility-record-request.fiscalCode')}
                </Label>
                <FormFeedback>{'' + errors?.fiscalCode?.message}</FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                
                <Input
                  disabled={loading || !isEdit}
                  id='firstName'
                  name='firstName'
                  type='text'
                  {...registerRs(register, 'firstName', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.firstName && errors.firstName != null}
                />
                <Label for='firstName'>
                  {t('facility-record-request.firstName')}
                </Label>
                <FormFeedback>{'' + errors?.firstName?.message}</FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
               
                <Input
                  disabled={loading || !isEdit}
                  id='lastName'
                  name='lastName'
                  type='text'
                  {...registerRs(register, 'lastName', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.lastName && errors.lastName != null}
                />
                 <Label for='lastName'>
                  {t('facility-record-request.lastName')}
                </Label>
                <FormFeedback>{'' + errors?.lastName?.message}</FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
                
                <Input
                  disabled={loading || !isEdit}
                  id='birthPlace'
                  name='birthPlace'
                  type='text'
                  {...registerRs(register, 'birthPlace', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.birthPlace && errors.birthPlace != null}
                />
                <Label for='birthPlace'>
                  {t('facility-record-request.birthPlace')}
                </Label>
                <FormFeedback>{'' + errors?.birthPlace?.message}</FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
               
                <Input
                  disabled={loading || !isEdit}
                  id='birthProvince'
                  name='birthProvince'
                  type='text'
                  {...registerRs(register, 'birthProvince', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.birthProvince && errors.birthProvince != null}
                />
                 <Label for='birthProvince'>
                  {t('facility-record-request.birthPlace')}
                </Label>
                <FormFeedback>
                  {'' + errors?.birthProvince?.message}
                </FormFeedback>
              </FormGroup>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <FormGroup className='form-field'>
               
                <Input
                  disabled={loading || !isEdit}
                  id='birthDate'
                  name='birthDate'
                  type='date'
                  {...registerRs(register, 'birthDate', {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.birthDate && errors.birthDate != null}
                />
                 <Label for='birthDate'>
                  {t('facility-record-request.birthDate')}
                </Label>
                <FormFeedback>{'' + errors?.birthDate?.message}</FormFeedback>
              </FormGroup>
            </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>{t('facility-record-request.society')}</CardHeader>
          <CardBody>
            <div className='row'>
              <FormGroup className="form-field">
                <Input
                  disabled={loading || !isEdit}
                  id='isvat'
                  name='isVat'
                  type='checkbox'
                  {...registerRs(register, 'isVat', {
                    required: t(`general.errors.required`),
                    onChange: (e: any) => {
                      setIsVat(e.target.checked);
                    },
                  })}
                />{' '}
                <Label check for='isVat'>
                  {t('facility-record-request.isVat')}
                </Label>
              </FormGroup>
              {isVat && (
                <FormGroup className='col-lg-6 col-md-6 col-sm-12'>
                  <Label for='companyName'>
                    {t('facility-record-request.companyName')}
                  </Label>
                  <Input
                    disabled={loading || !isEdit}
                    id='companyName'
                    name='companyName'
                    type='text'
                    {...registerRs(register, 'companyName', {
                      required: t(`general.errors.required`),
                    })}
                    invalid={errors.companyName && errors.companyName != null}
                  />
                  <FormFeedback>
                    {'' + errors?.companyName?.message}
                  </FormFeedback>
                </FormGroup>
              )}

              {isVat && (
                <FormGroup className='col-lg-6 col-md-6 col-sm-12'>
                  <Label for='vatNumber'>
                    {t('facility-record-request.vatNumber')}
                  </Label>
                  <Input
                    disabled={loading || !isEdit}
                    id='vatNumber'
                    name='vatNumber'
                    type='text'
                    {...registerRs(register, 'vatNumber', {
                      required: t(`general.errors.required`),
                    })}
                    invalid={errors.vatNumber && errors.vatNumber != null}
                  />
                  <FormFeedback>{'' + errors?.vatNumber?.message}</FormFeedback>
                </FormGroup>
              )}
            </div>
          </CardBody>
        </Card>
        <div className='mt-2'>
          <Button type='submit' disabled={loading || !isEdit}>
            {!loading ? (
              t('action.send')
            ) : (
              <>
                <Spinner size='sm'>Loading...</Spinner>
                <span> {t('action.loading')}</span>
              </>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}
