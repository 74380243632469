import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { useAppUtilContext } from '../../util/app-util.context';

import { ApiError } from '../../model/errors.model';
import { ErrorsView } from '../../components/errors.view';
import FacilityTypeForm from './facility_type.form';

export default function FacilityTypeCreatePage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();

  const onSubmitSuccess = (data: any) => {
    let message = data.message ? data.message : 'La struttura è stata creata';

    showModal(t('modal.request_success'), message, null);
    navigate('/facility-type');
  };

  const onSubmitError = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility-type'}>{t('facility-type.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('general.buttons.add')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('facility-type.create.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <FacilityTypeForm
            enabled={true}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
          ></FacilityTypeForm>
        </CardBody>
      </Card>
    </div>
  );
}
