import { StatisticSearchParams } from '../model/statistic.model';
import { defaultHeaders } from './common';

export const getStatisticPaymentConf = (params: StatisticSearchParams) => {
  let queryParts: string[] = [];
  const { fromDate, toDate } = params;
  if (fromDate) {
    queryParts.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    queryParts.push(`toDate=${toDate}`);
  }
  let url = `api/statistic/payments`;
  if (queryParts.length > 0) {
    url = `api/statistic/payments?${queryParts.join('&')}`;
  }
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: url,
    headers: defaultHeaders,
  };
};
export const getStatisticStayConf = (params: StatisticSearchParams) => {
  let queryParts: string[] = [];
  const { fromDate, toDate } = params;
  if (fromDate) {
    queryParts.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    queryParts.push(`toDate=${toDate}`);
  }
  let url = `api/statistic/stays`;
  if (queryParts.length > 0) {
    url = `api/statistic/stays?${queryParts.join('&')}`;
  }
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: url,
    headers: defaultHeaders,
  };
};
export const getStatisticOccupancyConf = (params: StatisticSearchParams) => {
  let queryParts: string[] = [];
  const { fromDate, toDate } = params;
  if (fromDate) {
    queryParts.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    queryParts.push(`toDate=${toDate}`);
  }
  let url = `api/statistic/occupancies`;
  if (queryParts.length > 0) {
    url = `api/statistic/occupancies?${queryParts.join('&')}`;
  }
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: url,
    headers: defaultHeaders,
  };
};

export const getStatisticOccupancyByPlaceConf = (
  params: StatisticSearchParams
) => {
  let queryParts: string[] = [];
  const { fromDate, toDate } = params;
  if (fromDate) {
    queryParts.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    queryParts.push(`toDate=${toDate}`);
  }
  let url = `api/statistic/occupancies-by-place`;
  if (queryParts.length > 0) {
    url = `api/statistic/occupancies-by-place?${queryParts.join('&')}`;
  }
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: url,
    headers: defaultHeaders,
  };
};
