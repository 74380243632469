import { createContext, useContext, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OfflineAlert from '../components/offline-alert';
import GenericModal, { ModalHandle } from '../modal/generic.modal';

export enum NotificationTypes {
  SUCCESS = 'succes',
  ERROR = 'error',
}
// Create a context
const AppUtilContext = createContext({
  showNotification: (text: string, type: string) => {},
  showModal: (title: string, body: any, footer: any) => {},
});

// Custom hook to access the context
export const useAppUtilContext = () => useContext(AppUtilContext);

// Provider component to wrap around the components that need access to the context
export const AppUtilContextProvider = ({ children }: any) => {
  const genericModalRef = useRef<ModalHandle>(null);
  // Define your method here
  const showNotification = (text: string, type: string) => {
    // Method logic
    if (type === NotificationTypes.SUCCESS) {
      toast.success(text);
    } else if (type === NotificationTypes.ERROR) {
      toast.error(text);
    }
  };

  const showModal = (title: string, body: any, footer: any) => {
    genericModalRef.current?.open(title, body, footer);
  };

  // Value object to provide to the context
  const contextValue = {
    showNotification: showNotification,
    showModal: showModal,
  };

  return (
    <AppUtilContext.Provider value={contextValue}>
      {children}
      <GenericModal ref={genericModalRef} />
      <ToastContainer />
      <OfflineAlert></OfflineAlert>
    </AppUtilContext.Provider>
  );
};
