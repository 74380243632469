import { useState } from 'react';

// Qr Scanner
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Button } from 'reactstrap';
import CardCodeReaderView from '../../components/card-code-reader-view';
import CardCodeView from '../../components/card-code-view';
import { ExtendedDetailStayGuest } from '../../model/stay-guest.model';

const QrReader = () => {
  const [entity, setEntity] = useState<ExtendedDetailStayGuest>();

  // ❌ If "camera" is not allowed in browser permissions, show an alert.

  return (
    <div className="container mt-4  ">
      {entity && (
        <Button
          color="trasparent"
          onClick={() => {
            setEntity(undefined);
          }}
          style={{ marginRight: '12px' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
          {t('general.buttons.back')}
        </Button>
      )}
      {entity && <CardCodeView entity={entity}></CardCodeView>}
      {!entity && (
        <CardCodeReaderView
          onSuccess={(entity) => setEntity(entity)}
        ></CardCodeReaderView>
      )}
      {entity && (
        <div className="row justify-content-center mt-4">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <Button
              type="submit"
              color="primary"
              style={{ width: '100%', fontSize: '24px' }}
              onClick={() => {
                setEntity(undefined);
              }}
            >
              Nuova Scansione
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrReader;
