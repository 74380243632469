import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import {StayCreatePage} from './stay-create-page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  currentUserSelector,
  Authorities,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import StayListPage from './stay-list.page';
import StayDetailPage from './stay-detail.page';
import { StayCreateAnonPage } from './stay-create-anon-page';

const StayRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {currentUser && (
        <>
          <Route path="" element={<StayListPage />} />
          <Route path=":id" element={<StayDetailPage />} />
          <Route path="/list/:id" element={<StayCreatePage />} />
          <Route path="/new" element={<StayCreatePage />} />
        </>
        )}
      
      <Route path="/new" element={<StayCreateAnonPage />} />
      <Route path="/finalizza" element={<StayCreatePage />} />

      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default StayRoutes;
