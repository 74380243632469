import { t } from 'i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';

import { useEffect, useState } from 'react';
import { getDetailFacilityConf } from '../../api/facilityAPI';
import useRequest from '../../hook/use-request.hook';
import { DetailFacility } from '../../model/facility.model';
import { useAppUtilContext } from '../../util/app-util.context';
import FacilityForm from './facility.form';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { ErrorsView } from '../../components/errors.view';

export default function FacilityUpdatePage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData } = useRequest<DetailFacility>();
  const [entity, setEntity] = useState<DetailFacility>();

  useEffect(() => {
    if (id) {
      fetchData(getDetailFacilityConf(id), onSuccessDetail, onErrorsDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailFacility) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/facility/' + id);
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility'}>{t('facility.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('facility.update.title')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('facility.update.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <FacilityForm
            initialData={entity}
            enabled={true}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></FacilityForm>
        </CardBody>
      </Card>
    </div>
  );
}
