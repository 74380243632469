import { faEye, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { getListByFacilityIdConf } from '../../api/solicitorAPI';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { DetailSolicitor } from '../../model/solicitor.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { useNavigate } from 'react-router-dom';
import IconButtonWithTooltip from '../../components/icon-button-with-tooltip';
import { ErrorsView } from '../../components/errors.view';
import RemoveFacilitySolicitorModal, {
  RemoveFacilitySolicitorModalHandle,
} from '../../modal/remove-solicitor.modal';
import { DetailFacility } from '../../model/facility.model';

export interface FacilitySolicitorsTableProps {
  facility?: DetailFacility;
}
export interface FacilitySolicitorsTableHandle {
  reload: () => void;
}

export const FacilitySolicitorsTable = forwardRef<
  FacilitySolicitorsTableHandle,
  FacilitySolicitorsTableProps
>((props: FacilitySolicitorsTableProps, ref) => {
  const { facility } = props;
  const navigate = useNavigate();
  const { showModal } = useAppUtilContext();
  const { fetchData } = useRequest<DetailSolicitor>();
  const removeFacilitySolicitorModalRef =
    useRef<RemoveFacilitySolicitorModalHandle>(null);
  const [solicitors, setSolicitors] = useState<DetailSolicitor[]>([]);

  useImperativeHandle(ref, () => ({
    reload() {
      reloadTable();
    },
  }));

  const reloadTable = () => {
    if (facility) {
      fetchData(getListByFacilityIdConf(facility.id), onSuccess, onErrors);
    }
  };
  useEffect(() => {
    reloadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility]);

  const onSuccess = (data: any) => {
    setSolicitors(data);
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <Table striped bordered className="mt-2">
      <thead>
        <tr>
          <th>{t('solicitor.firstName')}</th>
          <th>{t('solicitor.lastName')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {solicitors.map((it) => {
          return (
            <tr key={it.id}>
              <td>{it.firstName}</td>
              <td>{it.lastName}</td>
              <td>
                <div className="btn-group flex-btn-group-container">
                <IconButtonWithTooltip
                  className="btn-sm"
                    id={`view-${it.id}`}
                    icon={faEye}
                    color={'#212529'}
                    title={t('general.buttons.view')}
                    onClick={() => navigate(`/solicitor/${it.id}`)}
                  ></IconButtonWithTooltip>
                  <IconButtonWithTooltip
                   className="btn-sm"
                    id={`edit-${it.id}`}
                    icon={faPencil}
                    color={'#008055'}
                    title={t('general.buttons.edit')}
                    onClick={() => navigate(`/solicitor/${it.id}/update`)}
                  ></IconButtonWithTooltip>
                  <IconButtonWithTooltip
                   className="btn-sm"
                    id={`delete-${it.id}`}
                    icon={faTrash}
                    color={'#CE2B37'}
                    title={t('general.buttons.remove')}
                    onClick={() =>
                      facility &&
                      removeFacilitySolicitorModalRef.current?.open(
                        facility,
                        it
                      )
                    }
                  ></IconButtonWithTooltip>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
      <RemoveFacilitySolicitorModal
        ref={removeFacilitySolicitorModalRef}
        onClose={() => reloadTable()}
      ></RemoveFacilitySolicitorModal>
    </Table>
  );
});
