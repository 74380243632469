import {
  faBan,
  faCheck,
  faEye,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import { getApproveStayConf, getRejectStayConf } from '../../api/stayAPI';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import { useAppUtilContext } from '../../util/app-util.context';
import { giveUrl } from '../fullfacility/facility-list.page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  currentUserSelector,
  Authorities,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import IconButtonWithTooltip from '../../components/icon-button-with-tooltip';
import { useEffect, useState } from 'react';
import { getListEvent } from '../../api/eventsApi';
import EventsDisplay from '../events/events_display';
import { DetailEvent } from '../../model/event.model';
import { getDateYYYYMMDD } from '../../util/date.util';
import { useTranslation } from 'react-i18next';

interface StayDisplayProps {
  initialData?: any;
  exemptionList: any[];
  facilityList: any[];
}

const StayDisplay = (props: StayDisplayProps) => {
  const { initialData, exemptionList } = props;
  const navigate = useNavigate();
  const [eventsData, setEventsData] = useState<DetailEvent[]>([]);
  const { showModal, showNotification } = useAppUtilContext();
  const { fetchData } = useRequest();
  const currentUser = useAppSelector(currentUserSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if(initialData.id) fetchData(getListEvent(getDateYYYYMMDD(new Date(initialData.fromDate)), getDateYYYYMMDD(new Date(initialData.toDate))), onSuccess, onErrors);
  }, []);

  const onSuccess = (data: any) => {
    setEventsData(data);
  };
  const onErrors = (data: any) => {
    // setEventsData(data);
  };


  const onSuccessApproveReject = (data: any) => {
    showNotification(data.message, 'success');
    window.location.reload();
  };

  const onErrorsApproveReject = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  const onApproveClick = (id: number | undefined) => {
    if (id)
      fetchData(
        getApproveStayConf(id),
        onSuccessApproveReject,
        onErrorsApproveReject
      );
  };

  const onRejectClick = (id: number | undefined) => {
    if (id)
      fetchData(
        getRejectStayConf(id),
        onSuccessApproveReject,
        onErrorsApproveReject
      );
  };

  const giveType = (typeId: any) => {
    let ty = exemptionList.find((el) => el.id == typeId);
    return ty?.name;
  };

  let guestHaveCode = initialData?.guests[0]?.code !== undefined ? true : false;

  // console.log("initialData", initialData)
  return (
    <>
      <div className="row">
        <div className="col-sm-12 mb-2" key={initialData?.facilityId}>
          <div className="facility-card float-right">
            <div className="row border-gradient" style={{ margin: 0 }}>
              <div
                style={{
                  backgroundImage: giveUrl(
                    initialData?.images ? initialData : initialData?.facility
                  ),
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
                className="col-4 ml-0"
              ></div>
              <div className="col-8">
                <div
                  className="body mt-3 mb-3 d-flex flex-column justify-content-center"
                  style={{ fontSize: '1.2rem' }}
                >
                  {/* <span style={{ color: "#fbb03c" }}>
                    <FontAwesomeIcon icon={icons.get(initialData.facility?.typology.icon)} />{" "}
                    {initialData.facility?.typology.name}
                  </span> */}
                  <span>
                    {t("general.date.from")}{' '}
                    <strong>{initialData?.fromDate.substring(0, 10)}</strong> {t("general.date.to")}{' '}
                    <strong>{initialData?.toDate.substring(0, 10)}</strong>
                  </span>

                  <span>
                  {t("stay.facility")}:{' '}
                    <strong>
                      <Link to={'/facility/' + initialData.facility.id}>
                        {initialData.facility.name}
                      </Link>
                    </strong>
                  </span>

                  {initialData?.user !== undefined && (
                    <span>
                        {t("stay.user")}:{' '}
                      <strong>
                        {initialData?.user.firstName +
                          ' ' +
                          initialData?.user.lastName}
                      </strong>
                    </span>
                  )}
                  <span>
                  {t("stay.status")}:{' '}
                    <strong
                      className={
                        initialData?.status === 'REJECTED'
                          ? 'text-danger fw-bold'
                          : initialData?.status === 'ACCEPTED'
                          ? 'text-success fw-bold'
                          : 'fw-bold'
                      }
                    >
                      {initialData?.status}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 pt-3">
          <h3>Ospiti</h3>

          <Table borderless size="sm">
            <thead>
              <tr>
                <th style={{ width: '45px' }}>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Tipo</th>
                {guestHaveCode ? <th style={{ width: '120px' }}>Code</th> : ''}
                <th style={{ width: '55px' }}> </th>
              </tr>
            </thead>
            <tbody>
              {initialData?.guests.map((el: any, index: any) => {
                console.log('guests', el);
                return (
                  <tr>
                    <th style={{ width: '45px' }} scope="row">
                      {index + 1}
                    </th>
                    <td data-label={"First Name"}>{el.firstName}</td>
                    <td data-label={"Last Name"}>{el.lastName}</td>
                    <td data-label={"Email"}>{el.email}</td>
                    <td data-label={"Tipo"}>
                      {el.exemptionId ? giveType(el.exemptionId) : el.exemption}
                    </td>
                    {guestHaveCode ? (
                      <td data-label={el.Header} style={{ width: '120px' }}>{el.code}</td>
                    ) : (
                      ''
                    )}
                    <td>
                      <IconButtonWithTooltip
                        id={`view-${el.id}`}
                        icon={faQrcode}
                        color={'primary'}
                        title={t('general.buttons.view')}
                        style={{ float: 'right' }}
                        onClick={() => navigate(`/stay-guest/${el.id}`)}
                      ></IconButtonWithTooltip>{' '}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {checkRequiredAuthorities(
          [Authorities.MUNICIPALITY, Authorities.MANAGER],
          currentUser
        ) && (
          <div
            className="justify-content-end pt-3"
            style={{ display: 'flex', gap: '10px' }}
          >
            <MobileButton
              icon={faCheck}
              color={'success'}
              className={initialData?.status === 'PENDING' ? '' : 'd-none'}
              text={t('general.buttons.approve')}
              id={'approve' + initialData?.id}
              onClick={() => onApproveClick(initialData?.id)}
            />
            <MobileButton
              icon={faBan}
              color={'danger'}
              className={initialData?.status === 'PENDING' ? '' : 'd-none'}
              text={t('general.buttons.reject')}
              id={'reject' + initialData?.id}
              onClick={() => onRejectClick(initialData?.id)}
            ></MobileButton>
          </div>
        )}
 </div>

{ initialData.id && eventsData.length > 0 &&  <div className="row"><div className="col-md-12" >
<h2 className="font-bold text-center mt-5">{t("staycreate.events")}</h2>
</div>
{eventsData.map(event =>  <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={event.id}>
            <EventsDisplay event={event} isPrevious={false} />
          </div>) } 
     </div>}
     
    </>
  );
};

export default StayDisplay;
