import { CreatePayment } from '../model/payment.model';
import { defaultHeaders } from './common';

export const getCreatePaymentConf = (createDto: CreatePayment) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/payment',
    headers: defaultHeaders,
    data: JSON.stringify(createDto),
  };
};

export const getListPaymentPerFacilityConf = (facilityId: number) => {
  return {
    method: 'get',
    url: '/api/payment?facilityId=' + facilityId,
    headers: defaultHeaders,
  };
};
export const getListPaymentPagedConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url: '/api/payment?page=' + page + '&size=' + size + query,
    headers: defaultHeaders,
  };
};

export const getExistPaymentConf = (
  year: number,
  month: number,
  facilityId: number
) => {
  return {
    method: 'get',
    url:
      '/api/payment?year=' +
      year +
      '&month=' +
      month +
      '&facilityId=' +
      facilityId,
    headers: defaultHeaders,
  };
};

export const getConfirmpPaymentConf = (id: number) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/payment/' + id + '/confirm',
    headers: defaultHeaders,
  };
};
