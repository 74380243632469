import { t } from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import useRequest from '../hook/use-request.hook';
import { useAppUtilContext } from '../util/app-util.context';
import { getListPaymentPerFacilityConf } from '../api/paymentAPI';
import PaymentTable from '../pages/payment/payment-table';

export interface HistoryPaymentModalProps {
  // onClose: () => void;
}
export interface HistoryPaymentModalHandle {
  open: (facilityId: number) => void;
}

const HistoryPaymentModal = forwardRef<
  HistoryPaymentModalHandle,
  HistoryPaymentModalProps
>((props, ref) => {
  const { showModal, showNotification } = useAppUtilContext();
  const [facilityId, setFacilityId] = useState<number>();
  const [modal, setModal] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const { loading, fetchData: fetchHistory } = useRequest();

  const toggle = () => {
    // reset();
    setModal(!modal);
  };

  useImperativeHandle(ref, () => ({
    open(facId: number) {
      fetchHistory(
        getListPaymentPerFacilityConf(facId),
        (data, headers) => {
          if (Array.isArray(data)) {
            setList(data);
          }
        },
        () => {
          console.log('error');
        }
      );
      setFacilityId(facId);
      setModal(true);
    },
  }));

  return (
    <>
      <Modal size="xl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Storico Pagamenti</ModalHeader>
        <ModalBody>
          <PaymentTable list={list} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {t('general.buttons.close')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default HistoryPaymentModal;
