import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import OccupancyDashboardPage from './occupancy-dashboard.page';
import OccupancyCreatePage from './occypancy-create-page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  currentUserSelector,
  Authorities,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

const SolicitorRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY,  Authorities.MANAGER],
        currentUser
      ) && (
        <>
          <Route path="/new" element={<OccupancyCreatePage />} />
          <Route path="/new/:id" element={<OccupancyCreatePage />} />
          <Route path="" element={<OccupancyDashboardPage />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default SolicitorRoutes;
