
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardTitle, Form, FormFeedback, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { getResetPasswordCodeConf } from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from '../../util/app-util.context';
import { registerRs } from "../../util/form.util";
import { useNavigate } from 'react-router-dom';

export default function ForgotPasswordPage() {
    const { showModal } = useAppUtilContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, fetchData } = useRequest();

    const onSubmit = (data: any) => {
        fetchData(getResetPasswordCodeConf(data), onSuccess, onErrors);
    };

    const onSuccess = (data: any) => {
        let message = data.message;
        showModal(t('modal.request_success'), message, null);
        navigate('/');
    }

    const onErrors = (errors: any) => {
        let errorsView = <ul>{errors.map((it: any, idx: number) => <li key={idx}>{it.message}</li>)}</ul>
        showModal(t('modal.request_failed'), errorsView, null);
    }

    const { register, formState: { errors }, handleSubmit } = useForm()

    return (
        <div className="container" >
            <Card >
                <CardBody>
                    <CardTitle tag="h5">
                        {t('forgot_password.title')}
                    </CardTitle>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup className="form-field">
                            <Input disabled={loading}
                                {...registerRs(register, "email", {
                                    required: t(`general.errors.required`),
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: t(`general.errors.invalid_email`),
                                    }
                                })}
                                invalid={errors.email && errors.email != null}
                            />
                              <Label for="email">
                                {t('login.username')}
                            </Label>
                            <FormFeedback>
                                {'' + errors?.email?.message}
                            </FormFeedback>
                        </FormGroup>
                        {<Button type='submit' disabled={loading}>
                            {!loading ? t('action.send') :
                                <><Spinner size="sm">
                                    Loading...
                                </Spinner>
                                    <span>
                                        {' '}   {t('action.loading')}
                                    </span></>}
                        </Button>}
                    </Form>
                </CardBody>
            </Card>
        </div>
    );
};

