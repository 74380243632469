/* eslint-disable jsx-a11y/anchor-is-valid */
import { t } from 'i18next';
import { forwardRef, useEffect, useState } from 'react';
import 'react-form-wizard-component/dist/style.css';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { getListExemptionConf } from '../../api/exemptionAPI';
import { getListFacilityPagedConf } from '../../api/facilityAPI';
import { getCreateStayConf } from '../../api/stayAPI';
import useRequest from '../../hook/use-request.hook';
import { DetailFacility } from '../../model/facility.model';
import { currentUserSelector, userLngSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';
import { campoByLang, registerRs } from '../../util/form.util';

import Select from 'react-select';
import { ErrorsView } from '../../components/errors.view';
import { ApiError } from '../../model/errors.model';
import { customSelectStyles } from '../../util/form.util';
import StayDisplay from './stay.display';
import EventsDisplay from '../events/events_display';
import { DetailEvent } from '../../model/event.model';
import { getListEvent } from '../../api/eventsApi';
import { getDateYYYYMMDD } from '../../util/date.util';

export interface StayCreatePageHandle {}

export const StayCreatePage = forwardRef<StayCreatePageHandle>(
  (props: {}, ref) => {
    const { loading, fetchData } = useRequest();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [goto, setGoto] = useState(0);
    const [toSend, setToSend] = useState<any>({});
    const [facilityList, setFacilityList] = useState<DetailFacility[]>([]);
    const [exemptionList, setExemptionList] = useState<any[]>([]);
    const { fetchData: fetchDataDetail } = useRequest<any>();
    const { showModal, showNotification } = useAppUtilContext();
    const lang = useAppSelector(userLngSelector);
console.log("StayCreatePage", lang)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const facilityParamsId = queryParams.get("facility");
    const [eventsData, setEventsData] = useState<DetailEvent[]>([]);

    const onSuccess = (data: any) => {
      setEventsData(data);
    };
    const onErrors = (data: any) => {
      // setEventsData(data);
    };
    
    useEffect(() => {
      let pendingStay =
        localStorage.getItem('pendingStay') !== null
          ? localStorage.getItem('pendingStay')
          : '';
      if (pendingStay !== undefined && pendingStay !== '') {
        setToSend(JSON.parse(pendingStay as string));
        setStep(3);
      }

      fetchData(
        getListFacilityPagedConf(1, 1000, ''),
        (data: any) => {
          setFacilityList(data);
        },
        (data: any) => {
          setFacilityList([]);
        }
      );

      fetchDataDetail(
        getListExemptionConf(),
        (data: any) => {
          setExemptionList(data);
        },
        (data: any) => {
          setExemptionList([]);
        }
      );
    }, []);

    useEffect(() => {
      if (facilityParamsId) {
        const faciltyValues = facilityParamsId ? facilityList.find((el)=> el.id === parseInt(facilityParamsId, 10)) : undefined;
        let facilityDatas = {label: faciltyValues?.name, value: facilityParamsId}
        reset({place: facilityDatas});
      }

    }, [facilityList, facilityParamsId]);


    const currentUser = useAppSelector(currentUserSelector);
    const onSubmit = (data: any) => {
      console.log('onSubmit', data);
    };
    const {
      register,
      trigger,
      getFieldState,
      reset,
      setValue,
      control,
      formState: { errors, isValid },
      handleSubmit,
      getValues,
    } = useForm({
      mode: 'onBlur', // or 'onChange', 'onSubmit', etc.
      criteriaMode: 'all', // to get all errors, not just the first one
      shouldFocusError: true,
    });

    const changeStep = (nextStep: any) => {
      if (nextStep == 2) {
        trigger(['place', 'fromDate', 'toDate']);

      } else if (nextStep == 3) {
        let fields = getStage2Field();
        trigger(fields);
      }

      setGoto(nextStep);
    };

    const checkFields = (fieldArray: string[]) => {
      let isValid = true;
     
      fieldArray.forEach((element) => {
        trigger(element)
        let field = getFieldState(element);
        console.log("field",element, field)
        if (field.error !== undefined) {
          isValid = false;
        }
      });

      return isValid;
    };

    useEffect(() => {
      if(step === 2){
        setValue('firstNameg0', currentUser?.firstName)
        setValue('lastNameg0', currentUser?.lastName)
        setValue('emailg0', currentUser?.email)
      }

      if(step === 3) fetchData(getListEvent(getDateYYYYMMDD(new Date(toSend.fromDate)), getDateYYYYMMDD(new Date(toSend.toDate))), onSuccess, onErrors);
    }, [step]);

    const getStage2Field = () => {
      let fields = [];
      let values = getValues();
      if (values.guests > 0) {
        for (let i = 0; i < values.guests; i++) {
          fields.push('firstNameg' + i);
          fields.push('lastNameg' + i);
          fields.push('emailg' + i);
          fields.push('hasHandicappg' + i);
        }
      }
      if (values.children > 0) {
        for (let i = 0; i < values.children; i++) {
          fields.push('firstNamec' + i);
          fields.push('lastNamec' + i);
          fields.push('emailc' + i);
          fields.push('hasHandicappc' + i);
        }
      }
      if (values.over65s > 0) {
        for (let i = 0; i < values.over65s; i++) {
          fields.push('firstNameo' + i);
          fields.push('lastNameo' + i);
          fields.push('emailo' + i);
          fields.push('hasHandicappo' + i);
        }
      }

      return fields;
    };

    useEffect(() => {
      let values = getValues();
      // console.log('useEffect goto', goto, values, errors);

      if (goto == 2) {
        trigger(['place', 'fromDate', 'toDate']);
        if (checkFields(['place', 'fromDate', 'toDate'])) {
          setStep(2);
        } else {
          setGoto(0);
        }
      } else if (goto == 3) {
        let fields = getStage2Field();
        trigger(fields);

        if (checkFields(fields)) {
          setStep(3);
          let values = getValues();
          let gue = [];

          if (values.guests > 0) {
            for (let i = 0; i < values.guests; i++) {
              gue.push({
                firstName: values['firstNameg' + i],
                lastName: values['lastNameg' + i],
                email: values['emailg' + i],
                exemptionId: values['typeg' + i],
              });
            }
          }

          let fac = facilityList.find((el) => el.id == values.place.value);

          let v = {
            fromDate: values.fromDate,
            toDate: values.toDate,
            userId: null,
            facilityId: values.place.value,
            facility: fac,
            status: 'PENDING',
            guests: gue,
          };
          setToSend(v);
        } else {
          setGoto(0);
        }
      }
    }, [goto]);

    const placeholderCheck = (placeholder: string) => {
      let label = placeholder;

      if (placeholder.startsWith('firstName')) {
        label = 'firstName';
      } else if (placeholder.startsWith('lastName')) {
        label = 'lastName';
      } else if (placeholder.startsWith('email')) {
        label = 'email';
      } else if (placeholder.startsWith('hasHandicapp')) {
        label = 'hasHandicapp';
      }

      return 'stay.' + label;
    };

    const giveField = (
      st: any,
      nome: string,
      errori: any,
      checks: any,
      typ: InputType = 'text'
    ) => {
      let other = typ == 'number' ? { min: 1 } : {};
      return (
        <FormGroup className={step == st ? 'form-field' : 'form-field hidden'}>
          <Input
            id={nome}
            name={nome}
            type={typ}
            {...registerRs(register, nome, checks)}
            {...other}
            invalid={errori && errori != null}
            placeholder={t(placeholderCheck(nome))}
          />
          <Label className={typ} for={nome}>
            {t(placeholderCheck(nome))}
          </Label>
          <FormFeedback>{'' + errori?.message}</FormFeedback>
        </FormGroup>
      );
    };

    const afterDate = () => {
      let val = getValues();

      if(val.toDate == '')   
        return t(`general.errors.required`)
    
      return new Date (val.fromDate) < new Date (val.toDate) ||   t("staycreate.error.date")
    };


    const validateAtLeastOne = () => {
      let val = getValues();
      let arrayField = [];

      for (let i = 0; i < val.guests; i++) {
        arrayField.push('emailg' + i);
      }

      const values = getValues(arrayField);
      return (
        values.some((value) => value !== undefined && value.trim() != '') ||
        t("staycreate.error.mandatory")
      );
    };

    const getGuestFieldList = () => {
      let obj = [];
      let values = getValues();

      if (values.guests > 0) {
        obj.push(
          <div className="col-sm-12">
            <h2>{t("staycreate.guestnames")}</h2>
          </div>
        );
        obj.push(<hr />);
        for (let i = 0; i < values.guests; i++) {
          obj.push(
            <div className="col-sm-12 ">
              <div className="row primo">
                <div className="floating">{i + 1}</div>

                <div className="col-sm-6">
                  <FormGroup
                    className={step == 2 ? 'form-field' : 'form-field hidden'}
                  >
                    {/* <Label className={"select"} for={'typeg'+i}>Struttura</Label> */}
                    <Input
                      type="select"
                      name={'typeg' + i}
                      invalid={
                        errors['typeg' + i] && errors['typeg' + i] != null
                      }
                      {...registerRs(register, 'typeg' + i, {
                        value: exemptionList[0].id,
                        required: t(`general.errors.required`),
                      })}
                    >
                      {exemptionList.map((el) => {
                        return <option value={el.id}>{campoByLang(el.name, lang)}</option>;
                      })}
                    </Input>
                    <FormFeedback>{'' + errors.place?.message}</FormFeedback>
                  </FormGroup>
                </div>

                <div className="col-sm-6">
                  {giveField(2, 'emailg' + i, errors['emailg' + i], {
                    validate: validateAtLeastOne,
                  })}
                </div>
                <div className="col-sm-6">
                  {giveField(2, 'firstNameg' + i, errors['firstNameg' + i], {
                    required: t(`general.errors.required`),
                  })}
                </div>
                <div className="col-sm-6">
                  {giveField(2, 'lastNameg' + i, errors['lastNameg' + i], {
                    required: t(`general.errors.required`),
                  })}
                </div>
              </div>
            </div>
          );

          obj.push(<hr />);
        }
      }

      return obj;
    };

    const createSoggiorno = () => {
      fetchData(
        getCreateStayConf({
          ...toSend,
          ...{ userId: currentUser ? currentUser.id : '' },
        }),
        () => {
          localStorage.removeItem('pendingStay');
          setStep(4);
        },
        onErrorsDetail
      );
    };

    const onErrorsDetail = (errors: ApiError[]) => {
      showModal(
        t('modal.request_errors'),
        <ErrorsView errors={errors}></ErrorsView>,
        null
      );
    };

    const gotoStep = (x: number) => {
      setStep(x);
      setGoto(0);
      console.log("step", x)
    };

    return (
      <div className="container mt-2 get-in-touch">
        <div className="header-container">
          <h2 className="page-title">{t("staycreate.nameStep1")}</h2>
        </div>
        <div className="card-body">
          <div className="react-form-wizard">
            <div className="wizard-navigation">
              <div className="wizard-progress-with-circle">
                <div
                  className="wizard-progress-bar"
                  //  style="background-color: rgb(33, 150, 243); width: 50%; color: rgb(33, 150, 243);"
                ></div>
              </div>

              <ul
                className="form-wizard-steps  wizard-nav wizard-nav-pills  md"
                // style="border-color: rgb(33, 150, 243);"
              >
                <li className={step == 1 ? 'current active' : 'active'}>
                  <a
                    onClick={() => {
                      if (step > 1) {
                        gotoStep(1);
                      }
                    }}
                    style={{ alignItems: 'flex-start' }}
                    className={'active'}
                  >
                    <div className="wizard-icon-circle md checked " role="tab">
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-user">1</i>
                        </span>
                      </div>
                    </div>
                    <span className="stepTitle active">{t("staycreate.register")}</span>
                  </a>
                </li>
                <li
                  className={
                    step >= 2 ? (step == 2 ? 'current active' : 'active') : ''
                  }
                >
                  <a
                    onClick={() => {
                      if (step > 2) {
                        gotoStep(2);
                      }
                    }}
                    className={step >= 2 ? 'active' : ''}
                  >
                    <div
                      className="wizard-icon-circle md checked"
                      //  style="border-color: rgb(33, 150, 243);"
                    >
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-check">2</i>
                        </span>
                      </div>
                    </div>
                    <span
                      className="stepTitle "
                      // style="margin-top: 8px; color: rgb(33, 150, 243);"
                    >
                      {t("staycreate.names")}
                    </span>
                  </a>
                </li>
                <li
                  className={
                    step >= 3 ? (step == 3 ? 'current active' : 'active') : ''
                  }
                >
                  <a
                    onClick={() => {
                      if (step > 3) {
                        gotoStep(3);
                      }
                    }}
                    className={step >= 3 ? 'active' : ''}
                  >
                    <div className="wizard-icon-circle md checked">
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-check">3</i>
                        </span>
                      </div>
                    </div>
                    <span className="stepTitle ">{t("staycreate.summary")}</span>
                  </a>
                </li>
                <li
                  className={
                    step >= 4 ? (step == 4 ? 'current active' : 'active') : ''
                  }
                >
                  <a
                    onClick={() => {
                      if (step > 4) {
                        gotoStep(4);
                      }
                    }}
                    style={{ alignItems: 'flex-end' }}
                    className={step >= 4 ? 'active' : ''}
                  >
                    <div className="wizard-icon-circle md checked">
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-check">4</i>
                        </span>
                      </div>
                    </div>
                    <span className="stepTitle ">{t("staycreate.outcome")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <Form className="form-floating" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
             {   step == 1 && <Alert style={{ textAlign: 'center' }} color="info">
             {t("staycreate.saluto", { firstName: currentUser?.firstName, lastName: currentUser?.lastName })}
                  </Alert>}

                  <FormGroup
                    className={step == 1 ? 'form-field' : 'form-field hidden'}
                  >
                    <Label className={'select'} for={'place'}>
                      Struttura
                    </Label>
                    <Controller
                      name="place"
                      control={control}
                      rules={{ required: t(`general.errors.required`) }}
                      render={({ field }) => (
                        <Select
                          placeholder={t("staycreate.facility")}
                          {...field}
                          styles={customSelectStyles}
                          value={field.value}
                          options={facilityList.map((el) => {
                            return { value: el.id, label: el.name };
                          })}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption)
                          }
                        />
                      )}
                    />
                    <div
                      className={
                        errors.place?.message
                          ? 'invalid-feedback show'
                          : 'invalid-feedback'
                      }
                    >
                      {errors.place?.message != undefined
                        ? (errors.place?.message as string)
                        : ''}
                    </div>
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  {giveField(
                    1,
                    'fromDate',
                    errors.fromDate,
                    { required: t(`general.errors.required`) },
                    'date'
                  )}
                </div>
                <div className="col-sm-6">
                  {giveField(
                    1,
                    'toDate',
                    errors.toDate,
                    { validate: afterDate },
                    'date'
                  )}
                </div>

                <div className="col-sm-4">
                  {giveField(
                    1,
                    'guests',
                    errors.guests,
                    { value: 1 },
                    'number'
                  )}
                </div>
                <div className="col-sm-8"></div>
              </div>

              <div className="row">
                {step == 2 ? getGuestFieldList() : ''}

                {step == 4 ? (
                  <Alert>
                    <h4 className="alert-heading">
                      {t("staycreate.confirm")}
                    </h4>
                    <hr />
                    <Button
                      className="mr-2"
                      type="button"
                      onClick={(e) => {
                        navigate('/stay');
                      }}
                      color="primary"
                    >
                      {t("staycreate.gostay")}
                    </Button>
                  </Alert>
                ) : (
                  ''
                )}
              </div>

              {step == 3 ? (
                <>
                <div style={{ textAlign: 'right' }}> 
                <Button
                    className="mb-2 mr-2 "
                    size="md"
                    type="button"
                    onClick={() => {
                      localStorage.removeItem('pendingStay');
                      window.location.reload();
                    }}

                    color="warning"
                  >
                    {!loading ? (
                       t('general.buttons.cancel')
                    ) : (
                      <>
                        <Spinner size="sm"> Loading...</Spinner>
                        <span> {t('action.loading')}</span>
                      </>
                    )}
                  </Button>
                  </div>
                  {/* <Alert color="warning">
                    <h4 className="alert-heading">
                      Il tuo soggiorno non è ancora stato finalizzato
                    </h4>
                    <hr />
                    <p className="mb-0">
                      Ricontrolla tutti i dati da te inseriti e conferma per
                      inviare tutti i dati alla struttura
                    </p>
                  </Alert> */}
                  <StayDisplay
                    exemptionList={exemptionList}
                    facilityList={facilityList}
                    initialData={toSend}
                  />
             </>
              ) : (
                ''
              )}
              { step == 4 && eventsData.length > 0 &&  <div className="row"><div className="col-md-12" >
<h2 className="font-bold text-center mt-5">{t("staycreate.events")}</h2>
</div>
{eventsData.map(event =>  <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={event.id}>
            <EventsDisplay event={event} isPrevious={false} />
          </div>) } 
     </div>}

     { step == 3 && <Alert style={{ textAlign: 'center' }} color="info">
                  <h4 className="alert-heading">{t("staycreate.events")}</h4>
                  <hr />
                  <Button
                    className="mr-2"
                    size="lg"
                    type="button"
                    onClick={(e) => {
                      createSoggiorno();
                    }}
                    color="primary"
                  >
                    {!loading ? (
                      t("staycreate.confirmaction")
                    ) : (
                      <>
                        <Spinner size="sm"> Loading...</Spinner>
                        <span> {t('action.loading')}</span>
                      </>
                    )}
                  </Button>
                </Alert> }
              {step < 3 && (
                <Button
                  type="button"
                  onClick={(e) => {
                    changeStep(step + 1);
                  }}
                  color="primary"
                >
                  { step == 1 && t("staycreate.insernames")}
                  { step == 2 && t("staycreate.confirmaction")  }
                  
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
);
