import React from 'react';
import './dashboard-view.css'; // Import your CSS file for styling
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface DashBoardViewProps {
  icon: IconDefinition;
  title: string;
  subtitle: string;
  color: string;
}

function DashBoardView(props: DashBoardViewProps) {
  const { icon, title, subtitle, color } = props;
  return (
    <div className="big-icon-container">
      <div className="big-icon">
        <FontAwesomeIcon icon={icon} size="4x" color={color} />
      </div>
      <div className="big-text">
        <h2>{title}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

export default DashBoardView;
