import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, UncontrolledTooltip } from 'reactstrap';

interface IconButtonWithTooltipProps {
  icon: IconProp;
  color: string;
  className?: string;
  title: string;
  id: string;
  style?: any;
  onClick: () => void;
}

function IconButtonWithTooltip(props: IconButtonWithTooltipProps) {
  const { id, icon, color, title, style, onClick, className } = props;
  return (
    <>
      <Button className={className? className : ''} color="link" id={id} onClick={onClick} style={{ ...style , ...{ color: '#004C6C' }}}>
        <FontAwesomeIcon icon={icon} color={color} />
      </Button>
      <UncontrolledTooltip placement="top" target={id}>
        {title}
      </UncontrolledTooltip>
    </>
  );
}

export default IconButtonWithTooltip;
