import {
  faClockRotateLeft,
  faPlus,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Spinner,
} from 'reactstrap';
import { getListOccupancyPrevisionConf } from '../../api/occupancyAPI';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import EditOccupancyRecordsModal from '../../modal/edit-occupancy-records.modal';
import { useAppUtilContext } from '../../util/app-util.context';
import {
  formatDateForFilter,
  getDateDDMMYYYY,
  getDateYYYYMMDD,
} from '../../util/date.util';
import { icons } from '../../util/form.util';

import Calendar from 'react-calendar';
import { getListExemptionConf } from '../../api/exemptionAPI';
import { getDetailFacilityConf } from '../../api/facilityAPI';
import { getTaxConf } from '../../api/generalAPI';
import {
  getCreatePaymentConf,
  getExistPaymentConf,
} from '../../api/paymentAPI';
import { MobileTable } from '../../components/mobile-table';
import TouristTaxTable from '../../components/tourist-tax-table';
import '../../css/calendar.style.css';
import HistoryPaymentModal, {
  HistoryPaymentModalHandle,
} from '../../modal/history-payment.modal';
import UpdateOccupancyRecordsModal, {
  UpdateOccupancyRecordsModalHandle,
} from '../../modal/update-occupancy-records.modal';
import { DetailFacility } from '../../model/facility.model';

export default function FacilityOccupancyPage() {
  let { id } = useParams();
  const [exemptions, setExemptions] = useState<any[]>([]);
  const [alreadyPayed, setAlreadyPayed] = useState<boolean>(true);
  const [tax, setTax] = useState<any>(1);
  const [facility, setFacility] = useState<DetailFacility | null>(null);
  const [list, setList] = useState<any[]>([]);
  const { fetchData: fetchExemptions } = useRequest();
  const { fetchData: fetchDataDetail } = useRequest<DetailFacility>();

  const { fetchData: fetchCreatePayment } = useRequest<any>();
  const { showModal, showNotification } = useAppUtilContext();

  const historyPaymentModalModalRef = useRef<HistoryPaymentModalHandle>(null);
  const editOccupancyRecordsModalRef = useRef<any>(null);
  const updateOccupancyRecordsModalRef =
    useRef<UpdateOccupancyRecordsModalHandle>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentDate = new Date();
  const year = searchParams.get('year')
    ? parseInt(searchParams.get('year')!, 10)
    : currentDate.getFullYear();
  const month = searchParams.get('month')
    ? parseInt(searchParams.get('month')!, 10) - 1
    : currentDate.getMonth();

  const ddefault = new Date(year, month, 1);

  let facilityId = id ? parseInt(id, 10) : undefined;

  const { loading, fetchData } = useRequest();

  const groupByDate = (data: any) => {
    return data.reduce((acc: any, obj: any) => {
      const date = obj.date;

      if (!acc[date]) {
        acc[date] = {};
      }
      acc[date]['formattedDate'] = getDateDDMMYYYY(new Date(date));
      if (!acc[date]['exemptionId-' + obj.exemptionId]) {
        acc[date]['exemptionId-' + obj.exemptionId] = 0;
      }
      acc[date]['exemptionId-' + obj.exemptionId] += obj.guests;
      return acc;
    }, {});
  };
  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  useEffect(() => {
    refresh();
    if (id) {
      fetchDataDetail(
        getDetailFacilityConf(id),
        (data) => {
          setFacility(data);
        },
        () => {}
      );
    }
    fetchExemptions(
      getListExemptionConf(),
      (data, headers) => {
        if (Array.isArray(data)) {
          setExemptions(data);
        }
      },
      () => {
        console.log('error');
      }
    );
    if (id) {
      fetchExemptions(
        getTaxConf(id),
        (data: any, headers) => {
          console.log('getTaxConf', data);
          if (data) {
            setTax(data.tax / 100);
          }
        },
        () => {
          console.log('error');
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onConfirmClick = () => {
    showModal(
      t('payment.confirmPayment'),
      <>
        <MobileButton
          onClick={() => {
            if (facilityId) {
              fetchCreatePayment(
                getCreatePaymentConf({ facilityId, year, month: month }),
                (data: any, header: any) => {
                  const { message } = data;
                  showModal('Success', message, null);
                  refresh();
                },
                onErrors
              );
            }
          }}
          color="primary"
          className="mr-2"
          // icon={faClockRotateLeft}
          text={t('general.actions.confirm')}
        />
      </>,
      null
    );
  };

  const refresh = () => {
    const iDate = new Date(year, month, 1);
    const fDate = new Date(year, month + 1, 0);
    let query = `?fromDate=${formatDateForFilter(
      iDate
    )}&toDate=${formatDateForFilter(fDate)}&facilityId=${id}`;
    if (facilityId) {
      fetchData(
        getListOccupancyPrevisionConf(facilityId, query),
        (data: any, header: any) => {
          setList(data);
        },
        onErrors
      );
      fetchData(
        getExistPaymentConf(year, month + 1, facilityId as number),
        (data: any, header: any) => {
          console.log('getExistPaymentConf', data.length);
          if (data.length > 0) setAlreadyPayed(true);
          else setAlreadyPayed(false);
        },
        onErrors
      );
    }
  };
  const create = () => {
    facilityId && editOccupancyRecordsModalRef.current?.open(facilityId);
  };
  const onChange = (e: any) => {
    console.log(e);
    const date = new Date(e);

    let fDate = getDateYYYYMMDD(date);
    let item = list.find((it) => it.date === fDate);
    // console.log(item);
    if (facilityId) {
      updateOccupancyRecordsModalRef.current?.open(facilityId, date, item);
    }
  };

  const onActiveStartDateChange = (year: string, month: string) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('year', year);
    newSearchParams.set('month', month);
    setSearchParams(newSearchParams);
  };

  let grouped = groupByDate(list);
  console.log(grouped);

  const tableData: any[] = exemptions.map((it) => {
    return {
      name: (
        <>
          <FontAwesomeIcon icon={icons.get(it.icon)} /> {it.name}
        </>
      ),
      id: it.id,
      amount: it.amount,
      guests: 0,
    };
  });

  list.map((it) => {
    tableData.forEach((it2) => {
      if (it['exemption-' + it2.id]) {
        it2.guests += it['exemption-' + it2.id];
      }
    });
  });
  // Format the date using toLocaleDateString
  const longMonth = new Date(year, month, 1).toLocaleString('default', {
    month: 'long',
  });
  const renderDot = ({ date }: any) => {
    let fDate = getDateYYYYMMDD(date);
    let item = list.find((it) => it.date === fDate);
    let totGuests = 0;

    if (item) {
      exemptions.map((it) => {
        if (item['exemption-' + it.id]) {
          totGuests += item['exemption-' + it.id];
        }
      });
    }

    const isDateWithDot = totGuests > 0;
    return isDateWithDot ? <div className="dot"></div> : null;
  };

  let additionalColumns: any = [];
  let additionalHeaderTop: any[] = [];
  let additionalHeaderBottom: any[] = [];

  exemptions.map((it) => {
    additionalColumns.push({
      Header: it.name,
      accessor: 'exemption-' + it.id,
    });
    additionalColumns.push({
      Header: '(exp)',
      accessor: 'stay-exemption-' + it.id,
    });
    additionalHeaderTop.push(
      <th colSpan={2}>
        <FontAwesomeIcon icon={icons.get(it.icon)} /> {it.name}
      </th>
    );
    additionalHeaderBottom.push(<th>Conf</th>);
    additionalHeaderBottom.push(<th>Exp</th>);
  });

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility'}>{t('facility.list.title')}</Link>
        </BreadcrumbItem>

        <BreadcrumbItem color="link">
          <Link to={'/facility/' + id}>{facility?.name}</Link>
        </BreadcrumbItem>

        <BreadcrumbItem active>
          {t('occupancy-record.list.title')}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('occupancy-record.list.title')}</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <MobileButton
            onClick={() => refresh()}
            disabled={loading}
            color="secondary"
            outline
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className="d-none d-md-inline">
                  &nbsp; {t('general.buttons.refresh')}
                </span>
              </>
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span className="d-none d-md-inline">
                  {' '}
                  {t('action.loading')}{' '}
                </span>
              </>
            )}
          </MobileButton>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <Card>
            <CardBody style={{ alignItems: 'center' }}>
              <Calendar
                onChange={onChange}
                defaultActiveStartDate={ddefault}
                onViewChange={({ action, activeStartDate, value, view }) => {
                  // eslint-disable-next-line no-console
                  console.log('onViewChange');
                }}
                onActiveStartDateChange={({
                  action,
                  activeStartDate,
                  value,
                  view,
                }) => {
                  if (activeStartDate) {
                    const year = activeStartDate.getFullYear();
                    const month = activeStartDate.getMonth() + 1;
                    onActiveStartDateChange('' + year, '' + month);
                  }
                }}
                tileContent={renderDot}
              />
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12">
          <Card>
            <CardBody>
              <div
                className="mb-2 justify-content-between"
                style={{ display: 'flex', gap: '10px' }}
              >
                <h4>{t('occupancy.tax')}  <span style={{ fontSize: 'smaller', position: 'relative', top: '-1px' }}>{"(" + (tax) + "€" + t('occupancy.persona') + ")" } </span> </h4>

                <div>
                  <MobileButton
                    onClick={() => {
                      historyPaymentModalModalRef.current?.open(
                        facilityId ? facilityId : 0
                      );
                    }}
                    color="ligth"
                    className="mr-2"
                    icon={faClockRotateLeft}
                    text={t('general.actions.history')}
                  />
                  <MobileButton
                    onClick={() => {
                      onConfirmClick();
                    }}
                    disabled={loading || alreadyPayed}
                    color="success"
                    icon={faPlus}
                    text={t('general.actions.pay')}
                  />
                </div>
              </div>
              <TouristTaxTable tax={tax} data={tableData}></TouristTaxTable>
            </CardBody>
          </Card>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12  mt-2">
          <Card>
            <CardBody>
              <div className="header-container mt-2">
                <h4>{longMonth.toUpperCase() + ' ' + year}</h4>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <MobileButton
                    onClick={create}
                    disabled={loading || alreadyPayed}
                    color="success"
                    icon={faPlus}
                    text={t('general.buttons.add')}
                  />
                </div>
              </div>
              <div className="  mt-2">
                <MobileTable
                  columns={[
                    {
                      Header: t('occupancy-record.date'),
                      accessor: 'date',
                    },
                    ...additionalColumns,
                  ]}
                  structuredHeader={
                    <>
                      <tr>
                        <th rowSpan={2}>Data</th>
                        {additionalHeaderTop}
                        <th rowSpan={2}></th>
                      </tr>
                      <tr>{additionalHeaderBottom}</tr>
                    </>
                  }
                  data={list}
                  goTodetail={(e: any) => {
                    const [year, month, day] = e.date.split('-').map(Number);
                    if (facilityId) {
                      updateOccupancyRecordsModalRef.current?.open(
                        facilityId,
                        new Date(year, month - 1, day),
                        e
                      );
                    }
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <EditOccupancyRecordsModal
        ref={editOccupancyRecordsModalRef}
        onClose={() => refresh()}
      />
      <HistoryPaymentModal ref={historyPaymentModalModalRef} />
      <UpdateOccupancyRecordsModal
        onClose={() => refresh()}
        ref={updateOccupancyRecordsModalRef}
      ></UpdateOccupancyRecordsModal>
    </div>
  );
}
