import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconButtonWithTooltip from "../../components/icon-button-with-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faBaby, faEye, faClock, faTicket, faCalendar, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { SERVER_API_URL } from "../../axios/config";
import "./events_page.css";
import MobileButton from "../../components/mobile-button";
import { getDateDDMMYYYY, italianDateFormat } from "../../util/date.util";
import { useTranslation } from "react-i18next";

const EventsDisplay = ({ event, isPrevious }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const path = location.pathname;

  const handleButtonClick = (event: any) => {
    const newPath = `${path}/detail?event=${encodeURIComponent(event.id)}`;
    navigate(newPath);
  };

  const giveUrl = (it: any) => {
    let c ='url("' +
      (it?.images && it?.images.length > 0
        ? SERVER_API_URL + '/' + it.images[0].image_url
        : 
        SERVER_API_URL + '/blufestival.jpg') +
      '")'   
    return c;
  };

  const checkDateEvents = (evEnd:any, evStart:any) => {   
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if(evEnd === null) return false
    if(evEnd === undefined) return false
    const eventStartDate = new Date(evStart);
    const eventEndDate = new Date(evEnd); 
    if (eventEndDate >= today && eventStartDate < today) return true 
      else return false
  };

  return (
    <div className={`event-card card float-right ${isPrevious ? "previous-event" : ""}`} style={{marginTop: "1rem" }}>
    <div className="d-flex flex-column border-gradient" style={{ margin: 0 }}>
      <div
        // onClick={() => handleButtonClick(event)}
        style={{
          backgroundImage: giveUrl(event),
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "150px",
          
        }}
        className="ml-0"
      ></div>
      <div className="">
        <div className="body card-body mt-3 mb-3 d-flex flex-column justify-content-center">
          <span className="event-card-title">{event.name}</span>
          <span>
            <FontAwesomeIcon icon={faCity} color="#004C6C" /> {event.locationEvent}
          </span>
          {event?.weeklyEvent && (
            <span>
              <FontAwesomeIcon icon={faCalendar} color="#004C6C" /> {t("events.everyweek")}
            </span>
          )}
          <span>
            <FontAwesomeIcon icon={faCalendar} color="#004C6C" /> { getDateDDMMYYYY(new Date(event?.eventStartDate)) } - { getDateDDMMYYYY(new Date(event?.eventEndDate)) }
          </span>
          <span>
            <FontAwesomeIcon icon={faClock} color="#004C6C" /> {event?.startTimeEvent} {event?.endTimeEvent && ( " - " + event?.endTimeEvent)}
          </span>
          <span>
            <FontAwesomeIcon icon={faTicket} color="#004C6C" />{" "}
            {event?.eventReservation ? t("events.reservationmand") : t("events.freeentry")}
            <br />
            {event?.eventType ? (
              <>
                <strong>{t("events.price")}</strong> {event?.price / 100} €{" "}
              </>
            ) : (
              <strong>{t("events.free")}</strong>
            )}
          </span>
          {event?.eventForChildren && (
            <span>
              <FontAwesomeIcon icon={faBaby} color="#004C6C" /> {t("events.4baby")}
            </span>
          )}
            {isPrevious && (
            <span className="text-danger font-weight-bold" style={{ fontSize: "1.2rem" }}> <strong>{t("events.expired")}</strong></span>
          )}
          {event?.eventEndDate &&  checkDateEvents(event?.eventEndDate, event?.eventStartDate) && (
            <span style={{ color: "#004C6C" }}>
              {/* <FontAwesomeIcon icon={faClock} color="#004C6C" /> */}
              <strong>{" FINO A : "}
               {italianDateFormat(event?.eventEndDate)}
               </strong>
            </span>
          )}
        </div>
        <IconButtonWithTooltip
          id="pippo"
          icon={faEye}
          color={"primary"}
          title={t("general.buttons.view")}
          style={{ float: "right", marginTop: "-27px", marginRight: "0" }}
          onClick={() => handleButtonClick(event)}
        ></IconButtonWithTooltip>
      </div>
    </div>
  </div>
  );
};

export default EventsDisplay;
