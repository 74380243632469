import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import {
  getCreateFacilityImageConf,
  getDeleteFacilityImageConf,
} from '../api/facilityAPI';
import {
  getCreateSolicitorImageConf,
  getDeleteSolicitorImageConf,
} from '../api/solicitorAPI';
import { SERVER_API_URL } from '../axios/config';
import IconButtonWithTooltip from '../components/icon-button-with-tooltip';
import useRequest from '../hook/use-request.hook';
import { getCreateEventImageConf, getDeleteEventImageConf } from '../api/eventsApi';

export interface AddImageModalProps {
  onClose: () => void;
  refresh: () => void;
  type: string;
  single: boolean;
  list: any[] | undefined;
}
export interface AddImageModalHandle {
  open: (facilityId: number) => void;
}
const AddImageModal = forwardRef<AddImageModalHandle, AddImageModalProps>(
  (props: AddImageModalProps, ref) => {
    const { type, list, refresh, single } = props;
    const [fatherId, setFatherId] = useState<number>(0);
    const [modal, setModal] = useState(false);

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [image, setImage] = useState<any>(null);

    const { loading, fetchData } = useRequest();
    const toggle = () => setModal(!modal);
    useImperativeHandle(ref, () => ({
      open(id: number) {
        console.log('open modal')
        setFatherId(id);
        setModal(true);
      },
    }));

    const handleFileChange = (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      if (file.size > 1000000) {
        window.alert(t('general.errors.image_size'));
        return false;
      }

      reader.onloadend = (e: any) => {
        setImage(e.target.result);
      };
      if (file) {
        reader.readAsDataURL(file);
      }

      setSelectedFile(file);
    };

    const handleSubmit = (event: any) => {
      event.preventDefault();
      if (!image) {
        alert('Please select a file.');
        return;
      }
      const formData = new FormData();
      console.log(selectedFile);
      if (type === 'facility') {
        formData.append('facilityId', fatherId.toString());

        const config = getCreateFacilityImageConf(formData);
        fetchData(
          config,
          () => {
            //  debugger;
            setSelectedFile(null);
            setImage(null);
            refresh();
            toggle();
          },
          () => {
            //  debugger;
          }
        );
      } else if (type === 'solicitor') {
        formData.append('solicitorId', fatherId.toString());

        const config = getCreateSolicitorImageConf(formData);
        fetchData(
          config,
          () => {
            //  debugger;
            setSelectedFile(null);
            setImage(null);
            refresh();
            toggle();
          },
          () => {
            //  debugger;
          }
        );
      }else if (type === 'event') {
        formData.append('eventId', fatherId.toString());

        const config = getCreateEventImageConf(formData);
        fetchData(
          config,
          () => {
            //  debugger;
            setSelectedFile(null);
            setImage(null);
            refresh();
            toggle();
          },
          () => {
            //  debugger;
          }
        );
      }
      
      formData.append('file', selectedFile);
    };

    return (
      <>
        <Modal isOpen={modal} toggle={toggle}>
          <Form onSubmit={handleSubmit}>
            <ModalHeader toggle={toggle}>
              {t('modal.AddImage.title')}
            </ModalHeader>
            <ModalBody>
              {(single && list && list.length === 0) || !single ? (
                <>
                  {image && (
                    <img
                      src={image}
                      alt="Uploaded"
                      style={{ maxWidth: '100%', maxHeight: '150px' , marginBottom: '15px'}}
                    />
                  )}
                  <div className="d-flex">
                    <FormGroup
                      style={{ marginRight: '10px' }}
                      className="form-field"
                    >
                      <Input
                        name="file"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </FormGroup>
                    <Button type="submit" disabled={loading}>
                      {!loading ? (
                        t('action.send')
                      ) : (
                        <>
                          <Spinner size="sm"> Loading...</Spinner>
                          <span> {t('action.loading')}</span>
                        </>
                      )}
                    </Button>
                  </div>
                  <hr />
                </>
              ) : (
                ''
              )}
              {list && list.length > 0 ? (
                <>
                  {list.map((el, idx) => {          
                    return (
                      <div className="d-flex">
                        <div style={{ flexGrow: 1 }}>
                          <img
                            alt="Uploaded"
                            style={{ maxWidth: '200px' }}
                            src={SERVER_API_URL + '/' + el.url}
                          />
                        </div>
                        <IconButtonWithTooltip
                          id={`view`}
                          icon={faTrash}
                          color={'primary'}
                          title={t('general.buttons.view')}
                          style={{ width: '80px' }}
                          onClick={() => {
                            let config = null;
                            if (type === 'facility') {
                              config = getDeleteFacilityImageConf(el.id);
                            }else if (type === 'event') {
                              config = getDeleteEventImageConf(el.image_id);
                            } 
                            else {
                              config = getDeleteSolicitorImageConf(el.id);
                            }

                            fetchData(
                              config,
                              () => {
                                setSelectedFile(null);
                                setImage(null);
                                refresh();
                              },
                              () => {}
                            );
                          }}
                        ></IconButtonWithTooltip>
                      </div>
                    );
                  })}
                </>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                {t('general.buttons.close')}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
);

export default AddImageModal;
