import {
  faArrowLeft,
  faImage,
  faHouseLock,
  faPencil,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody } from 'reactstrap';
import { deleteFacilityConf, getDetailFacilityConf } from '../../api/facilityAPI';
import { SERVER_API_URL } from '../../axios/config';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import AddImageModal from '../../modal/add-image.modal';
import AddSolicitorModal, {
  AddSolicitorModalHandle,
} from '../../modal/add-solicitor.modal';
import EditCompanyModal, {
  EditCompanyModalHandle,
} from '../../modal/edit-company-facility.modal';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { DetailFacility } from '../../model/facility.model';
import { NotificationTypes, useAppUtilContext } from '../../util/app-util.context';
import CompanyForm from '../company/company.form';
import {
  FacilitySolicitorsTable,
  FacilitySolicitorsTableHandle,
} from './facility-solicitor-table';
import FacilityForm from './facility.form';
import { giveUrl } from './facility-list.page';
import FacilityDisplay from './facility-display';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import { useTranslation } from 'react-i18next';

export default function FacilityDetailPage(props: any) {
  let { id } = useParams();
  const facilityId = id ? parseInt(id, 10) : undefined;
  const { showModal, showNotification } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const { fetchData: fetchDataDetail } = useRequest<DetailFacility>();
  const [entity, setEntity] = useState<any>();
  const addSolicitorModalRef = useRef<AddSolicitorModalHandle>(null);
  const editCompanyModalRef = useRef<EditCompanyModalHandle>(null);
  const addImageModalRef = useRef<any>(null);
  const [activeTab, setActiveTab] = useState('1');
  const facilitySolicitorsTableRef =
    useRef<FacilitySolicitorsTableHandle>(null);
  const [canAccess, setCanAccess] = useState(false);

  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: any) => {
    // console.log("onSuccessDetail", data)
    if (
      checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      )
    ) {
      setCanAccess(true);
    } else if (
      data.company?.managerEmail === currentUser?.email ||
      data.company?.email === currentUser?.email
    ) {
      setCanAccess(true);
    } else {
      setCanAccess(false);
    }

    // console.log("onSuccessDetail perset", { ...entity, ...data})
    setEntity({ ...entity, ...data});
  };

  const reload = () => {
    if (id) {
      fetchDataDetail(
        getDetailFacilityConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    // console.log('data', data);
    let message = data.message ? data.message : t('facility.edit.success');

    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onAddSolicitor = () => {
    if (entity?.id) {
      addSolicitorModalRef.current?.open(entity?.id);
    }
  };

  const onEditCompanyClick = () => {
    if (entity?.id) {
      editCompanyModalRef.current?.open(entity?.id, entity.company);
    }
  };
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // console.log('formModeformModeformMode', formMode);

  const isMine = (company: any) => {
    if (company) {
      return (
        currentUser?.email == company?.managerEmail ||
        currentUser?.email == company?.email
      );
    }
    return false;
  };

  const onSuccessDelete = (data: any) => {   
    showModal("Struttura Rimossa",null,null);
    navigate(-1);
  };
  const deleteFacility = (e: any) => {
    showModal(
      t("general.actions.confirmDelete"),
      <>
        <MobileButton
          onClick={() => {
            if (entity && entity.id) {
             fetchData(deleteFacilityConf(entity.id), onSuccessDelete, onErrors);
            }
          }}
          color="danger"
          className="mr-2"
          // icon={faClockRotateLeft}
          text={t("general.actions.confirm")}
        />
      </>,
      null
    );
  };

  // console.log("entity render ", entity)
  
  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility'}>{t('facility.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('facility.detail.title')}</BreadcrumbItem>
      </Breadcrumb>
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: '12px' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
        {t('general.buttons.back')}
      </Button>
      <div className="header-container">
        <h2>{entity?.name ? entity.name : ' '}</h2>
        {canAccess && (
          <>
            <div>
              {checkRequiredAuthorities([Authorities.MANAGER], currentUser) &&
                isMine(entity?.company) && (
                  <MobileButton
                    onClick={() => navigate(`/facility/${id}/occupancy`)}
                    color="primary"
                    style={{ marginRight: '5px' }}
                    icon={faHouseLock}
                    text={'Gestione tassa di soggiorno'}
                  />
                )}

              {checkRequiredAuthorities([Authorities.ADMIN], currentUser) &&(          
                <MobileButton
                  onClick={deleteFacility}
                  color="danger"
                  style={{ marginRight: '5px' }}
                  icon={faTrash}
                  text={"Elimina Struttura"}
                />
              )}
                          
              <MobileButton
                onClick={() => addImageModalRef.current?.open(entity?.id)}
                color="success"
                style={{ marginRight: '5px' }}
                icon={faImage}
                text={t('general.buttons.addimage')}
              />
              <MobileButton
                onClick={() => navigate(`/facility/${id}/update`)}
                color="success"
                icon={faPencil}
                text={t('general.buttons.edit')}
              />
            </div>{' '}
          </>
        )}
      </div>
      <Card className="mt-2">
        {entity?.images && entity?.images.length > 0 ? (
          <div
            style={{
              width: '100%',
              minHeight: '250px',
              backgroundImage: giveUrl(entity),
              backgroundSize: 'cover',
            }}
          />
        ) : (
          ''
        )}
        {entity?.images && entity?.images.length > 1 ? (
          <div className="d-flex">
            {entity?.images
              .filter((el : any, index: any) => index !== 0)
              .map((el: any) => (
                <img
                  key={el.it}
                  alt="facility"
                  style={{ maxWidth: '100px' }}
                  src={SERVER_API_URL + '/' + el.url}
                />
              ))}
          </div>
        ) : (
          ''
        )}

        <CardBody>
          {canAccess? (
            <FacilityForm
              initialData={entity}
              enabled={false}
              onSubmitSuccess={onSuccess}
              onSubmitError={onErrors}
            ></FacilityForm>
          ) : (
            <FacilityDisplay initialData={entity} />
          )}
        </CardBody>
      </Card>
      {canAccess && (
        <>
          <div className="mt-2">
            <div className="header-container mt-2">
              <h3>{t('facility.company')}</h3>
              {checkRequiredAuthorities(
                [Authorities.ADMIN, Authorities.MUNICIPALITY],
                currentUser
              ) && (
                <MobileButton
                  onClick={() => onEditCompanyClick()}
                  color="success"
                  icon={entity?.company ? faPencil : faPlus}
                  text={t('general.buttons.addfiscaldata')}
                />
              )}
            </div>
            <CardBody>
              <CompanyForm
                enabled={false}
                initialData={entity?.company ? entity.company : undefined}
                onSubmitSuccess={() => console.log('succeq')}
                onSubmitError={() => console.log('error')}
              ></CompanyForm>
            </CardBody>
          </div>
          <div className="mt-2">
            <div className="header-container mt-2">
              <h3>{t('facility.solicitors')}</h3>
              <MobileButton
                onClick={() => onAddSolicitor()}
                color="success"
                icon={faPlus}
                text={t('general.buttons.addsolicitors')}
              />
            </div>
            <FacilitySolicitorsTable
              ref={facilitySolicitorsTableRef}
              facility={entity}
            ></FacilitySolicitorsTable>
          </div>

          <AddSolicitorModal
            ref={addSolicitorModalRef}
            onClose={() => facilitySolicitorsTableRef.current?.reload()}
          ></AddSolicitorModal>
          <EditCompanyModal
            ref={editCompanyModalRef}
            onClose={() => {
              reload();
            }}
          ></EditCompanyModal>
          <AddImageModal
            single={false}
            refresh={() => {
              fetchDataDetail(
                getDetailFacilityConf(id ? id.toString() : ''),
                onSuccessDetail,
                onErrorsDetail
              );
            }}
            list={entity?.images}
            ref={addImageModalRef}
            type="facility"
            onClose={() => facilitySolicitorsTableRef.current?.reload()}
          />
        </>
      )}
    </div>
  );
}
