import { t } from 'i18next';
import { Alert } from 'reactstrap';

const PageNotFound = () => {
  return (
    <div className='container mt-2'>
      <Alert color="danger">
        {t('general.errors.page_not_exists')}
      </Alert>
    </div>
  );
};

export default PageNotFound;
