import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import SolicitorPageCreate from './solicitor-create.page';
import SolicitorListPage from './solicitor-list.page';
import SolicitorDetailPage from './solicitor-detail.page';
import SolicitorUpdatePage from './solicitor-update.page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

const SolicitorRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY, Authorities.MANAGER],
        currentUser
      ) && (
        <>
          <Route path="" element={<SolicitorListPage />} />
          <Route path=":id" element={<SolicitorDetailPage />} />
          <Route path=":id/update" element={<SolicitorUpdatePage />} />
          <Route path="create" element={<SolicitorPageCreate />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default SolicitorRoutes;
