import { Action, AnyAction, ThunkAction, ThunkDispatch, configureStore, } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from "./reducers/userSlice";
import networkStatusSlice from "./reducers/networkStatusSlice";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  user: userReducer,
  networkStatus: networkStatusSlice

});

const persistConfig = {
  key: 'root',
  storage,
}


const persistedReducer = persistReducer(persistConfig, rootReducer)





export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },
});

export const persistor = persistStore(store)

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppThunkDispatch;
  rejectValue: string;
  extra: { s: string; n: number };
};
