import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { DetailStay } from '../../model/stay.model';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { getDetailStayConf } from '../../api/stayAPI';
// import StayForm from './stay.form';
import StayDisplay from './stay.display';
import { getListExemptionConf } from '../../api/exemptionAPI';

export default function StayDetailPage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData: fetchDataDetail } = useRequest<DetailStay>();
  const [entity, setEntity] = useState<DetailStay>();
  // const [exemptionList, setExemptionList] = useState<any[]>([]);

  useEffect(() => {
    // console.log("useEffect iiiiiis", id)
    if (id) {
      fetchDataDetail(getDetailStayConf(id), onSuccessDetail, onErrorsDetail);
    }
  }, [id]);

  // useEffect(() => {
  //   console.log("useEffect iiiiiis", id)

  //   if (id) {
  //     fetchDataDetail(getDetailStayConf(id), onSuccessDetail, onErrorsDetail);
  //   }
  //   fetchDataDetail(
  //     getListExemptionConf(),
  //     (data: any) => {
  //       setExemptionList(data);
  //     },
  //     (data: any) => {
  //       setExemptionList([]);
  //     }
  //   );
  // }, []);

  const onSuccessDetail = (data: DetailStay) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          {/* <Link to={'/dashboard'}>{t('navbar.home')}</Link> */}
          <Link to={'/'}>{t('navbar.home')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem color="link">
          <Link to={'/stay'}>{t('stay.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('stay.detail.title')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2 className="page-title">{t('stay.detail.title')}</h2>
      </div>
      <Card className="mt-2">
        <CardBody>
          {entity && <StayDisplay
            initialData={entity}
            exemptionList={[]}
            facilityList={[]}
          />}
        </CardBody>
      </Card>
    </div>
  );
}
