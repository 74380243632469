/* eslint-disable react/jsx-no-undef */
import { Button } from 'reactstrap';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from 'react';
import useRequest from '../../hook/use-request.hook';
import { getListFacilityPagedConf } from '../../api/facilityAPI';
import { useAppUtilContext } from '../../util/app-util.context';
import { ErrorsView } from '../../components/errors.view';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import './home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCrosshairs,
  faPhone,
  faBus,
  faRoute,
  faMapLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { icons } from '../../util/form.util';
import { giveUrl } from '../fullfacility/facility-list.page';
import { useTranslation } from 'react-i18next';
import { userLngSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

export default function HomePage() {
  const { showModal } = useAppUtilContext();
  const { loading, fetchData } = useRequest();
  const navigate = useNavigate();
  const [list, setList] = useState<any[]>([]);
  const { t } = useTranslation();
  const lang = useAppSelector(userLngSelector);
  useEffect(() => {
    fetchData(
      getListFacilityPagedConf(1, 50, ''),
      (data: any, header: any) => {
        let images: any[] = [];
        data.map((el: any) => {
          el.images.map((im: any) => {
            images.push({
              ...im,
              ...{
                zone: el.place ? el.place.id : 0,
                facilityId: el.id ? el.id : 0,
              },
            });
          });
          //  images = [ ...images, ...el.images]
        });

        setList(getRandom5Element(data));
      },
      onErrors
    );
  }, []);

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  const getRandom5Element = (list: any[]) => {
    let items = list.length;
    let total = items - 1;
    let estratti = [];

    while (estratti.length < 5) {
      let index = Math.floor(Math.random() * (total + 1));
      estratti.push(list[index]);
    }

    return estratti;
  };

  let col = [4, 8, 4, 4, 4];
  let h = [380, 380, 200, 200, 200];

  const handleButtonClick = (src: string) => {
    navigate('/pdf-viewer?src=' + encodeURIComponent(src));
  };

  let arrayLoghi = [
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Marine-Melendugno.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Puglia-Promozione.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-citta-miele-logo.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Echo-School.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Bandiera-Verde.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Bandiera-Cinque-Vele.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Bandiera-Blu.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Borghi-Autentici.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Assotour.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Marine-Melendugno.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Puglia-Promozione.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-citta-miele-logo.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Echo-School.png',
    'https://www.visitmelendugno.com/wp-content/uploads/2020/06/Visit_Melendugno-Logo-Bandiera-Verde.png',
  ];


  return (
    <>
      <div id="card" style={{     background:'#154c6b' }}>
        <div className="wpb_wrapper head">
          <div className="container" style={{    position: 'relative' }}>
            <div
              className="empty_space  visible-lg"
              style={{ height: '100px' }}
            >
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space" style={{ height: '10px' }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="widget-text-heading center_white">
              <h2 className="widget-title">
                <span>{t("home.teaser")}</span>
              </h2>
              <div
                style={{
                  fontWeight: '300',
                  lineHeight: '2rem',
                }}
                className="description"
              >
                {t("home.subteaser")}
              </div>
            </div>
            <div className="empty_space" style={{ height: '35px' }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="widget-listingsearch  style4">
              <div style={{ textAlign: 'center' }} className="widget-content">
                {/* <form className="job_search_form  js-search-form" action="https://www.visitmelendugno.com/lista/" method="get" role="search">
                     <div className="search_jobs clearfix visible-table search_jobs--frontpage">
                        <div className="inner-left">
                           <div className="visible-table table-wrapper">
                              <div className="search-field-wrapper  search-filter-wrapper has-suggestion grow1">
                                 <label className="hidden">Parole chiave</label>
                                 <input className="search-field" type="text" name="search_keywords" id="search_keywords" placeholder="Cosa stai cercando?" value="" />
                              </div>
                              <div className="search_location grow1 search-filter-wrapper">
                                 <input type="text" name="search_location" id="search_location" placeholder="Località" />
                                 <span className="clear-location hidden"><i className="fa fa-times"></i></span>
                                 <span className="find-me">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
                              <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="All-listings" transform="translate(-964.000000, -626.000000)" fill-rule="nonzero" fill="#9B9B9B">
                                    <g id="Group-4-Copy" transform="translate(731.000000, 611.000000)">
                                       <g id="target-with-circle" transform="translate(233.000000, 15.000000)">
                                          <g>
                                             <path d="M7.48790875,4.76543726 C5.98346008,4.76543726 4.76543726,5.98346008 4.76543726,7.48790875 C4.76543726,8.99235741 5.98346008,10.2103802 7.48790875,10.2103802 C8.99235741,10.2103802 10.2103802,8.99235741 10.2103802,7.48790875 C10.2103802,5.98346008 8.99235741,4.76543726 7.48790875,4.76543726 Z M13.5730894,6.80652091 C13.2604848,3.96798479 11.0072338,1.71473384 8.16869772,1.40272814 L8.16869772,0 L6.80714829,0 L6.80714829,1.40272814 C3.96858365,1.71473384 1.71536122,3.96798479 1.40210076,6.80652091 L0,6.80652091 L0,8.1693251 L1.40272814,8.1693251 C1.71596008,11.0078612 3.96921103,13.2611122 6.80774715,13.5731179 L6.80774715,14.975846 L8.16929658,14.975846 L8.16929658,13.5731179 C11.0078327,13.2611122 13.2610837,11.0078897 13.5736882,8.1693251 L14.975789,8.1693251 L14.975789,6.80652091 C14.975846,6.80652091 13.5730894,6.80652091 13.5730894,6.80652091 Z M7.48790875,12.253346 C4.85341255,12.253346 2.72247148,10.1224049 2.72247148,7.48790875 C2.72247148,4.85341255 4.85341255,2.72247148 7.48790875,2.72247148 C10.1224049,2.72247148 12.253346,4.85341255 12.253346,7.48790875 C12.253346,10.1224049 10.1224049,12.253346 7.48790875,12.253346 Z" id="Shape"></path>
                                          </g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg> 
                                 </span>
                                 <input type="hidden" name="search_lat" id="search_lat" />
                                 <input type="hidden" name="search_lng" id="search_lng" />
                                 <input className="hidden" type="checkbox" checked name="use_search_distance" id="use_search_distance" value="on" />
                                 <input type="hidden" name="search_distance" id="search_distance" value="5" />
                              </div>
                              <div className="search_categories grow1 search-filter-wrapper">
                                 <select name="search_categories[]" id="search_categories" className="job-manager-category-dropdown select2-hidden-accessible" >
                                    <option value="" data-select2-id="13">Tutte le categorie</option>
                                    <option className="level-0" value="147">Eventi</option>
                                    <option className="level-0" value="148">Itinerari</option>
                                    <option className="level-1" value="149">&nbsp;&nbsp;&nbsp;Arte e Cultura</option>
                                    <option className="level-1" value="150">&nbsp;&nbsp;&nbsp;Enogastronomia</option>
                                    <option className="level-1" value="151">&nbsp;&nbsp;&nbsp;Natura</option>
                                    <option className="level-1" value="152">&nbsp;&nbsp;&nbsp;Sport</option>
                                    <option className="level-0" value="191">Punti di Interesse</option>
                                    <option className="level-1" value="192">&nbsp;&nbsp;&nbsp;Curiosità</option>
                                    <option className="level-1" value="193">&nbsp;&nbsp;&nbsp;Storia e Cultura</option>
                                 </select>

                              </div>
                              <div className="submit grow1" style={{ padding: '0 10px' }}>
                                 <button className="search-submit d-block btn pull-right btn-theme " name="submit">
                                    <span>Cerca</span>
                                    <i className="flaticon-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>

                     </div>
                     <div className="filters_end">
                        <ul className="job_types">
                           <li><label className="evento"><input type="checkbox" name="filter_job_type[]" value="evento" id="job_type_evento" /> Evento</label></li>
                           <li><label className="itinerario"><input type="checkbox" name="filter_job_type[]" value="itinerario" id="job_type_itinerario" /> Itinerario</label></li>
                           <li><label className="punto-di-interesse"><input type="checkbox" name="filter_job_type[]" value="punto-di-interesse" id="job_type_punto-di-interesse" /> Punto di Interesse</label></li>
                           <li><label className="struttura-ricettiva"><input type="checkbox" name="filter_job_type[]" value="struttura-ricettiva" id="job_type_struttura-ricettiva" /> Struttura Ricettiva</label></li>
                        </ul>
                        <input type="hidden" name="filter_job_type[]" value="" />
                        <div className="showing_jobs"></div>
                     </div>
                  </form> */}
                <Button
                  className="mr-2"
                  type="button"
                  size="lg"
                  onClick={(e) => {
                    navigate('/stay/new');
                  }}
                  color="light"
                >
                   {t("home.callcard")}
                </Button>
              </div>
            </div>
            <div
              className="empty_space  visible-lg"
              style={{ height: '120px' }}
            >
              <span className="empty_space_inner"></span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container pt-2 mt-2 mb-5"
        style={{
          textAlign: 'center',
          paddingRight: '50px !important',
          paddingLeft: '50px !important ',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <h1 className="custom_heading do_custom_heading">
        {t("home.facility")}
        </h1>

        {/* <div className="widget-text-heading  center">
          <div className="description">
            Un Cicerone virtuale che ti conduce alla scoperta delle meraviglie
            di uno degli angoli più suggestivi del Salento.{' '}
          </div>
        </div> */}
      </div>
      <div
        id="strutture"
        className="container pt-2 mt-2 mb-5"
        style={{
          textAlign: 'center',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <div className="row wpb_row inner row-fluid">
          {list.map((el, index) => (
            <div
              className={
                'wpb_column column_container mb-4 col-sm-' + col[index]
              }
            >
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="widget-listing-categories-banner ">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/facility/${el.id}`)}
                    >
                      <div className="categories-banner-inner style2">
                        <div className="category-image">
                          <div
                            style={{
                              height: h[index] + 'px',
                              cursor: 'pointer',
                              backgroundImage: giveUrl(el),
                              backgroundSize: 'cover',
                              minHeight: '200px',
                              width: '100%',
                            }}
                            className="image-wrapper image-loaded"
                          ></div>
                        </div>
                        <div className="content-inner">
                          <h4 className="category-info-title">{el.name} </h4>
                          <span className="category-number">
                            {el.typology.name}
                          </span>
                        </div>
                        <div className="category-icon">
                          <div className="image-wrapper image-loaded">
                            <FontAwesomeIcon
                              icon={icons.get(el.typology.icon)}
                            />{' '}
                            <span
                              style={{ textTransform: 'capitalize' }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          className="mr-2"
          type="button"
          size="lg"
          onClick={(e) => {
            navigate('/facility');
          }}
          color="primary"
        >
           {t("home.callfacility")}
        </Button>
      </div>

      <div id="servizi">
        <div className="wpb_wrapper head blu">
          <div className="container" style={{    position: 'relative' }}>
            <div className="empty_space  visible-lg" style={{ height: '60px' }}>
              <span className="empty_space_inner"></span>
            </div>
            <div className="empty_space" style={{ height: '10px' }}>
              <span className="empty_space_inner"></span>
            </div>
            <div
              className="widget-text-heading center_white"
              style={{ fontSize: '22px' }}
            >
              <h2 className="widget-title">
                <span>{t("home.services.title")}</span>
              </h2>
              <div className="mt-5 d-md-flex justify-content-center ">
                <div className='home-col' >
                  <FontAwesomeIcon
                    size="3x"
                    style={{ marginLeft: '15px' }}
                    icon={faMapLocationDot} 
                  />
                  <div
                    style={{ fontWeight: '300', marginRight: '40px' }}
                    className="mlat-2 text-b"
                  >
                    <b
                      style={{
                        display: 'block',
                        fontWeight: '800',
                        marginTop: '15px',
                      }}
                    >
                      <Link
                        className="link-light link-underline link-underline-opacity-0 fs-6"
                        to={'/visite'}
                      >
                       {t("home.services.ticket")}
                      </Link>
                    </b>
                    <br />
                    {t("home.services.tickettext")}
                    <br /><Button
                  className="mt-4"
                  type="button"
                  onClick={(e) => {
                    navigate('/visite');
                  }}
                  color="light"
                >
                  {t("home.services.ticketcall")}
                </Button>
                    <br />
                  </div>
                </div>
                <div className='home-col' >
                  <FontAwesomeIcon
                    size="3x"
                    style={{ marginLeft: '15px' }}
                    icon={faRoute}
                  />
                  <div
                    style={{ fontWeight: '300', marginRight: '40px' }}
                    className="mlat-2 text-b"
                  >
                    <b
                      style={{
                        display: 'block',
                        fontWeight: '800',
                        marginTop: '15px',
                      }}
                    >
                      {' '}
                      <Link
                        className="link-light link-underline link-underline-opacity-0 fs-6"
                        to={'/servizio-sanitario'}
                      >
                        {t("home.services.tour")}
                      </Link>
                    </b>
                    <br />
                    {t("home.services.tourtext")}
                    <br />
                    <Button
                  className="mt-4"
                  type="button"
                  onClick={(e) => {
                    navigate('/servizio-sanitario');
                  }}
                  color="light"
                >
                  {t("home.services.tourcall")}
                </Button>
                  </div>
                </div>
                <div className='home-col'>
                  <FontAwesomeIcon
                    size="3x"
                    style={{ marginLeft: '15px' }}
                    icon={faBus}
                  />
                  <div style={{ fontWeight: '300' }} className="mlat-2 text-b">
                    <b
                      style={{
                        display: 'block',
                        fontWeight: '800',
                        marginTop: '15px',
                      }}
                    >
                      {' '}
                      <Link
                        className="link-light link-underline link-underline-opacity-0 fs-6"
                        to={'/navetta'}
                      >
                         {t("home.services.bus")}
                      </Link>
                    </b>
                    <br />
                    {t("home.services.bustext")}     
                    <br />
                    <Button
                  className="mt-4"
                  type="button"
                  onClick={(e) => {
                    navigate('/navetta');
                  }}
                  color="light"
                >
                   {t("home.services.bustcall")}
                </Button>    <br />
                    <Button
                  className="mt-4"
                  type="button"
                  onClick={(e) => {
                    navigate('/terra-mare');
                  }}
                  color="light"
                >
                  {t("home.services.buscall2")}
                </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="empty_space" style={{ height: '35px' }}>
            <span className="empty_space_inner"></span>
          </div>
          <div className="empty_space visible-lg" style={{ height: '60px' }}>
            <span className="empty_space_inner"></span>
          </div>
        </div>
      </div>
      <div
        className="container pt-4 pb-4 mt-2 mb-5"
        style={{
          textAlign: 'center',
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <h2 style={{ fontSize: '45px' }} className="widget-title">
          <Link
            className="link-underline link-underline-opacity-0 fs-1"
            to={'/infopoint'}
          >
            { lang === 'it' ? 'Infopoint Marine di Melendugno' : 'Infopoint Marine of Melendugno' }
          </Link>
        </h2>
        {lang === 'it' ? (
          <div style={{ fontSize: '22px' }}>
          <p className="text-b">
            Dal 1° Giugno sarà in funzione tutti i giorni dalle 9:00 alle 13:00
            e dalle 16:00 alle 20:00, il servizio di informazione e accoglienza
            turistica
          </p>
          <div className="mt-5 d-md-flex justify-content-around align-items-center text-b">
            <div className='home-col'>
              <b style={{ color: '#00415c' }}>San Foca</b>
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faCrosshairs}
              />{' '}
              Zona Ricci
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faPhone}
              />{' '}
              329 3658519
            </div>
            <div className='home-col'>
              <b style={{ color: '#00415c' }}>Torre dell'Orso</b>
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faCrosshairs}
              />{' '}
              Lungomare Matteotti
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faPhone}
              />{' '}
              329 2583678
            </div>
            <div className='home-col'>
              <b style={{ color: '#00415c' }}>Sant'Andrea</b>
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faCrosshairs}
              />{' '}
              Zona Faraglioni
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faPhone}
              />{' '}
              329 3952460
            </div>            
          </div>
          <div className="mt-5">              
            <h4> <strong>Servizio di Assistenza Sanitaria Turistica a Torre dell'Orso e San Foca</strong> </h4>
            <p>Dal 15 giugno riparte il Servizio di Assistenza Sanitaria Turistica a Torre dell’Orso e a San Foca, attivo tutti i giorni dalle ore 8.00 alle ore 20.00. </p>
            {/* <p> Ambulatorio <strong> San Foca </strong> - Piazza d'Oriente. Per informazioni tel. 329.3173692.</p>
            <p> Ambulatorio <strong> Torre dell'Orso </strong> - Piazza della Luna. Per informazioni tel. 327.7189629.</p>   
            <p> <strong> Melendugno </strong> - Via G.D'Amely 22. Per informazioni tel. 0832 5831002. Tutti i giorni dalle ore 8.00 alle ore 20.00. </p>          */}
            <Button
                  className="mt-2"
                  type="button"
                  onClick={(e) => {
                    navigate('/assistenza');
                  }}
                  color="primary"
                >
                Maggiori Informazioni 
                </Button>
          </div>
        </div>
        ):(
          <div style={{ fontSize: '22px' }}>
          <p className="text-b">
          Starting June 1, it will be in operation daily from 9:00 a.m. to 1:00 p.m.
            and from 4:00 p.m. to 8:00 p.m., the information and reception service
            tourist
          </p>
          <div className="mt-5 d-md-flex justify-content-around align-items-center text-b">
            <div className='home-col'>
              <b style={{ color: '#00415c' }}>San Foca</b>
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faCrosshairs}
              />{' '}
              Zona Ricci
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faPhone}
              />{' '}
              329 3658519
            </div>
            <div className='home-col'>
              <b style={{ color: '#00415c' }}>Torre dell'Orso</b>
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faCrosshairs}
              />{' '}
              Lungomare Matteotti
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faPhone}
              />{' '}
              329 2583678
            </div>
            <div className='home-col'>
              <b style={{ color: '#00415c' }}>Sant'Andrea</b>
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faCrosshairs}
              />{' '}
              Zona Faraglioni
              <br />
              <FontAwesomeIcon
                style={{ marginLeft: '15px', color: '#00415c' }}
                icon={faPhone}
              />{' '}
              329 3952460
            </div>            
          </div>
          <div className="mt-5">              
            <h4> <strong>Tourist Health Care Service in Torre dell'Orso and San Foca</strong> </h4>
            <p>Starting June 15, the Tourist Health Care Service restarts in Torre dell'Orso and San Foca, operating daily from 8 a.m. to 8 p.m. </p>
            {/* <p> Ambulatorio <strong> San Foca </strong> - Piazza d'Oriente. Per informazioni tel. 329.3173692.</p>
            <p> Ambulatorio <strong> Torre dell'Orso </strong> - Piazza della Luna. Per informazioni tel. 327.7189629.</p>   
            <p> <strong> Melendugno </strong> - Via G.D'Amely 22. Per informazioni tel. 0832 5831002. Tutti i giorni dalle ore 8.00 alle ore 20.00. </p>          */}
            <Button
                  className="mt-2"
                  type="button"
                  onClick={(e) => {
                    navigate('/assistenza');
                  }}
                  color="primary"
                >
                More Information 
                </Button>
          </div>
        </div>
        )}
        
      </div>
    </>
  );
}
