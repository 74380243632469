import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import LoginPage from './login.page';
import PageNotFound from '../../shared/page-not-found';
import RegisterPage from './register.page';
import ForgotPasswordPage from './forgot-password.page';
import { useAppSelector } from '../../redux/store';
import { userAccessTokenSelector } from '../../redux/reducers/userSlice';
import ResetPasswordPage from './reset-password.page';
import ActivatePage from './activate.page';
import DetailPage from './detail-page';

const UserRoutes = () => {
  const accessToken = useAppSelector(userAccessTokenSelector);
  return (
    <ErrorBoundaryRoutes>
      {!accessToken && (
        <>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
        </>
      )}
      {accessToken && <Route path="detail" element={<DetailPage />} />}
      <Route path="activate/:code" element={<ActivatePage />} />
      <Route path="reset-password/:code" element={<ResetPasswordPage />} />
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default UserRoutes;
