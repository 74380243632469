import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import {
  getCreateSolicitorConf,
  getUpdateSolicitorConf,
} from '../../api/solicitorAPI';
import useRequest from '../../hook/use-request.hook';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { DetailSolicitor } from '../../model/solicitor.model';
import { EMAIL_REGEX, registerRs } from '../../util/form.util';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SolicitorFormProps {
  initialData?: DetailSolicitor;
  enabled: boolean;
  onSubmitSuccess: (result: ApiSuccess) => void;
  onSubmitError: (errors: ApiError[]) => void;
}
export const SolicitorForm = (props: SolicitorFormProps) => {
  const { enabled, onSubmitSuccess, onSubmitError, initialData } = props;
  const { loading, fetchData } = useRequest();
  const [data, setData] = useState<any>();

  const navigate = useNavigate();
  useEffect(() => {
    if (initialData) {
      let d = JSON.parse(JSON.stringify(initialData));
      d.birthDate =
        initialData?.birthDate !== null
          ? initialData?.birthDate.split(' ')[0]
          : null;

      setData(d);
      console.log(data)
    }
  }, [initialData]);

  const onSubmit = (data: any) => {
    console.log('onSubmit');
    const {
      firstName,
      lastName,
      phoneNumber,
      birthPlace,
      birthProvince,
      birthDate,
      fiscalCode,
    } = data;

    let config;
    if (initialData && initialData.id) {
      config = getUpdateSolicitorConf(Number(initialData.id), {
        firstName,
        lastName,
        phoneNumber,
        birthPlace,
        birthProvince,
        birthDate,
        fiscalCode,
      });
    } else {
      config = getCreateSolicitorConf({
        firstName,
        lastName,
        phoneNumber,
        birthPlace,
        birthProvince,
        birthDate,
        fiscalCode,
      });
    }

    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    onSubmitSuccess(data);
  };

  const onErrors = (errors: ApiError[]) => {
    onSubmitError(errors);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...data } });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="firstName"
              name="firstName"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'firstName', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.firstName && errors.firstName != null}
            />
            <Label for="name">{t('solicitor.firstName')}</Label>
            <FormFeedback>{'' + errors?.firstName?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="lastName"
              name="lastName"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'lastName', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.lastName && errors.lastName != null}
            />
            <Label for="lastName">{t('solicitor.lastName')}</Label>
            <FormFeedback>{'' + errors?.lastName?.message}</FormFeedback>
          </FormGroup>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="email"
              name="email"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'email', {
                required: t(`general.errors.required`),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t(`general.errors.invalid_email`),
                },
              })}
              invalid={errors.email && errors.email != null}
            />
            <Label for="email">{t('facility.email')}</Label>
            <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
          </FormGroup>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="phoneNumber"
              name="phoneNumber"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'phoneNumber', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.phoneNumber && errors.phoneNumber != null}
            />
            <Label for="phoneNumber">{t('solicitor.phoneNumber')}</Label>
            <FormFeedback>{'' + errors?.phoneNumber?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="birthPlace"
              name="birthPlace"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'birthPlace', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.birthPlace && errors.birthPlace != null}
            />
            <Label for="birthPlace">{t('solicitor.birthPlace')}</Label>
            <FormFeedback>{'' + errors?.birthPlace?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="birthProvince"
              name="birthProvince"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'birthProvince', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.birthProvince && errors.birthProvince != null}
            />
            <Label for="birthProvince">{t('solicitor.birthProvince')}</Label>
            <FormFeedback>{'' + errors?.birthProvince?.message}</FormFeedback>
          </FormGroup>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="birthDate"
              name="birthDate"
              type="date"
              disabled={!enabled}
              {...registerRs(register, 'birthDate', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.birthDate && errors.birthDate != null}
              // className="no-calendar-icon"
            />
            <Label className="date" for="birthDate">
              {t('solicitor.birthDate')}
            </Label>
            <FormFeedback>{'' + errors?.birthDate?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="fiscalCode"
              name="fiscalCode"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'fiscalCode', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.fiscalCode && errors.fiscalCode != null}
            />
            <Label for="fiscalCode">{t('solicitor.fiscalCode')}</Label>
            <FormFeedback>{'' + errors?.fiscalCode?.message}</FormFeedback>
          </FormGroup>
        </div>
      </div>

      {enabled && (
        <Button
          disabled={loading}
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          {t('general.buttons.cancel')}
        </Button>
      )}
      {enabled && (
        <Button type="submit" disabled={loading} color="primary">
          {!loading ? (
            t('action.send')
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span> {t('action.loading')}</span>
            </>
          )}
        </Button>
      )}
    </Form>
  );
};
