import {
  faArrowLeft,
  faPlus,
  faRefresh
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, Form, FormGroup, Label, Spinner } from 'reactstrap';
import { getListFacilityPagedConf } from '../../api/facilityAPI';
import { getListPaymentPagedConf } from '../../api/paymentAPI';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { DetailFacility } from '../../model/facility.model';
import { ListPayment } from '../../model/payment.model';
import { currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import { useAppUtilContext } from '../../util/app-util.context';
import { customSelectStyles } from '../../util/form.util';
import PaymentTable from './payment-table';


export default function FacilityListPage() {
  const currentUser = useAppSelector(currentUserSelector);
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<ListPayment[]>([]);

  const [facilityList, setFacilityList] = useState<DetailFacility[]>([]);
  const [facilitySelected, setFacilitySelected] = useState<any>(undefined);

 // const [typeOptions, setTypeOptions] = useState<DetailFacilityType[]>([]);
  const [statusSelected, setStatusSelected] = useState<any | undefined>(undefined);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  // const handleChange = (e: any) => {
  //   if (e.target.value !== '') setText(e.target.value);
  //   else setText(undefined);
  // };

  const refresh = (pag: any, status: any, facility: any, year: any, month: any) => {
    let query = '';
    console.log("pag, status, facility", pag, status, facility)
    if (status !== undefined) query = '&status=' + status;
    if (facility !== undefined && facility !== 'undefined') query = query + '&facilityId=' + facility.value;

    fetchData(
      getListPaymentPagedConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrors
    );
  };
  const create = () => {
    navigate('create');
  };

  useEffect(() => {
    fetchData(
      getListFacilityPagedConf(1, 1000, ""),
      (data: any) => {
        setFacilityList([ ...[{ id: undefined, name: 'Tutte le strutture'}] ,...data]);
      },
      (data: any) => {
        setFacilityList([]);
      }
    );
    refresh(pagination, undefined, undefined, undefined, undefined);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = useForm();
  const onSubmit = (data: any) => {
    refresh(
      {
        page: 1,
        pageSize: 20,
        totalElements: null,
      },
      undefined, undefined, undefined, undefined
    );
  };
  return (
    <div className="container mt-2">
       <Button
          color="trasparent"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}  >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
          {t('general.buttons.back')}
        </Button>
      <div className="header-container">
        <h2 className="page-title">{t('payment.list.title')}</h2>
      </div>
      <div className="filter-card card mb-2">
        <div className='card-body'>
        <h6 className="mt-1 mb-3">Filtra i pagamenti</h6>
        <div className="mt-2">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-10 col-md-10 col-sm-10">
              <FormGroup  className={"form-field"} >
              <Label className={"select"} for={"place"}>
                Struttura
              </Label>
              <Controller
                name="place"
                control={control}
                rules={{ required: t(`general.errors.required`) }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customSelectStyles}
                    defaultValue={undefined}
                    value={field.value}
                    options={facilityList.map((el) => {
                      return { value: el.id, label: el.name}
                    })}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption)
                      setFacilitySelected(selectedOption)
                      refresh(pagination, statusSelected, selectedOption, undefined, undefined)
                    }
                    }
                  />
                )}
              />
              </FormGroup>
              </div>
            </div>
          </Form>
        </div>
        <div className="mt-2">
          <Button
            color={statusSelected == undefined ? 'primary' : 'light'}
            onClick={() => {
              setStatusSelected(undefined);
              refresh(pagination, undefined, facilitySelected, undefined, undefined);
            }}
            className="mb-2"
            style={{ marginRight: '10px' }}
          >
            Tutti
          </Button>
          {[ 'PENDING','CONFIRMED','CANCELLED' ].map((e: any, index: any) => {
            return (
              <Button
                color={statusSelected == e ? 'primary' : 'light'}
                onClick={() => {
                  setStatusSelected(e);
                  refresh(pagination, e, facilitySelected, undefined, undefined);
                }}
                className="mb-2"
                style={{ marginRight: '10px' }}
                key={index}
              >
                <span style={{ textTransform: 'capitalize' }}>{e}</span>
              </Button>
            );
          })}
        </div>
        </div>
      </div>
        <div className="justify-content-end pt-3" style={{ display: 'flex', gap: '10px' }}>
        {currentUser && false && <MobileButton
          onClick={create}
          disabled={loading}
          color="primary"
          icon={faPlus}
          text={t('general.buttons.add')}
        />}
          <MobileButton
            onClick={() => refresh(pagination, statusSelected, facilitySelected, undefined, undefined)}
            disabled={loading}
            color="secondary"
            outline
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className="d-none d-md-inline">
                  &nbsp; {t('general.buttons.refresh')}
                </span>
              </>
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span className="d-none d-md-inline">
                  {' '}
                  {t('action.loading')}{' '}
                </span>
              </>
            )}
          </MobileButton>
        </div>

        {pagination.totalElements > 0 && 
          <div className="mt-2  mb-3 row">
              <PaymentTable list={list} refresh={() => refresh(pagination, statusSelected, facilitySelected, undefined, undefined)} />
          </div>
        }


        <div className="pb-5" >
          <PaginationUtility
            {...pagination}
            onSizeChange={(n: any) => {
              if (pagination.pageSize !== n)
                refresh(
                  { ...pagination, ...{ page: 1, pageSize: n } },
                  statusSelected, facilitySelected, undefined, undefined
                );
            }}
            onChange={(n) => {
              if (pagination.page !== n)
               refresh({ ...pagination, ...{ page: n } }, statusSelected, facilitySelected, undefined, undefined);
            }}
          />
        </div>
      </div>
  );
}
