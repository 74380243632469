import { faArrowLeft, faBarcode, faPhone, faPlus, faRefresh, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { getListSolicitorConf } from "../../api/solicitorAPI";
import { SERVER_API_URL } from "../../axios/config";
import IconButtonWithTooltip from "../../components/icon-button-with-tooltip";
import MobileButton from "../../components/mobile-button";
import PaginationUtility from "../../components/pagination";
import useRequest from "../../hook/use-request.hook";
import { DetailSolicitor } from "../../model/solicitor.model";
import { useAppUtilContext } from "../../util/app-util.context";
import Avatar from "react-avatar";

export default function SolicitorListPage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailSolicitor[]>([]);
  const [text, setText] = useState<any | undefined>(undefined);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 21,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t("modal.request_errors"), errorsView, null);
  };
  const handleChange = (e: any) => {
    if (e.target.value !== "") setText(e.target.value);
    else setText(undefined);
  };
  const refresh = (pag: any) => {
    let query = "";
    if (text !== undefined) query = query + "&text=" + text;
    fetchData(
      getListSolicitorConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header["x-total-count"] },
        });
      },
      onErrors
    );
  };

  useEffect(() => {
    refresh(pagination);
  }, []);

  const giveUrl = (it: any) => {
    return (
      'url("' +
      (it?.images && it?.images.length > 0
        ? SERVER_API_URL + "/" + it.images[0].url
        : SERVER_API_URL + "/images/default.png") +
      '")'
    );
  };

  return (
    <div className="container mt-2">
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: "12px" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      <div className="header-container">
        <h2>{t("solicitor.list.title")}</h2>
        <div style={{ display: "flex", gap: "10px" }}>
          <MobileButton
            onClick={() => {
              navigate("create");
            }}
            disabled={loading}
            color="success"
            icon={faPlus}
            text={t("general.buttons.add")}
          />
          <MobileButton onClick={refresh} disabled={loading} color="secondary" outline>
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className="d-none d-md-inline">&nbsp; {t("general.buttons.refresh")}</span>
              </>
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span className="d-none d-md-inline"> {t("action.loading")} </span>
              </>
            )}
          </MobileButton>
        </div>
      </div>
      <div className="d-flex mt-2 mb-2">
        <input className="form-control" name="text" onChange={handleChange} />
        <Button
          color="primary"
          onClick={() => {
            refresh(pagination);
          }}
          style={{ marginLeft: "10px" }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
      <div className="row mt-2">
        {list.map((it, idx) => {
          return (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-2" key={idx}>
              <div className="facility-card f.loat-right">
                <div className="row" style={{ margin: 0 }}>
                  <div style={{ background: giveUrl(it), backgroundSize: "cover" }} className="col-5 ml-0 d-flex align-items-center justify-content-center">
                  {it.images.length === 0 && ( <Avatar name={it?.firstName + " " + it?.lastName} size="110"  style={{fontSize: "70px"}} round />)}
                  </div>
                  <div className="col-7">
                    <div className="body mt-4 mb-4 d-flex flex-column justify-content-center ">
                      <span>{`${it.birthPlace}, ${it.birthProvince}`}</span>
                      <b>{`${it.firstName} ${it.lastName}`}</b>
                      <span>
                        <FontAwesomeIcon icon={faPhone} color="#004C6C" /> {it.phoneNumber}
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faBarcode} color="#004C6C" /> {it.fiscalCode}
                      </span>
                    </div>
                    <IconButtonWithTooltip
                      id={`view-${it.id}`}
                      icon={faUser}
                      color={"primary"}
                      title={t("general.buttons.view")}
                      style={{
                        float: "right",
                        marginTop: "-27px",
                        marginRight: "-10px",
                      }}
                      onClick={() => navigate(`/solicitor/${it.id}`)}
                    ></IconButtonWithTooltip>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div >
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n) refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n) => {
            if (pagination.page !== n) refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
