import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import useRequest from '../../hook/use-request.hook';
import { DetailCompany } from '../../model/company.model';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { EMAIL_REGEX, registerRs } from '../../util/form.util';
import {
  getCreatCompanyConf,
  getUpdateCompanyConf,
} from '../../api/companyAPI';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface CompanyFormProps {
  initialData?: DetailCompany;
  enabled: boolean;
  onSubmitSuccess: (result: ApiSuccess) => void;
  onSubmitError: (errors: ApiError[]) => void;
}

const CompanyForm = (props: CompanyFormProps) => {
  const { enabled, onSubmitSuccess, onSubmitError, initialData } = props;
  const { loading, fetchData } = useRequest();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    const { vatNumber, name, email, fiscalCode, personal,managerFullname,
      manageFiscalCode,
      managerBirthDate,
      managerBithPlace,
      managerCity,
      managerProvince,
      managerCap,
      managerTel,
      managerFax,
      managerEmail,
      legalEntityAddress,
      legalEntityCity,
      legalEntityProvince,
      legalEntityZipCode
    } = data;

    let config;
    if (initialData && initialData.id) {
      config = getUpdateCompanyConf(
        { id: initialData.id, vatNumber, name, email, fiscalCode, personal, managerFullname,
          manageFiscalCode,
          managerBirthDate,
          managerBithPlace,
          managerCity,
          managerProvince,
          managerCap,
          managerTel,
          managerFax,
          managerEmail, 
          legalEntityAddress,
      legalEntityCity,
      legalEntityProvince,
      legalEntityZipCode},
        Number(initialData.id)
      );
    } else {
      config = getCreatCompanyConf({
        vatNumber,
        name,
        email,
        fiscalCode,
        personal,
        managerFullname,
        manageFiscalCode,
        managerBirthDate,
        managerBithPlace,
        managerCity,
        managerProvince,
        managerCap,
        managerTel,
        managerFax,
        managerEmail,
        legalEntityAddress,
        legalEntityCity,
        legalEntityProvince,
        legalEntityZipCode
      });
    }
    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    onSubmitSuccess(data);
  };

  const onErrors = (errors: ApiError[]) => {
    onSubmitError(errors);
  };
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...initialData } });
  // Watch the value of the password field
  const fiscalCode = watch('fiscalCode');
  const vatNumber = watch('vatNumber');

  useEffect(() => {
    trigger(['fiscalCode', 'vatNumber']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiscalCode, vatNumber]);

  useEffect(() => {
    if (initialData) {
    //  console.log(initialData);
      //let d = JSON.parse(JSON.stringify(initialData));
      initialData.managerBirthDate =
        (initialData?.managerBirthDate !== null && initialData?.managerBirthDate !== undefined)
          ? initialData?.managerBirthDate.split(' ')[0]
          : "";

      // setData(d);
      // console.log(data)
    }
  }, [initialData]);

  const [isPersonal,setIsPersonal] = useState(false);
  useEffect(() => {  
    if(initialData?.personal !== undefined) {
      setIsPersonal(initialData?.personal)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData?.personal]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field">
            <Input
              id="name"
              name="name"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'name', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.name && errors.name != null}
            />
            <Label for="name">{t('company.name')}</Label>
            <FormFeedback>{'' + errors?.name?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="email"
              name="email"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'email', {
                required: t(`general.errors.required`),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t(`general.errors.invalid_email`),
                },
              })}
              invalid={errors.email && errors.email != null}
            />
            <Label for="email">{t('company.email')}</Label>
            <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12" style={{ display:'none' }}> 
          <FormGroup check>
            <Input
              {...registerRs(register, 'personal', {
                //  required: t(`general.errors.required`),
              })}
              disabled={!enabled}
              type="checkbox"
              name="personal"
              onClick={()=>{
                setIsPersonal(!isPersonal)
              }}
            />{' '}
            <Label for="personal" check className="mb-2">
            {t('company.personal')}
            </Label>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12" style={{ display: isPersonal ? 'block' : 'none' }}>
          <FormGroup className="form-field ">
            <Input
              id="fiscalCode"
              name="fiscalCode"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'fiscalCode', {
                validate: (value: any) => {
               //   console.log(value);
                //  console.log(vatNumber);
                  if (value === '' && vatNumber === '') {
                    return t(`general.errors.invalid_fiscal_code`);
                  }
                },
              })}
              invalid={errors.fiscalCode && errors.fiscalCode != null}
            />
            <Label for="fiscalCode">{t('company.fiscalCode')}</Label>
            <FormFeedback>{'' + errors?.fiscalCode?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12" style={{ display: !isPersonal ? 'block' : 'none' }}>
          <FormGroup
            className={
              'form-field' +
              (initialData?.personal &&!enabled ? ' hidden' : '')
            }
          >
            <Input
              id="vatNumber"
              name="vatNumber"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'vatNumber', {
                validate: (value: any) => {
                  // console.log(value);
                 //  console.log(vatNumber);
                  if (value === '' && fiscalCode === '') {
                    return t(`general.errors.invalid_vat_number`);
                  }
                },
              })}
              invalid={errors.vatNumber && errors.vatNumber != null}
            />
            <Label for="vatNumber">{t('company.vatNumber')}</Label>
            <FormFeedback>{'' + errors?.vatNumber?.message}</FormFeedback>
          </FormGroup>
        </div>  
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="manageFiscalCode"
              name="manageFiscalCode"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'manageFiscalCode', {
                required: t(`general.errors.required`),
                
              })}
              invalid={errors.manageFiscalCode && errors.manageFiscalCode != null}
            />
            <Label for="manageFiscalCode">{t('company.managerfisc')}</Label>
            <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
          </FormGroup>
        </div>  
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerBirthDate"
              name="managerBirthDate"
              type="date"
              disabled={!enabled}
              {...registerRs(register, 'managerBirthDate', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.managerBirthDate && errors.managerBirthDate != null}
            />
            {/* <Label for="email">{t('company.email')}</Label> */}
            <FormFeedback>{'' + errors?.managerBirthDate?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerBithPlace"
              name="managerBithPlace"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerBithPlace', {
                required: t(`general.errors.required`),
                
              })}
              invalid={errors.managerBithPlace && errors.managerBithPlace != null}
            />
            <Label for="managerBithPlace">{t('company.managerbirthplace')}</Label>
            <FormFeedback>{'' + errors?.managerBithPlace?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerCity"
              name="managerCity"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerCity', {
                required: t(`general.errors.required`),
                
              })}
              invalid={errors.managerCity && errors.managerCity != null}
            />
            <Label for="managerCity">{t('company.managercity')}</Label>
            <FormFeedback>{'' + errors?.managerCity?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerProvince"
              name="managerProvince"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerProvince', {
                required: t(`general.errors.required`),
                
              })}
              invalid={errors.managerProvince && errors.managerProvince != null}
            />
            <Label for="managerProvince">{t('company.managerprovince')}</Label>
            <FormFeedback>{'' + errors?.managerProvince?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerCap"
              name="managerCap"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerCap', {
                required: t(`general.errors.required`),
                
              })}
              invalid={errors.managerCap && errors.managerCap != null}
            />
            <Label for="managerCap">{t('company.managercap')}</Label>
            <FormFeedback>{'' + errors?.managerCap?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerTel"
              name="managerTel"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerTel', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.managerTel && errors.managerTel != null}
            />
            <Label for="managerTel">{t('company.managertel')}</Label>
            <FormFeedback>{'' + errors?.managerTel?.message}</FormFeedback>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerFax"
              name="managerFax"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerFax', {
                //required: t(`general.errors.required`),
              })}
              // invalid={errors.managerFax && errors.managerFax != null}
            />
            <Label for="managerFax">{t('company.managerfax')}</Label>
            <FormFeedback>{'' + errors?.managerFax?.message}</FormFeedback>
          </FormGroup>
        </div>  
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="managerEmail"
              name="managerEmail"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'managerEmail', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerEmail && errors.managerEmail != null}
            />
            <Label for="managerEmail">{t('company.manageremail')}</Label>
            <FormFeedback>{'' + errors?.managerEmail?.message}</FormFeedback>
          </FormGroup>
        </div>  

       { //----------------------------------------------------------------------
      }

      <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="legalEntityAddress"
              name="legalEntityAddress"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'legalEntityAddress', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityAddress && errors.legalEntityAddress != null}
            />
            <Label for="legalEntityAddress">{t('company.legalEntityAddress')}</Label>
            <FormFeedback>{'' + errors?.legalEntityAddress?.message}</FormFeedback>
          </FormGroup>
        </div>  
      <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="legalEntityCity"
              name="legalEntityCity"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'legalEntityCity', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityCity && errors.legalEntityCity != null}
            />
            <Label for="legalEntityCity">{t('company.legalEntityCity')}</Label>
            <FormFeedback>{'' + errors?.legalEntityCity?.message}</FormFeedback>
          </FormGroup>
        </div>  
      <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="legalEntityProvince"
              name="legalEntityProvince"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'legalEntityProvince', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityProvince && errors.legalEntityProvince != null}
            />
            <Label for="legalEntityProvince">{t('company.legalEntityProvince')}</Label>
            <FormFeedback>{'' + errors?.legalEntityProvince?.message}</FormFeedback>
          </FormGroup>
        </div>  
      <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="legalEntityProvince"
              name="legalEntityProvince"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'legalEntityZipCode', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityZipCode && errors.legalEntityZipCode != null}
            />
            <Label for="legalEntityZipCode">{t('company.legalEntityZipCode')}</Label>
            <FormFeedback>{'' + errors?.legalEntityZipCode?.message}</FormFeedback>
          </FormGroup>
        </div>  











      </div>
      {enabled && (
        <Button
          disabled={loading}
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          {t('general.buttons.cancel')}
        </Button>
      )}
      {enabled && (
        <Button type="submit" disabled={loading} color="primary">
          {!loading ? (
            t('action.send')
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span> {t('action.loading')}</span>
            </>
          )}
        </Button>
      )}
    </Form>
  );
};

export default CompanyForm;
