import { t } from "i18next";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import useRequest from "../hook/use-request.hook";
import { ApiError } from "../model/errors.model";
import { NotificationTypes, useAppUtilContext } from "../util/app-util.context";
import { EMAIL_REGEX, registerRs } from "../util/form.util";
import { getCreateFacilitySolicitorConf } from "../api/facilitySolicitorAPI";

export interface AddSolicitorModalProps {
  onClose: () => void;
}
export interface AddSolicitorModalHandle {
  open: (facilityId: number) => void;
}
const AddSolicitorModal = forwardRef<
  AddSolicitorModalHandle,
  AddSolicitorModalProps
>((props: AddSolicitorModalProps, ref) => {
  const { showModal, showNotification } = useAppUtilContext();
  const [facilityId, setFacilityId] = useState<number | null>(null);
  const [modal, setModal] = useState(false);

  const { loading, fetchData } = useRequest();
  const toggle = () => setModal(!modal);
  useImperativeHandle(ref, () => ({
    open(facilityId: number) {
      setFacilityId(facilityId);
      setModal(true);
    },
  }));
  const onSubmit = (data: any) => {
    console.log("onSubmit");
    let datas = { ...data };
    if (facilityId) {
      const config = getCreateFacilitySolicitorConf(facilityId, datas);
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    const { message } = data;
    setModal(false);
    showNotification(message, NotificationTypes.SUCCESS);
    props.onClose();
  };

  const onErrors = (errors: ApiError[]) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t("modal.request_errors"), errorsView, null);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle}>
            {t("modal.addSolicitor.title")}
          </ModalHeader>
          <ModalBody>
            <FormGroup className="form-field">
              <Input
                id="firstName"
                name="firstName"
                type="text"
                {...registerRs(register, "firstName", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.firstName && errors.firstName != null}
              />
              <Label for="firstName">{t('solicitor.firstName')}</Label>
              <FormFeedback>{"" + errors?.firstName?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="lastName"
                name="lastName"
                type="text"
                {...registerRs(register, "lastName", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.lastName && errors.lastName != null}
              />
              <Label for="lastName">{t("solicitor.lastName")}</Label>
              <FormFeedback>{"" + errors?.lastName?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field ">
              <Input
                id="email"
                name="email"
                type="text"
                {...registerRs(register, "email", {
                  required: t(`general.errors.required`),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t(`general.errors.invalid_email`),
                  },
                })}
                invalid={errors.email && errors.email != null}
              />
              <Label for="email">{t("facility.email")}</Label>
              <FormFeedback>{"" + errors?.email?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                {...registerRs(register, "phoneNumber", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.phoneNumber && errors.phoneNumber != null}
              />
              <Label for="phoneNumber">{t("solicitor.phoneNumber")}</Label>
              <FormFeedback>{"" + errors?.phoneNumber?.message}</FormFeedback>
              
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="birthPlace"
                name="birthPlace"
                type="text"
                {...registerRs(register, "birthPlace", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.birthPlace && errors.birthPlace != null}
              /><Label for="birthPlace">{t("solicitor.birthPlace")}</Label>
              <FormFeedback>{"" + errors?.birthPlace?.message}</FormFeedback>
              
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="birthProvince"
                name="birthProvince"
                type="text"
                {...registerRs(register, "birthProvince", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.birthProvince && errors.birthProvince != null}
              />
              <Label for="birthProvince">{t("solicitor.birthProvince")}</Label>
              <FormFeedback>{"" + errors?.birthProvince?.message}</FormFeedback>
            </FormGroup>

            <FormGroup className="form-field">
              <Input
                id="birthDate"
                name="birthDate"
                type="date"
                {...registerRs(register, "birthDate", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.birthDate && errors.birthDate != null}
              />
              <Label className="date" for="birthDate">
                {t("solicitor.birthDate")}
              </Label>
              <FormFeedback>{"" + errors?.birthDate?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <Input
                id="fiscalCode"
                name="fiscalCode"
                type="text"
                {...registerRs(register, "fiscalCode", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.fiscalCode && errors.fiscalCode != null}
              />
              <Label for="fiscalCode">{t("solicitor.fiscalCode")}</Label>
              <FormFeedback>{"" + errors?.fiscalCode?.message}</FormFeedback>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              {t("general.buttons.close")}
            </Button>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                t("action.send")
              ) : (
                <>
                  <Spinner size="sm"> Loading...</Spinner>
                  <span> {t("action.loading")}</span>
                </>
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
});

export default AddSolicitorModal;
