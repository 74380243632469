import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import useRequest from '../../hook/use-request.hook';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { registerRs } from '../../util/form.util';
import { getCreatePlaceConf, getUpdatePlaceConf } from '../../api/placeAPI';
import { DetailPlace } from '../../model/place.model';
import { useNavigate } from 'react-router-dom';

interface PlaceFormProps {
  initialData?: DetailPlace;
  enabled: boolean;
  onSubmitSuccess: (result: ApiSuccess) => void;
  onSubmitError: (errors: ApiError[]) => void;
}

const PlaceForm = (props: PlaceFormProps) => {
  const { enabled, onSubmitSuccess, onSubmitError, initialData } = props;
  const { loading, fetchData } = useRequest();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log('onSubmit');
    const { name } = data;

    let config;
    if (initialData && initialData.id) {
      config = getUpdatePlaceConf(
        {
          name,
        },
        Number(initialData.id)
      );
    } else {
      config = getCreatePlaceConf({
        name,
      });
    }
    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    onSubmitSuccess(data);
  };

  const onErrors = (errors: ApiError[]) => {
    onSubmitError(errors);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...initialData } });
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <FormGroup className="form-field ">
            <Input
              id="name"
              name="name"
              type="text"
              disabled={!enabled}
              {...registerRs(register, 'name', {
                required: t(`general.errors.required`),
              })}
              invalid={errors.name && errors.name != null}
            />
            <Label for="name">{t('facility-type.name')}</Label>
            <FormFeedback>{'' + errors?.name?.message}</FormFeedback>
          </FormGroup>
        </div>
      </div>
      {enabled && (
        <Button
          disabled={loading}
          color="secondary"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          {t('general.buttons.cancel')}
        </Button>
      )}
      {enabled && (
        <Button type="submit" disabled={loading} color="primary">
          {!loading ? (
            t('action.send')
          ) : (
            <>
              <Spinner size="sm">Loading...</Spinner>
              <span> {t('action.loading')}</span>
            </>
          )}
        </Button>
      )}
    </Form>
  );
};

export default PlaceForm;
