import { defaultHeaders } from './common';

export const getDeleteFacilitySolicitorConf = (
  facilityId: number,
  solicitorId: number
) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: '/api/facility-solicitor',
    headers: defaultHeaders,
    data: JSON.stringify({
      facilityId,
      solicitorId,
    }),
  };
};

export const getCreateFacilitySolicitorConf = (
  facilityId: number,
  solicitor: any
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/facility-solicitor',
    headers: defaultHeaders,
    data: JSON.stringify({
      facilityId,
      solicitor,
    }),
  };
};


