import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { ErrorsView } from '../../components/errors.view';
import { ApiError } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { SolicitorForm } from './solicitor.form';

export default function SolicitorPageCreate() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const onSubmitSuccess = (data: any) => {
    const { message } = data;
    showModal(t('modal.request_success'), message, null);
    navigate('/solicitor');
  };

  const onSubmitError = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/solicitor'}>{t('solicitor.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('general.buttons.add')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('solicitor.create.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <SolicitorForm
            enabled={true}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
          ></SolicitorForm>
        </CardBody>
      </Card>
    </div>
  );
}
