import classnames from 'classnames';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { getDetailFacilityConf } from '../../api/facilityAPI';
import useRequest from '../../hook/use-request.hook';
import AddSolicitorModal, {
  AddSolicitorModalHandle,
} from '../../modal/add-solicitor.modal';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { DetailFacility } from '../../model/facility.model';
import { useAppUtilContext } from '../../util/app-util.context';
import {
  FacilitySolicitorsTable,
  FacilitySolicitorsTableHandle,
} from './facility-solicitor-table';
import FacilityForm from './facility.form';
import { ErrorsView } from '../../components/errors.view';
import {
  FacilityOccupanciesTable,
  FacilityOccupanciesTableHandle,
} from './facility-occupancy.table';

export default function FacilityDetailPage() {
  let { id } = useParams();
  const facilityId = id ? parseInt(id, 10) : undefined;
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData: fetchDataDetail } = useRequest<DetailFacility>();
  const [entity, setEntity] = useState<DetailFacility>();
  const addSolicitorModalRef = useRef<AddSolicitorModalHandle>(null);
  const [activeTab, setActiveTab] = useState('1');
  const facilitySolicitorsTableRef =
    useRef<FacilitySolicitorsTableHandle>(null);
  const facilityOccupanciesTableRef =
    useRef<FacilityOccupanciesTableHandle>(null);

  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailFacilityConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailFacility) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onAddSolicitor = () => {
    if (entity?.id) {
      addSolicitorModalRef.current?.open(entity?.id);
    }
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility'}>{t('facility.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('facility.detail.title')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('facility.detail.title')}</h2>
        <Button
          color="warning"
          onClick={() => navigate(`/facility/${id}/update`)}
        >
          <span>{t('general.buttons.edit')}</span>
        </Button>
      </div>
      <Card>
        <CardBody>
          <FacilityForm
            initialData={entity}
            enabled={false}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></FacilityForm>
        </CardBody>
      </Card>
      <div className="mt-2">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              {t('facility.solicitors')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              {t('facility.occupancy')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="header-container mt-2">
              <h3>{t('facility.solicitors')}</h3>
              <Button onClick={() => onAddSolicitor()} color="danger">
                <span>{t('general.buttons.add')}</span>
              </Button>
            </div>
            <FacilitySolicitorsTable
              ref={facilitySolicitorsTableRef}
              facility={entity}
            ></FacilitySolicitorsTable>
          </TabPane>
          <TabPane tabId="2">
            <FacilityOccupanciesTable
              ref={facilityOccupanciesTableRef}
              facilityId={facilityId}
            ></FacilityOccupanciesTable>
          </TabPane>
        </TabContent>
      </div>
      <AddSolicitorModal
        ref={addSolicitorModalRef}
        onClose={() => facilitySolicitorsTableRef.current?.reload()}
      ></AddSolicitorModal>
    </div>
  );
}
