import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { NotificationTypes, useAppUtilContext } from '../../util/app-util.context';

import { ApiError } from '../../model/errors.model';
import { ErrorsView } from '../../components/errors.view';
import EventForm from './events_form';

export default function EventCreate() {
  const { showModal,showNotification } = useAppUtilContext();
  const navigate = useNavigate();

  const onSubmitSuccess = (data: any) => {
    const { message } = data;
    showNotification("Evento creato con successo", NotificationTypes.SUCCESS);
    navigate('/events');
  };

  const onSubmitError = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/events'}>{t('events.list')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('general.buttons.add')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('events.create')}</h2>
      </div>
      <Card style={{ marginTop: "2%" }}>
        <CardBody>
          <EventForm
            //enabled={true}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
          ></EventForm>          
        </CardBody>
      </Card>
    </div>
  );
}
