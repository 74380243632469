
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { getActivateConf } from "../../api/userAPI";
import useRequest from "../../hook/use-request.hook";
import { useAppUtilContext } from '../../util/app-util.context';

export default function ActivatePage() {
    let { code } = useParams();
    const { showModal } = useAppUtilContext();
    const { t } = useTranslation();
    const { loading, fetchData } = useRequest();


    const navigate = useNavigate();

    const onSuccess = (data: any) => {
        let message = data.message;
        showModal(t('modal.request_success'), message, null);
        navigate('/');
    }

    const onErrors = (errors: any) => {
        console.log('errors', errors);
        let errorsView = <ul>{errors.map((it: any, idx: number) => <li key={idx}>{it.message}</li>)}</ul>
        showModal(t('modal.request_errors'), errorsView, null);
    }

    useEffect(() => {
        if (code) {

            fetchData(getActivateConf(code), onSuccess, onErrors);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])
    return (
        <div className="container" >
            {!loading &&
                <><Spinner size="sm">
                    Loading...
                </Spinner>
                    <span>
                        {' '}   {t('action.loading')}
                    </span></>}
        </div>
    );
};

