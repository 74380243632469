import { t } from 'i18next';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import useRequest from '../hook/use-request.hook';
import { ApiError } from '../model/errors.model';
import { NotificationTypes, useAppUtilContext } from '../util/app-util.context';
import { EMAIL_REGEX, registerRs } from '../util/form.util';
import { DetailCompany } from '../model/company.model';
import { getCreateCompanyFacilityConf } from '../api/companyFacilityAPI';
import { getUpdateCompanyConf } from '../api/companyAPI';

export interface EditCompanyModalProps {
  onClose: () => void;
}
export interface EditCompanyModalHandle {
  open: (facilityId: number, company?: DetailCompany) => void;
}
const AddCompanyModal = forwardRef<
  EditCompanyModalHandle,
  EditCompanyModalProps
>((props: EditCompanyModalProps, ref) => {
  const { showModal, showNotification } = useAppUtilContext();
  const [ isPersonal, setIsPersonal ]= useState<boolean>(false);
  const [companyData, setCompanyData] = useState<DetailCompany | undefined>(
    undefined
  );
  const [facilityId, setFacilityId] = useState<number | null>(null);
  const [modal, setModal] = useState(false);

  const { loading, fetchData } = useRequest();
  const toggle = () => setModal(!modal);
  useImperativeHandle(ref, () => ({
    open(facilityId: number, company?: DetailCompany) {
      setFacilityId(facilityId);
      setCompanyData(company);
      setIsPersonal(company?.personal? company?.personal: false )
      setModal(true);
    },
  }));
  const onSubmit = (data: any) => {
    console.log('onSubmit', data, companyData);
    let datas = { ...data };
    isPersonal ?  delete datas.vatNumber : delete datas.fiscalCode 
    if (facilityId) {
      let config = null
      if(companyData == undefined) {
         config = getCreateCompanyFacilityConf(
          facilityId,
          undefined,
          datas,
          undefined
        );
      } else {
         config = getUpdateCompanyConf(
          datas,
          companyData.id ? companyData.id  : 0
        );
      }
      
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    const { message } = data;
    setModal(false);
    showNotification(message, NotificationTypes.SUCCESS);
    props.onClose();
  };

  const onErrors = (errors: ApiError[]) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues
  } = useForm({ defaultValues: {}, values: { ...companyData } });
// console.log("companyData?.personal", companyData?.personal)

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('modal.editCompany.title')}
        </ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormGroup className="form-field ">
              <Input
                id="name"
                name="name"
                type="text"
                {...registerRs(register, 'name', {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.name && errors.name != null}
              />
              <Label for="name">{t('company.name')}</Label>
              <FormFeedback>{'' + errors?.name?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field ">
              <Input
                id="email"
                name="email"
                type="text"
                {...registerRs(register, 'email', {
                  required: t(`general.errors.required`),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t(`general.errors.invalid_email`),
                  },
                })}
                invalid={errors.email && errors.email != null}
              />
              <Label for="email">{t('company.email')}</Label>
              <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
            </FormGroup>
            <FormGroup check>
          <Input {...registerRs(register, 'personal', {
               onChange: (e:any) => {
                let g = getValues()
                setIsPersonal(g.personal !== undefined ? g.personal : false)
               }
                //  required: t(`general.errors.required`),
              })} type="checkbox" name="personal" />
          {' '}
          <Label for="personal" check className="mb-2">
          {t('company.personal')}
          </Label>
        </FormGroup>
          { isPersonal &&  <FormGroup className="form-field ">
              <Input
                id="fiscalCode"
                name="fiscalCode"
                type="text"
                {...registerRs(register, 'fiscalCode', {
                    required: document.getElementById('fiscalCode') != null && isPersonal ? t(`general.errors.required`) : false,
                })}
                invalid={errors.fiscalCode && errors.fiscalCode != null}    
              />
              <Label for="fiscalCode">{t('company.fiscalCode')}</Label>
              <FormFeedback>{'' + errors?.fiscalCode?.message}</FormFeedback>
            </FormGroup>
            }
            <FormGroup className={"form-field" + ( isPersonal? ' hidden' : '')}>
              <Input
                id="vatNumber"
                name="vatNumber"
                type="text"
                {...registerRs(register, 'vatNumber', {
                  required: document.getElementById('vatNumber') != null && !isPersonal  ? t(`general.errors.required`) : false,
                })}
                invalid={errors.vatNumber && errors.vatNumber != null}
              />
              <Label for="vatNumber">{t('company.vatNumber')}</Label>
              <FormFeedback>{'' + errors?.vatNumber?.message}</FormFeedback>
            </FormGroup>
    
          <FormGroup className="form-field ">
            <Input
              id="manageFiscalCode"
              name="manageFiscalCode"
              type="text"
              // disabled={!enabled}
              {...registerRs(register, 'manageFiscalCode', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.manageFiscalCode && errors.manageFiscalCode != null}
            />
            <Label for="manageFiscalCode">{t('company.managerfisc')}</Label>
            <FormFeedback>{'' + errors?.email?.message}</FormFeedback>
          </FormGroup>
      
          <FormGroup className="form-field ">
            <Input
              id="managerBirthDate"
              name="managerBirthDate"
              type="date"
               // disabled={!enabled}
              {...registerRs(register, 'managerBirthDate', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_date`),
                // },
              })}
              invalid={errors.managerBirthDate && errors.managerBirthDate != null}
            />
            {/* <Label for="email">{t('company.email')}</Label> */}
            <FormFeedback>{'' + errors?.managerBirthDate?.message}</FormFeedback>
          </FormGroup>
  
          <FormGroup className="form-field ">
            <Input
              id="managerBithPlace"
              name="managerBithPlace"
              type="text"
               // disabled={!enabled}
              {...registerRs(register, 'managerBithPlace', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerBithPlace && errors.managerBithPlace != null}
            />
            <Label for="managerBithPlace">{t('company.managerbirthplace')}</Label>
            <FormFeedback>{'' + errors?.managerBithPlace?.message}</FormFeedback>
          </FormGroup>
  
          <FormGroup className="form-field ">
            <Input
              id="managerCity"
              name="managerCity"
              type="text"
               // disabled={!enabled}
              {...registerRs(register, 'managerCity', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerCity && errors.managerCity != null}
            />
            <Label for="managerCity">{t('company.managercity')}</Label>
            <FormFeedback>{'' + errors?.managerCity?.message}</FormFeedback>
          </FormGroup>
 
          <FormGroup className="form-field ">
            <Input
              id="managerProvince"
              name="managerProvince"
              type="text"
               // disabled={!enabled}
              {...registerRs(register, 'managerProvince', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerProvince && errors.managerProvince != null}
            />
            <Label for="managerProvince">{t('company.managerprovince')}</Label>
            <FormFeedback>{'' + errors?.managerProvince?.message}</FormFeedback>
          </FormGroup>
       
          <FormGroup className="form-field ">
            <Input
              id="managerCap"
              name="managerCap"
              type="text"
               // disabled={!enabled}
              {...registerRs(register, 'managerCap', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerCap && errors.managerCap != null}
            />
            <Label for="managerCap">{t('company.managercap')}</Label>
            <FormFeedback>{'' + errors?.managerCap?.message}</FormFeedback>
          </FormGroup>
  
          <FormGroup className="form-field ">
            <Input
              id="managerTel"
              name="managerTel"
              type="text"
               // disabled={!enabled}
              {...registerRs(register, 'managerTel', {
                required: t(`general.errors.required`),
                //pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerTel && errors.managerTel != null}
            />
            <Label for="managerTel">{t('company.managertel')}</Label>
            <FormFeedback>{'' + errors?.managerTel?.message}</FormFeedback>
          </FormGroup>
  
          <FormGroup className="form-field ">
            <Input
              id="managerFax"
              name="managerFax"
              type="text"
               // disabled={!enabled}
              {...registerRs(register, 'managerFax', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerFax && errors.managerFax != null}
            />
            <Label for="managerFax">{t('company.managerfax')}</Label>
            <FormFeedback>{'' + errors?.managerFax?.message}</FormFeedback>
          </FormGroup>

          <FormGroup className="form-field ">
            <Input
              id="managerEmail"
              name="managerEmail"
              type="text"
              // disabled={!enabled}
              {...registerRs(register, 'managerEmail', {
                required: t(`general.errors.required`),
                // pattern: {
                //   value: EMAIL_REGEX,
                //   message: t(`general.errors.invalid_email`),
                // },
              })}
              invalid={errors.managerEmail && errors.managerEmail != null}
            />
            <Label for="managerEmail">{t('company.manageremail')}</Label>
            <FormFeedback>{'' + errors?.managerEmail?.message}</FormFeedback>
          </FormGroup>


          
          <FormGroup className="form-field ">
            <Input
              id="legalEntityAddress"
              name="legalEntityAddress"
              type="text"
              {...registerRs(register, 'legalEntityAddress', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityAddress && errors.legalEntityAddress != null}
            />
            <Label for="legalEntityAddress">{t('company.legalEntityAddress')}</Label>
            <FormFeedback>{'' + errors?.legalEntityAddress?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-field ">
            <Input
              id="legalEntityCity"
              name="legalEntityCity"
              type="text"
              {...registerRs(register, 'legalEntityCity', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityCity && errors.legalEntityCity != null}
            />
            <Label for="legalEntityCity">{t('company.legalEntityCity')}</Label>
            <FormFeedback>{'' + errors?.legalEntityCity?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-field ">
            <Input
              id="legalEntityProvince"
              name="legalEntityProvince"
              type="text"
              {...registerRs(register, 'legalEntityProvince', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityProvince && errors.legalEntityProvince != null}
            />
            <Label for="legalEntityProvince">{t('company.legalEntityProvince')}</Label>
            <FormFeedback>{'' + errors?.legalEntityProvince?.message}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-field ">
            <Input
              id="legalEntityProvince"
              name="legalEntityProvince"
              type="text"
              {...registerRs(register, 'legalEntityZipCode', {
                required: t(`general.errors.required`)
              })}
              invalid={errors.legalEntityZipCode && errors.legalEntityZipCode != null}
            />
            <Label for="legalEntityZipCode">{t('company.legalEntityZipCode')}</Label>
            <FormFeedback>{'' + errors?.legalEntityZipCode?.message}</FormFeedback>
          </FormGroup>


 
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              {t('general.buttons.close')}
            </Button>
            <Button type="submit" disabled={loading}>
              {!loading ? (
                t('action.send')
              ) : (
                <>
                  <Spinner size="sm"> Loading...</Spinner>
                  <span> {t('action.loading')}</span>
                </>
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
});

export default AddCompanyModal;
