import { CreateStay } from '../model/stay.model';
import { defaultHeaders } from './common';

export const getCreateStayConf = (createStay: CreateStay) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/stay',
    headers: defaultHeaders,
    data: JSON.stringify(createStay),
  };
};


export const getListStayPagedConf = (page: number, size: number, query: string) => {
  return {
    method: 'get',
    url: '/api/stay?page=' + page + '&size=' + size  +query,
    headers: defaultHeaders,
  };
};
export const getListStayConf = () => {
  return {
    method: 'get',
    url: '/api/stay',
    headers: defaultHeaders,
  };
};

export const getDetailStayConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/stay/${id}`,
    headers: defaultHeaders,
  };
};

export const getApproveStayConf = (
  id: number
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/stay/' + id + '/approve',
    headers: defaultHeaders
  };
};

export const getRejectStayConf = (
  id: number
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/stay/' + id + '/reject',
    headers: defaultHeaders
  };
};






