import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDetailStayGuestConf } from '../../api/stayGuestAPI';
import CardCodeView from '../../components/card-code-view';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { ExtendedDetailStayGuest } from '../../model/stay-guest.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';

export default function StayGuestDetailPage() {
  const navigate = useNavigate();
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const { fetchData: fetchDataDetail } = useRequest<any>();
  const [entity, setEntity] = useState<ExtendedDetailStayGuest>();
  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailStayGuestConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
  }, [id]);

  const onSuccessDetail = (data: ExtendedDetailStayGuest) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-4  ">
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: '12px' }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
        {t('general.buttons.back')}
      </Button>
      {entity && <CardCodeView entity={entity}></CardCodeView>}
    </div>
  );
}
