import { CreateEvent } from '../model/event.model';
import { DetailEvent } from '../model/event.model';
import { defaultHeaders } from './common';

export const getListEvent = (startDate:any, endDate:any) => {
    return {
      method: 'get',
      url: '/api/event',
      headers: defaultHeaders,
      params: { start: startDate, end: endDate },
    };
  };

  export const createEventAPI = (createEvent: CreateEvent) => {
    return {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/event',
      headers: defaultHeaders,
      data: JSON.stringify(createEvent),
    };
  };

  export const updateEventAPI = (eventId:number, detailEvent:DetailEvent) => {
    return {
      method: 'put',
      url: '/api/event/' + eventId,
      headers: defaultHeaders,
      data: JSON.stringify(detailEvent)
    };
  };
  export const deleteEventAPI = (id:number) => {
    return {
      method: 'delete',
      url: '/api/event/' + id,
      headers: defaultHeaders,
      data: JSON.stringify({id})
    };
  };


  export const getDetailEventConf = (id: string) => {
    return {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/event/${id}`,
      headers: defaultHeaders,
    };
  };

  export const getCreateEventImageConf = (formData: any) => {
    return {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/event-image',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
      body: formData,
    };
  };
  export const getDeleteEventImageConf = (eventId: number) => {
    return {
      method: 'delete',
      maxBodyLength: Infinity,
      url: '/api/event-image/' + eventId,
      headers: defaultHeaders,
    };
  };