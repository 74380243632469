import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { ProgressBar } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
const CustomPDFViewer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("src");
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column", backgroundColor: "#292929" }}>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "12px" }}>
        <Button color="transparent" onClick={() => navigate(-1)} style={{ marginRight: "12px" }}>
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: "35px", color: "white" }} />
        </Button>
      </div>

      {source ? (
        <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto" }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={source}
              theme={"dark"}
              plugins={[defaultLayoutPluginInstance]}
              renderLoader={(percentages: number) => (
                <div style={{ width: "240px" }}>
                  <ProgressBar progress={Math.round(percentages)} />
                </div>
              )}
            />
            ;
          </Worker>
        </div>
      ) : (
        // <embed
        //   src={source}
        //   type="application/pdf"
        //   style={{ width: '100%', height: '100%'}}
        // />
        <div>Orari non caricati</div>
      )}
    </div>
  );
};

export default CustomPDFViewer;
