import { CreateFacility, CreateFacilityImage } from '../model/facility.model';
import { defaultHeaders } from './common';

export const getCreateFacilityConf = (createFacility: CreateFacility) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/facility',
    headers: defaultHeaders,
    data: JSON.stringify(createFacility),
  };
};
export const getUpdateFacilityConf = (
  createFacility: CreateFacility,
  id: number
) => {
  return {
    method: 'put',
    maxBodyLength: Infinity,
    url: '/api/facility/' + id,
    headers: defaultHeaders,
    data: JSON.stringify(createFacility),
  };
};

export const getListFacilityPagedConf = (
  page: number,
  size: number,
  query: string
) => {
  return {
    method: 'get',
    url: '/api/facility?page=' + page + '&size=' + size + query,
    headers: defaultHeaders,
  };
};
export const getListFacilityConf = () => {
  return {
    method: 'get',
    url: '/api/facility',
    headers: defaultHeaders,
  };
};

export const getDetailFacilityConf = (id: string) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/facility/${id}`,
    headers: defaultHeaders,
  };
};

export const getListFacilityBySolicitorIdConf = (solicitorId: number) => {
  return {
    method: 'get',
    maxBodyLength: Infinity,
    url: `/api/solicitor/${solicitorId}/facility`,
    headers: defaultHeaders,
  };
};

export const getCreateFacilityImageConf = (formData: any) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/facility-image',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    body: formData,
  };
};
export const getDeleteFacilityImageConf = (facilityId: number) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: '/api/facility-image/' + facilityId,
    headers: defaultHeaders,
  };
};

export const deleteFacilityConf = (id:number) => {
  return {
    method: 'delete',
    url: '/api/facility/' + id,
    headers: defaultHeaders
  };

};
