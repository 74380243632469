import { Card, CardBody } from 'reactstrap';
import { ExtendedDetailStayGuest } from '../model/stay-guest.model';
import QRCode from 'react-qr-code';
import { getDateDDMMYYYY } from '../util/date.util';

interface Props {
  entity: ExtendedDetailStayGuest;
}

const CardCodeView = (props: Props) => {
  const { entity } = props;
  const fullname = entity?.guest?.firstName + ' ' + entity?.guest?.lastName;

  const validFrom = entity?.stay?.fromDate
    ? getDateDDMMYYYY(new Date(entity?.stay?.fromDate))
    : '-';
  const valiTo = entity?.stay?.toDate
    ? getDateDDMMYYYY(new Date(entity?.stay?.toDate))
    : '-';

  const getBackgrounColor = (codeStatus: string) => {
    switch (codeStatus) {
      case 'VALID':
        return '#798E12';
      case 'PENDING':
        return '#D18000';
      case 'INVALID':
        return '#D18000';
      case 'CANCELLED':
        return '#D18000';
      case 'EXPIRED':
        return '#BF1054';
      case 'REJECTED':
        return '#BF1054';
    }
  };
  let codeStatus = entity?.codeStatus || '';
  let message = entity?.message;
  let backgroundColor = getBackgrounColor(codeStatus);
  return (
    <div className="row justify-content-center">
      <Card  style={{     maxWidth: '400px' }} >
        <CardBody>
          <div
            className="justify-content-center"
            style={{ textAlign: 'center' }}
          >
            {entity?.guest.code && (
              <QRCode
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={entity?.guest.code}
              />
            )}
            <div
              style={{
                marginTop: '12px',
                color: 'white',
                backgroundColor: backgroundColor,
                fontSize: '67px',
                fontWeight: '600',
                padding: '4px ',
              }}
            >
              {entity?.guest.code}
            </div>

            <div
              style={{
                marginTop: '8px',
                color: backgroundColor,
                fontSize: '24px',
                fontWeight: '600',
              }}
            >
              {message}
            </div>
            <div
              style={{
                marginTop: '8px',
                fontSize: '57px',
                fontWeight: '600',
              }}
            >
              {fullname}
            </div>
            <hr style={{ margin: '0px' }}></hr>
            <div
              style={{
                textAlign: 'right',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              nominativo
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '8px',
              }}
            >
              <div
                style={{
                  textAlign: 'left',
                  fontSize: '24px',
                  fontWeight: '600',
                }}
              >
                {validFrom}
                <hr style={{ margin: '0px' }}></hr>

                <div
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  inizio validità
                </div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '24px',
                  fontWeight: '600',
                }}
              >
                {valiTo}
                <hr style={{ margin: '0px' }}></hr>
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  fine validità
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: '8px',
                fontSize: '26px',
                textAlign: 'right',
              }}
            >
              {entity?.facility.name}

              <hr style={{ margin: '0px' }}></hr>
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                struttura ricettiva
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardCodeView;
