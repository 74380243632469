import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useSortBy, useTable } from 'react-table';
import { ButtonGroup, Table } from 'reactstrap';
import IconButtonWithTooltip from './icon-button-with-tooltip';
 import './mobile-table.css';


export function MobileTable({ columns, data, goTodetail, goTodetailProps, structuredHeader, goToedit }: any) {

  function extract(entity :any, path: string) {
    const keys = path.split('.');
    return keys.reduce((acc, key) => acc && acc[key], entity);
}

  return (
    <>

<Table striped responsive className='mobile-table'>
    <thead> 
      {structuredHeader ? structuredHeader : <tr>
        {columns.map((el :any) => {
          return  <th {...el.props}>{el.Header}</th>
        })}
      {goTodetail !== undefined && <th {...goTodetailProps}></th>}
      {goToedit !== undefined && <th {...goTodetailProps}></th>}
      </tr> }
    </thead>
    <tbody>
      {data && data.map((it:any, idx:number) => {
        
        let tds :any[] = []
        columns.map((el :any) => {
          console.log(it, el.accessor)
          if(el.accessor){
            tds.push(<td {...el.props} data-label={el.Header}>{extract(it, el.accessor)}</td>)
          } else if(el.render) {
            tds.push(<td {...el.props} data-label={el.Header}>{el.render(it)}</td>)
          } else {
            tds.push(<td {...el.props} data-label={el.Header} />)
          }
        })

        if(goTodetail !== undefined)  (
          tds.push(<td {...goTodetailProps}>
            <ButtonGroup>
              <IconButtonWithTooltip
                style={{ padding: 0 }}
                icon={faEye}
                color={'warning'}
                title={t('general.buttons.detail')}
                id={'view' + idx}
                onClick={() => {
                  goTodetail(it);
                }}
              ></IconButtonWithTooltip>
            </ButtonGroup>
          </td>)
        )
        if(goToedit !== undefined)  (
          tds.push(<td {...goTodetailProps}>
            <ButtonGroup>
              <IconButtonWithTooltip
                style={{ padding: 0 }}
                icon={faPencil}
                color={'warning'}
                title={t('general.buttons.detail')}
                id={'view' + idx}
                onClick={() => {
                  goToedit(it);
                }}
              ></IconButtonWithTooltip>
            </ButtonGroup>
          </td>)
        )
        return <tr>{tds}</tr>
      })}
    </tbody>
  </Table>
    </>
  );
}
