import { t } from 'i18next';
import {
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';
import { registerRs } from '../util/form.util';
import { getDateYYYYMMDD } from '../util/date.util';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  onFilterChange: (data: any) => void;
}

export const DashboardFilterView = (props: Props) => {
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);

  let initialData = { fromDate: getDateYYYYMMDD(lastMonth), toDate: getDateYYYYMMDD(nextMonth) };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = useForm({ defaultValues: { ...initialData } });
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');

  useEffect(() => {
    props.onFilterChange(initialData);
  }, []);

  useEffect(() => {
    trigger(['fromDate', 'toDate']);
  }, [fromDate, toDate]);

  const onSubmit = (data: any) => {
    console.log('onSubmit', data);
    props.onFilterChange(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
     <div className='row'> 
     <div className='col-6'> 
     <FormGroup className="form-field">
        <Input
          id="fromDate"
          name="fromDate"
          type="date"
          {...registerRs(register, 'fromDate', {
            required: t(`general.errors.required`),
            validate: (value: any) => {
              if (fromDate && toDate && new Date(toDate) < new Date(fromDate)) {
                return t(`general.errors.startDateGreaterThanEndDate`);
              }
            },
          })}
          invalid={errors.fromDate && errors.fromDate != null}
        />
        <Label className="date" for="fromDate">
          {t('occupancy-record.startDate')}
        </Label>
        <FormFeedback>{'' + errors?.fromDate?.message}</FormFeedback>
      </FormGroup></div>
      <div className='col-6'> 
      <FormGroup className="form-field">
        <Input
          id="toDate"
          name="toDate"
          type="date"
          {...registerRs(register, 'toDate', {
            required: t(`general.errors.required`),
            validate: (value: any) => {
              if (fromDate && toDate && toDate < fromDate) {
                return t(`general.errors.startDateGreaterThanEndDate`);
              }
            },
          })}
          invalid={errors.toDate && errors.toDate != null}
        />
        <Label className="date" for="toDate">
          {t('occupancy-record.endDate')}
        </Label>
        <FormFeedback>{'' + errors?.toDate?.message}</FormFeedback>
      </FormGroup></div>
      </div>
      <Button color="primary" type="submit">
        {t('general.actions.search')}
      </Button>
    </Form>
  );
};
