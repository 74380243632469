import { t } from "i18next";
import { useForm } from "react-hook-form";
import { Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import useRequest from "../../hook/use-request.hook";
import { ApiError, ApiSuccess } from "../../model/errors.model";
import { registerRs } from "../../util/form.util";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faImage } from "@fortawesome/free-solid-svg-icons";
import { DetailEvent } from "../../model/event.model";
import { createEventAPI, getDetailEventConf, updateEventAPI } from "../../api/eventsApi";
import MobileButton from "../../components/mobile-button";
import { useEffect, useRef, useState } from "react";
import AddImageModal from "../../modal/add-image.modal";
import { JSX } from "react/jsx-runtime";
import { ErrorsView } from "../../components/errors.view";
import { NotificationTypes, useAppUtilContext } from "../../util/app-util.context";

interface EventFormProps {
  initialData?: DetailEvent;
  // enabled: boolean;
  onSubmitSuccess: (result: ApiSuccess) => void;
  onSubmitError: (errors: ApiError[]) => void;
}

const EventForm = (props: EventFormProps) => {
  const { loading, fetchData } = useRequest();
  const { onSubmitSuccess, onSubmitError, initialData } = props;
  const navigate = useNavigate();
  const { showModal, showNotification } = useAppUtilContext();
  const addImageModalRef = useRef<any>(null);
  const [entity, setEntity] = useState<any>();
  const [priceNeeded, setPriceNeeded] = useState<boolean>(false);
  // console.log(initialData,"dati iniziali")

  useEffect(() => {
    if (initialData) {
      setEntity(initialData);
      setPriceNeeded(initialData.eventType);
      // console.log("initialData",initialData)
    }
  }, [initialData]);

  const onSuccessDetail = (data: any, res: any) => {
    if (initialData && entity?.images.length > 0 && data.images.length < entity?.images.length) {
      showNotification("Immagine Rimossa", NotificationTypes.SUCCESS);
    } else {
      showNotification("Immagine Caricata", NotificationTypes.SUCCESS);
    }

    // console.log('onSuccessDetail', data);
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t("modal.request_errors"),
      // eslint-disable-next-line react/jsx-no-undef
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSubmit = (data: any) => {
    let {
      name,
      price,
      fullNameEvent,
      eventStartDate,      
      locationEvent,
      addressEvent,
      startTimeEvent,
      endTimeEvent,
      eventDescription,
      eventType,
      urlTicket,
      eventReservation,
      eventForChildren,
      weeklyEvent,
      aloneEvent,
      coupleEvent,
      groupEvent,
    } = data;

    let eventEndDate = data.eventEndDate ? data.eventEndDate : eventStartDate;
    eventDescription = JSON.stringify(eventDescription)
  
    let config;
    if (initialData && initialData.id) {
      config = updateEventAPI(initialData.id,{
        id: initialData.id,
        name,
        fullNameEvent,
        price: eventType ? price * 100 : 0,
        eventStartDate,
        eventEndDate,
        locationEvent,
        addressEvent,
        startTimeEvent,
        endTimeEvent,
        eventDescription,
        eventType,
        urlTicket,
        eventReservation,
        eventForChildren,
        weeklyEvent,
        aloneEvent,
        coupleEvent,
        groupEvent,
      });
    } else {
      config = createEventAPI({
        name,
        price: eventType ? price * 100 : 0,
        fullNameEvent,
        eventStartDate,
        eventEndDate,
        locationEvent,
        addressEvent,
        startTimeEvent,
        endTimeEvent,
        eventDescription,
        eventType,
        urlTicket,
        eventReservation,
        eventForChildren,
        weeklyEvent,
        aloneEvent,
        coupleEvent,
        groupEvent,
      });
    }

    if(new Date (eventStartDate) > new Date (eventEndDate)){
      showNotification("La data di fine evento non può essere precedente a quella di inizio", NotificationTypes.ERROR);
      return;
    }
    console.log(config, "CONFIG");
    if (config) {
      fetchData(config, onSuccess, onErrors);
    };
  };

  const onSuccess = (dataa: any) => {
    onSubmitSuccess(dataa);
  };

  const onErrors = (errors: ApiError[]) => {
    onSubmitError(errors);
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {}, values: { ...initialData } });

console.log("errors", errors)

  return (
    <div className="mt-2" style={{ marginLeft: "5%", marginRight: "5%" }}>
      <Button
        color="transparent"
        onClick={() => {
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      {initialData && (
        <MobileButton
          onClick={() => addImageModalRef.current?.open(entity?.id)}
          color="success"
          style={{ marginRight: "5px", float: "right" }}
          icon={faImage}
          text={t("general.buttons.addimage")}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row  mt-2">
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field">
              <Input
                id="name"
                name="name"
                type="text"
                //disabled={!enabled}
                {...registerRs(register, "name", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.name && errors.name != null}
              />
              <Label for="name">{t(`events.name`)}</Label>
              <FormFeedback>{"" + errors?.name?.message}</FormFeedback>
            </FormGroup>
          </div>
          
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field">
              <Input
                id="addressEvent"
                name="addressEvent"
                type="text"
                // disabled={!enabled}
                {...registerRs(register, "addressEvent", {
                  required: t(`general.errors.required`),
                  // pattern: {
                  //   value: ZIP_CODE_REGEX,
                  //   message: t(`general.errors.invalid_zip_code`),
                  // },
                })}
                invalid={errors.addressEvent && errors.addressEvent != null}
              />
              <Label for="addressEvent">{t(`events.address`)}</Label>
              <FormFeedback>{"" + errors?.addressEvent?.message}</FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field">
              <Input
                id="startTimeEvent"
                name="startTimeEvent"
                type="time"
                // placeholder="00:00"
                //disabled={!enabled}
                {...registerRs(register, "startTimeEvent", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.startTimeEvent && errors.startTimeEvent != null}
              />{" "}
              <Label style={{ marginRight: "12%", top: "4px" }} for="startTimeEvent">{t(`events.startTime`)}</Label>
              <FormFeedback>{"" + errors?.startTimeEvent?.message}</FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field">
              <Input
                id="endTimeEvent"
                name="endTimeEvent"
                type="time"
                // placeholder="00:00"
                //disabled={!enabled}
                {...registerRs(register, "endTimeEvent", {
                  // required: t(`general.errors.required`),
                })}
                invalid={errors.endTimeEvent && errors.endTimeEvent != null}
              />{" "}
              <Label style={{ marginRight: "12%", top: "4px" }} for="endTimeEvent">{t(`events.endTime`)}</Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field ">
              <Input
                id="eventStartDate"
                name="eventStartDate"
                type="date"
                disabled={false}
                {...registerRs(register, "eventStartDate", {
                  required: t(`general.errors.required`),
                  // pattern: {
                  //   value: EMAIL_REGEX,
                  //   message: t(`general.errors.invalid_email`),
                  // },
                })}
                invalid={errors.eventStartDate && errors.eventStartDate != null}
              />
              <Label style={{ marginRight: "12%", top: "4px" }} for="eventStartDate">
                Data Inizio
              </Label>
              <FormFeedback>{"" + errors?.eventStartDate?.message}</FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field ">
              <Input
                id="eventEndDate"
                name="eventEndDate"
                type="date"
                disabled={false}
                {...registerRs(register, "eventEndDate", {
                  // required: t(`general.errors.required`),
                  // pattern: {
                  //   value: EMAIL_REGEX,
                  //   message: t(`general.errors.invalid_email`),
                  // },
                })}
                invalid={errors.eventEndDate && errors.eventEndDate != null}
              />
              <Label className="mb-2" style={{ marginRight: "12%", top: "4px" }} for="eventEndDate">
              Data Fine
              </Label>


              <FormFeedback>{"" + errors?.eventEndDate?.message}</FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup className="form-field">
              <Input
                id="locationEvent"
                name="locationEvent"
                type="text"
                // disabled={!enabled}
                {...registerRs(register, "locationEvent", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.locationEvent && errors.locationEvent != null}
              />{" "}
              <Label for="locationEvent">{t(`events.location`)}</Label>
              <FormFeedback>{"" + errors?.locationEvent?.message}</FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input
                id="eventType"
                name="eventType"
                {...registerRs(register, "eventType", {})}
                type="checkbox"
                onClick={() => setPriceNeeded(!priceNeeded)}
              />{" "}
              <Label for="eventType" check className="mb-2">
                {t(`events.type`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input
                id="eventReservation"
                name="eventReservation"
                {...registerRs(register, "eventReservation", {})}
                type="checkbox"
              />{" "}
              <Label for="eventReservation" check className="mb-2">
                {t(`events.reservation`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input
                id="eventForChildren"
                name="eventForChildren"
                {...registerRs(register, "eventForChildren", {})}
                type="checkbox"
              />{" "}
              <Label for="eventForChildren" check className="mb-2">
                {t(`events.child`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input id="weeklyEvent" name="weeklyEvent" {...registerRs(register, "weeklyEvent", {})} type="checkbox" />{" "}
              <Label for="weeklyEvent" check className="mb-2">
                {t(`events.weekly`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input id="aloneEvent" name="aloneEvent" {...registerRs(register, "aloneEvent", {})} type="checkbox" />{" "}
              <Label for="aloneEvent" check className="mb-2">
                {t(`events.alone`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input id="coupleEvent" name="coupleEvent" {...registerRs(register, "coupleEvent", {})} type="checkbox" />{" "}
              <Label for="coupleEvent" check className="mb-2">
                {t(`events.couple`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            <FormGroup check>
              <Input id="groupEvent" name="groupEvent" {...registerRs(register, "groupEvent", {})} type="checkbox" />{" "}
              <Label for="groupEvent" check className="mb-2">
                {t(`events.group`)}
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-6  mb-3">
            {document.getElementById("eventType") && (
              <FormGroup className="form-field">
                <Input
                  id="urlTicket"
                  name="urlTicket"
                  type="url"
                  //disabled={!enabled}
                  {...registerRs(register, "urlTicket", {})}
                />
                <Label for="urlTicket">{t(`events.url`)}</Label>
                {/* <FormFeedback>{"" + errors?.urlTicket?.message}</FormFeedback> */}
              </FormGroup>
            )}
          </div>
          <div className="col-md-6  mb-3">
            {priceNeeded && (
              <FormGroup className="form-field">
                <Input
                  id="price"
                  name="price"
                  type="number"
                  placeholder="€ 0,00"
                  step={0.01}
                  //disabled={!enabled}
                  {...registerRs(register, "price", {
                    required: t(`general.errors.required`),
                  })}
                  invalid={errors.price && errors.price != null}
                />{" "}
                <Label style={{ marginRight: "6%" }} for="urlTicket">
                  € {t(`events.price`)}
                </Label>
                <FormFeedback>{"" + errors?.price?.message}</FormFeedback>
              </FormGroup>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <FormGroup className="form-field">
              <span style={{ color: "#6c757d" }}>{t(`events.description`)} IT</span>{" "}
              <Input
                id="eventDescription"
                name="eventDescription"
                type="textarea"
                //disabled={!enabled}
                style={{ width: "100%", height: "100px" }}
                {...registerRs(register, "eventDescription.it", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.eventDescription && errors.eventDescription != null}
              />{" "}
              {/* <Label for="note">{t('facility.note')}</Label> */}
              <FormFeedback>{"" + errors?.eventDescription?.message}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-field">
              <span style={{ color: "#6c757d" }}>{t(`events.description`)} EN</span>{" "}
              <Input
                id="eventDescription"
                name="eventDescription"
                type="textarea"
                //disabled={!enabled}
                style={{ width: "100%", height: "100px" }}
                {...registerRs(register, "eventDescription.en", {
                  required: t(`general.errors.required`),
                })}
                invalid={errors.eventDescription && errors.eventDescription != null}
              />{" "}
              {/* <Label for="note">{t('facility.note')}</Label> */}
              <FormFeedback>{"" + errors?.eventDescription?.message}</FormFeedback>
            </FormGroup>
            <Button type="submit" color="primary" style={{ float: "right" }}>
            { initialData ? t("general.buttons.edit"): t("general.buttons.add")}
            </Button>
          </div>
        </div>
      </Form>
      <AddImageModal
        single={false}
        refresh={() => {
          fetchData(getDetailEventConf(initialData?.id ? initialData.id.toString() : ""), onSuccessDetail, onErrorsDetail);
        }}
        list={entity?.images}
        ref={addImageModalRef}
        type="event"
        onClose={() => addImageModalRef.current?.reload()}
      />
    </div>
  );
};

export default EventForm;
