import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { getListFacilityConf } from '../../api/facilityAPI';
import useRequest from '../../hook/use-request.hook';
import { DetailFacility } from '../../model/facility.model';
import { useAppUtilContext } from '../../util/app-util.context';
import FacilityView from '../../components/facility-view';
import { ErrorsView } from '../../components/errors.view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import MobileButton from '../../components/mobile-button';

export default function FacilityListPage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailFacility[]>([]);

  const onSuccess = (data: any) => {
    setList(data);
  };

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const refresh = () => {
    fetchData(getListFacilityConf(), onSuccess, onErrors);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-2">
      <div className="header-container">
        <h2>{t('facility.list.title')}</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <MobileButton
            onClick={() => {
              navigate('create');
            }}
            disabled={loading}
            color="success"
            icon={faPlus} 
            text={t('general.buttons.add')}
          />
          <MobileButton
            onClick={refresh}
            disabled={loading}
            color="secondary"
            outline
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className='d-none d-md-inline'>&nbsp; {t('general.buttons.refresh')}</span>
              </>
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span  className='d-none d-md-inline'> {t('action.loading')} </span>
              </>
            )}
          </MobileButton>
        </div>
      </div>
      <div className="row">
        {list.map((it, idx) => {
          return (
            <div className="mt-2 col-lg-3 col-md-4 col-sm-12" key={idx}>
              <FacilityView
                facility={it}
                onDetailClick={(facility) =>
                  navigate(`/facility/${facility.id}`)
                }
              ></FacilityView>
            </div>

            
          );
        })}
      </div>
    </div>
  );
}
