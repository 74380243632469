import { faArrowLeft, faPlus, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { getListCompanyPagedConf } from '../../api/companyAPI';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import { MobileTable } from '../../components/mobile-table';
import PaginationUtility from '../../components/pagination';
import useRequest from '../../hook/use-request.hook';
import { DetailCompany } from '../../model/company.model';
import { useAppUtilContext } from '../../util/app-util.context';

export default function CompanyListPage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const [text, setText] = useState<any | undefined>(undefined);
  const { loading, fetchData } = useRequest();
  const [list, setList] = useState<DetailCompany[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    pageSize: 20,
    totalElements: null,
  });

  const onErrors = (errors: any) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const refresh = (pag: any) => {
    let query = '';
    if (text !== undefined) query = query + '&text=' + text;
    fetchData(
      getListCompanyPagedConf(pag.page, pag.pageSize, query),
      (data: any, header: any) => {
        setList(data);
        setPagination({
          ...pag,
          ...{ totalElements: header['x-total-count'] },
        });
      },
      onErrors
    );
  };
  const create = () => {
    console.log('click');
    navigate('create');
  };

  useEffect(() => {
    refresh(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e: any) => {
    if (e.target.value !== '') setText(e.target.value);
    else setText(undefined);
  };
  return (
    <div className="container mt-2">
       <Button
          color="trasparent"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
          {t('general.buttons.back')}
        </Button>
      <div className="header-container">
        <h2>{t('company.list.title')}</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <MobileButton
            onClick={create}
            disabled={loading}
            color="success"
            icon={faPlus}
            text={t('general.buttons.add')}
          />
          <MobileButton
            onClick={refresh}
            disabled={loading}
            color="secondary"
            outline
          >
            {!loading ? (
              <>
                <FontAwesomeIcon icon={faRefresh} />
                <span className="d-none d-md-inline">
                  &nbsp; {t('general.buttons.refresh')}
                </span>
              </>
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span className="d-none d-md-inline">
                  {' '}
                  {t('action.loading')}{' '}
                </span>
              </>
            )}
          </MobileButton>
        </div>
      </div>
      <div className="d-flex mt-2 mb-2">
        <input className="form-control" name="text" onChange={handleChange} />
        <Button
          color="primary"
          onClick={() => {
            refresh({
              page: 1,
              pageSize: 20,
              totalElements: null,
            });
          }}
          style={{ marginLeft: '10px' }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
      <div>
        <MobileTable
          columns={[
            {
              Header: t('company.id'),
              accessor: 'id',
              props: { width : '10%'}
            },
            {
              Header: t('company.name'),
              accessor: 'name',
           //   props: { width : '10%'}
            },
            {
              Header: t('company.fiscalCode'),
              accessor: 'fiscalCode',
            //  props: { width : '10%'}
            },
            {
              Header: t('company.vatNumber'),
              accessor: 'vatNumber',
            //  props: { width : '10%'}
            },
            {
              Header: t('company.email'),
              accessor: 'email',
             // props: { width : '10%'}
            },
          ]}
          data={list}
          goTodetail={(it: DetailCompany) => navigate(`/company/${it.id}`)}
          goTodetailProps={{ width : '50px'}}
        />
      </div>
      <div className="mt-2 row">
        {/* <Table striped>
          <thead>
            <tr>
              <th>{t('company.id')}</th>
              <th>{t('company.name')}</th>
              <th>{t('company.fiscalCode')}</th>
              <th>{t('company.vatNumber')}</th>
              <th>{t('company.email')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.map((it, idx) => {
              return (
                <tr>
                  <th scope="row">{it.id}</th>
                  <td>{it.name}</td>
                  <td>{it.fiscalCode}</td>
                  <td>{it.vatNumber}</td>
                  <td>{it.email}</td>
                  <td>
                    <ButtonGroup>
                      <IconButtonWithTooltip
                        icon={faEye}
                        color={'warning'}
                        title={t('general.buttons.detail')}
                        id={'view' + it.id}
                        onClick={() => navigate(`/company/${it.id}`)}
                      ></IconButtonWithTooltip>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table> */}
      </div>
      <div >
        <PaginationUtility
          {...pagination}
          onSizeChange={(n: any) => {
            if (pagination.pageSize !== n)
              refresh({ ...pagination, ...{ page: 1, pageSize: n } });
          }}
          onChange={(n) => {
            if (pagination.page !== n)
              refresh({ ...pagination, ...{ page: n } });
          }}
        />
      </div>
    </div>
  );
}
