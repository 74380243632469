import { t } from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { icons } from '../util/form.util';
import useRequest from '../hook/use-request.hook';
import { DetailCompany } from '../model/company.model';
import { ApiError } from '../model/errors.model';
import { NotificationTypes, useAppUtilContext } from '../util/app-util.context';
import { registerRs } from '../util/form.util';
import { getListExemptionConf } from '../api/exemptionAPI';
import { getCreateMultiOccupancyConf } from '../api/occupancyAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
export interface EditOccupancyRecordsModalProps {
  onClose: () => void;
}
export interface EditOccupancyRecordsModalHandle {
  open: (facilityId: number) => void;
}
const EditOccupancyRecordsModal = forwardRef<
  EditOccupancyRecordsModalHandle,
  EditOccupancyRecordsModalProps
>((props: EditOccupancyRecordsModalProps, ref) => {
  const { showModal, showNotification } = useAppUtilContext();
  const [facilityId, setFacilityId] = useState<number | null>(null);
  const [modal, setModal] = useState(false);
  const [exemptions, setExemptions] = useState<any[]>([]);
  const { loading, fetchData } = useRequest();

  const { loading: loadingExemptions, fetchData: fetchExemptions } =
    useRequest();
  const toggle = () => {
    reset();
    setModal(!modal);
  };
  useImperativeHandle(ref, () => ({
    open(facilityId: number, company?: DetailCompany) {
      setFacilityId(facilityId);
      setModal(true);
    },
  }));

  const onSubmit = (data: any) => {
    console.log('onSubmit', data);
    let { startDate, endDate } = data;

    const keys = Object.keys(data).filter((it) => it.startsWith('exemption-'));

    const currentDate = new Date(startDate);
    const finalDate = new Date(endDate);
    const toSend: any[] = [];

    while (currentDate <= finalDate) {
      keys.forEach((it) => {
        let exemptionId = it.split('-')[1];
        console.log(it, data[it]);
        let guests = parseInt(data[it]) ? parseInt(data[it]) : 0;
        // if (guests ) {
        toSend.push({
          date: formatDate(currentDate),
          guests: guests,
          exemptionId: parseInt(exemptionId),
          facilityId: facilityId,
        });
        // }
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log('toSend', toSend);
    let config = getCreateMultiOccupancyConf(toSend);
    if (config) {
      fetchData(config, onSuccess, onErrors);
    }
  };
  useEffect(() => {
    fetchExemptions(
      getListExemptionConf(),
      (data, headers) => {
        if (Array.isArray(data)) {
          setExemptions(data);
        }
      },
      () => {
        console.log('error');
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (data: any) => {
    const { message } = data;
    setModal(false);
    showNotification(message, NotificationTypes.SUCCESS);
    props.onClose();
  };

  const onErrors = (errors: ApiError[]) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  let initialData = { startDate: null, endDate: null };
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = useForm({ defaultValues: { ...initialData } });
  // console.log("companyData?.personal", companyData?.personal)
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  useEffect(() => {
    trigger(['startDate', 'endDate']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('modal.addOccupancy.title')}
        </ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormGroup className="form-field ">
              <Input
                id="startDate"
                name="startDate"
                type="date"
                {...registerRs(register, 'startDate', {
                  required: t(`general.errors.required`),
                  validate: (value: any) => {
                    if (
                      startDate &&
                      endDate &&
                      new Date(endDate) < new Date(startDate)
                    ) {
                      return t(`general.errors.startDateGreaterThanEndDate`);
                    }
                  },
                })}
                invalid={errors.startDate && errors.startDate != null}
              />
              <Label  className="date" for="startDate">{t('occupancy-record.startDate')}</Label>
              <FormFeedback>{'' + errors?.startDate?.message}</FormFeedback>
            </FormGroup>

            <FormGroup className="form-field">
              <Input
                id="endDate"
                name="endDate"
                type="date"
                {...registerRs(register, 'endDate', {
                  required: t(`general.errors.required`),
                  validate: (value: any) => {
                    if (startDate && endDate && endDate < startDate) {
                      return t(`general.errors.startDateGreaterThanEndDate`);
                    }
                  },
                })}
                invalid={errors.endDate && errors.endDate != null}
              />
              <Label className="date" for="endDate">{t('occupancy-record.endDate')}</Label>
              <FormFeedback>{'' + errors?.endDate?.message}</FormFeedback>
            </FormGroup>

            {exemptions &&
              exemptions.length > 0 &&
              exemptions.map((it) => {
                return (
                  <FormGroup className="form-field ">
                    <Input
                      id={it.id}
                      name={`exemption-${it.id}`}
                      type="number"
                      step="1"
                      {...registerRs(register, `exemption-${it.id}`, {})}
                    />
                    <Label className="number" for={`exemption-${it.id}`}>
                    <FontAwesomeIcon icon={icons.get(it.icon)} />{" "}
                      {it.name}</Label>
                  </FormGroup>
                );
              })}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              {t('general.buttons.close')}
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              {!loading ? (
                t('action.send')
              ) : (
                <>
                  <Spinner size="sm"> Loading...</Spinner>
                  <span> {t('action.loading')}</span>
                </>
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
});

export default EditOccupancyRecordsModal;
