import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import PlaceCreatePage from './place-create.page';
import PlaceDetailPage from './place-detail.page';
import PlaceListPage from './place-list.page';
import PlaceUpdatePage from './place-update.page';
import { useAppSelector } from '../../redux/store';
import {
  Authorities,
  currentUserSelector,
} from '../../redux/reducers/userSlice';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';

const PlaceRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);

  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      ) && (
        <>
          <Route path="" element={<PlaceListPage />} />
          <Route path=":id" element={<PlaceDetailPage />} />
          <Route path="create" element={<PlaceCreatePage />} />
          <Route path=":id/update" element={<PlaceUpdatePage />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default PlaceRoutes;
