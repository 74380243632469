/* eslint-disable jsx-a11y/alt-text */
import { faArrowLeft, faBaby, faCalendar, faCity, faClock, faEdit, faPlus, faTicket, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import images from "./imagesData";
import useRequest from "../../hook/use-request.hook";
import { deleteEventAPI, getDetailEventConf, getListEvent } from "../../api/eventsApi";
import { checkRequiredAuthorities } from "../../components/authority-boundary-view";
import { Authorities, currentUserSelector, userLngSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import MobileButton from "../../components/mobile-button";
import { SERVER_API_URL } from "../../axios/config";
import { useAppUtilContext } from "../../util/app-util.context";
import { useTranslation } from "react-i18next";
import { campoByLang } from "../../util/form.util";

const EventDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId = queryParams.get("event");
  const [event, setEvent] = useState<any>([]);
  const { loading, fetchData } = useRequest();
  const currentUser = useAppSelector(currentUserSelector);
  const [delayedLoading, setDelayedLoading] = useState(true);
  const { showModal, showNotification } = useAppUtilContext();
  const [popupImage, setPopupImage] = useState<string | null>(null);
  const { t } = useTranslation();
  const lang = useAppSelector(userLngSelector);


  useEffect(() => {
    if (eventId) {
      fetchData(getDetailEventConf(eventId), onSuccess, onErrors);
    } else {
      setEvent([]);
    }
  }, []);

  const onSuccess = (data: any) => {
    setEvent(data);
  };

  const onSuccessDelete = (data: any) => {
    showModal("Success", "Evento Rimosso", null);
    navigate(-1);
  };

  const onErrors = (data: any) => {
    //setEventsData(data);
  };
  const path = location.pathname;
  const update = (e: any) => {
    const newPath = `${path}/${encodeURIComponent(event.id)}/update`;
    navigate(newPath);
  };

  const deleteEvent = (e: any) => {
    showModal(
      t("general.actions.confirmDelete"),
      <>
        <MobileButton
          onClick={() => {
            if (event.id) {
              fetchData(deleteEventAPI(event.id), onSuccessDelete, onErrors);
            }
          }}
          color="primary"
          className="mr-2"
          // icon={faClockRotateLeft}
          text={t("general.actions.confirm")}
        />
      </>,
      null
    );
  };

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 100); // 0.1 seconds delay

      return () => clearTimeout(timer); // Cleanup timeout on component unmount or loading change
    }
  }, [loading]);
  // const event = eventsData.find((image: any) => image.id === Number(eventId));
  const [dataInizioFormattata, setDataInizioFormattata] = useState("");
  const [dataFineFormattata, setDataFineFormattata] = useState<any>("");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (event) {
      if(event?.eventStartDate) setDataInizioFormattata(formattaDataDettagliata(event?.eventStartDate))
      if(event?.eventEndDate) setDataFineFormattata(formattaDataDettagliata(event?.eventEndDate))
    }
  }, [event]);

  function formattaDataDettagliata(dateString: any) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);



    const formattedDate = day + "-" + month + "-" + year;
    // console.log(formattedDate);
    return formattedDate;
  }

  
  const giveUrl = (it: any) => {
    let c ='url("' +
      (it?.images && it?.images.length > 0
        ? SERVER_API_URL + '/' + it.images[0].image_url
        : 
          SERVER_API_URL + '/blufestival.jpg') +
      '")'   
    return c;
  };
  const italianDateFormat = (dateString: string): string => {
    const days = [
     t("general.date.giorni.domenica"), 
     t("general.date.giorni.lunedì"), 
     t("general.date.giorni.martedì"), 
     t("general.date.giorni.mercoledì"), 
     t("general.date.giorni.giovedì"), 
     t("general.date.giorni.venerdì"),  
     t("general.date.giorni.sabato")
    ];
    const months = [
     t("general.date.mesi.gennaio"), 
     t("general.date.mesi.febbraio"), 
     t("general.date.mesi.marzo"), 
     t("general.date.mesi.aprile"), 
     t("general.date.mesi.maggio"), 
     t("general.date.mesi.giugno"), 
     t("general.date.mesi.luglio"), 
     t("general.date.mesi.agosto"), 
     t("general.date.mesi.settembre"), 
     t("general.date.mesi.ottobre"), 
     t("general.date.mesi.novembre"), 
     t("general.date.mesi.dicembre")
    ];

    const [day, month, year] = dateString.split("-").map(Number);
    const date = new Date(year, month - 1, day);
    const dayOfWeek = days[date.getDay()];
    const monthName = months[month - 1];

    return `${dayOfWeek} ${day} ${monthName} ${year}`;
  };

  return (
    <div className="container" style={{ display: "flex", flexDirection: "column" }}>
      <div>
        {" "}
        <Button
          color="transparent"
          onClick={() => {
            navigate(-1);
          }}
          style={{ alignSelf: "flex-start" }}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
          {t("general.buttons.back")}
        </Button>
      </div>

      <div className="header-container">
        <h2>{event?.name ? event?.name : ""}</h2>

        {currentUser && checkRequiredAuthorities([Authorities.ADMIN, Authorities.MUNICIPALITY, Authorities.OPERATOR], currentUser) && (
          <div>
            <MobileButton
              onClick={deleteEvent}
              disabled={loading}
              color="danger"
              // icon={faPlus}
              text={t("general.buttons.remove")}
              className="ml-auto mr-2"
              icon={faTrash}
            />
            <MobileButton
              onClick={update}
              disabled={loading}
              color="primary"
              // icon={faPlus}
              text={t("general.buttons.edit")}
              className="ml-auto mr-2"
              icon={faEdit}
            />
          </div>
        )}
      </div>

      <Card className="mt-2">
        {!delayedLoading && (
          <div
            style={{
              width: "100%",
              minHeight: "350px",
              backgroundImage: giveUrl(event),
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        )}
        <CardBody>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div
                className="card mb-3"
                style={{
                  flexDirection: "row",
                  padding: "9px",
                  alignItems: "center",
                  background: "#509fbb29",
                  textTransform: "capitalize",
                  justifyContent: "space-between",
                  marginTop: "0rem"

                }}
              >
                {italianDateFormat(dataInizioFormattata)} 
  
                {event?.eventStartDate !== event?.eventEndDate &&(" - " + italianDateFormat(dataFineFormattata))}
             

                {event?.urlTicket && (
                  <>
                    <hr />
                    <Button className="mr-2" type="button" onClick={() => window.open(event.urlTicket)} color="primary">
                      {t("events.buyticket")}
                    </Button>
                  </>
                )}
              </div>
              {campoByLang(event?.eventDescription, lang )}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <span
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  display: "inline-block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <FontAwesomeIcon icon={faCity} color="#004C6C" /> {event?.locationEvent}
              </span>
              {event?.settimanale && (
                <span
                  style={{
                    textOverflow: "ellipsis",
                    width: "100%",
                    display: "inline-block",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <FontAwesomeIcon icon={faCalendar} color="#004C6C" /> {t("events.everyweek")}
                </span>
              )}
              <span
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  display: "inline-block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                
                <FontAwesomeIcon icon={faClock} color="#004C6C" /> {event?.startTimeEvent} {event?.endTimeEvent && ( " - " + event?.endTimeEvent)}
              </span>
              <span
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  display: "inline-block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <FontAwesomeIcon icon={faTicket} color="#004C6C" />{" "}
                {event?.eventReservation ? t("events.reservationmand") : t("events.freeentry")}
                <br />
                {event?.eventType ? (
                  <>
                    <strong>{t("events.price")}</strong> {Number(event?.price) / 100} €{" "}
                  </>
                ) : (
                  <strong>{t("events.free")}</strong>
                )}
              </span>
              {event?.eventForChildren && (
                <span>
                  <FontAwesomeIcon icon={faBaby} color="#004C6C" /> {t("events.4baby")}
                </span>
              )}
  
              {event?.urlTicket && (
                <>
                  <hr />
                  <Button
                    className="mr-2"
                    type="button"
                    size="lg"
                    onClick={() => window.open(event.urlTicket)}
                    color="primary"
                  >
                    Acquista ora
                  </Button>
                </>
              )}
            </div>
          </div>
          {event?.images && event?.images.length > 0 && (
            <div className="container-images">
              <h1>{t("events.gallery")}</h1>
              <div className="d-flex justify-content-start" style={{ overflowX: "auto", paddingBottom: "20px" }}>
                <div className="container-images-details">
                  {event.images.map((image: any, index: number) => (
                    <div key={index} className="image-container">
                      <img
                        src={`${SERVER_API_URL}/${image.image_url}`}
                        alt="Event"
                        className="image-in-container"
                        onClick={() => setPopupImage(`${SERVER_API_URL}/${image.image_url}`)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>

      {popupImage && (
        <div
          onClick={() => setPopupImage(null)}
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            zIndex: "100",
          }}
        >
          <img src={popupImage} alt="Full-size Event" style={{ maxWidth: "90%", maxHeight: "90%" }} />
        </div>
      )}
    </div>
  );
};
export default EventDetails;
