import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import FacilityCreatePage from './facility-create.page';
import FacilityListPage from './facility-list.page';
import FacilityDetailPage from './facility-detail.page';
import FacilityUpdatePage from './facility-update.page';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import {
  currentUserSelector,
  Authorities,
} from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';
import FacilityOccupancyPage from './facility-occupancy.page';

const FullFacilityRoutes = () => {
  const currentUser = useAppSelector(currentUserSelector);
  return (
    <ErrorBoundaryRoutes>
      {checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY, Authorities.MANAGER],
        currentUser
      ) && (
        <>
          <Route path="" element={<FacilityListPage />} />
          <Route path=":id" element={<FacilityDetailPage formMode={true} />} />
          <Route path="create" element={<FacilityCreatePage />} />
          <Route path=":id/update" element={<FacilityUpdatePage />} />
          <Route path=":id/occupancy" element={<FacilityOccupancyPage />} />
        </>
      )}
      {!checkRequiredAuthorities(
        [Authorities.ADMIN, Authorities.MUNICIPALITY],
        currentUser
      ) && (
        <>
          <Route path="" element={<FacilityListPage />} />
          <Route path=":id" element={<FacilityDetailPage formMode={false} />} />
        </>
      )}
      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default FullFacilityRoutes;
