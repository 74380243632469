import { t } from 'i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';

import { useEffect, useState } from 'react';
import { getDetailCompanyConf } from '../../api/companyAPI';
import { ErrorsView } from '../../components/errors.view';
import useRequest from '../../hook/use-request.hook';
import { DetailCompany } from '../../model/company.model';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { useAppUtilContext } from '../../util/app-util.context';
import CompanyForm from './company.form';
import { checkRequiredAuthorities } from '../../components/authority-boundary-view';
import { Authorities, currentUserSelector } from '../../redux/reducers/userSlice';
import { useAppSelector } from '../../redux/store';

export default function CompanyUpdatePage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData } = useRequest<DetailCompany>();
  const [entity, setEntity] = useState<DetailCompany>();
  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    if (id) {
      fetchData(getDetailCompanyConf(id), onSuccessDetail, onErrorsDetail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailCompany) => {
    console.log('onSuccessDetail', data);
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    let message = data.message
      ? data.message
      : 'La struttura è stata modificata';

    showModal(t('modal.request_success'), message, null);
    navigate('/company/' + id);
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      {!checkRequiredAuthorities([Authorities.MANAGER, Authorities.SOLICITOR, Authorities.USER], currentUser) && <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/company'}>{t('company.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('company.update.title')}</BreadcrumbItem>
      </Breadcrumb>}
      <div className="header-container">
        <h2>{t('company.edit.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <CompanyForm
            initialData={entity}
            enabled={true}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></CompanyForm>
        </CardBody>
      </Card>
    </div>
  );
}
