/* eslint-disable react/jsx-no-undef */
import { Button } from "reactstrap";
import "../home/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBus, faTent } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function Tour() {
  const navigate = useNavigate();

  const handleButtonClick = (src: string) => {
    navigate("/pdf-viewer?src=" + encodeURIComponent(src));
  };

  return (
    <>
      <div id="card"></div>
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: "12px" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      <div className="clearfix" style={{ marginTop: "20px", justifyContent: "center", alignItems: "center" }}>
        <h1 style={{ textAlign: "center" }} className="page-title">
          <strong>MELENDUGNO TOUR </strong>
        </h1>
        <div className="clearfix" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {" "}
          <img
            src={"/mappaShort.jpg"}
            alt={"Melendugno Tour"}
            className="image-locality"
            style={{ height: "550px", borderRadius: "10px" }}
          />
          <br />
        </div>{" "}
        <div className="locality-container">
          <div className="locality-info">
            <div className="inner-locality-info">
              <div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="circleItem" style={{ background: "#2db9aa" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>MELENDUGNO</strong>
                    </h3>

                    <p>
                      <strong> Centro storico, fatto di case a corte, palazzi e chiese. </strong>
                      <br />
                      Historic center of Melendugno, made up of courtyard, houses, palaces and churches.
                    </p>
                    <p>
                      <strong>Chiesa parrocchiale Maria SS. Assunta (XVI secolo). </strong> <br />
                      Parish church of Maria SS. Assunta (16th century).
                    </p>
                    <p>
                      <strong>Cappella dell'Immacolata (1666). </strong> <br />
                      Chapel of the Immaculate Conception (1666).
                    </p>

                    <p>
                      <strong>Cappella dell'Addolorata o Dei medici (1760). </strong>
                      <br />
                      Chapel of the Addolorata or Dei Medici (1760).
                    </p>
                    <p>
                      <strong>Palazzo D'Amely (XVI secolo). </strong>
                      <br />
                      D'Amely Palace (16th century).
                    </p>
                    <p>
                      <strong> Cappella dell'Annunziata (XVII secolo). </strong>
                      <br />
                      Annunziata Chapel (17th century).
                    </p>
                    <p>
                      <strong> Abbazia di San Niceta </strong>
                      <br /> Saint Nicetas Abbey
                    </p>
                  </div>
                </div>
              </div>

              <div className="inner-locality-info">
                <div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                    <div style={{ paddingTop: "20px" }}>
                      <div className="circleItem" style={{ background: "#35bdb0" }}></div>
                    </div>
                    <div>
                      <h3 className="page-title">
                        <strong>BORGAGNE</strong>
                      </h3>
                      <p>
                        <strong> Centro storico con caratteristiche case a torre. </strong>
                        <br /> Historic center with characteristic tower-houses.
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Castello Petraroli (1497) del Palazzo fa parte la Cappella del Rosario Petraroli Castle (1497){" "}
                        </strong>{" "}
                        <br /> The Rosary Chapel is also a part of the building.
                      </p>
                      <p>
                        <strong>Chiesa Matrice Presentazione del Signore. </strong>
                        <br />
                        Matrix Church Presentation of the Lord.
                      </p>
                      <p>
                        <strong>Chiesa della Madonna del Carmine (1619). </strong>
                        <br /> Church of the Madonna del Carmine (1619).
                      </p>
                      <p>
                        <strong>
                          Frantoio semipogeo Sciurti, di proprietà comunale dal 1988, il cui impianto risale probabilmente al
                          1500.{" "}
                        </strong>{" "}
                        <br />
                        Sciurti semi-pogeal oil mill, of municipal property since 1988, whose implant probably dates back to
                        the 1500s.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="circleItem" style={{ background: "#2db9aa" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>
                        PAESAGGIO RURALE <br />
                        THE RURAL LANDSCAPE
                      </strong>
                    </h3>
                    <p>
                      <strong>La Cappella di San Niceta (XIV secolo), lungo la strada Melendugno-San Foca. </strong> <br />{" "}
                      The Chapel of San Niceta (14th century), along the Melendugno-San Foca road.
                    </p>
                    <p>
                      <strong> Il Dolmen Gurgulante, monumento megalitico dell'Età del Bronzo. </strong>
                      <br /> The Gurgulante Dolmen, a megalithic monument from the Bronze Age.
                    </p>
                    <p>
                      <strong> Il Dolmen Placa, risalente al III-II millennio a.C., ubicato lungo la SP 29. </strong>
                      <br /> The Placa Dolmen, dating back to the 3rd-2nd millennium BC, located along the SP 29.
                    </p>
                    <p>
                      <strong>
                        {" "}
                        La campagna melendugnese è ricca di "furnieddhri" o "pajare", antiche costruzioni pietra a secco
                        utilizzate dai contadini come deposito di attrezzi o come ricovero per il bestiame.{" "}
                      </strong>
                      <br />
                      The Melendugnese countryside is full of "furnieddhri" or "pajare", ancient dry stone buildings used by
                      farmers as a tool shed or as a shelter for livestock.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="locality-info">
              <div>
                <h2 style={{ textAlign: "center" }} className="page-title">
                  <strong>
                    LE MARINE <br />
                    THE SEASIDE CITIES
                  </strong>
                </h2>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cubeItem" style={{ background: "#3883a7" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>TORRE SPECCHIA RUGGERI </strong>
                    </h3>
                    <strong>
                      {" "}
                      Posta più a nord, segna il confine con il Comune di Vernole. Completata la costruzione nel 1568,
                      presenta una struttura difensiva diversa rispetto alle altre torri costiere.{" "}
                    </strong>
                    <p>
                      <br />
                      Located further north, marks the border with the Municipality of Vernole. Its construction was
                      completed in 1568 and it has a different defensive structure than the other coastal towers.
                    </p>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cubeItem" style={{ background: "#bb1e61" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>SAN FOCA</strong>
                    </h3>
                    <p>
                      <strong>
                        {" "}
                        Originariamente piccolo villaggio di pescatori.
                        <br />{" "}
                      </strong>
                      Originally a small fishing village.
                    </p>
                    <p>
                      <strong> Torre (XVI secolo), costruita nel 1568.</strong> <br />
                      The 16th century tower, built in 1568.
                    </p>
                    <p>
                      <strong>Porto turistico.</strong> <br />
                      Tourist port.
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cubeItem" style={{ background: "#299991" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>TORRE DELL'ORSO</strong>
                    </h3>
                    <p>
                      <strong> Baia delle Due Sorelle.</strong>
                      <br /> Due Sorelle Bay.
                    </p>
                    <p>
                      <strong> Grotta santuario di S. Cristoforo, situata nella nota baia delle Due Sorelle.</strong> <br />{" "}
                      Cave sanctuary of S. Cristoforo, located in the well-known bay of the Due Sorelle.
                    </p>
                    <p>
                      <strong> Torre costiera (XVI secolo).</strong> <br /> Coastal tower (16th century).
                    </p>
                    <p>
                      <Link to={"/pdf-viewer?src=" + encodeURIComponent("/opuscolo.pdf")}>
                        <strong> Pinete Torre dell'Orso (Clicca qui per maggiori informazioni)</strong> <br />
                        Torre dell'Orso Pinewoods (Click here for more information)
                      </Link>
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cubeItem" style={{ background: "#c8832a" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>TORRE SANT'ANDREA</strong>
                    </h3>
                    <p>
                      <strong>
                        Segna il confine territoriale con il Comune di Otranto. La principale caratteristica naturale è
                        costituita dall'alta costa rocciosa a strapiombo sul mare e dalla presenza dei rinomati Faraglioni.
                      </strong>
                      <br />
                      Marks the territorial border with the Municipality of Otranto. The main natural feature is the high
                      rocky coast overlooking the sea and the presence of the renowned Faraglioni.
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "0.5rem" }}>
                  <div style={{ paddingTop: "20px" }}>
                    <div className="cubeItem" style={{ background: "#749039" }}></div>
                  </div>
                  <div>
                    <h3 className="page-title">
                      <strong>ROCA E GROTTA DELLA POESIA (info 371 3424134)</strong>
                    </h3>
                    <p>
                      <strong>
                        {" "}
                        Sito archeologico di Roca Vecchia, frequentato a partire dalla metà del II millennio a.C. e fino
                        all'età tardomedievale.
                      </strong>{" "}
                      <br />
                      Archaeological site of Roca Vecchia, frequented from the middle of the 2nd millennium BC up to the late
                      Middle Ages.
                    </p>
                    <p>
                      <strong>
                        Grotta Poesia Grande, una delle sette piscine naturali più belle del mondo, ricca di tesori
                        archeologici e paesaggistici.
                      </strong>{" "}
                      <br />
                      Poesia Grande (Big Poetry) Cave, considered one of the seven most beautiful natural swimming pools in
                      the world, rich in archaeological and landscape treasures.
                    </p>
                    <p>
                      <strong>
                        Grotta Poesia Piccola, una cavità di origine carsica sulle cui pareti si sovrappongono centinaia di
                        simboli ed iscrizioni, in lingua greca, latina e messapica, che testimoniano una frequentazione a
                        scopo cultuale della grotta.
                      </strong>
                      <br /> Poesia Piccola (Little Poetry) Cave, a cavity of karstic originon whose walls hundreds of
                      symbols and inscriptions overlap, in Greek, Latin and Messapian, which testify its attendance for
                      cultic/worship purposes.
                    </p>
                    <p>
                      <strong>
                        Roca Nuova, un insediamento rurale fortificato del XVI secolo, sorto nell'entroterra in seguito alla
                        distruzione e all'abbandono dell'insediamento costiero di Roca Vecchia.
                      </strong>{" "}
                      <br />
                      Roca Nuova, a fortified rural settlement from the 16th century, built inland following the destruction
                      and abandonment of the coastal settlement of Roca Vecchia.
                    </p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <hr />

          <div style={{ textAlign: "center" }}>
            <h3 style={{ textAlign: "center", top: "20px" }} className="page-title">
              <strong>VISITE GUIDATE GRATUITE DELL'ENTROTERRA SU PRENOTAZIONE</strong> <br />
              FREE GUIDED TOURS OF THE HINTERLAND ON RESERVATION
            </h3>
            <p>
              <strong>Martedi/Tuesday: BORGAGNE Punto di ritrovo Piazza Sant'Antonio, ore 18.30 </strong>
              <br />
              Meeting Point Sant'Antonio square, at 6.30 pm <br />
              <br />
              <strong> Giovedi /Thursday: MELENDUGNO Punto di ritrovo Piazza Pertini, ore 18.30 </strong> <br />
              Meeting point Pertini square, at 6.30 pm
            </p>
            <p>
              <strong>Per prenotazioni (a partire da 5 partecipanti): 328 7892521 </strong>
              <br />
              For reservation (minimum 5 participants): 328 7892521
            </p>
            <p>
              <strong>
                VISITE GUIDATE ROCA E GROTTA DELLA POESIA L'ingresso, prevede il pagamento di un ticket di soli 2,00 euro per
                i possessori della Melendugno Card.
                <br /> GROTTA DELLA POESIA Orario d'apertura dalle 9.30 alle 19.30 SITO ARCHEOLOGICO DI ROCA VECCHIA
                Visitabile dalle ore 15.30 alle ore 19.30 nei seguenti giorni:
                <br /> 15 maggio - 30 giugno, settembre aperto il sabato, la domenica e festivi, e gli altri giorni su
                prenotazione contattando il numero 371/3424134 entro 24 ore prima, per gruppi di minimo 5 persone. <br />
                Luglio, aperto lunedì, mercoledì, sabato, e gli altri giorni su prenotazione contattando il numero
                371/3424134 entro 24 ore prima, per gruppi di minimo 5 persone. Agosto, tutti i giorni.
                {""}{" "}
              </strong>
              <br />
              <br />
              GUIDED TOURS ROCA AND POETRY CAVE The ticket for Melendugno Card holders is only euro 2,00. <br /> POETRY CAVE
              Opening hours from 9.30 am to 7.30 pm ARCHAEOLOGICAL SITE OF ROCA VECCHIA Opened from 3.30 to 7.30pm on the
              following days: <br />
              From 15th May to 30th June, in September, the site is open on Saturday, Sunday and holidays, and the other days
              by reservation by contacting the number 371/3424134 within 24 hours before, for groups of at least 5 people.
              <br /> July the site is open on Monday, Wednesday, Saturday and the other days by reservation by contacting the
              number 371/3424134 within 24 hours before, for groups of at least 5 people. August, the site s open every day.
            </p>
          </div>
        </div>
        <div style={{ backgroundColor: "#d8ecfc", padding: "20px", marginBottom: "-13rem" }}>
          <div style={{ textAlign: "center" }} className="page-title">
            <h3 style={{ textAlign: "center" }} >
              <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faBus} />
              <strong>SERVIZIO NAVETTA/PUBLIC TRANSPORT SERVICE</strong>
            </h3>
            <p>
              <strong>
                {" "}
                Ticket con sconto del 50% per il servizio di trasporto da e verso le marine per i possessori della Melendugno
                Card.{" "}
              </strong>
              <br /> Ticket with 50% discount for transport service to and from the seaside cities for Melendugno Card
              holders.
            </p>
          </div>
          <div style={{ textAlign: "center" }} className="page-title">
            <h3 style={{ textAlign: "center" }} >
              <FontAwesomeIcon size="1x" style={{ marginLeft: "15px", marginRight: "15px" }} icon={faTent} />
              <strong>AREE PICNIC/ PICNIC AREAS</strong>
            </h3>
            <p>
              <strong>Sant'Andrea: Area parcogiochi </strong> /Playground area Area sita nel parco dietro l'info point. /Area
              behind the info point. <br />
              <strong>
                {" "}
                Torre dell'Orso: Parco Sentinella, 40.274897, 18.414800. San Foca: Area sita nel parco dietro l'info point.{" "}
              </strong>
              /Area behind the info point.
              <strong>
                {" "}
                <br /> <br />
                {/* Testi a cura di APS UniROCA{" "}<br /><br /> */}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
