import { faEye, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { getListByFacilityIdConf } from '../../api/solicitorAPI';
import useRequest from '../../hook/use-request.hook';
import { ApiError } from '../../model/errors.model';
import { DetailSolicitor } from '../../model/solicitor.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { useNavigate } from 'react-router-dom';
import IconButtonWithTooltip from '../../components/icon-button-with-tooltip';
import { ErrorsView } from '../../components/errors.view';
import RemoveFacilitySolicitorModal, {
  RemoveFacilitySolicitorModalHandle,
} from '../../modal/remove-solicitor.modal';
import { DetailFacility } from '../../model/facility.model';
import { getListFacilityBySolicitorIdConf } from '../../api/facilityAPI';

export interface SolicitorFacilitiesTableProps {
  solicitor?: DetailSolicitor;
}
export interface SolicitorFacilitiesTableHandle {
  reload: () => void;
}

export const SolicitorFacilitiesTable = forwardRef<
  SolicitorFacilitiesTableHandle,
  SolicitorFacilitiesTableProps
>((props: SolicitorFacilitiesTableProps, ref) => {
  const { solicitor } = props;
  const navigate = useNavigate();
  const { showModal } = useAppUtilContext();
  const { fetchData } = useRequest<DetailFacility>();
  const removeFacilitySolicitorModalRef =
    useRef<RemoveFacilitySolicitorModalHandle>(null);
  const [facilities, setFacilities] = useState<DetailFacility[]>([]);

  useImperativeHandle(ref, () => ({
    reload() {
      reloadTable();
    },
  }));

  const reloadTable = () => {
    if (solicitor) {
      fetchData(
        getListFacilityBySolicitorIdConf(solicitor.id),
        onSuccess,
        onErrors
      );
    }
  };
  useEffect(() => {
    reloadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitor]);

  const onSuccess = (data: any) => {
    setFacilities(data);
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <>
      {facilities && facilities.length > 0 ? (
        <Table striped bordered className="mt-2">
          <thead>
            <tr>
              <th>{t('facility.name')}</th>
              <th>{t('facility.address')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {facilities.map((it) => {
              return (
                <tr key={it.id}>
                  <td>{it.name}</td>
                  <td>{it.address}</td>
                  <td>
                    <div className="btn-group flex-btn-group-container">
                      <IconButtonWithTooltip
                        id={`delete-${it.id}`}
                        icon={faTrash}
                        color={'#CE2B37'}
                        title={t('general.buttons.remove')}
                        onClick={() =>
                          solicitor &&
                          removeFacilitySolicitorModalRef.current?.open(
                            it,
                            solicitor
                          )
                        }
                      ></IconButtonWithTooltip>
                      <IconButtonWithTooltip
                        id={`edit-${it.id}`}
                        icon={faPencil}
                        color={'#DEB841'}
                        title={t('general.buttons.edit')}
                        onClick={() => navigate(`/facility/${it.id}/update`)}
                      ></IconButtonWithTooltip>
                      <IconButtonWithTooltip
                        id={`view-${it.id}`}
                        icon={faEye}
                        color={'#008055'}
                        title={t('general.buttons.view')}
                        onClick={() => navigate(`/facility/${it.id}`)}
                      ></IconButtonWithTooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <RemoveFacilitySolicitorModal
            ref={removeFacilitySolicitorModalRef}
            onClose={() => reloadTable()}
          ></RemoveFacilitySolicitorModal>
        </Table>
      ) : (
        <div>Nessuna struttura presente</div>
      )}
    </>
  );
});
