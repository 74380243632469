import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useRequest from '../../hook/use-request.hook';
import { useNavigate } from 'react-router-dom';
import { useAppUtilContext } from '../../util/app-util.context';
import { ApiError } from '../../model/errors.model';
import { t } from 'i18next';
import { DetailOccupancyRecord } from '../../model/occupancy-record.model';
import { Button, Table } from 'reactstrap';
import { faTrash, faPencil, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface FacilityOccupanciesTableProps {
  facilityId?: number;
}
export interface FacilityOccupanciesTableHandle {
  reload: () => void;
}
export const FacilityOccupanciesTable = forwardRef<
  FacilityOccupanciesTableHandle,
  FacilityOccupanciesTableProps
>((props: FacilityOccupanciesTableProps, ref) => {
  const { facilityId } = props;
  const navigate = useNavigate();
  const { fetchData } = useRequest<DetailOccupancyRecord>();
  const [occupancies, setOccupancies] = useState<DetailOccupancyRecord[]>([]);

  const { showModal } = useAppUtilContext();

  useImperativeHandle(ref, () => ({
    reload() {
      if (facilityId) {
        //  fetchData(getListByFacilityIdConf(facilityId), onSuccess, onErrors);
      }
    },
  }));
  useEffect(() => {
    if (facilityId) {
      //  fetchData(getListByFacilityIdConf(facilityId), onSuccess, onErrors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const onSuccess = (data: any) => {
    // setSolicitors(data);
  };

  const onErrors = (errors: ApiError[]) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  return (
    <Table striped bordered className="mt-2">
      <thead>
        <tr>
          <th>#</th>
          {/* <th>First Name</th>
      <th>Last Name</th> */}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {occupancies.map((it) => {
          return (
            <tr key={it.id}>
              <th scope="row">{it.id}</th>
              {/* <td>{it.firstName}</td>
          <td>{it.lastName}</td> */}
              <td>
                <div className="btn-group flex-btn-group-container">
                  <Button color="link">
                    <FontAwesomeIcon icon={faTrash} color="#CE2B37" />
                  </Button>
                  <Button
                    color="link"
                    onClick={() => navigate(`/solicitor/${it.id}/update`)}
                  >
                    <FontAwesomeIcon icon={faPencil} color="#DEB841" />
                  </Button>
                  <Button
                    color="link"
                    onClick={() => navigate(`/solicitor/${it.id}`)}
                  >
                    <FontAwesomeIcon icon={faEye} color="#008055" />
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
});
