import { CreateCompany } from '../model/company.model';
import { CreateFacility } from '../model/facility.model';
import { defaultHeaders } from './common';

export const getDeleteCompanyFacilityConf = (
  facilityId: number,
  companyId: number
) => {
  return {
    method: 'delete',
    maxBodyLength: Infinity,
    url: '/api/company-facility',
    headers: defaultHeaders,
    data: JSON.stringify({
      facilityId,
      companyId,
    }),
  };
};

export const getCreateCompanyFacilityConf = (
  facilityId?: number,
  companyId?: number,
  company?: CreateCompany,
  facility?: CreateFacility
) => {
  return {
    method: 'post',
    maxBodyLength: Infinity,
    url: '/api/company-facility',
    headers: defaultHeaders,
    data: JSON.stringify({
      facilityId,
      companyId,
      company,
      facility,
    }),
  };
};
