import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner, Badge, ButtonGroup } from 'reactstrap';
import {
  getApproveFacilityRecordReqConf,
  getListFacilityRecordReqConf,
  getRejectFacilityRecordReqConf,
} from '../../api/facilityRecordRequestAPI';
import FacilityRequestView from '../../components/facility-request-view';
import useRequest from '../../hook/use-request.hook';
import { DetailFacilityRequest } from '../../model/facility-record-request.model';
import { useAppUtilContext } from '../../util/app-util.context';
import { MobileTable } from '../../components/mobile-table';
import MobileButton from '../../components/mobile-button';
import { getColor } from '../../util/form.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faCheck,
  faEye,
  faBan,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

export default function FacilityRequestRecordPageList() {
  const { showModal, showNotification } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const { loading: loadingApproveReject, fetchData: fetchDataApproveReject } =
    useRequest();
  const [list, setList] = useState<DetailFacilityRequest[]>([]);

  const onSuccess = (data: any) => {
    setList(data);
  };

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  const refresh = () => {
    fetchData(getListFacilityRecordReqConf(), onSuccess, onErrors);
  };
  const onSuccessApproveReject = (data: any) => {
    showNotification(data.message, 'success');
    refresh();
  };

  const onErrorsApproveReject = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };

  const approveFacilityRecordRequest = (id: number) => {
    fetchDataApproveReject(
      getApproveFacilityRecordReqConf(id),
      onSuccessApproveReject,
      onErrorsApproveReject
    );
  };

  const rejectFacilityRecordRequest = (id: number) => {
    fetchDataApproveReject(
      getRejectFacilityRecordReqConf(id),
      onSuccessApproveReject,
      onErrorsApproveReject
    );
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let buttonsDisabled = loading || loadingApproveReject;

  const onApproveClick = (facility: DetailFacilityRequest) => {
    approveFacilityRecordRequest(facility.id);
  };
  const onRejectClick = (facility: DetailFacilityRequest) => {
    rejectFacilityRecordRequest(facility.id);
  };
  return (
    <div className="container mt-2">
      <div className="header-container">
        <h2>{t('facility-record-request.list.title')}</h2>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            onClick={() => {
              navigate('create');
            }}
            disabled={buttonsDisabled}
            color="primary"
          >
            {t('general.buttons.add')}
          </Button>
          <Button onClick={refresh} disabled={buttonsDisabled}>
            {!loading ? (
              t('general.buttons.refresh')
            ) : (
              <>
                <Spinner size="sm">Loading...</Spinner>
                <span> {t('action.loading')} </span>
              </>
            )}
          </Button>
        </div>
      </div>
      <div className="row">
        <MobileTable
          columns={[
            {
              Header: t('facility-record-request.id'),
              accessor: 'id',
              props: { width: '50px' },
            },
            {
              Header: t('facility-record-request.managerName'),
              render: (it: any) => (
                <>
                  {it.firstName} {it.lastName}
                  <br />
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="xs"
                    color={'grey'}
                  />{' '}
                  {it.managerPhoneNumber}
                  <br />
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="xs"
                    color={'grey'}
                  />{' '}
                  {it.managerEmail}
                </>
              ),
            },
            {
              Header: t('facility-record-request.facility'),
              render: (it: any) => (
                <>
                  {it.facilityName}
                  <br />
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="xs"
                    color={'grey'}
                  />{' '}
                  {it.phoneNumber}
                  <br />
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="xs"
                    color={'grey'}
                  />{' '}
                  {it.email}
                </>
              ),
            },

            {
              Header: t('facility-record-request.city'),
              render: (it: any) => (
                <>
                  {it.city}
                  <br />
                  {it.address}
                  <br />
                  {it.zipCode}
                </>
              ),
            },
            {
              Header: '',
              render: (it: any) => (
                <>
                  <Badge
                    color={getColor(it.status)}
                    style={{ display: 'block', marginBottom: '5px' }}
                  >
                    {t('facility-record-request.status') +
                      ' - ' +
                      t(
                        `facility-record-request.enum_status.${it.status.toLowerCase()}`
                      )}
                  </Badge>
                  <ButtonGroup>
                    <MobileButton
                      inTable={true}
                      icon={faEye}
                      color={'primary'}
                      size="sm"
                      text={t('general.buttons.detail')}
                      id={'view' + it.id}
                      onClick={() =>
                        navigate(`/facility-record-request/${it.id}`)
                      }
                    />{' '}
                    {it.status === 'PENDING' && (
                      <MobileButton
                        inTable={true}
                        icon={faCheck}
                        color={'success'}
                        size="sm"
                        text={t('general.buttons.approve')}
                        id={'approve' + it.id}
                        onClick={() => onApproveClick(it.id)}
                      />
                    )}
                    {it.status === 'PENDING' && (
                      <MobileButton
                        inTable={true}
                        icon={faBan}
                        color={'danger'}
                        size="sm"
                        className={it.status === 'PENDING' ? '' : 'd-none'}
                        text={t('general.buttons.reject')}
                        id={'reject' + it.id}
                        onClick={() => onRejectClick(it.id)}
                      ></MobileButton>
                    )}
                  </ButtonGroup>
                </>
              ),
            },
          ]}
          data={list}
        />
        {/* 
        {list.map((it, idx) => {
          return (
            <div className="mt-2 col-lg-3 col-md-4 col-sm-12" key={idx}>
              <FacilityRequestView
                facility={it}
                onApproveClick={onApproveClick}
                onRejectClick={onRejectClick}
                onDetailClick={(facilityRecordRequest) =>
                  navigate(
                    `/facility-record-request/${facilityRecordRequest.id}`
                  )
                }
              ></FacilityRequestView>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
