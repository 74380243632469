import { t } from 'i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { NotificationTypes, useAppUtilContext } from '../../util/app-util.context';

import { ApiError } from '../../model/errors.model';
import { ErrorsView } from '../../components/errors.view';
import EventForm from './events_form';
import { useEffect, useMemo, useState } from 'react';
import { getDetailEventConf, getListEvent } from '../../api/eventsApi';
import useRequest from '../../hook/use-request.hook';
import { splitCampi } from '../../util/form.util';

export default function EventUpdate() {
  const { showModal, showNotification } = useAppUtilContext();
  const navigate = useNavigate();
  const { loading, fetchData } = useRequest();
  const location = useLocation();
  const [eventsData, setEventsData] = useState<any>([]);
  const { id : eventId } = useParams();
  const onSubmitSuccess = (data: any) => {
    const { message } = data;
    showNotification("Evento aggiornato con successo", NotificationTypes.SUCCESS);
    navigate(-1);
  };

  const [initialData, setInitialData] = useState<any>([]);

  function formattaDataDettagliata(dateString: any) {
    const date = new Date(dateString);    
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); 
    const day = ("0" + date.getDate()).slice(-2);

    const formattedDate = year + "-" + month + "-" + day;    
    return formattedDate
}

  useEffect( () => {
    if(eventId){
      fetchData(
        getDetailEventConf(eventId), onSuccess, onErrors
        
      )
    } else {
      setInitialData([])
    }

  },[eventId])

  const onSuccess = (data: any) => {
    // console.log(data,"data?")
    let newData = {...data}
    newData.eventStartDate = formattaDataDettagliata(newData.eventStartDate)
    newData.eventEndDate = formattaDataDettagliata(newData.eventEndDate)
    newData.price = Number(newData.price)/100.    
    setInitialData(newData)
  };

  const onErrors = (data: any) => {
    //setEventsData(data);
  };

  const onSubmitError = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  
  const data = useMemo(() => {
    let splittedData = initialData;
    if(initialData !== undefined && splittedData !== undefined){
      splittedData = splitCampi(splittedData, ['eventDescription']) 
    }
  
    return splittedData
  }, [initialData])

  // const event = eventsData.find((image:any) => image.id === Number(eventId));
  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/events'}>{t('events.list')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('general.buttons.edit')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('events.update')}</h2>
      </div>
      <Card style={{ marginTop: "2%" }}>
        <CardBody>
          <EventForm
            key={initialData.id} //usiamo l'id per far rimontare il componente all' aggiornamento
            //enabled={true}
            initialData={data}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
          ></EventForm>          
        </CardBody>
      </Card>
    </div>
  );
}


