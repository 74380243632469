// import image1 from './imagesSample/image1.jpg';
// import image2 from './imagesSample/image2.jpeg';
// import image3 from './imagesSample/image3.jpg';
// import image4 from './imagesSample/image4.jpg';
// import image5 from './imagesSample/image5.jpg';
//import image1 from './public/imagesSample/image1.jpg';
const image1 = '/imagesSample/santandrea-grotta.jpg';
const image2 = '/imagesSample/image2.jpeg';
const image3 = '/imagesSample/grotta-poesia.jpg';
const image4 = '/imagesSample/image4.jpg';
const image5 = '/imagesSample/image5.jpg';
const images = [
  {
    src: image1,
    // url: "detail",
    name: 'festaTramonto',
    fullNameEvent: 'Festa al Tramonto',
    localita: 'Melendugno',
    indirizzo: 'Via XX Settembre, 10',
    orarioInizio: '20:00',
    orarioFine: '24:00',
    data: '2024-07-07',
    descrizione:
      "Un'atmosfera di festa e allegria avvolge la spiaggia al calar del sole, mentre l'oceano culla le sue onde dolci sulla riva. La Festa al Tramonto è un evento spettacolare che celebra l'estate, l'amicizia e il divertimento in riva al mare. Le luci colorate adornano le capanne di paglia e i gazebo allestiti sulla sabbia, creando un'atmosfera vibrante e accogliente. Gli ospiti possono godersi una vasta selezione di cocktail tropicali e stuzzichini freschi preparati al momento, mentre i DJ mixano musica dal vivo che fa ballare tutti sulla sabbia. Non mancano poi le attività divertenti come giochi sulla spiaggia, tornei di beach volley e le immancabili nuotate notturne nell'oceano. Con il suono delle onde che si infrangono sulla costa e i colori caldi del tramonto dipingono il cielo, questa festa al mare promette di essere un'esperienza indimenticabile per tutti i partecipanti.",
    costo: '18,00',
    urlBiglietto: 'https://www.eventbrite.it/',
    tipoEvento: 'Gratuito', //gratuito o pagamento, prenotazione obbligatoria, bambini
    prenotazione: true, //prenotazione obbligatoria o accesso libero
    bambini: false, // si/no
    //tipoPartecipanti: "coppia",solo/coppia/compagnia
    solo: true,
    coppia: false,
    compagnia: false,
    settimanale: true,
  },

  {
    src: image2,
    // url: "detail",
    name: 'giroInBarca',
    fullNameEvent: 'Tour sulle coste del Salento',
    localita: 'Roca',
    indirizzo: 'Via XXI Settembre, 20',
    orarioInizio: '09:00',
    orarioFine: '18:00',
    data: '2024-07-07',
    descrizione:
      "Un'indimenticabile giornata di avventura attende coloro che si uniranno all'Escursione sulle coste del Salento a Roca. Lasciatevi cullare dalle dolci onde mentre esplorate le pittoresche coste di Roca a bordo di una suggestiva imbarcazione. Ammirate gli scogli che si ergono maestosi dall'acqua cristallina e lasciatevi incantare dalla bellezza naturale di questo tratto di mare. Questa escursione è un'opportunità imperdibile per esplorare i segreti nascosti lungo le coste del Salento. Sia che siate in coppia, con amici o in famiglia, questa avventura vi permetterà di creare ricordi preziosi mentre vi immergete nella bellezza mozzafiato dei paesaggi marini. Preparatevi a essere rapiti dai panorami mozzafiato e a vivere un'esperienza indimenticabile che vi porterà a scoprire la magia delle coste del Salento. Un'esperienza da non perdere per gli amanti della natura e degli scenari marini mozzafiato.",
    costo: '',
    tipoEvento: 'Gratuito',
    prenotazione: false,
    bambini: true,
    solo: false, // solo/coppia/compagnia
    coppia: true,
    compagnia: false,
    settimanale: true,
  },
  {
    src: image3,
    // url: "detail",
    name: 'sabbiaSole',
    fullNameEvent: 'Sabbia e Sole',
    localita: "Sant'Andrea",
    indirizzo: 'Via XXII Settembre, 30',
    orarioInizio: '10:00',
    orarioFine: '16:00',
    data: '2024-08-07',
    descrizione:
      "La sabbia calda sotto i piedi, il sole brillante che splende alto nel cielo e il suono rassicurante delle onde che si infrangono sulla costa creano l'ambiente perfetto per il torneo di beach volley 'Sabbia e Sole'. Le squadre si affrontano su una spiaggia lunga e spaziosa, circondata da palme che donano un'atmosfera tropicale. La sabbia morbida e compatta è il terreno di gioco ideale per i giocatori agili e veloci che si muovono con grazia sulla sabbia. L'energia e l'entusiasmo sono palpabili mentre le squadre si sfidano con passaggi precisi, schiacciate potenti e difese acrobatiche. Lo spirito di competizione è vivo e vibrante, ma c'è anche spazio per il divertimento e la camaraderie tra i giocatori e gli spettatori che sostengono le loro squadre preferite con grida di incoraggiamento. Il suono di una pallina che rimbalza sulla rete e le grida di gioia dopo un punto segnato riempiono l'aria, creando un'esperienza indimenticabile per tutti coloro che partecipano a questo torneo di beach volley.",
    costo: '10,00',
    urlBiglietto: 'https://www.eventbrite.it/',
    tipoEvento: 'Gratuito',
    prenotazione: true,
    bambini: true,
    solo: false, // solo/coppia/compagnia
    coppia: false,
    compagnia: true,
    settimanale: true,
  },
  {
    src: image4,
    // url: "detail",
    name: 'picnicRiva',
    fullNameEvent: 'Picnic sulla Riva',
    localita: "Torre dell'Orso",
    indirizzo: 'Via XXIII Settembre, 40',
    orarioInizio: '12:00',
    orarioFine: '15:00',
    data: '2024-08-07',
    descrizione:
      "Il Picnic sulla Riva è un'esperienza di relax e gioia, dove amici e familiari si riuniscono per condividere cibo delizioso e momenti indimenticabili in riva al mare. Il profumo salato dell'oceano e il suono delle onde che si infrangono sulla costa creano la cornice perfetta per questo evento informale e divertente. Le coperte colorate e gli ombrelloni decorati puntellano la sabbia dorata, offrendo riparo dal sole e uno spazio accogliente per sedersi e socializzare. Il cestino da picnic è pieno di prelibatezze: panini appena preparati, insalate fresche, frutta succosa e dolci squisiti. Mentre gli adulti si rilassano sorseggiando drink freschi, i bambini si divertono costruendo castelli di sabbia e giocando con le palline da spiaggia. La musica leggera e le risate riempiono l'aria, creando un'atmosfera di festa e allegria. Con il sole che splende alto nel cielo e l'acqua cristallina che si estende all'orizzonte, il Picnic sulla Riva è un'occasione perfetta per rilassarsi, godersi la compagnia degli amici e creare ricordi indimenticabili sulla spiaggia.",
    tipoEvento: 'Gratuito',
    prenotazione: false,
    bambini: false,
    solo: true, // solo/coppia/compagnia
    coppia: false,
    compagnia: false,
    settimanale: true,
  },
  {
    src: image5,
    // url: "detail",
    name: 'CleanUpDaY',
    fullNameEvent: 'Clean Up Day',
    localita: 'San Foca',
    indirizzo: 'Via XXIII Settembre, 40',
    orarioInizio: '12:00',
    orarioFine: '15:00',
    data: '2024-07-25',
    descrizione:
      "La Giornata di Pulizia delle Spiagge è un'occasione dedicata alla cura dell'ambiente marino, dove persone di tutte le età si riuniscono per preservare la bellezza naturale della costa. Il profumo salato dell'oceano si mescola con l'odore della sabbia fresca mentre i partecipanti si dedicano con impegno alla rimozione dei rifiuti che minacciano la salute degli ecosistemi marini. Guanti protettivi e sacchetti per la raccolta dei rifiuti sostituiscono le coperte e gli ombrelloni, mentre gli strumenti per la pulizia punteggiano la spiaggia, pronti per essere impiegati con determinazione. Il cestino da picnic viene sostituito da contenitori per il riciclaggio, pronti a ospitare plastica, cartacce e qualsiasi altro tipo di rifiuto. Mentre gli adulti lavorano insieme per ripulire la spiaggia, i più giovani si uniscono con entusiasmo, imparando l'importanza della conservazione ambientale attraverso l'azione diretta. La musica leggera e le risate lasciano spazio al suono delle onde e al canto degli uccelli marini, mentre l'atmosfera di impegno e solidarietà riempie l'aria. Con il sole che splende alto nel cielo e l'acqua cristallina che si estende all'orizzonte, la Giornata di Pulizia delle Spiagge diventa un'opportunità preziosa per prendersi cura del nostro pianeta, lavorando insieme per un futuro più pulito e sostenibile.",
    tipoEvento: 'Gratuito',
    prenotazione: false,
    bambini: false,
    solo: true, // solo/coppia/compagnia
    coppia: false,
    compagnia: false,
    urlBiglietto: 'https://www.eventbrite.it/',
  },
];
export const imagesVisite = [
  {
    src: image1,
    // url: "detail",
    name: 'festaTramonto',
    fullNameEvent: 'Festa al Tramonto',
    localita: 'Melendugno',
    indirizzo: 'Via XX Settembre, 10',
    orarioInizio: '20:00',
    orarioFine: '24:00',
    data: '2024-07-07',
    descrizione:
      "Un'atmosfera di festa e allegria avvolge la spiaggia al calar del sole, mentre l'oceano culla le sue onde dolci sulla riva. La Festa al Tramonto è un evento spettacolare che celebra l'estate, l'amicizia e il divertimento in riva al mare. Le luci colorate adornano le capanne di paglia e i gazebo allestiti sulla sabbia, creando un'atmosfera vibrante e accogliente. Gli ospiti possono godersi una vasta selezione di cocktail tropicali e stuzzichini freschi preparati al momento, mentre i DJ mixano musica dal vivo che fa ballare tutti sulla sabbia. Non mancano poi le attività divertenti come giochi sulla spiaggia, tornei di beach volley e le immancabili nuotate notturne nell'oceano. Con il suono delle onde che si infrangono sulla costa e i colori caldi del tramonto dipingono il cielo, questa festa al mare promette di essere un'esperienza indimenticabile per tutti i partecipanti.",
    costo: '18,00',
    urlBiglietto: 'https://www.eventbrite.it/',
    tipoEvento: 'Gratuito', //gratuito o pagamento, prenotazione obbligatoria, bambini
    prenotazione: true, //prenotazione obbligatoria o accesso libero
    bambini: false, // si/no
    //tipoPartecipanti: "coppia",solo/coppia/compagnia
    solo: true,
    coppia: false,
    compagnia: false,
    settimanale: true,
  }
];
export const imagesSicurezza = [
  {
    src: image3,
    // url: "detail",
    name: 'CleanUpDaY',
    fullNameEvent: 'Clean Up Day',
    localita: 'San Foca',
    indirizzo: 'Via XXIII Settembre, 40',
    orarioInizio: '12:00',
    orarioFine: '15:00',
    data: '2024-07-25',
    descrizione:
      "La Giornata di Pulizia delle Spiagge è un'occasione dedicata alla cura dell'ambiente marino, dove persone di tutte le età si riuniscono per preservare la bellezza naturale della costa. Il profumo salato dell'oceano si mescola con l'odore della sabbia fresca mentre i partecipanti si dedicano con impegno alla rimozione dei rifiuti che minacciano la salute degli ecosistemi marini. Guanti protettivi e sacchetti per la raccolta dei rifiuti sostituiscono le coperte e gli ombrelloni, mentre gli strumenti per la pulizia punteggiano la spiaggia, pronti per essere impiegati con determinazione. Il cestino da picnic viene sostituito da contenitori per il riciclaggio, pronti a ospitare plastica, cartacce e qualsiasi altro tipo di rifiuto. Mentre gli adulti lavorano insieme per ripulire la spiaggia, i più giovani si uniscono con entusiasmo, imparando l'importanza della conservazione ambientale attraverso l'azione diretta. La musica leggera e le risate lasciano spazio al suono delle onde e al canto degli uccelli marini, mentre l'atmosfera di impegno e solidarietà riempie l'aria. Con il sole che splende alto nel cielo e l'acqua cristallina che si estende all'orizzonte, la Giornata di Pulizia delle Spiagge diventa un'opportunità preziosa per prendersi cura del nostro pianeta, lavorando insieme per un futuro più pulito e sostenibile.",
    tipoEvento: 'Gratuito',
    prenotazione: false,
    bambini: false,
    solo: true, // solo/coppia/compagnia
    coppia: false,
    compagnia: false,
    urlBiglietto: 'https://www.eventbrite.it/',
  },
];

export default images;
