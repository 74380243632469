/* eslint-disable react/jsx-no-undef */
import "../home/home.css";
import EventSlideShow from "../events/events_carousel";
import { imagesSicurezza } from "../events/imagesData";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useAppSelector } from "../../redux/store";
import { userLngSelector } from "../../redux/reducers/userSlice";

export default function VisiteEntroterra() {
  const navigate = useNavigate();
  const lang = useAppSelector(userLngSelector);
  return (
    <>
      <EventSlideShow
        caption={
          <h5>
            {lang === "it"
              ? "Visite Guidate Gratuite a Melendugno e Borgagne"
              : "Free Guided Tours in Melendugno and Borgagne "}
          </h5>
        }
        name="eventi"
        images={imagesSicurezza}
      />
      <Button
        color="trasparent"
        onClick={() => {
          navigate(-1);
        }}
        style={{ marginRight: "12px" }}
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
        {t("general.buttons.back")}
      </Button>
      {/* <div className="container pt-2 mt-2 mb-5">
        <h1>Servizio di assistenza sanitaria turistica a Torre dell'Orso e a San Foca</h1>       

Dal 15 giugno riparte il Servizio di Assistenza Sanitaria Turistica a Torre dell’Orso e a San Foca, attivo tutti i giorni dalle ore 8.00 alle ore 20.00.
<br /><br />
➡️ Ambulatorio San Foca - Piazza d’Oriente.<br />
Per informazioni tel. 329.3173692.<br />
<br />
➡️ Ambulatorio Torre dell'Orso - Piazza della Luna.<br />
Per informazioni tel. 327.7189629.<br />

      </div> */}
      {lang === "it" ? (
        <div className="container pt-2 mt-2 mb-5" style={{ textAlign: "center", fontSize: "20px" }}>
          {/* <h1 className="page-title">Entroterra</h1> */}
          Visita guidata gratuita, su prenotazione, degli splendidi borghi di Melendugno e Borgagne.
          <br />
          <br />
          <div className="clearfix">
            <p>
              <strong>Melendugno</strong>
              <br />
              Giovedì - punto di ritrovo: Piazza Pertini
              <br />
              <strong>Borgagne</strong>
              <br />
              Martedì - punto di ritrovo: Piazza sant'Antonio.
              <br />
              <br />
              {/* Dalle ore 19,00 alle 20,00 */} Orario di ritrovo: 18:30
              <br />
              <br />
              Numero minimo di partecipanti : 5
              <br />
              Prenotazioni: +39 3287892521
            </p>
          </div>
        </div>
      ) : (
        <div className="container pt-2 mt-2 mb-5" style={{ textAlign: "center", fontSize: "20px" }}>
          {/* <h1 className="page-title">Entroterra</h1> */}
          Free guided tour, upon reservation, of the beautiful villages of Melendugno and Borgagne.
          <br />
          <br />
          <div className="clearfix">
            <p>
              <strong>Melendugno</strong>
              <br />
              Thursday - meeting point: Piazza Pertini
              <br />
              <strong>Borgagne</strong>
              <br />
              Tuesday - meeting point: Piazza sant'Antonio.
              <br />
              <br />
              {/* Dalle ore 19,00 alle 20,00 */} Meeting time: 18:30
              <br />
              <br />
              Minimum number of participants: 5
              <br />
              Reservations: +39 3287892521
            </p>
          </div>
        </div>
      )}
    </>
  );
}
