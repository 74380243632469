import { t } from 'i18next';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface ModalHandle {
    open: (title: string, body: string | Node, footer: string | Node) => void;
}
const GenericModal = forwardRef<ModalHandle>((props: {}, ref) => {

    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [modalFooter, setModalFooter] = useState('');
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    useImperativeHandle(ref, () => ({
        open(title: string, body: any, footer: any) {
            setModalTitle(title)
            setModalBody(body)
            setModalFooter(footer)
            setModal(true)
        },
    }));

    return (
        <>
            <Modal isOpen={modal} toggle={toggle}  >
                <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                <ModalBody>
                    {modalBody ? modalBody : ''}
                </ModalBody>
                <ModalFooter>
                    {modalFooter ? modalFooter : <Button color="secondary" onClick={toggle}>
                        {t('general.buttons.close')}
                    </Button>}
                </ModalFooter>
            </Modal>
        </>
    );
});

export default GenericModal;