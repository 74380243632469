import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';
import i18n from '../i18n';
import {
  Authorities,
  changeUserLanguage,
  currentUserSelector,
  logout,
  userAccessTokenSelector,
  userLngSelector,
} from '../redux/reducers/userSlice';
import { AppThunkDispatch, useAppSelector } from '../redux/store';
import { useEffect, useState } from 'react';
import {
  faBars,
  faXmark,
  faUser,
  faGauge,
  faBell,
  faUserTie,
  faBuilding,
  faLandmark,
  faList,
  faMapPin,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppMenu from './menu';
import SidebarFooter from './sidebar-footer';
import { checkRequiredAuthorities } from '../components/authority-boundary-view';

export default function AppNavBar() {
  const supportedLanguages = ['it', 'en'];
  const currentUser = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppThunkDispatch;
  const accessToken = useAppSelector(userAccessTokenSelector);
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const initialCollapsed = screenWidth < 1000;
  const [collapsed, setCollapsed] = useState(!initialCollapsed);
  const lang = useAppSelector(userLngSelector);

  useEffect(() => {
    if (i18n.language !== lang) {
      console.log("CHANGING", lang, i18n.language)
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const handleLanguageSelect = (lng: string) => {
    i18n.changeLanguage(lng, () => dispatch(changeUserLanguage(lng)));
  };

  const menuUtente = [
    {
      text: t('sidebar.dashboard'),
      link: '',
      icon: faGauge,
    },
    {
      text: t('sidebar.requests'),
      link: 'facility-record-request',
      icon: faBell,
    },
    {
      text: t('sidebar.solicitors'),
      link: 'solicitor',
      icon: faUserTie,
    },
    {
      text: t('sidebar.facilities'),
      link: 'facility',
      icon: faBuilding,
    },
    {
      text: t('sidebar.companies'),
      link: 'company',
      icon: faLandmark,
    },
    {
      text: t('sidebar.facility-types'),
      link: 'facility-type',
      icon: faList,
    },
    {
      text: t('sidebar.places'),
      link: 'place',
      icon: faMapPin,
    },
  ];

  const handleMenuToggle = () => {
    if(initialCollapsed) setCollapsed(!collapsed);
  };

  return (
    <>
      <Navbar
        light
        expand="lg"
        style={{  position: 'sticky', top: 0, zIndex: 100 }}
        className="border-gradient"
      >
        <NavbarBrand href="/">
          <img
            src="/logo-marinemel_01.svg"
            alt="Visit Melendugno"
          />
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        />
        <Collapse isOpen={collapsed} navbar>
          <FontAwesomeIcon
            className="x-to-close"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            icon={faXmark}
          />
          <AppMenu onMenuItemClick={handleMenuToggle} />
        </Collapse>

        {!accessToken && (
          <UncontrolledDropdown inNavbar direction="start">
            <DropdownToggle color="link" className="text-light">
              <FontAwesomeIcon color="#ffffff" icon={faUser} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => navigate('/user/login')}>
                {t('general.actions.login')}
              </DropdownItem>
              <DropdownItem onClick={() => navigate('/user/register')}>
                {t('general.actions.register')}
              </DropdownItem>
              <DropdownItem onClick={() => navigate('/user/forgot-password')}>
                {t('general.actions.forgot_password')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {/* {accessToken && currentUser?.id !== 88 && (
          <Button color={"primary"} onClick={() => navigate('/facility-record-request/create')}>
            {t('general.actions.registerfacility')}
          </Button>
        )} */}
         <UncontrolledDropdown inNavbar  >
                <DropdownToggle nav caret style={{ color: '#fff' }}
                    color="primary">
                    {lang.toUpperCase()}
                </DropdownToggle>
                <DropdownMenu >
                    <DropdownItem onClick={() => handleLanguageSelect('en')}>EN</DropdownItem>
                    <DropdownItem onClick={() => handleLanguageSelect('it')}>IT</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown >
        {accessToken && (
          <UncontrolledDropdown inNavbar direction="start">
            <DropdownToggle color="link" className="text-light">
              <Avatar
                name={currentUser?.firstName + ' ' + currentUser?.lastName}
                size="30"
                round
              />
            </DropdownToggle>

            
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigate('/user/detail');
                }}
              >
                {t('general.buttons.profile')}
              </DropdownItem>
              {checkRequiredAuthorities([Authorities.MANAGER], currentUser) && (
                <DropdownItem
                  onClick={() => {
                    navigate(`/company/my/detail`);
                  }}
                >
                  {t('general.actions.fiscal_data')}
                </DropdownItem>
              )}
              <DropdownItem
                onClick={() => {
                  dispatch(logout());
                  navigate('/');
                }}
              >
                {t('general.actions.logout')}
              </DropdownItem>

              {checkRequiredAuthorities(
                [Authorities.MUNICIPALITY],
                currentUser
              ) && (
                <>
                  <DropdownItem divider />
                  {menuUtente.map((el: any) => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          navigate('/' + el.link);
                        }}
                      >
                        {el.text}
                      </DropdownItem>
                    );
                  })}
                </>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Navbar>
    </>
  );
}
