import { t } from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { icons } from '../util/form.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { getListExemptionConf } from '../api/exemptionAPI';
import { getUdateMultiOccupancyConf } from '../api/occupancyAPI';
import useRequest from '../hook/use-request.hook';
import { ApiError } from '../model/errors.model';
import { NotificationTypes, useAppUtilContext } from '../util/app-util.context';
import { registerRs } from '../util/form.util';
import { getDateDDMMYYYY } from '../util/date.util';
function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
export interface UpdateOccupancyRecordsModalProps {
  onClose: () => void;
}
export interface UpdateOccupancyRecordsModalHandle {
  open: (facilityId: number, date: Date, datas: any) => void;
}
const UpdateOccupancyRecordsModal = forwardRef<
  UpdateOccupancyRecordsModalHandle,
  UpdateOccupancyRecordsModalProps
>((props: UpdateOccupancyRecordsModalProps, ref) => {
  const { showModal, showNotification } = useAppUtilContext();
  const [facilityId, setFacilityId] = useState<number | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const [datas, setDatas] = useState<any>({});
  const [modal, setModal] = useState(false);
  const [exemptions, setExemptions] = useState<any[]>([]);
  const { loading, fetchData } = useRequest();

  const { loading: loadingExemptions, fetchData: fetchExemptions } =
    useRequest();
  const toggle = () => {
    reset();
    setModal(!modal);
  };
  useImperativeHandle(ref, () => ({
    open(facilityId: number, date: Date, datas: any) {
      console.log('open', facilityId, date, datas);
      let initialdata: any = {};
      for (let i = 0; i < exemptions.length; i++) {
        initialdata['exemption-' + exemptions[i].id] = undefined;
      }
      initialdata = { ...initialdata, ...datas };
      setFacilityId(facilityId);
      setDate(date);
      setDatas(initialdata);
      setModal(true);
    },
  }));

  const onSubmit = (data: any) => {
    console.log('onSubmit', data);

    const keys = Object.keys(data).filter((it) => it.startsWith('exemption-'));

    console.log(keys);
    const toSend: any[] = [];
    if (date) {
      keys.map((it) => {
        let exemptionId = it.split('-')[1];
        console.log(it, data[it]);
        let guests = parseInt(data[it]) ? parseInt(data[it]) : 0;
        // if (guests) {
        toSend.push({
          date: formatDate(date),
          guests: guests,
          exemptionId: parseInt(exemptionId),
          facilityId: facilityId,
        });
        //  }
      });
      let config = getUdateMultiOccupancyConf(toSend);
      if (config) {
        fetchData(config, onSuccess, onErrors);
      }
    }
  };
  useEffect(() => {
    fetchExemptions(
      getListExemptionConf(),
      (data, headers) => {
        if (Array.isArray(data)) {
          setExemptions(data);
        }
      },
      () => {
        console.log('error');
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (data: any) => {
    const { message } = data;
    setModal(false);
    showNotification(message, NotificationTypes.SUCCESS);
    props.onClose();
  };

  const onErrors = (errors: ApiError[]) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };
  let initialData = {};
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialData },
    values: { ...datas },
  });
  console.log('errors', errors);
  return (
    <>
      <Modal size={"lg"}  isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('modal.updateOccupancy.title')}
        </ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <h3>{date ? getDateDDMMYYYY(date) : ''}</h3>
          <div className='d-flex'>
            {exemptions &&
              exemptions.length > 0 &&
              exemptions.map((it) => {
                return (
                  <FormGroup className="form-field" style={{ margin: '0 2px' }} key={it.id}>
                    <Input
                      id={`exemption-${it.id}`}
                      name={`exemption-${it.id}`}
                      type="number"
                      step="1"
                      {...registerRs(register, `exemption-${it.id}`, {
                        min: {
                          value: datas['stay-exemption-' + it.id]
                            ? datas['stay-exemption-' + it.id]
                            : 0,
                          message:
                            'Il valore deve essere maggiore o uguale a ' +
                            (datas['stay-exemption-' + it.id]
                              ? datas['stay-exemption-' + it.id]
                              : 0),
                        },
                      })}
                    />
                    <Label  className="number" for={`exemption-${it.id}`}><FontAwesomeIcon icon={icons.get(it.icon)} />{" "}{it.name}</Label>
                    <FormFeedback
                      style={{
                        display: errors['exemption-' + it.id]
                          ? 'block'
                          : 'none',
                      }}
                    >
                      {'' + errors['exemption-' + it.id]?.message}
                    </FormFeedback>
                  </FormGroup>
                );
              })}</div>  
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              {t('general.buttons.close')}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {!loading ? (
                t('action.send')
              ) : (
                <>
                  <Spinner size="sm"> Loading...</Spinner>
                  <span> {t('action.loading')}</span>
                </>
              )}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
});

export default UpdateOccupancyRecordsModal;
