import { faArrowLeft, faImage, faPencil } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { getDetailSolicitorConf } from '../../api/solicitorAPI';
import { SERVER_API_URL } from '../../axios/config';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import AddImageModal from '../../modal/add-image.modal';
import { ApiError } from '../../model/errors.model';
import { DetailSolicitor } from '../../model/solicitor.model';
import { useAppUtilContext } from '../../util/app-util.context';
import {
  SolicitorFacilitiesTable,
  SolicitorFacilitiesTableHandle,
} from './solicitor-facility-table';
import { SolicitorForm } from './solicitor.form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SolicitorDetailPage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData: fetchDataDetail } = useRequest<DetailSolicitor>();
  const addImageModalRef = useRef<any>(null);
  const [entity, setEntity] = useState<DetailSolicitor>();
  const solicitorFacilitiesTableRef =
    useRef<SolicitorFacilitiesTableHandle>(null);
  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailSolicitorConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const onSuccessDetail = (data: any) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: any) => {
    let message = data.message;
    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };
  const [activeTab, setActiveTab] = useState('1');

  const onErrors = (errors: any) => {
    let errorsView = (
      <ul>
        {errors.map((it: any, idx: number) => (
          <li key={idx}>{it.message}</li>
        ))}
      </ul>
    );
    showModal(t('modal.request_errors'), errorsView, null);
  };
console.log(entity,"entity")
  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/solicitor'}>{t('solicitor.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('solicitor.detail.title')}</BreadcrumbItem>
      </Breadcrumb>
      <div> 
        <Button
          color="trasparent"
          onClick={() => {
            navigate(-1);
          }}
          style={{ marginRight: '12px' }}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
          {t('general.buttons.back')}
        </Button>
      </div>
      <div className="header-container">        
        <h2>{t('solicitor.detail.title')}</h2>
        <div>
          <MobileButton
            onClick={() => addImageModalRef.current?.open(entity?.id)}
            color="success"
            style={{ marginRight: '5px' }}
            icon={faImage}
            text={t('general.buttons.addimage')}
          />
          <MobileButton
            onClick={() => navigate(`/solicitor/${id}/update`)}
            color="success"
            icon={faPencil}
            text={t('general.buttons.edit')}
          />
        </div>
      </div>
      <Card className="mt-2">
        {entity?.images && entity?.images.length > 0 ? (
          <img
            className="w-100"
            src={
              entity?.images && entity?.images.length > 0
                ? SERVER_API_URL + '/' + entity.images[0].url
                : ''
            }
          />
        ) : (
          ''
        )}
        <CardBody>       
          <SolicitorForm
            initialData={entity}
            enabled={false}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></SolicitorForm>
        </CardBody>
      </Card>
      <div className="mt-2">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              {t('solicitor.facilities')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="header-container mt-2">
              <h3>{t('solicitor.facilities')}</h3>
            </div>
            <SolicitorFacilitiesTable
              ref={solicitorFacilitiesTableRef}
              solicitor={entity}
            ></SolicitorFacilitiesTable>
          </TabPane>
        </TabContent>
        <AddImageModal
          single
          refresh={() => {
            fetchDataDetail(
              getDetailSolicitorConf(id ? id.toString() : ''),
              onSuccessDetail,
              onErrorsDetail
            );
          }}
          list={entity?.images}
          ref={addImageModalRef}
          type="solicitor"
          onClose={() => console.log('close')}
        />
      </div>
    </div>
  );
}
