import { Route } from 'react-router-dom';
import ErrorBoundaryRoutes from '../../shared/error-boundary-routes';
import PageNotFound from '../../shared/page-not-found';
import QRcodeDetailPage from './qrcode-detail.page';
import QrReader from './qrcode-reader.page';

const QrcodeRoutes = () => {
  return (
    <ErrorBoundaryRoutes>
      <>
      <Route path="" element={<QRcodeDetailPage />} />
      <Route path="/check" element={<QrReader />} />
        {/* <Route path=":id" element={<SolicitorDetailPage />} />
        <Route path=":id/update" element={<SolicitorUpdatePage />} />
        <Route path="create" element={<SolicitorPageCreate />} /> */}
      </>

      <Route path="*" element={<PageNotFound />} />
    </ErrorBoundaryRoutes>
  );
};

export default QrcodeRoutes;
