import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { useAppUtilContext } from '../../util/app-util.context';

import { ApiError } from '../../model/errors.model';
import { ErrorsView } from '../../components/errors.view';
import PlaceForm from './place.form';

export default function PlaceCreatePage() {
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();

  const onSubmitSuccess = (data: any) => {
    let message = data.message ? data.message : 'La struttura è stata creata';

    showModal(t('modal.request_success'), message, null);
    navigate('/place');
  };

  const onSubmitError = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/place'}>{t('place.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{t('general.buttons.add')}</BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('place.create.title')}</h2>
      </div>
      <Card>
        <CardBody>
          <PlaceForm
            enabled={true}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitError={onSubmitError}
          ></PlaceForm>
        </CardBody>
      </Card>
    </div>
  );
}
