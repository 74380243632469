/* eslint-disable jsx-a11y/anchor-is-valid */
import { t } from 'i18next';
import { forwardRef } from 'react';
import 'react-form-wizard-component/dist/style.css';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
} from 'reactstrap';


export interface StayCreatePageHandle {}

export const StayCreateAnonPage = forwardRef<StayCreatePageHandle>(
  (props: {}, ref) => {
    const navigate = useNavigate();

    return (
      <div className="container mt-2 get-in-touch">
        <div className="header-container">
          <h2 className="page-title">{t("staycreate.nameStep1")}</h2>
        </div>
        <div className="card-body">
          <div className="react-form-wizard">
            <div className="wizard-navigation">
              <div className="wizard-progress-with-circle">
                <div
                  className="wizard-progress-bar"
                ></div>
              </div>

              <ul
                className="form-wizard-steps  wizard-nav wizard-nav-pills  md"
              >
                <li className={'current active'}>
                  <a
                    style={{ alignItems: 'flex-start' }}
                    className={'active'}
                  >
                    <div className="wizard-icon-circle md checked " role="tab">
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-user">1</i>
                        </span>
                      </div>
                    </div>
                    <span className="stepTitle active">{t("staycreate.register")}</span>
                  </a>
                </li>
                <li
                  
                >
                  <a
                  
                  >
                    <div
                      className="wizard-icon-circle md checked"
                      //  style="border-color: rgb(33, 150, 243);"
                    >
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-check">2</i>
                        </span>
                      </div>
                    </div>
                    <span
                      className="stepTitle "
                      // style="margin-top: 8px; color: rgb(33, 150, 243);"
                    >
                     {t("staycreate.names")}
                    </span>
                  </a>
                </li>
                <li
                >
                  <a>
                    <div className="wizard-icon-circle md checked">
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-check">3</i>
                        </span>
                      </div>
                    </div>
                    <span className="stepTitle ">{t("staycreate.summary")}</span>
                  </a>
                </li>
                <li>
                  <a style={{ alignItems: 'flex-end' }}>
                    <div className="wizard-icon-circle md checked">
                      <div className="wizard-icon-container ">
                        <span className="wizard-icon">
                          <i className="ti-check">4</i>
                        </span>
                      </div>
                    </div>
                    <span className="stepTitle ">{t("staycreate.outcome")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      
                <Alert style={{ textAlign: 'center' }} color="warning">
                  <h4 className="alert-heading">{t("staycreate.registerstay")}</h4>
                  <hr />
                  <div className="d-flex justify-content-around align-items-stretch">
                    <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          margin: '2px'
                    }}>
                      <span>{t("staycreate.alreadyreg")}</span>
                  
                      <Button
                        className="mt-3"
                        type="button"
                        onClick={(e) => {
                          navigate('/user/login');
                        }}
                        color="primary"
                      >
                        {t("staycreate.signin")}
                      </Button>
                    </div>
                    <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          margin: '2px'
                    }}>
                    <span>{t("staycreate.newuser")}</span>
                   
                      <Button
                        className="mt-3"
                        type="button"
                        onClick={(e) => {
                          navigate('/user/register');
                        }}
                        color="primary"
                      >
                        {t("staycreate.signup")}
                      </Button>
                    </div>
                  </div>
                </Alert>
     
      </div>
    );
  }
);
