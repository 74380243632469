import { t } from 'i18next';
import { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import useRequest from '../hook/use-request.hook';
import { ApiError } from '../model/errors.model';
import { DetailSolicitor } from '../model/solicitor.model';
import { NotificationTypes, useAppUtilContext } from '../util/app-util.context';
import { ErrorsView } from '../components/errors.view';
import { getDeleteFacilitySolicitorConf } from '../api/facilitySolicitorAPI';
import { DetailFacility } from '../model/facility.model';

export interface RemoveFacilitySolicitorModalProps {
  onClose: () => void;
}
export interface RemoveFacilitySolicitorModalHandle {
  open: (facility: DetailFacility, solicitor: DetailSolicitor) => void;
}
const RemoveSolicitorModal = forwardRef<
  RemoveFacilitySolicitorModalHandle,
  RemoveFacilitySolicitorModalProps
>((props: RemoveFacilitySolicitorModalProps, ref) => {
  const { showModal, showNotification } = useAppUtilContext();
  const [facility, setFacility] = useState<DetailFacility | null>(null);
  const [solicitor, setSolecitor] = useState<DetailSolicitor | null>(null);
  const [modal, setModal] = useState(false);

  const { loading, fetchData } = useRequest();
  const toggle = () => setModal(!modal);
  useImperativeHandle(ref, () => ({
    open(facility: DetailFacility, solicitor: DetailSolicitor) {
      setFacility(facility);
      setSolecitor(solicitor);
      setModal(true);
    },
  }));
  const onSubmit = () => {
    console.log('onSubmit');
    if (facility && solicitor) {
      const config = getDeleteFacilitySolicitorConf(facility.id, solicitor.id);
      fetchData(config, onSuccess, onErrors);
    }
  };

  const onSuccess = (data: any) => {
    const { message } = data;
    setModal(false);
    showNotification(message, NotificationTypes.SUCCESS);
    props.onClose();
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('modal.removeFacilitySolicitor.title')}
        </ModalHeader>
        <ModalBody>
          {t('modal.removeFacilitySolicitor.message', {
            solicitor: `${solicitor?.firstName} ${solicitor?.lastName} `,
            facility: `${facility?.name}  `,
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {t('general.buttons.close')}
          </Button>
          <Button onClick={onSubmit} disabled={loading}>
            {!loading ? (
              t('action.send')
            ) : (
              <>
                <Spinner size="sm"> Loading...</Spinner>
                <span> {t('action.loading')}</span>
              </>
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default RemoveSolicitorModal;
