import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Card, CardBody } from 'reactstrap';
import { getDetailFacilityTypeConf } from '../../api/facilityTypeAPI';
import { ErrorsView } from '../../components/errors.view';
import MobileButton from '../../components/mobile-button';
import useRequest from '../../hook/use-request.hook';
import { ApiError, ApiSuccess } from '../../model/errors.model';
import { DetailFacilityType } from '../../model/facility-type.model';
import { useAppUtilContext } from '../../util/app-util.context';
import FacilityTypeForm from './facility_type.form';

export default function FacilityTypeDetailPage() {
  let { id } = useParams();
  const { showModal } = useAppUtilContext();
  const navigate = useNavigate();
  const { fetchData: fetchDataDetail } = useRequest<DetailFacilityType>();
  const [entity, setEntity] = useState<DetailFacilityType>();
  useEffect(() => {
    if (id) {
      fetchDataDetail(
        getDetailFacilityTypeConf(id),
        onSuccessDetail,
        onErrorsDetail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSuccessDetail = (data: DetailFacilityType) => {
    setEntity(data);
  };

  const onErrorsDetail = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  const onSuccess = (data: ApiSuccess) => {
    console.log('data', data);
    let message = data.message ? data.message : 'Il tipo è stata modificata';

    showModal(t('modal.request_success'), message, null);
    navigate('/');
  };

  const onErrors = (errors: ApiError[]) => {
    showModal(
      t('modal.request_errors'),
      <ErrorsView errors={errors}></ErrorsView>,
      null
    );
  };

  return (
    <div className="container mt-2">
      <Breadcrumb>
        <BreadcrumbItem color="link">
          <Link to={'/facility-type'}>{t('facility-type.list.title')}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t('facility-type.detail.title')}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="header-container">
        <h2>{t('facility-type.detail.title')}</h2>
        <div>
          <MobileButton
            onClick={() => navigate(`/facility-type/${id}/update`)}
            color="success"
            icon={faPencil}
            text={t('general.buttons.edit')}
          />
        </div>
      </div>
      <Card className="mt-2">
        <CardBody>
          <FacilityTypeForm
            initialData={entity}
            enabled={false}
            onSubmitSuccess={onSuccess}
            onSubmitError={onErrors}
          ></FacilityTypeForm>
        </CardBody>
      </Card>
    </div>
  );
}
