/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import EventSlideShow from "./events_carousel";
import "./events_page.css";
import EventsItem from "./events_Item";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCalendar, faPlus } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import EventsFooter from "./footerEvents";
import images from "./imagesData";
import useRequest from "../../hook/use-request.hook";
import { getListEvent } from "../../api/eventsApi";
import { Authorities, currentUserSelector } from "../../redux/reducers/userSlice";
import { useAppSelector } from "../../redux/store";
import { checkRequiredAuthorities } from "../../components/authority-boundary-view";
import MobileButton from "../../components/mobile-button";
import { getDateYYYYMMDD, getMondayOfWeek, getWeekNumber } from "../../util/date.util";
import { useTranslation } from "react-i18next";
const EventsPageMain = () => {
  const navigate = useNavigate();
  const [eventsData, setEventsData] = useState([]);
  const [startDate, setStartDate] = useState<Date|null>(null);
  const [noEvent, setNoEvent] = useState<boolean>(false);
  const { loading, fetchData } = useRequest();
  const currentUser = useAppSelector(currentUserSelector);
  const { t } = useTranslation();

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setStartDate(today)
  }, []);

  useEffect(() => {
    console.log("startDate", startDate)
    setNoEvent(false)
    if(startDate !== null){
        fetchData(getListEvent(getDateYYYYMMDD(startDate), null), onSuccess, onErrors);
    } else {
        fetchData(getListEvent(null, null), onSuccess, onErrors);
      }
  }, [startDate]);

  const onSuccess = (data: any) => {
    if(data.length == eventsData.length)
      setNoEvent(true)

      setEventsData(data);
  };
  const onErrors = (data: any) => {
    // setEventsData(data);
  };

  const setStart = () => {
    const weekNum = getWeekNumber(startDate) -1
   // console.log("nu--", getMondayOfWeek(startDate? startDate.getFullYear() : 2024, weekNum-1))

    let lunedi = getMondayOfWeek(startDate? startDate.getFullYear() : 2024, weekNum -1)
    setStartDate(lunedi)
  };

  const create = (data: any) => {
    navigate('create');
  };

  // per il momento sono inseriti solo quelli settimanali (formato immagine fullHd horizontal oriented)
  const carouselImages = images.filter((im: any) => im.settimanale);

  return (
    <div className="events-page-main">
      <div className="events-page-main-home">{/* <h1 className="font-bold text-center  text-2xl">Eventi</h1>   */}</div>

      <div style={{ marginBottom: '2.5rem' }}>
        <div>
          <EventSlideShow caption={<><h5>{t("general.sliderteaser")}</h5>
               <p>{t("general.slidersubteaser")}</p></>}name="eventi" images={carouselImages} />
        </div>
        <div className="container mt-2 d-flex justify-content-between align-items-center">
          <Button
            color="trasparent"
            onClick={() => {
              navigate(-1);
            }}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
            {t("general.buttons.back")}
          </Button>
    
          <Button
            color="primary"
            onClick={setStart}>
            <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "8px" }} />
          {t("action.loadmore")}
          </Button>
               
      {currentUser &&
          checkRequiredAuthorities(
            [Authorities.ADMIN, Authorities.MUNICIPALITY, Authorities.OPERATOR],
            currentUser
          ) && (
          
            <MobileButton
              onClick={create}
              disabled={loading}
              color="primary"
              icon={faPlus}
              text={t('general.buttons.add')}
              className="ml-auto mr-2"
            />
         
          )}
          </div>
      </div>
      <div>
        {loading && <div className="container" style={{ textAlign: 'center'  }}><Alert color="info">
          <Spinner size="sm"> Loading </Spinner>
          <span> {t('action.loading')}</span></Alert>
        </div>}
        {noEvent && <div className="container" style={{ textAlign: 'center' }}><Alert color="warning">
          <span> {t('action.noevent')}</span></Alert>
        </div>}
        <EventsItem events={eventsData} anno={startDate? startDate.getFullYear() : 2024} />
      </div>

      {/* <div>
        <EventsFooter />
      </div> */}
    </div>
  );
};
export default EventsPageMain;
